import AddIcon from '@mui/icons-material/Add'
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material'

import RemoveIcon from '@mui/icons-material/Remove'

const timeSelectorStyles = {
    display: 'flex',
    '& .MuiInputBase-input': {
        textAlign: 'center',
    },
}
interface CustomTimeSelectorProps {
    setTime: (time: number) => void
    setIncrement: (increment: number) => void
    time: number
    increment: number
}

export const CustomTimeSelector = (props: CustomTimeSelectorProps) => {
    const { setTime, setIncrement, time, increment } = props
    const handleTimeChange = (value: any) => {
        const numberValue = Number(value)
        if (isNaN(numberValue) || numberValue < 1) {
            return ''
        }
        const clampedValue = Math.min(numberValue, 180)
        if (clampedValue.toString() !== value.toString()) {
            return clampedValue.toString()
        }
        return value
    }
    const handleSetTime = (value: any) => {
        if (value < 1 || value > 180) {
            return
        }
        setTime(value)
    }

    const handleSetIncrement = (value: any) => {
        if (value < 0 || value > 180) {
            return
        }
        setIncrement(value)
    }

    return (
        <Box>
            <Typography variant="h6" sx={{ paddingBottom: '1rem' }}>
                Time control
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} gap={4}>
                <TextField
                    label="Minutes"
                    variant="standard"
                    sx={timeSelectorStyles}
                    value={time}
                    fullWidth
                    onChange={(e) => setTime(Number(handleTimeChange(e.target.value)))}
                    InputLabelProps={{
                        sx: { fontSize: '20px', fontFamily: 'Space Grotesk' },
                    }}
                    inputProps={{ maxLength: 4 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    sx={{
                                        borderRadius: '0',

                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                    onClick={() => {
                                        handleSetTime(time - 1)
                                    }}
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {
                                        handleSetTime(time + 1)
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    label="Increment"
                    variant="standard"
                    sx={timeSelectorStyles}
                    value={increment}
                    onChange={(e) => setIncrement(Number(handleTimeChange(e.target.value)))}
                    fullWidth
                    inputProps={{ maxLength: 4 }}
                    InputLabelProps={{
                        sx: { fontSize: '20px', fontFamily: 'Space Grotesk' },
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    sx={{
                                        borderRadius: '0',

                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                    onClick={() => {
                                        handleSetIncrement(increment - 1)
                                    }}
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {
                                        handleSetIncrement(increment + 1)
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    )
}
