import { useCallback } from 'react'
import useSound from 'use-sound'
import { Settings } from '../../../sharedComponents/src/globalHeader/GlobalHeader'

const useResultSounds = (settings: Settings) => {
    // Result sounds
    const failedSoundURL = `/assets/sounds/${settings.soundStyle}/GameEndDraworLoss.mp3`
    const [playFail] = useSound(failedSoundURL, { volume: 0.25 })
    const resolvedSoundURL = `/assets/sounds/${settings.soundStyle}/Resolved.mp3`
    const [playResolve] = useSound(resolvedSoundURL, { volume: 0.25 })

    const playResultSound = useCallback(
        (result: boolean) => {
            if (!settings.soundOn) return

            if (result) {
                console.log('playResolve')
                playResolve()
            } else {
                console.log('playFail')
                playFail()
            }
        },
        [settings, playResolve, playFail],
    )

    return playResultSound
}

export default useResultSounds
