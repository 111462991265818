import { Box, Button, Dialog, DialogContent, Paper, Stack, Typography } from '@mui/material'
import { colors } from '../sharedComponents/src/globalHeader/theme/colors'
const mainMenuStyles = {
    '& .MuiDialog-paperScrollPaper': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '@media (max-width: 600px)': {
            display: 'grid',
        },
    },
}
const dialogStyles = {
    position: 'relative',
    height: 'auto',
    overflow: 'hidden',
    marginRight: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
}

const boxStyles = {
    margin: '0',
    backgroundColor: colors.darkBlue,
    justifySelf: 'center',
    alignSelf: 'center',
    height: 'auto',
    width: '100%',
    border: `0.313rem solid ${colors.white}`,
    borderRadius: '0rem',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
}

const titleStyles = {
    fontSize: '55px',
    lineHeight: '100%',
    textAlign: 'center',
    marginBottom: '48px',
}

const containerStyles = {
    width: '100%',
    flexGrow: 1,
    padding: 4,
    marginTop: '-118px',
}

export interface MainMenuProps {
    onPlayClick: () => void
    onWatchClick: () => void
    onAnalysisClick: () => void
    dialog: boolean
}

export const MainMenu = (props: MainMenuProps) => {
    const { onPlayClick, onWatchClick, onAnalysisClick, dialog } = props

    const MainMenu = (
        <Paper style={boxStyles}>
            <Box sx={containerStyles}>
                <Typography variant="h1" sx={titleStyles}>
                    Main Menu
                </Typography>
                <Stack direction="column" alignItems="center" spacing={2}>
                    <Button onClick={onPlayClick} variant="contained">
                        Play
                    </Button>
                    <Button onClick={onWatchClick} variant="outlined">
                        Watch
                    </Button>
                    <Button onClick={onAnalysisClick} variant="outlined">
                        Analysis
                    </Button>
                </Stack>
            </Box>
        </Paper>
    )

    return (
        <>
            {dialog ? (
                <Dialog
                    open={true}
                    BackdropProps={{
                        onClick: undefined,
                    }}
                    disableEnforceFocus
                    sx={mainMenuStyles}
                >
                    <DialogContent sx={dialogStyles}>{MainMenu}</DialogContent>
                </Dialog>
            ) : (
                MainMenu
            )}
        </>
    )
}
