import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useStoreActions, useStoreState } from '../../../../store/hooks'

const marks = [
    {
        value: 1,
        label: '1',
        rating: '500-800',
    },
    {
        value: 2,
        label: '2',
        rating: '800-1100',
    },
    {
        value: 3,
        label: '3',
        rating: '1100-1400',
    },
    {
        value: 4,
        label: '4',
        rating: '1400-1700',
    },
    {
        value: 5,
        label: '5',
        rating: '1700-2000',
    },
    {
        value: 6,
        label: '6',
        rating: '2000-2300',
    },
    {
        value: 7,
        label: '7',
        rating: '2300-2600',
    },
    {
        value: 8,
        label: '8',
        rating: '2600-2900',
    },
    {
        value: 9,
        label: '9',
        rating: '2900-3200',
    },
    {
        value: 10,
        label: '10',
        rating: '3200-3500',
    },
]

const StrengthSelector: React.FC = () => {
    const settings = useStoreState((state) => state.gameView.settings)
    const searchOptions = useStoreState((state) => state.matchMaker.searchOptions.computerChallenge)
    const fullSearchOptions = useStoreState((state) => state.matchMaker.searchOptions)

    const setSearchOptions = useStoreActions((state) => state.matchMaker.setSearchOptions)

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setSearchOptions({
            ...fullSearchOptions,
            computerChallenge: {
                ...searchOptions,
                strength: newValue as number,
            },
        })
    }

    return (
        <Stack>
            <Stack direction="row" justifyContent="space-between" sx={{ flexGrow: 1 }}>
                <Typography variant="h6">Strength</Typography>
                {!settings.friendlyMode && (
                    <Typography variant="h6" ml={1} color="secondary" textAlign="right">
                        {marks[searchOptions.strength - 1]?.rating || ''}
                    </Typography>
                )}
            </Stack>
            <Slider
                aria-label="Playing Strength Slider"
                value={searchOptions.strength}
                step={1}
                marks={marks}
                min={1}
                max={10}
                valueLabelDisplay="off"
                sx={{ width: '95%', color: 'text.primary', alignSelf: 'center' }}
                onChange={handleSliderChange}
            />
        </Stack>
    )
}

StrengthSelector.displayName = 'StrengthSelector'

export default StrengthSelector
