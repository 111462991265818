import { Typography } from '@mui/material'
import { colors } from '../../../sharedComponents/src/globalHeader/theme/colors'

const evaluationStyles = (isWhiteWinning: boolean) => ({
    height: '30px',
    lineHeight: '26px',
    padding: '0 0.313rem',
    marginRight: '0.313rem',
    color: isWhiteWinning ? colors.black : colors.white,
    backgroundColor: isWhiteWinning ? colors.white : 'background.default',
    borderRadius: '5px',
})

type Props = {
    score: number
    scoreType: string
}

export const Evaluation = ({ score, scoreType }: Props) => {
    const isWhiteWinning = score >= 0

    const scoreString =
        scoreType === 'mate'
            ? `#${score !== 0 ? Math.abs(score) : ''}`
            : scoreType === 'cp' && score !== 0
              ? `${isWhiteWinning ? '+' : '-'}${Math.abs(score / 100)}`
              : '0'

    return (
        <Typography
            variant="body2"
            sx={(theme) => ({
                ...evaluationStyles(isWhiteWinning),
                border: `0.125rem solid ${theme.palette.primary.main}`,
            })}
            component={'span'}
        >
            {scoreString}
        </Typography>
    )
}
