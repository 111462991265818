import { Box, Button, Dialog, DialogContent, DialogProps, Typography } from '@mui/material'
import { FC } from 'react'

interface DialogScreen {
    header: string
    description: string
    handleClose: () => any
}

interface NotificationDialog extends DialogProps, DialogScreen {}

export const NotificationDialog: FC<NotificationDialog> = ({ children, open, header, description, handleClose }) => {
    return (
        <Dialog open={open && Boolean(description)} onClose={handleClose}>
            <DialogContent sx={{ padding: '1rem', border: '4px solid white', maxWidth: '600px' }}>
                <Box gap="2rem" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <Typography variant="h4" color="secondary" mb="-1rem">
                        {header}
                    </Typography>
                    <Typography variant="body1" align="center">
                        {description}
                    </Typography>
                    {children}
                    <Button onClick={handleClose}>Ok</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
