import React from 'react'
import { Piece } from '../../react-chessboard/src/chessboard/types'

import { BoardPosition } from '../../react-chessboard/src/chessboard/types'
import { SetupChessBoard } from './components/setupChessBoard/SetupChessBoard'

type Props = {
    fen: string
    flipped: boolean
    onUpdatePosition: (currentPosition: BoardPosition) => void
    deleteModeOn: boolean
    selectedPiece: Piece | undefined
    selectModeOn: boolean

    layout: string
}

const BoardSetupBoardContent: React.FC<Props> = ({
    fen,
    flipped,
    onUpdatePosition,
    deleteModeOn,
    selectedPiece,
    selectModeOn,
    layout,
}) => {
    return (
        <SetupChessBoard
            fen={fen}
            flipped={flipped}
            updatePosition={onUpdatePosition}
            deleteModeOn={deleteModeOn}
            selectedPiece={selectedPiece}
            selectModeOn={selectModeOn}
        />
    )
}

export default BoardSetupBoardContent
