const SettingsIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.8193 17.9818L20.3841 15.9745L21.8375 17.9818C22.2034 18.3326 22.8487 19.5144 22.9497 20.0011C23.0507 20.4878 22.9989 20.9924 22.8009 21.4509C22.603 21.9094 22.2677 22.3013 21.8375 22.5771C21.4073 22.8528 20.9015 23 20.3841 23C19.8667 23 19.3609 22.8528 18.9307 22.5771C18.5005 22.3013 18.1652 21.9094 17.9672 21.4509C17.7692 20.9924 17.7175 20.4878 17.8185 20.0011C17.9195 19.5144 18.4534 18.3326 18.8193 17.9818ZM7.70471 1L19.5433 12.3543C19.6406 12.4475 19.7178 12.5582 19.7704 12.68C19.8231 12.8019 19.8502 12.9325 19.8502 13.0644C19.8502 13.1962 19.8231 13.3268 19.7704 13.4487C19.7178 13.5705 19.6406 13.6812 19.5433 13.7744L10.6649 22.2904C10.4687 22.4785 10.2026 22.5842 9.9251 22.5842C9.64764 22.5842 9.38154 22.4785 9.18532 22.2904L0.306886 13.7744C0.209598 13.6812 0.132419 13.5705 0.0797615 13.4487C0.0271037 13.3268 0 13.1962 0 13.0644C0 12.9325 0.0271037 12.8019 0.0797615 12.68C0.132419 12.5582 0.209598 12.4475 0.306886 12.3543L8.44554 4.5479L6.22514 2.41916L7.70471 1ZM9.9251 5.96806L2.52623 13.0639C8.86556 11.8449 12.0386 11.9899 17.3229 13.0639L9.9251 5.96806Z"
            fill="white"
        />
    </svg>
)

SettingsIcon.displayName = 'SettingsIcon'

export default SettingsIcon
