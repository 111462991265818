const ShopIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.061 4.18203C10.4443 3.79489 10.9327 3.53117 11.4646 3.42424C11.9964 3.3173 12.5478 3.37195 13.0488 3.58128C13.5499 3.7906 13.9782 4.14519 14.2795 4.60019C14.5808 5.05519 14.7416 5.59016 14.7416 6.1374V7.18543H9.25895V6.1374C9.25895 5.40394 9.54718 4.69893 10.061 4.18203ZM6.90925 7.18543V6.1374C6.90925 4.77488 7.44562 3.46816 8.40037 2.50471C9.35513 1.54126 10.65 1 12.0003 1C13.3505 1 14.6454 1.54126 15.6002 2.50471C16.5549 3.46816 17.0913 4.77488 17.0913 6.1374V7.18543H19.8326C20.0252 7.18537 20.211 7.25691 20.3546 7.38638C20.4983 7.51585 20.5896 7.69417 20.6111 7.88728L21.9379 19.8946L21.9505 20.0052C21.9849 20.2629 22.0398 20.6865 21.9552 21.0959C21.8651 21.5237 21.6609 21.9186 21.3647 22.2378C21.0684 22.5571 20.6914 22.7886 20.2743 22.9075C19.8906 23.0149 19.4551 23.0023 19.1904 22.996L19.0494 22.9928H4.95117C4.912 22.9928 4.86501 22.9928 4.81018 22.996C4.54388 23.0023 4.10841 23.0149 3.72619 22.9075C2.87716 22.6703 2.22395 21.959 2.04537 21.0959C1.96078 20.6865 2.01404 20.2629 2.0485 20.0052L2.0626 19.8946L3.3894 7.88728C3.41097 7.69417 3.50228 7.51585 3.64589 7.38638C3.78951 7.25691 3.97536 7.18537 4.16793 7.18543H6.90925Z"
            fill="white"
        />
    </svg>
)

ShopIcon.displayName = 'ShopIcon'

export default ShopIcon
