import { useTheme } from '@mui/material'
import { Color } from '../../../../../../chess/types'

type Props = {
    color: Color
    style?: React.CSSProperties
}

export const ColorIndicator = ({ color, style }: Props) => {
    const { palette } = useTheme()

    return (
        <div
            style={{
                width: '1.5rem',
                height: '1.5rem',
                borderRadius: '50%',
                border: `1px solid ${palette.text.primary}`,
                backgroundColor: color === Color.White ? '#FFFFFF' : '#000000',
                ...style,
            }}
        />
    )
}
