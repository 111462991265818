import { Box } from '@mui/material'
import React from 'react'

import * as utils from './utils'

type Props = {
    evalScore: number
    mate?: boolean
    colorToMove: number
}

const HorizontalEvalBar: React.FC<Props> = ({ evalScore, mate, colorToMove }) => {
    const whitePercentage = utils.calculateWhitePercentage(evalScore, colorToMove, mate)

    return (
        <Box
            sx={(theme) => ({
                position: 'absolute',
                left: '-2px',
                right: '-2px',
                height: '14px',
                bottom: '-14px',
                border: `2px solid ${theme.palette.primary.light} !important`,
                borderColor: 'primary.light',
                display: 'flex',
                flexDirection: 'column',

                borderRadius: '0rem',
                overflow: 'hidden',
                animation: '1s ease-in-out infinite alternate',
                margin: '0',
            })}
        >
            <Box
                style={{
                    width: whitePercentage + '%',
                    height: '100%',
                    backgroundColor: 'white',
                    transition: '0.5s ease-in-out',
                }}
            />
            <Box
                style={{
                    flex: '1',
                    height: '100%',
                    backgroundColor: 'black',
                    transition: '0.5s ease-in-out',
                }}
            />
        </Box>
    )
}

export default HorizontalEvalBar
