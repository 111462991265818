import { Box, Slide, Typography } from '@mui/material'
import React from 'react'
import { TransitionGroup } from 'react-transition-group'
import { ChallengeEntry, MMChallengeType } from '../../store/types'
import { ChallengeCard, RatingType } from './ChallengeCard'

export enum ChallengeVariant {
    MY_CHALLENGES = 'My Challenges',
    OPEN_CHALLENGES = 'Open Challenges',
    DIRECT_CHALLENGES = 'Direct Challenges',
    VIEW_CHALLENGE = 'View Challenge',
}

export interface ChallengesProps {
    variant: ChallengeVariant
    challenges: ChallengeEntry[]
    handleAction: (challenge: ChallengeEntry) => void
}

export const Challenges: React.FC<ChallengesProps> = ({ variant, challenges, handleAction }) => {
    const challengeAction = (event, challenge: ChallengeEntry) => {
        event.preventDefault()
        handleAction(challenge)
    }

    const modifyPlayerInfo = (challenge: ChallengeEntry) => {
        if (variant === ChallengeVariant.MY_CHALLENGES && challenge.challengeType === MMChallengeType.CUSTOM) {
            const rating = challenge.ratingRange
                ? challenge.ratingRange.from === 0 && challenge.ratingRange.to === 9999
                    ? 'Any'
                    : `${challenge.ratingRange.from}-${challenge.ratingRange.to}`
                : 0

            return {
                ...challenge,
                challenger: {
                    ...challenge.challenger,
                    username: 'Any Opponent',
                    avatarUrl: '/random-user-avatar.svg',
                    rating,
                    is_provisional: false,
                    nationality: undefined!,
                },
            }
        } else {
            return challenge
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {Boolean(challenges.length) && <Typography variant="h5">{variant}</Typography>}
            <TransitionGroup style={{ display: 'flex', flexDirection: 'column' }}>
                {challenges.map((challenge) => {
                    return (
                        <Slide key={challenge.challengeId} direction="left">
                            {/* `div` is hack for TransitionGroup */}
                            <div>
                                <ChallengeCard
                                    variant={variant}
                                    ratingType={challenge.timeMode.name as RatingType}
                                    type={variant === ChallengeVariant.MY_CHALLENGES ? 'invite' : 'challenge'}
                                    challenge={challenge}
                                    player={{
                                        ...modifyPlayerInfo(challenge).challenger,
                                        color: challenge.challengerColor,
                                    }}
                                    handleAction={(event) => challengeAction(event, challenge)}
                                />
                            </div>
                        </Slide>
                    )
                })}
            </TransitionGroup>
        </Box>
    )
}
