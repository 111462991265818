import { Layout } from '../../../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreState } from '../../../../store/hooks'
import { GameData, GameResult, LiveGameData } from '../../../../store/types'
import { GameOverDialog } from '../../../gameView/components/gameOverDialog/GameOverDialog'

type Props = {
    onAnalysis: () => void
    result: GameResult
    layout: Layout
    liveGameData: LiveGameData
    open: boolean
    onClose: () => void
}

export const ObserverGameOverDialog = ({ onAnalysis, result, layout, liveGameData, open, onClose }: Props) => {
    const sessionResultHistory = useStoreState((state) => state.observeView.sessionResultHistory)

    // this is because there is some unnecessary discrepancy between the types of GameData and LiveGameData
    const asGameData: GameData = {
        ...liveGameData,
        player1: liveGameData.player1,
        player2: liveGameData.player2,
        timeStamp: Date.now(),
        matchKind: liveGameData.matchKind as 'human_vs_human' | 'human_vs_ai',
    }

    return (
        <GameOverDialog
            observer={true}
            gameData={asGameData}
            result={result}
            layout={layout}
            onAnalysis={onAnalysis}
            open={open}
            onClose={onClose}
            sessionResultHistory={sessionResultHistory}
        />
    )
}
