import { GameTree } from '../../../chess/types'
import { Layout } from '../../../sharedComponents/src/hooks/useResponsiveSizings'
import { GameResult } from '../../../store/types'
import { Notation } from '../../notation/Notation'
import { NotationControls } from '../../notation/components/NotationControls'

export interface GameTabProps {
    gameTree: GameTree
    currentPositionId: string
    onMoveSelected: (move: string) => void
    gameResult: GameResult | undefined
    matchTitle: string
    hasNextMoves: boolean
    hasPrevMoves: boolean
    onFirst: () => void
    onPrev: () => void
    onNext: () => void
    onLast: () => void
    layout: Layout
    opening?: string
}

export const GameTab = (props: GameTabProps) => {
    const {
        gameTree,
        currentPositionId,
        onMoveSelected,
        gameResult,
        matchTitle,
        hasNextMoves,
        hasPrevMoves,
        onFirst,
        onPrev,
        onNext,
        onLast,
        layout,
        opening,
    } = props

    return (
        <>
            <Notation
                gameTree={gameTree}
                currentPositionId={currentPositionId}
                gameResult={gameResult}
                matchTitle={matchTitle}
                editableAnnotations={false}
                opening={opening}
                customOnMoveSelected={onMoveSelected}
            />

            <NotationControls
                vertical={layout === Layout.MOBILE}
                hasNextMoves={hasNextMoves}
                hasPrevMoves={hasPrevMoves}
                onFirstMove={onFirst}
                onPrevMove={onPrev}
                onNextMove={onNext}
                onLastMove={onLast}
            />
        </>
    )
}
