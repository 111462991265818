interface IProps {
    size?: number
}

const Random: React.FC<IProps> = (props) => {
    const { size = 24 } = props

    return (
        <svg width={size} height={size} viewBox="12 12 24 24" fill="none">
            <g clipPath="url(#clip0_123_14455)">
                <path
                    d="M30.3234 19.3913C28.6819 19.3913 27.4811 19.9781 26.5311 20.7456C26.6451 20.4319 26.7135 20.1478 26.7135 19.8637C26.7135 18.7161 25.9422 17.9116 25.1442 17.5758V16.0592H26.4019V13.9928H25.1442V12.5869H22.8567V13.9928H21.5989V16.0592H22.8567V17.5758C22.0587 17.9116 21.2873 18.7161 21.2873 19.8637C21.2873 20.1478 21.3557 20.4319 21.4697 20.7456C20.5235 19.9744 19.3228 19.3913 17.6774 19.3913C14.6907 19.3913 12.6084 21.2142 12.6084 23.8194C12.6084 25.9854 14.2537 26.9891 15.2911 27.7936C15.8687 28.2437 16.3703 28.6312 16.5109 29.1183L17.1378 31.2364C16.6743 32.026 16.0777 33.7161 15.9561 35.0371L15.9219 35.4135H32.0752L32.041 35.0371C31.9194 33.7161 31.3228 32.026 30.8592 31.2364L31.4862 29.122C31.6268 28.6349 32.1283 28.2474 32.7097 27.7972C33.7471 26.9928 35.3924 25.9891 35.3924 23.8231C35.3924 21.2142 33.3063 19.395 30.3234 19.395V19.3913Z"
                    fill="#F4F4F4"
                />
                <path d="M30.8624 31.2324H23.5742" stroke="black" strokeMiterlimit="10" />
                <path d="M31.611 28.8418H23.5742" stroke="black" strokeMiterlimit="10" />
                <path
                    d="M25.144 17.5761C24.3802 17.3067 23.6202 17.3067 22.8564 17.5761"
                    stroke="black"
                    strokeMiterlimit="10"
                />
                <path
                    d="M30.3233 19.3913C28.6818 19.3913 27.481 19.9781 26.531 20.7456C26.645 20.4319 26.7134 20.1478 26.7134 19.8637C26.7134 18.7161 25.942 17.9116 25.1441 17.5758V16.0592H26.4018V13.9928H25.1441V12.5869H22.8566V13.9928H21.5988V16.0592H22.8566V17.5758C22.0586 17.9116 21.2872 18.7161 21.2872 19.8637C21.2872 19.9891 21.3024 20.1183 21.3252 20.2474L20.9984 20.395C20.1282 19.8046 19.0605 19.3877 17.6735 19.3877C14.6868 19.3877 12.6045 21.2105 12.6045 23.8157C12.6045 25.9817 14.2498 26.9854 15.2872 27.7899C15.8648 28.24 16.3664 28.6275 16.507 29.1146L17.1339 31.2327C16.6704 32.0223 16.0738 33.7124 15.9522 35.0334L15.918 35.4098H32.075L32.0408 35.0334C31.9192 33.7124 31.3227 32.0223 30.8591 31.2327L31.4861 29.1183C31.6267 28.6312 32.1282 28.2437 32.7096 27.7936C33.747 26.9891 35.3923 25.9854 35.3923 23.8194C35.3923 21.2105 33.3062 19.3913 30.3233 19.3913Z"
                    stroke="black"
                    strokeMiterlimit="10"
                />
                <path d="M26.5307 20.7451C26.5307 20.7451 24 22.7673 24 26.4462" stroke="black" strokeMiterlimit="10" />
            </g>
            <path
                d="M22.8566 13.9926V13.5H24.0003V35.0332H22.8566L15.918 35.4095L15.9522 35.0332C16.0738 33.7121 16.6704 32.0221 17.1339 31.2324L16.507 29.1143C16.3676 28.6317 15.8739 28.2469 15.303 27.8019L15.2872 27.7896C14.2498 26.9852 12.6045 25.9815 12.6045 23.8155C12.6045 21.2103 14.6868 19.3874 17.6735 19.3874C19.0605 19.3874 20.1282 19.8044 20.9984 20.3948L21.3252 20.2472C21.3024 20.118 21.2872 19.9889 21.2872 19.8634C21.2872 18.7158 22.0586 17.9114 22.8566 17.5756V16.059H21.5988V13.9926H22.8566Z"
                fill="black"
            />
            <path
                d="M22.8566 13.5V13.9926H21.5988V16.059H22.8566V17.5756C22.0586 17.9114 21.2872 18.7158 21.2872 19.8634C21.2872 19.9889 21.3024 20.118 21.3252 20.2472L20.9984 20.3948C20.1282 19.8044 19.0605 19.3874 17.6735 19.3874C14.6868 19.3874 12.6045 21.2103 12.6045 23.8155C12.6045 25.9815 14.2498 26.9852 15.2872 27.7896C15.8648 28.2398 16.3664 28.6273 16.507 29.1143L17.1339 31.2324C16.6704 32.0221 16.0738 33.7121 15.9522 35.0332L15.918 35.4095L22.8566 35.0332M22.8566 13.5V35.0332M22.8566 13.5H24.0003V35.0332H22.8566"
                stroke="black"
                strokeMiterlimit="10"
            />
            <path
                d="M22.8567 12.5869V13.9928H21.5989V16.0592H22.8567V17.5758C22.0587 17.9116 21.2873 18.7161 21.2873 19.8637C21.2873 20.1478 21.3557 20.4319 21.4697 20.7456C20.5235 19.9744 19.3228 19.3913 17.6774 19.3913C14.6907 19.3913 12.6084 21.2142 12.6084 23.8194C12.6084 25.9854 14.2537 26.9891 15.2911 27.7936C15.8687 28.2437 16.3703 28.6312 16.5109 29.1183L17.1378 31.2364C16.6743 32.026 16.0777 33.7161 15.9561 35.0371L15.9219 35.4135H24.0004"
                stroke="#465766"
            />
            <defs>
                <clipPath id="clip0_123_14455">
                    <rect width="24" height="24" fill="white" transform="translate(12 12)" />
                </clipPath>
            </defs>
        </svg>
    )
}

Random.displayName = 'Random'

export default Random
