import { PaletteMode, PaletteOptions, alpha, createTheme } from '@mui/material'
import { BoardColor, colors } from '../sharedComponents/src/globalHeader/theme/colors'

type CustomPalette = (boardColor: BoardColor) => Record<PaletteMode, PaletteOptions>

const breakpoints = {
    values: {
        xs: 0,
        sm: 744,
        md: 1024,
        lg: 1440,
        xl: 1620,
    },
}

const palette: CustomPalette = (boardColor) => ({
    light: {
        mode: 'light',
        background: {
            default: colors.white,
            paper: colors.white,
            defaultChannel: '#FFFFFF',
            buttonBorder: colors.darkBlue,
            buttonText: colors.darkBlue,
            settingSelection: colors.lightGray,
            light: colors.lightGray, // update when light theme will exist
        },
        text: {
            primary: colors.darkBlue,
            secondary: colors.darkBlue,
        },
        action: {
            active: colors.darkBlue,
        },
        primary: {
            main: boardColor.button.color,
            // light: boardColor.main.backgroundColor,
            // contrastText: theme.palette.getContrastText(boardColor.button.color),
        },
        secondary: {
            main: colors.white,
            light: colors.white,
            dark: colors.white,
            contrastText: colors.white,
        },
        warning: {
            main: boardColor.button.color,
            dark: colors.error.dark,
            light: colors.error.main,
            // contrastText: theme.palette.getContrastText(colors.error.main),
        },
        error: {
            main: colors.error.main,
            dark: colors.error.dark,
            light: colors.error.main,
            // contrastText: theme.palette.getContrastText(colors.error.main),
        },
        success: {
            main: colors.success.main,
            dark: colors.success.dark,
            light: colors.success.main,
            // contrastText: theme.palette.getContrastText(colors.success.main),
        },
    },
    dark: {
        mode: 'dark',
        background: {
            default: colors.darkBlue,
            paper: colors.lightDarkBlue,
        },
        text: {
            primary: colors.white,
            secondary: colors.white,
        },
        action: {
            active: boardColor.button.color,
            disabledBackground: alpha(boardColor.button.color, 0.5),
            disabledOpacity: 0.5,
        },
        primary: {
            main: boardColor.button.color,
            // light: boardColor.main.backgroundColor,
            // contrastText: theme.palette.getContrastText(boardColor.button.color),
        },
        secondary: {
            main: colors.orange,
            // contrastText: theme.palette.getContrastText(colors.yellow),
        },
        warning: {
            main: boardColor.button.color,
        },
        error: {
            main: '#D80031',
            dark: '#EC1C24',
        },
        success: {
            main: '#137A08',
            dark: '#137A08',
        },
        board: {
            primary: boardColor.main.backgroundColor,
            secondary: boardColor.secondary.backgroundColor,
        },
    },
})

export const getDesignTokens = (mode: PaletteMode, boardColor: BoardColor) => {
    let theme = createTheme({ palette: palette(boardColor)[mode], breakpoints })
    theme = createTheme({
        breakpoints,
        palette: palette(boardColor)[mode],
        typography: {
            fontFamily: ['Fira Sans', 'Sans Serif'].join(','),
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    '.MuiStack-root, .MuiBox-root, .MuiPaper-root, .MuiGrid-root, .MuiDialogContent-root': {
                        // borderColor: `${alpha(theme.palette.primary.main, 0.5)} !important`,
                        borderWidth: '2px !important',
                    },
                    body: {
                        userSelect: 'none',
                    },
                    img: {
                        pointerEvents: 'none',
                        userSelect: 'none',
                    },
                    a: {
                        cursor: 'pointer',
                        color: 'inherit',
                        textDecoration: 'under',
                    },
                    html: {
                        fontSize: '16px',
                    },

                    'div[title="Choose promotion piece"]': {
                        backgroundColor: 'transparent !important',
                    },

                    'input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                    'input[type=number]::-webkit-outer-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    'input[type=number]::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: { border: `1px solid ${theme.palette.text.primary}` },
                },
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 0, // paper should have a default elevation of 0 so we can use it as a basis surface for many components
                },
                styleOverrides: {
                    root: {
                        fill: theme.palette.text.primary,
                        backgroundImage: 'none',

                        // the connection status indicator
                        '&.serverConnection': {
                            backgroundColor: 'transparent',
                            fontSize: '0.75rem',
                            position: 'absolute',
                            bottom: '0.3rem',
                            left: '0.625rem',
                            '&.gs': {
                                bottom: '1rem',
                            },
                            pointerEvents: 'none',
                        },
                        '&.icclogo': {
                            backgroundColor: 'transparent',
                            '& .logopart': {
                                fill: theme.palette.text.primary,
                                backgroundColor: 'transparent',
                            },
                        },
                        '& .boardsetup-svgs': {
                            '.mark': {
                                stroke: theme.palette.mode === 'dark' ? colors.white : colors.hover,
                            },
                            '.border': {
                                stroke: theme.palette.mode === 'dark' ? colors.white : colors.hover,
                            },
                            '.square': {
                                fill: theme.palette.mode === 'dark' ? colors.white : colors.hover,
                            },
                        },

                        // PGN Form Buttons
                        '& .pgn-form': {
                            '& .uploadPGNFormButton , .usePGNFormButton': {
                                width: '100%',
                                border: `0.125rem solid ${theme.palette.mode === 'dark' ? colors.lightGray02opacity : colors.hover02opacity
                                    } `,
                                backgroundColor: `${theme.palette.background.default}`,

                                '&:hover': {
                                    border: `0.125rem solid `,
                                    opacity: '1',
                                    backgroundColor: `${theme.palette.background.default}`,
                                },
                                '&:active': {
                                    color: theme.palette.primary.contrastText,
                                    backgroundColor: theme.palette.primary.main,
                                },
                            },

                            // PGN From Textfield customizations
                            '& .pgn-textbox': {
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: `${theme.palette.mode === 'dark'
                                            ? colors.lightGray02opacity
                                            : colors.hover02opacity
                                            }`,
                                        borderRadius: '0rem',
                                    },

                                    '&:hover fieldset': {
                                        transition: 'border-color 250ms',
                                        color: theme.palette.text.primary,
                                        borderColor: theme.palette.text.primary,
                                    },

                                    '&.Mui-focused fieldset': {
                                        transition: 'border-color 250ms',
                                        color: theme.palette.text.primary,
                                        borderColor: theme.palette.primary.main,
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    fontFamily: 'Space Grotesk',
                                    color: theme.palette.text.primary,
                                },
                            },
                        },
                        // the hint box if you are not logged in
                        '&.loginHint': {
                            fontFamily: 'Space Grotesk',
                            padding: '1rem',
                            border: `0.188rem solid ${colors.lightBlue}`,
                            color: theme.palette.text.primary,
                        },
                        '&.StockFishEngine': {
                            margin: 0,
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: '0rem',
                            color: theme.palette.text.primary,
                            '& .StockFishEngine-line': {
                                alignContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'nowrap',
                                overflowX: 'hidden !important',
                                textOverflow: 'hidden !important',
                                '&.expanded': {
                                    flexWrap: 'wrap',
                                    overflowX: 'auto',
                                    textOverflow: 'auto',
                                },
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                transition: 'all 1s ease',
                            },
                        },
                    },
                },
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        transition: 'all 250ms',
                        color: theme.palette.text.primary,
                        borderRadius: 0,
                        border: `2px solid ${theme.palette.text.primary} !important`,
                        svg: {
                            path: {
                                transition: 'fill 250ms',
                                fill: alpha(theme.palette.text.primary, 0.7),
                            },
                            width: '40px',
                            height: '100%',
                            opacity: '0.3',
                            zIndex: 1,
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: '4px',
                        },
                        '&:hover': {
                            border: `2px solid ${theme.palette.primary.main} !important`,
                            color: theme.palette.primary.main,
                            textShadow: `1px 1px 1px ${theme.palette.background.default}`,
                            backgroundColor: `${alpha(theme.palette.primary.main, 0.08)} !important`,
                            svg: {
                                opacity: 1,
                                path: {
                                    fill: theme.palette.primary.main,
                                },
                            },
                        },
                        '&.Mui-selected': {
                            border: `2px solid ${theme.palette.primary.main} !important`,
                            color: theme.palette.primary.contrastText,
                            backgroundColor: `${theme.palette.primary.main} !important`,
                            svg: {
                                path: {
                                    fill: theme.palette.primary.contrastText,
                                },
                            },
                            '&:hover': {
                                textShadow: 'none',
                                border: `2px solid ${theme.palette.primary.dark} !important`,
                                backgroundColor: `${theme.palette.primary.dark} !important`,
                                svg: {
                                    path: {
                                        opacity: '0.25',
                                        fill: theme.palette.primary.contrastText,
                                    },
                                },
                            },
                        },
                    },
                },
            },
            MuiSlider: {
                styleOverrides: {
                    valueLabel: {
                        fontFamily: 'Space Grotesk',
                        fontWeight: 700,
                        backgroundColor: theme.palette.background.paper,
                        border: `1px solid ${theme.palette.secondary.main}`,
                        padding: '4px 8px',
                        '::before': {
                            borderRight: `1px solid ${theme.palette.secondary.main}`,
                            borderBottom: `1px solid ${theme.palette.secondary.main}`,
                            bottom: '-1px',
                        },
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    variant: 'contained',
                    size: 'medium',
                },
                styleOverrides: {
                    root: {
                        // borderColor: alpha(theme.palette.primary.main, 0.5),
                        fontFamily: 'Space Grotesk',
                        fontWeight: 700,
                        height: '50px',
                        boxShadow: 'none',
                        fontSize: '20px',
                        padding: '0 16px',
                        textWrap: 'nowrap',
                        width: '100%',
                        maxWidth: '340px',
                        borderRadius: 0,
                        ':not(.colorfulSvg)': {
                            svg: {
                                path: {
                                    fill: theme.palette.primary.main,
                                },
                            },
                        },

                        [theme.breakpoints.down('md')]: {
                            fontSize: '18px',
                            height: '44px',
                        },
                        '&.MuiButton-sizeSmall': {
                            fontSize: '20px',
                            height: '44px',
                        },
                        '&.MuiButton-contained': {
                            // to imitate `2px` border
                            padding: '0 18px',
                            ':not(.colorfulSvg)': {
                                svg: {
                                    path: {
                                        fill: theme.palette.primary.contrastText,
                                    },
                                },
                            },
                        },
                        '&.MuiButton-outlined': {
                            borderWidth: '2px',
                            borderColor: theme.palette.text.primary,
                            color: theme.palette.text.primary,
                            '&:hover': {
                                borderColor: theme.palette.primary.main,
                                color: theme.palette.primary.main,
                            },
                        },
                        '&.MuiButton-text': {
                            color: theme.palette.text.primary,
                            width: 'unset',
                            '&:disabled': {
                                color: theme.palette.text.disabled,
                            },
                        },
                        '&.MuiButton-fullWidth': {
                            maxWidth: 'unset',
                            width: '100%',
                        },
                        '&:disabled': {
                            color: theme.palette.primary.contrastText,
                            opacity: '0.5',
                        },
                    },
                },
            },
            MuiGrid: {
                defaultProps: {},
                styleOverrides: {
                    root: {
                        //  RankRangeSelector component styles
                        '&.rankRangeSelectorWrapper': {
                            borderRadius: '0.625rem',
                            padding: '1.25rem',
                            boxShadow: '0rem 0rem 0.625rem 0rem rgba(0,0,0,0.2)',
                            '& .rankRangeSelector-title': {
                                padding: '0rem 0rem 1.25rem 0rem',
                            },
                            '& .rankRangeSelector': {
                                '& .MuiTypography-root ': {
                                    '& .lowestRank': {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                    },
                                    fontSize: '1.5rem',
                                },
                            },

                            '& .lowestRank': {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                            },
                            '& .userRank': {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '& .MuiTypography-root ': {
                                    color: colors.gray,
                                },
                            },
                            '& .highestRank': {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            },
                        },
                    },
                },
            },

            MuiTabs: {
                styleOverrides: {
                    root: {
                        // Tab Component for challenges
                        '&.tabBar': {
                            width: '100%',
                            boxSizing: 'border-box',
                            height: '2.75rem',
                            minHeight: '2.75rem',
                            overflow: 'visible',
                            '& .MuiTabs-scroller': {
                                overflow: 'visible !important',
                            },
                            '& .MuiTabs-flexContainer': {
                                gap: '8px',
                            },
                            '& .MuiTabs-indicator': {
                                backgroundColor: 'transparent',
                            },
                            '& button': {
                                fontSize: '18px',
                            },
                        },
                    },
                },
            },

            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        textTransform: 'capitalize',
                        cursor: 'pointer',
                        '.MuiTypography-root': {
                            fontSize: '16px',
                        },

                        '&:hover > .MuiCheckbox-root:not(.Mui-disabled)': {
                            color: theme.palette.primary.main,
                            backgroundColor: 'rgba(255, 228, 158, 0.08)',
                        },
                    },
                    label: {
                        userSelect: 'none',
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        textTransform: 'uppercase',
                    },
                },
            },

            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            color: theme.palette.primary.main,
                        },
                        '&.Mui-checked': {
                            color: theme.palette.primary.main,
                        },
                    },
                },
            },

            MuiRadio: {
                styleOverrides: {
                    root: {
                        // Color Selector Radio Buttons
                        '&.colorSelector': {
                            padding: '0',
                            transition: 'all 0.2s ease-in-out',
                            border: `0.188rem solid ${colors.white}`,
                            borderRadius: '0',
                            width: '100%',
                            backgroundColor: theme.palette.background.default,
                            '&:hover': {
                                border: `0.188rem solid ${theme.palette.primary.main}`,
                            },
                            '&.Mui-checked': {
                                border: `0.188rem solid ${colors.white}`,
                                backgroundColor: theme.palette.primary.main,
                                '&:hover': {
                                    outline: 'none',
                                },
                            },
                        },
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: 'Space Grotesk',
                        ':not(.Mui-focused, .MuiInputLabel-shrink)': {
                            transform: 'translate(8px, 6px) scale(1)',
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        fontFamily: 'Space Grotesk',
                        // linked with MuiFormLabel
                        padding: '8px !important',
                    },
                    root: {
                        svg: {
                            path: { fill: theme.palette.text.primary },
                        },
                        fontFamily: 'Space Grotesk',
                        fontSize: '16px',
                        // padding: '8px',
                        fieldset: {
                            borderRadius: '0',
                            borderColor: colors.white,
                        },
                        '&.Mui-focused': {
                            svg: {
                                fill: theme.palette.primary.main,
                                path: { fill: theme.palette.primary.main },
                            },
                        },
                        '&.Mui-disabled': {
                            fieldset: {
                                borderColor: `${theme.palette.text.disabled} !important`,
                            },
                        },
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontSize: '1rem',
                        fontFamily: 'Space Grotesk',
                        '&.customTitles': {
                            fontSize: '1rem',
                            color: theme.palette.text.primary,
                            textTransform: 'uppercase',
                        },
                        '> a': {
                            color: theme.palette.text.primary,
                        },
                    },
                    body1: {
                        fontSize: '20px',
                        fontFamily: 'Space Grotesk',
                        lineHeight: '22px',
                        // [theme.breakpoints.down('md')]: {
                        //     fontSize: '18px',
                        //     lineHeight: '20px',
                        // },
                    },
                    body2: {
                        fontSize: '16px',
                        fontFamily: 'Space Grotesk',
                        lineHeight: '18px',
                        // [theme.breakpoints.down('md')]: {
                        //     fontSize: '14px',
                        //     lineHeight: '16px',
                        // },
                    },

                    // Issue with `as const` - https://github.com/mui/material-ui/issues/16307
                    h1: {
                        fontSize: '46px',
                        fontFamily: 'Fira Sans',
                        textTransform: 'uppercase' as const,
                        lineHeight: '48px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '38px',
                            lineHeight: '40px',
                        },
                    },
                    h2: {
                        fontSize: '38px',
                        fontFamily: 'Fira Sans',
                        textTransform: 'uppercase' as const,
                        lineHeight: '40px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '34px',
                            lineHeight: '36px',
                        },
                    },
                    h3: {
                        fontSize: '34px',
                        fontFamily: 'Fira Sans',
                        textTransform: 'uppercase' as const,
                        lineHeight: '36px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '30px',
                            lineHeight: '32px',
                        },
                    },
                    h4: {
                        fontSize: '30px',
                        fontFamily: 'Fira Sans',
                        textTransform: 'uppercase' as const,
                        lineHeight: '32px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '26px',
                            lineHeight: '28px',
                        },
                    },
                    h5: {
                        fontSize: '26px',
                        fontFamily: 'Fira Sans',
                        textTransform: 'uppercase' as const,
                        lineHeight: '28px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '22px',
                            lineHeight: '24px',
                        },
                    },
                    // REQUIRES DOUBLE CHECK
                    h6: {
                        fontSize: '1rem',
                        fontFamily: 'Space Grotesk',
                        fontWeight: 700,
                        textTransform: 'uppercase' as const,
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: theme.palette.text.primary,
                        '&:disabled': {
                            color: theme.palette.text.primary,
                            opacity: '0.5',
                        },
                    },
                    sizeSmall: {
                        height: '24px',
                        width: '24px',
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        '&.userTitle': {
                            height: '1.063rem',
                            //opacity: '0.3',
                            padding: '0',
                            width: 'fit-content',
                            backgroundColor: theme.palette.text.primary,
                            color: theme.palette.background.paper,
                            borderRadius: '0.125rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&.black': {
                                backgroundColor: colors.black,
                                color: colors.white,
                            },
                            '& .MuiChip-label': {
                                padding: '0 0.125rem',
                            },
                        },
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    arrow: {
                        color: theme.palette.text.primary,
                    },
                    tooltip: {
                        fontWeight: 500,
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.secondary.main,
                        border: `1px solid`,
                        borderColor: 'secondary.main',
                        fontSize: '0.9rem',
                        fontFamily: 'Space Grotesk',
                        padding: '0.2rem 0.75rem',
                        borderRadius: '0rem',
                    },
                },
            },

            MuiCard: {
                styleOverrides: {
                    root: {
                        '&.bubble-container': {
                            position: 'relative',
                            display: 'inline-block',
                            marginTop: '1.25rem',
                            marginBottom: '1.25rem',
                            width: '100%',
                            '&.bubble-border': {
                                border: `0.188rem solid ${theme.palette.text.primary}`,
                                borderRadius: '0',
                            },
                            '& .bubble': {
                                //move to the right of the container
                                position: 'absolute',
                                right: '0.625rem',
                                top: '-1.25rem',
                                fontFamily: 'Fira Sans',
                                textAlign: 'center',
                                border: `0.25rem solid ${theme.palette.text.primary}`,
                                backgroundColor: theme.palette.background.default,
                                color: theme.palette.text.primary,
                                width: 'fit-content',
                                borderRadius: '0',
                                maxWidth: '11.25rem',
                                '& .bubble-text': {
                                    textTransform: 'uppercase',
                                    lineHeight: '1rem',
                                    padding: '0.438rem',
                                },
                            },
                            '& .bubble:before': {
                                content: '""',
                                position: 'absolute',
                                width: '0',
                                height: '0',
                            },
                            '& .bubble:after': {
                                content: '""',
                                position: 'absolute',
                                width: '0',
                                height: '0',
                            },
                            '& .bubble-bottom-left:before': {
                                left: '-0.188rem',
                                bottom: '-1.438rem',
                                border: '0.688rem solid',
                                borderColor: `${theme.palette.text.primary} transparent transparent ${theme.palette.text.primary}`,
                                //This is a hack to fix a bug in positioning in firefox
                                '@media (min--moz-device-pixel-ratio: 0)': {
                                    left: '-0.25rem',
                                },
                            },
                            '& .bubble-bottom-left:after': {
                                left: '0rem',
                                bottom: '-0.875rem',
                                border: '0.688rem solid',
                                borderColor: `${theme.palette.background.default} transparent transparent ${theme.palette.background.default}`,
                            },
                        },
                    },
                },
            },

            MuiMobileStepper: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.background.paper,
                        '.MuiMobileStepper-dotActive': {
                            height: '8px !important',
                            width: '8px !important',
                        },
                        '.MuiMobileStepper-dots': {
                            alignItems: 'center',
                            gap: 10,
                        },
                        '.MuiMobileStepper-dot': {
                            height: '4px',
                            width: '4px',
                            margin: 0,
                            backgroundColor: theme.palette.secondary.main,
                        },
                    },
                },
            },

            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        // marginTop: '32px',
                    },
                },
            },

            MuiDialog: {
                styleOverrides: {
                    root: {
                        '& .MuiDialog-paper': {
                            maxWidth: 'unset',
                            flexDirection: 'unset',
                            margin: '1rem',
                        },
                    },
                },
            },
        },
    })

    return theme
}
