// <svg width="50" height="50" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M2 6H30M2.00781 15.5H30.0078M2.01562 25H30.0156" stroke="white" strokeWidth="3" />
// </svg>

const BurgerIcon: React.FC = () => (
    <svg width="35" height="30" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.89285 27.1508L32.1784 27.1508C32.6805 27.1508 33.1619 27.3009 33.5169 27.5681C33.8719 27.8353 34.0713 28.1976 34.0713 28.5755C34.0713 28.9534 33.8719 29.3158 33.5169 29.5829C33.1619 29.8501 32.6805 30.0002 32.1784 30.0002L1.89285 30.0002C1.39083 30.0002 0.909378 29.8501 0.554401 29.583C0.199421 29.3158 -9.76207e-08 28.9534 -1.32856e-07 28.5755C-1.68092e-07 28.1976 0.199421 27.8353 0.554401 27.5681C0.909378 27.3009 1.39083 27.1508 1.89285 27.1508Z"
            fill="white"
        />
        <path
            d="M1.89285 13.403L32.1784 13.403C32.6805 13.403 33.1619 13.5531 33.5169 13.8203C33.8719 14.0874 34.0713 14.4498 34.0713 14.8277C34.0713 15.2056 33.8719 15.568 33.5169 15.8351C33.1619 16.1023 32.6805 16.2524 32.1784 16.2524L1.89285 16.2524C1.39083 16.2524 0.909378 16.1023 0.554401 15.8351C0.199421 15.568 -9.76207e-08 15.2056 -1.32856e-07 14.8277C-1.68092e-07 14.4498 0.199421 14.0875 0.554401 13.8203C0.909378 13.5531 1.39083 13.403 1.89285 13.403Z"
            fill="white"
        />
        <path
            d="M1.89285 0.000133052L32.1784 0.00013057C32.6805 0.000130529 33.1619 0.150235 33.5169 0.417426C33.8719 0.684616 34.0713 1.04701 34.0713 1.42487C34.0713 1.80273 33.8719 2.16512 33.5169 2.43231C33.1619 2.6995 32.6805 2.84961 32.1784 2.84961L1.89285 2.84961C1.39083 2.84961 0.909378 2.69951 0.554401 2.43232C0.199421 2.16513 -9.76207e-08 1.80274 -1.32856e-07 1.42487C-1.68092e-07 1.04701 0.199421 0.684619 0.554401 0.417429C0.909378 0.150238 1.39083 0.000133093 1.89285 0.000133052Z"
            fill="white"
        />
    </svg>
)

BurgerIcon.displayName = 'BurgerIcon'

export default BurgerIcon
