import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import { loadScript } from '../globalHeader/common/loadScript'
import { SECOND_STEP, loginSocial, upgradeGuestSocial } from '../globalHeader/functions/ams-functions'
import { analyticsManager } from '../globalHeader/services/analytics/AnalyticsManager'
import { SocialType, Theme } from './SocialLogin'

declare global {
    interface Window {
        AppleID: any
    }
}

interface IProps {
    apiURL: string
    appleClientId: string
    appleRedirectURL: string
    isGuest: boolean
    onSignUpSocial: (socialResponse: any) => void
    onLoginSuccessful: () => void
    logoutHandler: () => Promise<void>
    theme: Theme
}

const AppleAuth: React.FC<IProps> = (props) => {
    const {
        onSignUpSocial,
        onLoginSuccessful,
        isGuest,
        logoutHandler,
        apiURL,
        appleClientId,
        appleRedirectURL,
        theme,
    } = props
    const [socialType, setSocialType] = useState(SocialType.APPLE)

    useEffect(() => {
        const src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'

        loadScript(src)
            .then(() => {
                window.AppleID.auth.init({
                    clientId: appleClientId,
                    scope: 'email name',
                    redirectURI: appleRedirectURL,
                    state: 'ICC user authentication',
                    nonce: 'ICC auth',
                    usePopup: true,
                })
            })
            .catch(console.error)

        return () => {
            const scriptTag = document.querySelector(`script[src="${src}"]`)
            if (scriptTag) document.body.removeChild(scriptTag)
        }
    }, [])

    const handleResponse = async () => {
        try {
            // Handle successful response.
            const response = await window.AppleID.auth.signIn()

            let data: any = null
            if (isGuest) {
                data = await upgradeGuestSocial(
                    apiURL,
                    response.authorization.code,
                    response.authorization.id_token,
                    socialType,
                    logoutHandler,
                )
            } else {
                data = await loginSocial(
                    apiURL,
                    response.authorization.code,
                    response.authorization.id_token,
                    socialType,
                )
            }

            if (data.error) {
                const error = JSON.parse(data.error)
                if (error.message === SECOND_STEP) {
                    onSignUpSocial({
                        ...response,
                        socialToken: error.socialToken,
                        socialType,
                    })
                } else {
                    console.log('Apple auth error:', data.error)
                }
            } else {
                analyticsManager.dispatchEvent('completeLogin', { authType: 'apple' })
                onLoginSuccessful()
            }
        } catch (error) {
            // Handle error.
            console.log('Apple auth error:', error)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                px: 2,
            }}
        >
            <Box
                sx={{
                    border: '2px solid #000000',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    background: '#FFFFFF',
                }}
                onClick={handleResponse}
            >
                <img style={{ width: '23px', height: '23px' }} src="/assets/images/apple.svg" />
            </Box>
            <Typography
                sx={{
                    color: theme === 'light' ? 'rgb(16 24 32)' : '#F5F5F5',
                    fontWeight: 400,
                    pt: 1,
                    fontSize: '1rem',
                    fontFamily: 'Space Grotesk',
                }}
            >
                Apple
            </Typography>
        </Box>
    )
}

AppleAuth.displayName = 'AppleAuth'

export default AppleAuth
