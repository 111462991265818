const VideosIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.328 7.54199H8.67196C5.29796 7.54199 3.60996 7.54199 2.66196 8.52899C1.71396 9.51599 1.93696 11.04 2.38396 14.089L2.80596 16.981C3.15596 19.372 3.33096 20.568 4.22796 21.284C5.12596 22 6.44796 22 9.09496 22H14.905C17.551 22 18.875 22 19.772 21.284C20.669 20.568 20.844 19.372 21.194 16.981L21.616 14.089C22.063 11.04 22.286 9.51599 21.338 8.52899C20.39 7.54199 18.702 7.54199 15.328 7.54199ZM14.581 15.794C15.14 15.448 15.14 14.552 14.581 14.206L11.21 12.116C10.667 11.779 9.99996 12.217 9.99996 12.91V17.09C9.99996 17.783 10.667 18.22 11.21 17.884L14.581 15.794Z"
            fill="white"
        />
        <path
            opacity="0.4"
            d="M8.50997 2H15.49C15.722 2 15.9 2 16.056 2.015C17.164 2.124 18.071 2.79 18.456 3.687H5.54297C5.92697 2.79 6.83397 2.124 7.94197 2.015C8.09897 2 8.27697 2 8.50997 2Z"
            fill="white"
        />
        <path
            opacity="0.7"
            d="M6.30998 4.72314C4.91998 4.72314 3.77998 5.56314 3.39898 6.67614C3.39098 6.69937 3.38331 6.7227 3.37598 6.74614C3.77398 6.62614 4.18798 6.54714 4.60798 6.49314C5.68798 6.35514 7.05398 6.35514 8.63998 6.35514H15.532C17.118 6.35514 18.483 6.35514 19.564 6.49314C19.9809 6.5437 20.3929 6.62831 20.796 6.74614C20.7887 6.7227 20.781 6.69936 20.773 6.67614C20.393 5.56214 19.252 4.72314 17.861 4.72314H6.30998Z"
            fill="white"
        />
    </svg>
)

VideosIcon.displayName = 'VideosIcon'

export default VideosIcon
