import Box from '@mui/material/Box'
import CircularButton from '../../analysisView/components/analyisControls/CircularButton'
import FlipIcon from '../../gameView/components/gameEndControls/icons/Flip'
import ResignIcon from '../../gameView/components/gameEndControls/icons/Resign'
import HintIcon from '../icons/Hint'
import ShowDescriptionIcon from '../icons/ShowDescription'
import TagIcon from '../icons/Tag'

interface IProps {
    showDescriptionButton: boolean
    disableByState: boolean
    isLastPuzzle: boolean
    showSolutionDisabled: boolean
    isInitialized: boolean
    welcomeDescOpen?: boolean
    tagsBadge?: number
    onShowSolution: () => void
    onFlip: () => void
    onHint: () => void
    onTags?: () => void
    onShowDescription: () => void
}

const PuzzleControls: React.FC<IProps> = (props) => {
    const {
        showDescriptionButton,
        disableByState,
        isLastPuzzle,
        showSolutionDisabled,
        isInitialized,
        tagsBadge = 0,
        onShowSolution,
        onFlip,
        onHint,
        onTags,
        onShowDescription,
        welcomeDescOpen,
    } = props

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 0,
                gap: '16px',
            }}
        >
            <CircularButton
                onClick={onShowSolution}
                title="Show Solution"
                icon={<ResignIcon />}
                disabled={disableByState || isLastPuzzle || showSolutionDisabled || !isInitialized}
            />

            <CircularButton onClick={onFlip} title="Flip board" icon={<FlipIcon />} disabled={false} />

            <CircularButton
                onClick={onHint}
                title="Hint"
                icon={<HintIcon />}
                disabled={disableByState || isLastPuzzle || showSolutionDisabled || !isInitialized}
            />

            {onTags && (
                <CircularButton
                    onClick={onTags}
                    title="Tags"
                    icon={<TagIcon />}
                    badge={tagsBadge}
                    disabled={isLastPuzzle || showSolutionDisabled}
                />
            )}

            {showDescriptionButton && (
                <CircularButton
                    onClick={welcomeDescOpen ? () => {} : onShowDescription}
                    title="Show description"
                    icon={<ShowDescriptionIcon />}
                    disabled={isLastPuzzle || showSolutionDisabled}
                />
            )}
            {/* <ChallengeSlide type="quest" /> */}
        </Box>
    )
}

PuzzleControls.displayName = 'PuzzleControls'

export default PuzzleControls
