import { Typography } from '@mui/material'

type Props = {
    change: number
}

export const RatingChange = ({ change }: Props) => {
    if (change === 0) {
        return null
    }

    const negative = change < 0

    return (
        <Typography
            fontFamily="Space Grotesk"
            fontWeight={700}
            variant="body1"
            sx={{ color: negative ? '#FD1212' : '#73F844', pt: '5px' }}
        >
            {negative ? '' : '+'}
            {change}
        </Typography>
    )
}
