import SearchIcon from '@mui/icons-material/SearchOutlined'
import { Box, Button, Checkbox, FormControlLabel, InputAdornment, TextField, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { DialogTemplate } from '../../../../sharedComponents/src/DialogTemplate/DialogTemplate'
import { Layout, useResponsiveSizings } from '../../../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreState } from '../../../../store/hooks'
import { ITag } from '../../functions/puzzlesApi'

interface PuzzleTagsProps {
    open: boolean
    currentTags: number[]
    onClose: () => void
    onApply: (tags: number[]) => void
}

type GroupedTags = {
    keys: string[]
    groups: { [key: string]: ITag[] }
}

const TAGS_LIMIT = 5

const PuzzleTags: React.FC<PuzzleTagsProps> = ({ open, currentTags, onClose, onApply }) => {
    const [search, setSearch] = useState('')
    const { layout } = useResponsiveSizings()
    const [tagsIDs, setTagsIDs] = useState<number[]>(currentTags)

    const allTags = useStoreState((state) => state.puzzlesView.allTags)

    const parsedTagsIDs = useMemo(() => {
        return tagsIDs.reduce((acc, tagID) => {
            acc[tagID] = true
            return acc
        }, {})
    }, [tagsIDs])

    useEffect(() => {
        if (layout !== Layout.MOBILE) {
            setSearch('')
        }
    }, [layout])

    const isTagsLimited = useMemo(() => tagsIDs.length >= TAGS_LIMIT, [tagsIDs])

    const groupedTags: GroupedTags = useMemo(() => {
        let groups: GroupedTags['groups'] = {}
        allTags.forEach((tag) => {
            const currentSymbol = tag.friendlyName.charAt(0)
            if (!search || tag.friendlyName.toLocaleLowerCase().includes(search)) {
                if (groups[currentSymbol]) {
                    groups[currentSymbol].push(tag)
                } else {
                    groups[currentSymbol] = [tag]
                }
            }
        })
        const keys = Object.keys(groups)

        return { keys, groups }
    }, [allTags, search])

    const onResetClick = () => {
        setTagsIDs([])
        onApply([])
    }

    const onApplyClick = () => {
        onApply(tagsIDs)
    }

    const handleCheckbox = (value: number): void => {
        setTagsIDs((prevTags) =>
            prevTags.includes(value) ? prevTags.filter((item) => item !== value) : prevTags.concat([value]),
        )
    }

    const isVertical = layout === Layout.MOBILE

    return (
        <DialogTemplate
            handleCloseOrPrev={onClose}
            open={open}
            header={{
                top: 'Tags',
                middle: `Select up to ${TAGS_LIMIT} tags to practice specific puzzles.`,
            }}
        >
            {isVertical && (
                <TextField
                    sx={{
                        '> div': { padding: '0.5rem' },
                        '.MuiInputAdornment-root': {
                            marginRight: '0.5rem',
                        },
                        input: {
                            fontSize: '14px',
                            padding: '0.5rem 0 ',
                        },
                        svg: {
                            height: '2.5rem',
                            width: '2.5rem',
                        },
                    }}
                    variant="outlined"
                    color="warning"
                    onChange={(e) => setSearch(e.target.value.toLocaleLowerCase())}
                    value={search}
                    placeholder="Find Skill To Improve"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    py: '16px',
                    alignItems: 'flex-start',
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
                    flexGrow: 1,
                    overflow: 'auto',
                    gap: '1rem',
                }}
            >
                {groupedTags.keys.map((key) => (
                    <Box sx={{ userSelect: 'none', width: isVertical ? '100%' : 'auto' }} key={key}>
                        <Typography variant="h3" textTransform="uppercase" sx={{ fontSize: '40px' }}>
                            {key}
                        </Typography>
                        {groupedTags.groups[key].map((tag) => (
                            <FormControlLabel
                                key={tag.id}
                                sx={{
                                    height: isVertical ? '22px' : '32px',
                                    marginRight: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                value="end"
                                control={
                                    <Checkbox
                                        sx={{ mr: '-0.25rem' }}
                                        disabled={!parsedTagsIDs[tag.id] && isTagsLimited}
                                        size="medium"
                                        checked={Boolean(parsedTagsIDs[tag.id])}
                                        onChange={() => handleCheckbox(tag.id)}
                                    />
                                }
                                labelPlacement="end"
                                label={tag.friendlyName}
                            />
                        ))}
                    </Box>
                ))}
            </Box>

            <Box display="flex" gap="0.5rem" width="100%" justifyContent="center">
                <Button onClick={onResetClick}>Reset</Button>
                <Button onClick={onApplyClick}>Apply</Button>
            </Box>
        </DialogTemplate>
    )
}

PuzzleTags.displayName = 'PuzzleTags'

export default PuzzleTags
