export interface IOverallRecordStats {
    win: number
    draw: number
    loss: number
}

export interface IOverallRecord {
    player1Id: string
    player2Id: string
    stats: IOverallRecordStats
}

const getPlayersStats = async (player1Id: string, player2Id: string, token: string): Promise<IOverallRecord | null> => {
    return null
    // TODO: fix player livetime stats
    const response = await fetch(
        `${
            import.meta.env.VITE_REACT_APP_MM_URL
        }/v1/rest/match/statistic/players?player1Id=${player1Id}&player2Id=${player2Id}&token=${token}`,
        {
            method: 'GET',
        },
    )
    const data: IOverallRecord = await response.json()
    if (!response.ok) {
        return null
    }
    return !!data && data
}

export default getPlayersStats
