import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'

import { getMoveById, getPositionById, getStartingPosition } from '../../chess/gameTree'
import { Color, GameTree, GameTreeMove, GameTreePosition } from '../../chess/types'
import { GameResultType } from '../../store/types'
import { dialogData } from '../gameView/components/gameOverDialog/utils'
import MoveBox from './MoveBox'

type Props = {
    gameTree: GameTree
    timeMode?: {
        name: string
        durationMinutes: number
        clockIncrementSeconds: number
    }
    currentPositionId?: string
    selectMove: (moveId: string) => void
    setEditMoveID: (moveId: string) => void
    setAnnotation: (annotation: string) => void
    handleClickOpen: () => void
    editableAnnotations?: boolean
    result: string
    children?: React.ReactNode
    resultType?: GameResultType
}

const CondensedNotation: React.FC<Props> = ({
    gameTree,
    timeMode,
    currentPositionId,
    selectMove,
    setEditMoveID,
    setAnnotation,
    handleClickOpen,
    editableAnnotations,
    result,
    children,
    resultType,
}) => {
    const { palette } = useTheme()

    let output: JSX.Element[] = []
    let pos = getStartingPosition(gameTree)

    const writeMove = (move: GameTreeMove) => {
        if (!!move) {
            const moveElement = (
                <MoveBox
                    key={move.id}
                    selectMove={() => selectMove(move.id)}
                    setEditMoveID={() => setEditMoveID(move.id)}
                    setAnnotation={() => setAnnotation(move.annotation ?? '')}
                    handleClickOpen={handleClickOpen}
                    editableAnnotations={editableAnnotations}
                    isActive={move.nextPositionId === currentPositionId}
                    move={move}
                />
            )

            output.push(moveElement)
        }
    }

    const writeMoveNumber = (pos: GameTreePosition, nextMoveId: string) => {
        const moveNumberElement = (
            <Typography
                component={'span'}
                style={{
                    fontWeight: 'bold',
                    fontSize: '1.125rem',
                    color: palette.text.primary,
                    marginLeft: '0.75rem',
                }}
                key={pos.position.moveNum + nextMoveId}
            >
                {pos.position.moveNum}
                {pos.position.turn === Color.White ? '.' : '...'}
            </Typography>
        )

        output.push(moveNumberElement)
    }

    const writeVariation = (pos: GameTreePosition) => {
        let forceMoveNumber: boolean = false

        while (pos.nextMoveIds.length > 0) {
            const nextMoveId = pos.nextMoveIds[0]

            if (pos.position.turn === Color.White || forceMoveNumber) {
                writeMoveNumber(pos, nextMoveId)
            }
            forceMoveNumber = false

            const nextMove = getMoveById(gameTree, nextMoveId)
            if (nextMove) {
                writeMove(nextMove)
            }

            for (let i = 1; i < pos.nextMoveIds.length; i++) {
                const nextMove = getMoveById(gameTree, pos.nextMoveIds[i])
                if (!!nextMove) {
                    output.push(
                        <Typography
                            key={nextMove.id + '0'}
                            component={'span'}
                            sx={{ color: 'red', marginLeft: '0.5rem', marginRight: '-0.25rem' }}
                        >
                            {'('}
                        </Typography>,
                    )
                    writeMoveNumber(pos, pos.nextMoveIds[i])
                    writeMove(nextMove)
                    const nextPos = getPositionById(gameTree, nextMove.nextPositionId)
                    writeVariation(nextPos)
                    output.push(
                        <Typography
                            key={nextMove.id + '1'}
                            component={'span'}
                            sx={{ color: 'red', marginLeft: '0.5rem', marginRight: '-0.25rem' }}
                        >
                            {')'}
                        </Typography>,
                    )
                }
                forceMoveNumber = true
            }

            const nextPos = getPositionById(gameTree, nextMove!.nextPositionId)
            if (!nextPos) break
            pos = nextPos
        }
    }

    writeVariation(pos)
    const winner = result === '1-0' ? Color.White : result === '0-1' ? Color.Black : ''
    const description = dialogData[`${resultType}${winner}`]?.description || ''

    return (
        <Box
            sx={{
                backgroundColor: palette.background.paper,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                scrollBehavior: 'smooth',
                flexGrow: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'baseline',
                }}
            >
                {children}
                {output}
            </Box>
            {result && (
                <Typography
                    variant="body2"
                    textAlign="end"
                    key={'result'}
                    component={'span'}
                    color="secondary"
                    sx={{ px: 1 }}
                >
                    {`${description}${result === '*' ? '' : `: ${result}`}`}
                </Typography>
            )}
        </Box>
    )
}

export default CondensedNotation
