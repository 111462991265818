const patterns = [
    {
        key: '{{colorToMove}}',
        value: 'myColor',
    },
    {
        key: '{{engineColor}}',
        value: 'opponentColor',
    },
    {
        key: '{{moves}}',
        value: 'movesCount',
    },
]

interface IDescriptionData {
    myColor: string
    opponentColor: string
    movesCount: number
}

const useDescriptionText = (description: string, data: IDescriptionData) => {
    let result = description
    const info = {
        movesCount: `${data.movesCount} move${data.movesCount > 1 ? 's' : ''}`,
        opponentColor: data.opponentColor,
        myColor: data.myColor,
    }

    patterns.map((pattern) => {
        result = result.replace(new RegExp(pattern.key, 'g'), info[pattern.value])
    })

    return result
}

export default useDescriptionText
