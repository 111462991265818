import { IMAGES_CDN_LINK } from '../../utils/links';

export type BoardColor = {
    main: { backgroundColor: string; backgroundImage?: string; backgroundSize?: string }
    secondary: { backgroundColor: string; backgroundImage?: string; backgroundSize?: string }
    button: { color: string }
}

export const boardColors: Record<string, BoardColor> = {
    default: {
        main: { backgroundColor: '#a4e9f5' },
        secondary: { backgroundColor: '#278bb6' },
        button: { color: '#a4e9f5' },
    },

    spooky: {
        main: { backgroundColor: '#6453AB' },
        secondary: { backgroundColor: '#592E76' },
        button: { color: '#6453AB' },
    },

    treats: {
        main: {
            backgroundColor: '#A79ADF',
            backgroundSize: 'contain',
            backgroundImage: `url(${IMAGES_CDN_LINK}/static/content/trick-or-treat.jpg)`,
        },
        secondary: {
            backgroundColor: '#212842',
        },
        button: {
            color: '#A79ADF',
        },
    },

    haunted: {
        main: { backgroundColor: '#60603C' },
        secondary: { backgroundColor: '#79402D' },
        button: { color: '#60603C' },
    },

    blitzin: {
        main: {
            backgroundColor: '#ECF3FD',
            backgroundSize: 'contain',
            backgroundImage: `url("${IMAGES_CDN_LINK}/static/content/blitzin-primary.jpg")`,
        },
        secondary: {
            backgroundColor: '#7F9AAF',
            backgroundSize: 'contain',
            backgroundImage: `url("${IMAGES_CDN_LINK}/static/content/blitzin-secondary.jpg")`,
        },
        button: { color: '#ECF3FD' },
    },

    blossom: {
        main: { backgroundColor: '#ffffff' },
        secondary: { backgroundColor: '#eea9c2' },
        button: { color: '#eea9c2' },
    },

    cedar: {
        main: {
            backgroundColor: '#fff7e8',
            backgroundSize: 'contain',
            backgroundImage: `url(${IMAGES_CDN_LINK}/static/content/white-wood-1.jpg)`,
        },
        secondary: {
            backgroundColor: '#f7bc78',
            backgroundSize: 'contain',
            backgroundImage: `url(${IMAGES_CDN_LINK}/static/content/orange-wood-4.jpg)`,
        },
        button: {
            color: '#f9b874',
        },
    },

    chocolate: {
        main: {
            backgroundColor: '#6B575D',
        },
        secondary: {
            backgroundColor: '#AC7D7A',
        },
        button: {
            color: '#AC7D7A',
        },
    },

    dasher: {
        main: {
            backgroundColor: '#CFA363',
            backgroundSize: 'contain',
            backgroundImage: `url("${IMAGES_CDN_LINK}/static/content/dasher-primary.jpg")`,
        },
        secondary: {
            backgroundColor: '#481D1E',
            backgroundSize: 'contain',
            backgroundImage: `url("${IMAGES_CDN_LINK}/static/content/dasher-secondary.jpg")`,
        },
        button: { color: '#CFA363' },
    },

    granite: {
        main: {
            backgroundColor: '#2A2C31',
            backgroundSize: 'contain',
            backgroundImage: `url("${IMAGES_CDN_LINK}/static/content/granit-black.webp")`,
        },
        secondary: {
            backgroundColor: '#CCCCCC',
            backgroundSize: 'contain',
            backgroundImage: `url("${IMAGES_CDN_LINK}/static/content/granit-white.webp")`,
        },
        button: {
            color: '#CCCCCC',
        },
    },

    historical: {
        main: { backgroundColor: '#ffdba3' },
        secondary: { backgroundColor: '#b58863' },
        button: { color: '#ffdba3' },
    },

    ice: {
        main: {
            backgroundColor: '#ffffff',
        },
        secondary: {
            backgroundColor: '#70a3c2',
            backgroundSize: 'contain',
            backgroundImage: `url(${IMAGES_CDN_LINK}/static/content/blue-wood-4.jpg)`,
        },
        button: { color: '#a4e9f5' },
    },

    lavender: {
        main: { backgroundColor: '#ffffff' },
        secondary: { backgroundColor: '#7e69ac' },
        button: { color: '#7e69ac' },
    },

    marble: {
        main: {
            backgroundColor: '#ffffff',
            backgroundImage: `url("${IMAGES_CDN_LINK}/static/content/light-marble-2.jpg")`,
        },
        secondary: {
            backgroundColor: '#777777',
            backgroundImage: `url("${IMAGES_CDN_LINK}/static/content/dark-marble-2.jpg")`,
        },
        button: {
            color: '#767676',
        },
    },

    mint: {
        main: { backgroundColor: '#cbe7e1' },
        secondary: { backgroundColor: '#379680' },
        button: { color: '#cbe7e1' },
    },

    newspaper: {
        main: {
            backgroundColor: '#ffffff',
            backgroundSize: 'contain',
            backgroundImage: `url("${IMAGES_CDN_LINK}/static/content/newspaper-light-3.jpg")`,
        },
        secondary: {
            backgroundColor: '#f2f2ef',
            backgroundSize: 'contain',
            backgroundImage: `url("${IMAGES_CDN_LINK}/static/content/newspaper-dark-3.jpg")`,
        },
        button: { color: '#f2f2ef' },
    },

    rust: {
        main: {
            backgroundColor: '#c56d0e',
            backgroundSize: 'contain',
            backgroundImage: `url(${IMAGES_CDN_LINK}/static/content/rusty-white.jpg)`,
        },
        secondary: {
            backgroundColor: '#b58863',
            backgroundSize: 'contain',
            backgroundImage: `url(${IMAGES_CDN_LINK}/static/content/marble-rust.jpg)`,
        },
        button: { color: '#be6303' },
    },

    sorbet: {
        main: { backgroundColor: '#e7cbcb' },
        secondary: { backgroundColor: '#c16172' },
        button: { color: '#c16172' },
    },

    tournament: {
        main: {
            backgroundColor: '#fff3e6',
            backgroundSize: 'contain',
            backgroundImage: `url(${IMAGES_CDN_LINK}/static/content/white-wood-1.jpg)`,
        },
        secondary: {
            backgroundColor: '#60a47d',
            backgroundSize: 'contain',
            backgroundImage: `url(${IMAGES_CDN_LINK}/static/content/green-wood-2.jpg)`,
        },
        button: {
            color: '#5fa47d',
        },
    },

    white: {
        main: { backgroundColor: '#ffffff' },
        secondary: { backgroundColor: '#717274' },
        button: { color: '#ffffff' },
    },

    // greenTexture2: {
    //     main: {
    //         backgroundColor: '#fff9f2',
    //         backgroundSize: 'contain', backgroundImage: `url(${IMAGES_CDN_LINK}/static/content/green-texture-white-1.jpg)`,
    //     },
    //     secondary: {
    //         backgroundColor: '#60a359',
    //         backgroundSize: 'contain', backgroundImage: `url(${IMAGES_CDN_LINK}/static/content/green-texture-4.jpg)`,
    //     },
    //     button: {
    //         color: '#5fa47d',
    //         backgroundColor: '#a9ccb8',
    //     },
    // },
}

export const colors = {
    black: '#000000',
    blue: '#01ABEA',
    buttonHover: '#113F46',
    buttonHoverLight: '#EDF5F6',
    darkBlue: '#101820',
    darkGray: '#40464D',
    gray: '#979797',
    green: '#50AB64',
    hover: '#172B3F',
    hover02opacity: 'rgba(23, 43, 63, 0.2)',
    hover05opacity: 'rgba(23, 43, 63, 0.5)',
    lightBlue: '#A4E9F5',
    lightBlueOnDark: '#39717B',
    lightDarkBlue: '#131D26',
    lightGray: '#F3F0F0',
    lightGray02opacity: 'rgba(243, 240, 240, 0.2)',
    midGray: '#55586A',
    orange: '#FFC58C',
    orangeOnDark: '#8D5E30',
    red: '#FF0900',
    lightRed: '#FF5C5C',
    white: '#FFFFFF',
    whiteHalfOpacity: 'rgba(255, 255, 255, 0.5)',
    yellow: '#FFE49E',
    yellowOnDark: '#9E8545',

    error: {
        main: '#D80031',
        dark: '#EC1C24',
    },
    success: {
        main: '#137A08',
        dark: '#137A08',
    },
    glow: {
        main: '#DCFB19',
    },
}
