import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createRootGameTree, getLastPosition } from '../../../chess/gameTree'
import { BasePositionFEN } from '../../../chess/positionPresets'
import { Rules } from '../../../sharedComponents/src/globalHeader/GlobalHeader'
import { analyticsManager } from '../../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import BotsList from './components/BotsList'
import CustomPanel from './components/CustomPanel'
import updateSelectedBotData from './hooks/updateSelectedBotData'

const PlayBotsPanel: React.FC = () => {
    const botsExist = useStoreState((state) => state.matchMaker.bots.length > 0)
    const gSSocket = useStoreState((state) => state.gameView.socket)
    const playComputerFen = useStoreState((state) => state.analysisMode.playComputerFen)
    const searchOptions = useStoreState((state) => state.matchMaker.searchOptions.computerChallenge)
    const fullSearchOptions = useStoreState((state) => state.matchMaker.searchOptions)
    const openCustomBotPanel = useStoreState((state) => state.matchMaker.openCustomBotPanel)
    const rules = useStoreState((state) => state.rules)
    const connectionStatus = useStoreState((state) => state.matchMaker.connectionStatus)

    const setMatchData = useStoreActions((state) => state.gameView.setMatchData)
    const clearGSConnection = useStoreActions((state) => state.gameView.shutDownGSConnection)
    const sendAIChallenge = useStoreActions((state) => state.matchMaker.sendAIChallenge)
    const setSearchOptions = useStoreActions((state) => state.matchMaker.setSearchOptions)
    const setOpenCustomBotPanel = useStoreActions((state) => state.matchMaker.setOpenCustomBotPanel)
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)
    const setPlayComputerFen = useStoreActions((state) => state.analysisMode.setPlayComputerFen)
    const setGameTree = useStoreActions((state) => state.gameView.setGameTree)
    const setCurrentPositionId = useStoreActions((state) => state.gameView.setCurrentPositionId)

    const [playDisabled, setPlayDisabled] = useState<boolean>(true)
    const [validFen, setValidFen] = useState<boolean>(true)

    const navigate = useNavigate()

    const playBotsEnabled = rules.some((rule) => rule === Rules.FULL_ACCESS || rule === Rules.PLAY_BOTS)

    const setupBoard = (fen: string) => {
        const newGameTree = createRootGameTree(fen)
        setGameTree(newGameTree)
        const currentPositionId = getLastPosition(newGameTree).id
        setCurrentPositionId(currentPositionId)
    }

    // reset board on unmount
    useEffect(() => {
        return () => {
            setupBoard(BasePositionFEN)
        }
    }, [])

    // reset selected bot data on close custom bot panel
    useEffect(() => {
        if (searchOptions.selectedBot && openCustomBotPanel === false) {
            const updatedSearchOptions = updateSelectedBotData(fullSearchOptions, searchOptions.selectedBot)
            setSearchOptions(updatedSearchOptions)
            setPlayComputerFen(searchOptions.selectedBot.startingFEN || '')
        }
    }, [openCustomBotPanel])

    // set up board on playComputerFen change
    useEffect(() => {
        let fen = playComputerFen
        if (playComputerFen === '') {
            fen = BasePositionFEN
        }
        setTimeout(() => {
            setupBoard(fen)
        }, 1)
    }, [playComputerFen])

    // validation for play button
    useEffect(() => {
        if (botsExist && searchOptions.minutes > 0 && searchOptions.increment >= 0 && validFen) {
            setPlayDisabled(false)
        } else {
            setPlayDisabled(true)
        }
    }, [
        botsExist,
        connectionStatus,
        searchOptions.customTime,
        searchOptions.minutes,
        searchOptions.increment,
        playComputerFen,
        openCustomBotPanel,
        validFen,
    ])

    // create game on play button click
    const send = () => {
        if (playBotsEnabled || !searchOptions.selectedBot?.strengthLevel) {
            gSSocket?.close()
            clearGSConnection()
            gSSocket?.addEventListener('close', () => {
                setMatchData({
                    matchId: '',
                    gameServiceUrl: '',
                })
            })

            let customMinutes = searchOptions.minutes
            let selectedMinutes = searchOptions.selectedChallenge
                ? Number(searchOptions.selectedChallenge.split(',')[0])
                : null
            let customIncrement = searchOptions.increment
            let selectedIncrement = searchOptions.selectedChallenge
                ? Number(searchOptions.selectedChallenge.split(',')[1])
                : null

            sendAIChallenge({
                color: searchOptions.color,
                fen: playComputerFen,
                strength: searchOptions.strength,
                time: searchOptions.customTime ? customMinutes : selectedMinutes!,
                increment: searchOptions.customTime ? customIncrement : selectedIncrement!,
                challengeeId: searchOptions.selectedBot?.id,
                navigate: navigate,
            })

            analyticsManager.dispatchEvent('computerChallenge', {
                minutes: searchOptions.customTime ? customMinutes : selectedMinutes!,
                increment: searchOptions.customTime ? customIncrement : selectedIncrement!,
                difficulty: searchOptions.strength,
                color: searchOptions.color === 'no_color' ? 'noColor' : searchOptions.color,
                gameType: openCustomBotPanel ? 'custom' : 'standard',
                botName: searchOptions.selectedBot?.name || 'Stockfish',
                botId: searchOptions.selectedBot?.id || '',
            })

            navigate('/')
        } else {
            setOverlayTrigger('joinUs')
        }
    }

    return (
        <Stack gap={1.5} sx={{ flexGrow: 1, minHeight: 0 }}>
            <Stack sx={{ flexGrow: 1, minHeight: 0 }}>
                {botsExist ? (
                    openCustomBotPanel ? (
                        <CustomPanel validFen={validFen} setValidFen={setValidFen} />
                    ) : (
                        <Stack sx={{ flexGrow: 1, minHeight: 0 }}>
                            <Typography variant="h6">Choose your opponent:</Typography>
                            <BotsList />
                        </Stack>
                    )
                ) : (
                    <Stack gap={1} sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="h6">No bots available</Typography>
                        <Typography variant="body2">Please try again later</Typography>
                    </Stack>
                )}
            </Stack>
            <Stack gap={1} direction="row">
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => setOpenCustomBotPanel(!openCustomBotPanel)}
                    disabled={!botsExist}
                >
                    {openCustomBotPanel ? 'Cancel' : 'Custom'}
                </Button>
                <Button variant={'contained'} fullWidth onClick={send} disabled={playDisabled}>
                    {playBotsEnabled || searchOptions.selectedBot?.difficultyLevel === -1 ? 'Play' : 'Unlock'}
                </Button>
            </Stack>
        </Stack>
    )
}

PlayBotsPanel.displayName = 'PlayBotsPanel'

export default PlayBotsPanel
