import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { Countdown } from '../components/Countdown'
import { resendEmailTimeout } from '../GlobalHeader'

const buttonStyle = {
    padding: '10px 57px',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: '21px',
    border: '2px solid #000000',
    width: '182px',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0',
    fontFamily: 'Fira Sans',
    '&:hover': {
        backgroundColor: '#101820',
        color: '#FFFFFF',
    },

    '&:disabled': {
        color: '#C3C3C3',
        border: '2px solid #C3C3C3',
    },
}

const typographyStyle = {
    color: 'rgb(16 24 32)',
    fontSize: '14px',
    fontFamily: 'Space Grotesk',
}

const stackStyle = {
    padding: '16px',
    backgroundColor: '#fff',
    minWidth: '338px',
    maxWidth: '338px',
    border: '2px solid #101820',
}

export interface ConfirmEmailFormProps {
    onClose: () => void
    onResend: (event: any, email?: string) => void
    resent: '' | 'success' | 'error'
    countDown: number
    setCountDown: React.Dispatch<React.SetStateAction<number>>
    resendMessage: string
    failed: boolean
}

function ConfirmEmailForm(props: ConfirmEmailFormProps) {
    const { onClose, onResend, resent, resendMessage, failed, countDown, setCountDown } = props
    const urlParams = new URLSearchParams(decodeURIComponent(window.location.search))
    const email = urlParams.get('email')
    const decodedEmail = email?.replace(' ', '+') || ''

    useEffect(() => {
        if (failed) {
            setCountDown(0)
        }
        return () => {
            setCountDown(resendEmailTimeout)
        }
    }, [failed])

    return (
        <Dialog onClose={onClose} open={true} sx={{ zIndex: '1600' }} PaperProps={{ sx: { m: 0 } }}>
            <Stack style={stackStyle}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton sx={{ color: 'black' }} onClick={onClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
                <Typography
                    variant="h3"
                    sx={{
                        color: 'rgb(16 24 32)',
                        textAlign: 'center',
                    }}
                >
                    {failed ? 'Whoops, that link expired' : 'Confirm your email'}
                </Typography>
                <Box style={{ display: 'flex', justifyContent: 'center', paddingTop: 20, paddingLeft: '20px' }}>
                    <img src="/assets/images/icons/confirm-mail.svg" alt="Email envelope" />
                </Box>

                <Box justifyContent={'center'} alignSelf={'center'}>
                    <Typography sx={typographyStyle} style={{ textAlign: 'center', paddingTop: '20px' }}>
                        {failed
                            ? 'Click resend to receive a new activation email. Link expires in 15 minutes.'
                            : 'Please check your e-mail and click on the link to activate your account. Link expires in 15 minutes'}
                    </Typography>
                </Box>

                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '35px',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    {resent === '' && (
                        <Typography
                            variant="body1"
                            sx={{
                                color: 'rgb(16 24 32)',
                                fontSize: '14px',
                                fontFamily: 'Space Grotesk',
                                paddingBottom: '10px',
                            }}
                        >
                            {failed ? '' : 'Didn’t receive an e-mail?'}
                        </Typography>
                    )}
                    {resent && (
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#101820',
                                fontSize: '14px',
                                fontFamily: 'Space Grotesk',
                                fontWeight: 'bold',
                                paddingBottom: '10px',
                            }}
                        >
                            {resendMessage}
                        </Typography>
                    )}
                    <Button
                        disabled={Boolean(countDown)}
                        sx={buttonStyle}
                        onClick={(event) => onResend(event, decodedEmail)}
                    >
                        {Boolean(countDown) ? (
                            <>
                                Resend in <Countdown count={countDown} setCount={setCountDown} />
                            </>
                        ) : (
                            'Resend'
                        )}
                    </Button>
                </Box>
            </Stack>
        </Dialog>
    )
}

export default ConfirmEmailForm
