import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Slider,
    TextField,
    Typography,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { ColorSelector } from '../../components/colorSelector/ColorSelector'
import { determineTimeControlName } from '../../functions/determineTimeControl'
import { analyticsManager } from '../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { MMChallengeType } from '../../store/types'

export type CustomChallengeDrawerProps = {
    onClose: () => void
    userRating?: number
}

function CustomChallengeDrawer(props: CustomChallengeDrawerProps) {
    const { onClose } = props
    const maxRatingInputRef = useRef<HTMLInputElement>(null)
    const minRatingInputRef = useRef<HTMLInputElement>(null)
    const sendPlayerChallenge = useStoreActions((state) => state.matchMaker.sendPlayerChallenge)
    const searchOptions = useStoreState((state) => state.matchMaker.searchOptions.customChallenge)
    const fullSearchOptions = useStoreState((state) => state.matchMaker.searchOptions)
    const setSearchOptions = useStoreActions((state) => state.matchMaker.setSearchOptions)
    const userData = useStoreState((state) => state.userData)
    const settings = useStoreState((state) => state.gameView.settings)

    const timeControlName = determineTimeControlName(searchOptions.minutes, searchOptions.increment)

    const rating = userData.userData ? userData.userData.ratings[timeControlName].rating : 0

    const [strength, setStrength] = useState<number[]>([rating - 150, rating + 150])
    useEffect(() => {
        if (minRatingInputRef.current && maxRatingInputRef.current) {
            minRatingInputRef.current.value = strength[0].toString()
            maxRatingInputRef.current.value = strength[1].toString()
        }
    }, [strength])

    useEffect(() => {
        if (searchOptions.fullRatingRange) setStrength([0, 9999])
        else setStrength([rating - 150, rating + 150])
    }, [timeControlName, rating, searchOptions.fullRatingRange])

    function valuetext(value: number) {
        return `${value}`
    }

    const createMarks = (userRating: number) => {
        const marks = [] as { value: number; label: string }[]
        for (let i = rating - 500 < 0 ? 0 : rating - 500; i <= rating + 500; i += 100) {
            let previousValue = i - 100
            let nextValue = i + 100
            if (userRating >= previousValue && userRating <= nextValue) {
                marks.push({ value: userRating, label: '' })
                marks.push({ value: nextValue, label: '' })
            } else {
                marks.push({ value: i, label: '' })
            }
        }
        return marks
    }

    const marks = createMarks(rating)

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setStrength(newValue as number[])
    }

    const send = () => {
        sendPlayerChallenge({
            color: searchOptions.color,
            rated: searchOptions.rated,
            time: searchOptions.minutes,
            increment: searchOptions.increment,
            ratingFrom: strength[0],
            ratingTo: strength[1],
            challengeType: MMChallengeType.CUSTOM,
        })
        analyticsManager.dispatchEvent('customChallenge', {
            color: searchOptions.color === 'no_color' ? 'noColor' : searchOptions.color,
            minutes: searchOptions.minutes,
            increment: searchOptions.increment,
            rated: searchOptions.rated,
            ratingFrom: strength[0],
            ratingTo: strength[1],
        })
        onClose()
    }

    useEffect(() => {
        if (searchOptions.fullRatingRange) {
            setStrength([0, 9999])
        } else {
            setStrength([rating - 150, rating + 150])
        }
    }, [searchOptions.fullRatingRange, rating])

    const handleStrengthChange = (event: any, newValue: number, index: number) => {
        let validatedValue = 0
        if (index === 0) {
            if (newValue >= strength[1]) {
                validatedValue = Math.max(strength[1] - 1, rating - 500)
            } else {
                validatedValue = Math.max(newValue, rating - 500)
            }
        } else {
            if (newValue <= strength[0]) {
                validatedValue = Math.min(strength[0] + 1, rating + 500)
            } else {
                validatedValue = Math.min(newValue, rating + 500)
            }
        }

        const newStrength = [...strength]
        newStrength[index] = Number(validatedValue)
        setStrength(newStrength)
    }

    const handleTimeChange = (value: any) => {
        const numberValue = Number(value)
        if (isNaN(numberValue) || numberValue < 1) {
            return ''
        }
        const clampedValue = Math.min(numberValue, 180)
        if (clampedValue.toString() !== value.toString()) {
            return clampedValue.toString()
        }
        return value
    }

    return (
        <>
            <Typography variant={'h4'}>CUSTOM CHALLENGE</Typography>

            <Box
                sx={{
                    flexGrow: 1,
                    gap: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    justifyContent: !settings.friendlyMode ? 'flex-start' : 'space-around',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: !settings.friendlyMode ? 'flex-start' : 'center',
                    }}
                >
                    <Typography variant="h6" fontFamily="Space Grotesk" fontWeight={700} paragraph>
                        TIME CONTROL
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: !settings.friendlyMode ? '100%' : '60%',
                            flexDirection: !settings.friendlyMode ? 'row' : 'column',
                        }}
                        gap={4}
                    >
                        <TextField
                            label="Minutes"
                            variant="standard"
                            sx={{
                                display: 'flex',
                                '& .MuiInputBase-input': {
                                    textAlign: 'center',
                                },
                            }}
                            value={searchOptions.minutes}
                            onChange={(e) =>
                                setSearchOptions({
                                    ...fullSearchOptions,
                                    customChallenge: {
                                        ...searchOptions,
                                        minutes: Number(handleTimeChange(e.target.value)),
                                    },
                                })
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            onClick={() => {
                                                setSearchOptions({
                                                    ...fullSearchOptions,
                                                    customChallenge: {
                                                        ...searchOptions,
                                                        minutes: Number(handleTimeChange(searchOptions.minutes - 1)),
                                                    },
                                                })
                                            }}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                setSearchOptions({
                                                    ...fullSearchOptions,
                                                    customChallenge: {
                                                        ...searchOptions,
                                                        minutes: Number(handleTimeChange(searchOptions.minutes + 1)),
                                                    },
                                                })
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="Increment"
                            variant="standard"
                            sx={{
                                display: 'flex',
                                '& .MuiInputBase-input': {
                                    textAlign: 'center',
                                },
                            }}
                            value={searchOptions.increment}
                            onChange={(e) =>
                                setSearchOptions({
                                    ...fullSearchOptions,
                                    customChallenge: {
                                        ...searchOptions,
                                        increment: Number(handleTimeChange(e.target.value)),
                                    },
                                })
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            sx={{
                                                borderRadius: '0',

                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}
                                            onClick={() => {
                                                setSearchOptions({
                                                    ...fullSearchOptions,
                                                    customChallenge: {
                                                        ...searchOptions,
                                                        increment: Number(
                                                            handleTimeChange(searchOptions.increment - 1),
                                                        ),
                                                    },
                                                })
                                            }}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                setSearchOptions({
                                                    ...fullSearchOptions,
                                                    customChallenge: {
                                                        ...searchOptions,
                                                        increment: Number(
                                                            handleTimeChange(searchOptions.increment + 1),
                                                        ),
                                                    },
                                                })
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>
                {!settings.friendlyMode && (
                    <>
                        <Box display="flex" flexDirection="column" width="100%" margin="0 auto" mt="32px">
                            <FormControlLabel
                                sx={{ margin: 0, justifyContent: 'space-between' }}
                                control={<Checkbox />}
                                label="Rated Game"
                                labelPlacement="start"
                                onChange={(e: any) =>
                                    setSearchOptions({
                                        ...fullSearchOptions,
                                        customChallenge: {
                                            ...searchOptions,
                                            rated: e.target.checked,
                                        },
                                    })
                                }
                                checked={searchOptions.rated}
                            />
                            <FormControlLabel
                                sx={{ margin: 0, justifyContent: 'space-between' }}
                                control={<Checkbox />}
                                label="Entire Rating Range"
                                labelPlacement="start"
                                checked={searchOptions.fullRatingRange}
                                onChange={() =>
                                    setSearchOptions({
                                        ...fullSearchOptions,
                                        customChallenge: {
                                            ...searchOptions,
                                            fullRatingRange: !searchOptions.fullRatingRange,
                                        },
                                    })
                                }
                            />
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} gap={4}>
                                <TextField
                                    onKeyDown={(event) => event.key === 'Enter' && maxRatingInputRef.current?.select()}
                                    inputRef={minRatingInputRef}
                                    type="number"
                                    label="Min Rating"
                                    variant="standard"
                                    sx={{
                                        display: 'flex',
                                        '& .MuiInputBase-input': {
                                            textAlign: 'center',
                                        },
                                        '& .MuiInput-input': {
                                            padding: '8px 0px !important',
                                        },
                                    }}
                                    defaultValue={strength[0]}
                                    disabled={searchOptions.fullRatingRange}
                                    onBlur={(e) => handleStrengthChange(e, Number(handleTimeChange(e.target.value)), 0)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    disabled={searchOptions.fullRatingRange}
                                                    onClick={() => {
                                                        handleStrengthChange(
                                                            { target: { value: strength[0] - 50 } },
                                                            strength[0] - 50,
                                                            0,
                                                        )
                                                    }}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={searchOptions.fullRatingRange}
                                                    onClick={() => {
                                                        handleStrengthChange(
                                                            { target: { value: strength[0] + 50 } },
                                                            strength[0] + 50,
                                                            0,
                                                        )
                                                    }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    onKeyDown={(event) => event.key === 'Enter' && maxRatingInputRef.current?.blur()}
                                    inputRef={maxRatingInputRef}
                                    type="number"
                                    disabled={searchOptions.fullRatingRange}
                                    label="Max Rating"
                                    variant="standard"
                                    sx={{
                                        display: 'flex',
                                        '& .MuiInputBase-input': {
                                            textAlign: 'center',
                                        },
                                        '& .MuiInput-input': {
                                            padding: '8px 0px !important',
                                        },
                                    }}
                                    defaultValue={strength[1]}
                                    onBlur={(e) => handleStrengthChange(e, Number(handleTimeChange(e.target.value)), 1)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    disabled={searchOptions.fullRatingRange}
                                                    onClick={() => {
                                                        handleStrengthChange(
                                                            { target: { value: strength[1] - 50 } },
                                                            strength[1] - 50,
                                                            1,
                                                        )
                                                    }}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={searchOptions.fullRatingRange}
                                                    onClick={() => {
                                                        handleStrengthChange(
                                                            { target: { value: strength[1] + 50 } },
                                                            strength[1] + 50,
                                                            1,
                                                        )
                                                    }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                            <Box padding="0px 26px">
                                <Slider
                                    getAriaLabel={() => 'Rating Range'}
                                    defaultValue={rating}
                                    getAriaValueText={valuetext}
                                    value={strength}
                                    step={1}
                                    marks={marks}
                                    min={rating - 500 < 0 ? 0 : rating - 500}
                                    max={rating + 500}
                                    valueLabelDisplay="auto"
                                    onChange={handleSliderChange}
                                    sx={{ marginTop: '1.875rem', color: 'text.primary' }}
                                    disabled={searchOptions.fullRatingRange}
                                />
                            </Box>
                            <Typography textAlign="center" variant="body2">
                                {`Your ${timeControlName} rating: `}
                                <Typography component="span" variant="h6" color="secondary.main">
                                    {rating.toString()}
                                </Typography>
                            </Typography>
                        </Box>
                    </>
                )}

                <ColorSelector
                    value={searchOptions.color}
                    onChange={(color) => {
                        setSearchOptions({
                            ...fullSearchOptions,
                            customChallenge: {
                                ...searchOptions,
                                color,
                            },
                        })
                    }}
                />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                gap="8px"
            >
                <Button variant="outlined" fullWidth onClick={onClose}>
                    CANCEL
                </Button>
                <Button variant="contained" fullWidth onClick={send} disabled={searchOptions.minutes < 1}>
                    CREATE
                </Button>
            </Box>
        </>
    )
}

export default CustomChallengeDrawer
