import { Action, action } from 'easy-peasy'
import { positionToFEN } from '../../chess/fen'
import { createRootGameTree, getStartingPosition, undoLastMove } from '../../chess/gameTree'
import { BasePositionFEN } from '../../chess/positionPresets'
import { Color, GameTree } from '../../chess/types'
import { ITag } from './functions/puzzlesApi'

export type PuzzlesModel = {
    flipped: boolean
    puzzleGameTree: GameTree
    progressCurrentPositionId: string
    gameTree: GameTree
    currentPositionId: string
    myColor: Color
    colorToMove: number
    allTags: Array<ITag>
    userRating: number
    setGameTree: Action<PuzzlesModel, GameTree>
    setProgressCurrentPositionId: Action<PuzzlesModel, string>
    setCurrentPositionId: Action<PuzzlesModel, string>
    setFlipBoard: Action<PuzzlesModel, boolean>
    initialiseGame: Action<PuzzlesModel, { gameTree?: GameTree | undefined; isFirstMove?: boolean | undefined }>
    setColorToMove: Action<PuzzlesModel, number>
    undoLastMove: Action<PuzzlesModel, GameTree>
    setAllTags: Action<PuzzlesModel, Array<ITag>>
    resetGame: Action<PuzzlesModel>
    setUserRating: Action<PuzzlesModel, number>
}

const initialGameTree = createRootGameTree(BasePositionFEN)
const initialCurrentPosition = getStartingPosition(initialGameTree).id

const PuzzlesViewModel: PuzzlesModel = {
    myColor: Color.White,
    flipped: false,
    puzzleGameTree: initialGameTree,
    progressCurrentPositionId: initialCurrentPosition,
    gameTree: initialGameTree,
    currentPositionId: initialCurrentPosition,
    colorToMove: 1,
    allTags: [],
    userRating: 0,

    // ----------------------------------------------------------------------------- actions
    setGameTree: action((state, payload) => {
        state.gameTree = payload
        state.currentPositionId = getStartingPosition(payload).id
    }),

    setProgressCurrentPositionId: action((state, payload) => {
        state.progressCurrentPositionId = payload
    }),

    setCurrentPositionId: action((state, payload) => {
        state.currentPositionId = payload
    }),

    setFlipBoard: action((state, flipped) => {
        state.flipped = flipped
    }),

    initialiseGame: action((state, gd) => {
        const newPuzzleGameTree = gd.gameTree || initialGameTree
        state.puzzleGameTree = newPuzzleGameTree
        const startingProgressPosition = getStartingPosition(newPuzzleGameTree)
        state.progressCurrentPositionId = startingProgressPosition.id

        const newGameTree = createRootGameTree(positionToFEN(startingProgressPosition.position))
        state.gameTree = newGameTree
        state.currentPositionId = getStartingPosition(newGameTree).id

        if (gd.isFirstMove) {
            state.myColor = startingProgressPosition.position.turn
        } else if (startingProgressPosition.position.turn === Color.Black) {
            state.myColor = Color.White
        } else {
            state.myColor = Color.Black
        }

        state.flipped = state.myColor === Color.Black
    }),

    setColorToMove: action((state, number) => {
        state.colorToMove = number
    }),

    undoLastMove: action((state, payload) => {
        const previousPositionId = undoLastMove(payload).id || initialCurrentPosition
        state.currentPositionId = previousPositionId
        state.gameTree = payload
    }),

    setAllTags: action((state, tags) => {
        state.allTags = tags
    }),

    resetGame: action((state) => {
        state.myColor = Color.White
        state.puzzleGameTree = initialGameTree
        state.progressCurrentPositionId = initialCurrentPosition
        state.gameTree = initialGameTree
        state.currentPositionId = initialCurrentPosition
        state.colorToMove = 1
    }),

    setUserRating: action((state, rating) => {
        state.userRating = rating
    }),
}

export default PuzzlesViewModel
