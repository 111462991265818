import toPrecision from '../functions/toPrecision'

class PgnCommandHandler {
    static extractCommandsFromAnnotation(annotation: string): [string, Map<string, string[]>, string[]] {
        const regex = /\[%(\w+)\s+([^[\]]+)\]/g
        const commands = new Map<string, string[]>()
        const commandsAsString: string[] = []
        let match,
            pureComment = annotation

        while ((match = regex.exec(annotation))) {
            const fullMatch = match[0]
            const commandName = match[1]
            const parameterList = match[2]
            const parameters = parameterList.split(',').map((param) => param.trim())

            commands.set(commandName, parameters)
            commandsAsString.push(fullMatch)
            pureComment = pureComment.replace(fullMatch, '')
        }

        // Trim the modified comment to remove any leading/trailing whitespace
        pureComment = pureComment
            .trim()
            .split(' ')
            .filter((word) => word !== '')
            .join(' ')

        return [pureComment, commands, commandsAsString]
    }

    static injectPgnCommandsIntoAnnotation(annotation: string, commands: string[] | Map<string, string[]>): string {
        if (Array.isArray(commands)) {
            return commands.join(' ') + ' ' + annotation
        } else {
            const commandArray: string[] = []

            commands.forEach((parameters, command) => {
                commandArray.push('[%' + command + ' ' + parameters.join(',') + ']')
            })

            return commandArray.join(' ') + ' ' + annotation
        }
    }

    static parseClkCommand(commands: Map<string, string[]>): number | undefined {
        const parameters = commands.get('clk')

        if (!parameters || parameters.length === 0) {
            return undefined
        }

        return this.parseClockCommandParameter(parameters[0])
    }

    static parseEmtCommand(commands: Map<string, string[]>): number | undefined {
        const parameters = commands.get('emt')

        if (!parameters || parameters.length === 0) {
            return undefined
        }

        return this.parseClockCommandParameter(parameters[0])
    }

    static createClkCommand(clock: number): { key: string; params: string[] } {
        const seconds = clock % 60
        const minutes = Math.floor(clock / 60) % 60
        const hours = Math.floor(clock / 3600)

        const param = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds.toFixed(3)}`

        return { key: 'clk', params: [param] }
    }

    static parseClockCommandParameter(parameter: string) {
        const timeComponents = parameter.split(':')
        let seconds = 0

        // format is HH:MM:SS.SSS
        if (timeComponents.length >= 3) {
            const hours = parseInt(timeComponents[0])
            const minutes = parseInt(timeComponents[1])
            seconds = parseFloat(timeComponents[2])
            seconds += hours * 3600 + minutes * 60
        }
        // format is MM:SS.SSS 
        else if (timeComponents.length >= 2) {
            const minutes = parseInt(timeComponents[0])
            seconds = parseFloat(timeComponents[1])
            seconds += minutes * 60
        }

        return toPrecision(seconds, 1)
    }

    static stringifyClockCommandParameter(seconds: number) {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds - hours * 3600) / 60)
        const remainingSeconds = seconds - hours * 3600 - minutes * 60

        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${Math.round(
            remainingSeconds,
        )}`
    }
}

export default PgnCommandHandler
