import { Color } from '../../../../chess/types'
import { useStoreActions, useStoreState } from '../../../../store/hooks'
import { GameViewState } from '../../GameViewModel'
import { Clock } from './Clock'

const SHOW_ADD_TIME = false

type Props = {
    color: Color
    extraClass?: string
}

const GameViewClock = ({ color, extraClass }: Props) => {
    const clockInstantWhite = useStoreState((state) => state.gameView.clockInstantWhite)
    const clockInstantBlack = useStoreState((state) => state.gameView.clockInstantBlack)
    const gameState = useStoreState((state) => state.gameView.gameState)
    const myColor = useStoreState((state) => state.gameView.myColor)
    const clockRunningFor = useStoreState((state) => state.gameView.clockRunningFor)

    const sendAdd30Seconds = useStoreActions((state) => state.gameView.sendAdd30Seconds)

    const gameRunning = gameState === GameViewState.GAME_RUNNING

    return (
        <Clock
            onAddTime={SHOW_ADD_TIME && gameRunning && myColor !== color ? sendAdd30Seconds : undefined}
            extraClass={extraClass}
            running={gameRunning && clockRunningFor === color}
            instant={color === Color.White ? clockInstantWhite : clockInstantBlack}
        />
    )
}

export default GameViewClock
