const SupportIcon: React.FC = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14 0.666992C6.64002 0.666992 0.666687 6.64033 0.666687 14.0003C0.666687 21.3603 6.64002 27.3337 14 27.3337C21.36 27.3337 27.3334 21.3603 27.3334 14.0003C27.3334 6.64033 21.36 0.666992 14 0.666992ZM15.3334 23.3337H12.6667V20.667H15.3334V23.3337ZM18.0934 13.0003L16.8934 14.227C15.9334 15.2003 15.3334 16.0003 15.3334 18.0003H12.6667V17.3337C12.6667 15.867 13.2667 14.5337 14.2267 13.5603L15.88 11.8803C16.3734 11.4003 16.6667 10.7337 16.6667 10.0003C16.6667 8.53366 15.4667 7.33366 14 7.33366C12.5334 7.33366 11.3334 8.53366 11.3334 10.0003H8.66669C8.66669 7.05366 11.0534 4.66699 14 4.66699C16.9467 4.66699 19.3334 7.05366 19.3334 10.0003C19.3334 11.1737 18.8534 12.2403 18.0934 13.0003Z"
            fill="white"
        />
    </svg>
)

SupportIcon.displayName = 'SupportIcon'

export default SupportIcon
