import { colors } from '../../../sharedComponents/src/globalHeader/theme/colors'

type Props = { status: 'online' | 'offline'; style?: React.CSSProperties }

export const StatusIndicator = ({ status, style }: Props) => {
    return (
        <div
            style={{
                backgroundColor: status === 'online' ? colors.green : colors.gray,
                width: '.625rem',
                height: '.625rem',
                borderRadius: '100%',
                ...style,
            }}
        />
    )
}
