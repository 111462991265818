import { Button, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useStoreActions } from '../../store/hooks'

type Props = {
    onPlayClick: () => void
}

const AnalysisNavigation = ({ onPlayClick }: Props) => {
    const setDrawerOpen = useStoreActions((state) => state.setDrawerOpen)
    const setIsDrawerMenuOpen = useStoreActions((state) => state.setIsDrawerMenuOpen)
    const navigate = useNavigate()

    return (
        <Stack direction="row" justifyContent={'center'} spacing="4px">
            <Button variant={'contained'} onClick={onPlayClick}>
                Play
            </Button>
            <Button
                variant={'contained'}
                onClick={() => {
                    setIsDrawerMenuOpen(true)
                    setDrawerOpen('open')
                    navigate('/')
                }}
            >
                Menu
            </Button>
        </Stack>
    )
}

export default AnalysisNavigation
