import AddIcon from '@mui/icons-material/Add'
import ComputerIcon from '@mui/icons-material/Computer'
import DownloadIcon from '@mui/icons-material/Download'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import UploadIcon from '@mui/icons-material/Upload'
import { Box, Button, Paper, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { Form } from 'react-router-dom'
import CircularButton from './CircularButton'
export type AnalysisControlsProps = {
    onNewGameClick: () => void
    onNewGameClickIsDisabled?: boolean
    onResetVariationsClick: () => void
    onResetVariationsClickIsDisabled?: boolean
    onPlayComputerClick: () => void
    onPlayComputerClickIsDisabled?: boolean
    onFolderClick: () => void
    onFolderClickIsDisabled?: boolean
    onDownloadPGNClick: () => void
    onDownloadPGNClickIsDisabled?: boolean
    onUploadPGNCLick: (e: React.ChangeEvent<HTMLInputElement> | HTMLTextAreaElement) => void
    onUploadPGNCLickIsDisabled?: boolean
    onShareGameClick: () => void
    onShareGameClickIsDisabled?: boolean
    onFlipClick: () => void
}

export function AnalysisControls(props: AnalysisControlsProps) {
    const {
        onNewGameClick,
        onNewGameClickIsDisabled,
        onResetVariationsClick,
        onResetVariationsClickIsDisabled,
        onPlayComputerClick,
        onPlayComputerClickIsDisabled,
        onDownloadPGNClick,
        onDownloadPGNClickIsDisabled,
        onUploadPGNCLick,
        onUploadPGNCLickIsDisabled,
        onFlipClick,
    } = props
    const [uploadPGNFormOpen, setUploadPGNFormOpen] = React.useState(false)

    return (
        <>
            <Paper className={'pgn-form'}>
                <Box
                    sx={{
                        display: uploadPGNFormOpen ? 'block' : 'none',
                    }}
                    pb={1}
                >
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            const input = document.getElementById('pgn-textbox') as HTMLTextAreaElement
                            if (input) {
                                onUploadPGNCLick(input)
                                //clear input
                                input.value = ''
                            }
                        }}
                    >
                        <TextField
                            id="pgn-textbox"
                            className="pgn-textbox"
                            label="PASTE PGN HERE"
                            multiline
                            rows={1}
                            fullWidth
                        />

                        <Stack gap={1} direction="row" width="100%" justifyContent="center" flex={1} pt={2}>
                            <Button
                                onClick={() => {
                                    const input = document.getElementById('contained-button-file')
                                    if (input) {
                                        input.click()
                                    }
                                    setUploadPGNFormOpen(false)
                                }}
                                variant="outlined"
                                className="uploadPGNFormButton"
                                disabled={onUploadPGNCLickIsDisabled}
                            >
                                Upload PGN File
                            </Button>
                            <Button
                                className="usePGNFormButton"
                                variant="outlined"
                                type="submit"
                                onClick={() => setUploadPGNFormOpen(false)}
                            >
                                Use Pasted PGN
                            </Button>
                        </Stack>
                    </Form>
                    <input
                        accept="application/x-chess-pgn"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) => {
                            console.log('onUploadPGNCLick', e)
                            onUploadPGNCLick(e as React.ChangeEvent<HTMLInputElement>)
                        }}
                    />
                </Box>
            </Paper>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '12px' }}>
                <CircularButton onClick={onFlipClick} title="Flip board" icon={<SwapVertIcon />} />
                <CircularButton
                    onClick={onNewGameClick}
                    title="New Analysis"
                    disabled={onNewGameClickIsDisabled}
                    icon={<AddIcon />}
                />
                <CircularButton
                    onClick={onResetVariationsClick}
                    title="Reset variations"
                    icon={<RestartAltIcon />}
                    disabled={onResetVariationsClickIsDisabled}
                />
                <CircularButton
                    onClick={onPlayComputerClick}
                    title="Play computer from position"
                    icon={<ComputerIcon />}
                    disabled={onPlayComputerClickIsDisabled}
                />
                <CircularButton
                    onClick={onDownloadPGNClick}
                    title="Download PGN"
                    icon={<DownloadIcon />}
                    disabled={onDownloadPGNClickIsDisabled}
                />
                <CircularButton
                    onClick={() => {
                        setUploadPGNFormOpen(!uploadPGNFormOpen)
                    }}
                    active={uploadPGNFormOpen}
                    title="Upload PGN"
                    icon={<UploadIcon />}
                    disabled={onUploadPGNCLickIsDisabled}
                />
            </Box>
        </>
    )
}
