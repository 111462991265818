export function Blitz() {
    return (
        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_301_37011)">
                <path d="M3.23232 9.61243C3.18189 9.61183 3.13217 9.60046 3.08649 9.57909C3.01806 9.54672 2.96287 9.49176 2.93024 9.42345C2.8976 9.35515 2.88951 9.27768 2.90732 9.20411L3.76982 5.45411H2.58649C2.53525 5.45368 2.48474 5.44186 2.43862 5.41952C2.3925 5.39718 2.35192 5.36488 2.31982 5.32494C2.28889 5.28381 2.2679 5.23605 2.2585 5.18545C2.24911 5.13485 2.25155 5.08277 2.26565 5.03327L3.40315 0.866603C3.42313 0.795807 3.4657 0.733469 3.52436 0.689092C3.58303 0.644715 3.65459 0.620717 3.72815 0.620758H6.88232C6.93886 0.620714 6.99447 0.635047 7.04394 0.662415C7.09341 0.689782 7.13511 0.729293 7.16511 0.777212C7.19511 0.82513 7.21243 0.87988 7.21544 0.936335C7.21845 0.99279 7.20706 1.0491 7.18232 1.09994L5.89066 3.69576H7.41149C7.47448 3.69533 7.53631 3.71275 7.58981 3.74601C7.6433 3.77928 7.68628 3.82703 7.71375 3.88372C7.74122 3.94041 7.75207 4.00372 7.74503 4.06632C7.738 4.12892 7.71336 4.18825 7.67399 4.23742L3.50732 9.49992C3.47351 9.53892 3.43101 9.56944 3.38324 9.58898C3.33547 9.60853 3.28377 9.61655 3.23232 9.61243ZM3.02815 4.79577H4.19065C4.24199 4.79457 4.2929 4.80539 4.3393 4.82738C4.38571 4.84937 4.42632 4.8819 4.4579 4.92239C4.48948 4.96288 4.51116 5.0102 4.52119 5.06056C4.53121 5.11092 4.52932 5.16293 4.51565 5.21243L3.89065 7.93742L6.72815 4.36659H5.35316C5.2964 4.36627 5.24067 4.35149 5.19124 4.32361C5.14181 4.29573 5.10032 4.25569 5.07069 4.20729C5.04106 4.15888 5.02428 4.10372 5.02194 4.04702C5.0196 3.99031 5.03178 3.93395 5.05732 3.88327L6.34899 1.29161H3.98649L3.02815 4.79577Z" />
            </g>
            <defs>
                <clipPath id="clip0_301_37011">
                    <rect width="10" height="11" fill="white" transform="translate(0 0.117126)" />
                </clipPath>
            </defs>
        </svg>
    )
}
