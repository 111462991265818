import { Button, Drawer, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GetCookie from '../globalHeader/common/getCookie'
import setCookie from '../globalHeader/common/setCookie'
import GlobalLink from '../globalHeader/components/GlobalLink'
import { getCookieDomain } from '../globalHeader/functions/ams-functions'
import { AreaType } from '../globalHeader/GlobalHeader'
import { getAppConfig } from '../globalHeader/services/AppConfigService'

const cookiePolicyKey = 'cookie_policy'

interface IProps {
    Link: any
    websiteURL: string
    apiURL: string
    area: AreaType
}

const CookiesDialog: React.FC<IProps> = (props) => {
    const { Link, websiteURL, area, apiURL } = props
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const config = getAppConfig()
        if (!config?.hideCookies) {
            const isCookiePolicyAccepted = GetCookie(cookiePolicyKey)
            if (isCookiePolicyAccepted !== undefined) {
                saveToCookie(isCookiePolicyAccepted === 'true')
            }
            setOpen(isCookiePolicyAccepted === undefined)
        } else {
            onAccept()
        }
    }, [])

    const saveToCookie = (value: boolean) => {
        const expirationDate = new Date()
        expirationDate.setFullYear(expirationDate.getFullYear() + 1)
        setCookie(cookiePolicyKey, value, getCookieDomain(apiURL), expirationDate)
    }

    const onAccept = () => {
        setOpen(false)
        saveToCookie(true)
    }

    const onReject = () => {
        setOpen(false)
        saveToCookie(false)
    }

    return (
        <Drawer anchor="bottom" variant="persistent" open={open} onClose={onAccept}>
            <Stack
                gap={4}
                sx={{
                    py: 4,
                    px: {
                        xs: 4,
                        md: 6,
                        lg: 8,
                        xl: 10,
                    },
                    flexDirection: {
                        xs: 'column',
                        md: 'row',
                    },
                    alignItems: 'center',
                }}
            >
                <Typography variant="body2">
                    Our website uses cookies and similar technologies to enhance your browsing experience, serve
                    personalized ads or content, and analyze our website traffic. By clicking “Accept All,” you consent
                    to our use of cookies. For more information about our privacy practices, please see our{' '}
                    <GlobalLink href={`${websiteURL}/privacy`} target="_blank" Link={Link} area={area}>
                        Privacy Notice
                    </GlobalLink>
                    .
                </Typography>
                <Stack spacing={1}>
                    <Button variant="contained" sx={{ minWidth: '260px' }} onClick={onAccept}>
                        Accept all
                    </Button>
                    <Button variant="outlined" sx={{ minWidth: '260px' }} onClick={onReject}>
                        Reject all
                    </Button>
                </Stack>
            </Stack>
        </Drawer>
    )
}

export default CookiesDialog
