import { useCallback, useEffect, useMemo, useState } from 'react'
import { nameOfSquare, squareByName } from '../../chess/basics'
import { generateMoves } from '../../chess/core'
import { positionToFEN } from '../../chess/fen'
import { getMoveById, getPositionById } from '../../chess/gameTree'
import { Color, GameTree, Move, MoveType, Piece, Position } from '../../chess/types'
import { Chessboard } from '../../react-chessboard/src'
import { Piece as LibPiece, Square } from '../../react-chessboard/src/chessboard/types'
import { piecesProvider } from '../../sharedComponents/src/globalHeader/common/PiecesProvider'
import { boardColors } from '../../sharedComponents/src/globalHeader/theme/colors'
import { useStoreState } from '../../store/hooks'
import useMoveSounds from './hooks/useMoveSounds'
import {
    combineStyles,
    getCheckHighlightStyles,
    getLastMoveHighlightsStyles,
    getRightClickedSquareStyles,
    getSelectedSquareStyles,
} from './utils'

// TODO: react lazy load chess pieces

export const BoardOffsetX = 25
export const BoardOffsetY = 21
export const BoardSquareSize = 70.25

// which side of the board is up
export enum BoardDirection {
    WHITE_VIEW = 1,
    BLACK_VIEW = 0,
}

// what types of interactions can a user have with the current position
export enum InteractionMode {
    VIEW_ONLY,
    BLACK_TO_MOVE,
    WHITE_TO_MOVE,
    FREE,
}

export type PieceProps = {
    color: Color
    enabled: boolean
    balance?: boolean
}

export type ChessBoardProps = {
    gameTree?: GameTree | undefined
    currentPositionId?: string | undefined
    position: Position
    flipped: boolean
    onMove: (mov: Move) => boolean
}

const promotionPieceSelect = (piece: string) => {
    let promotionPiece = Piece.Queen
    switch (piece) {
        case 'wR':
            promotionPiece = Piece.WhiteRook
            break
        case 'wN':
            promotionPiece = Piece.WhiteKnight
            break
        case 'wB':
            promotionPiece = Piece.WhiteBishop
            break
        case 'bR':
            promotionPiece = Piece.BlackRook
            break
        case 'bN':
            promotionPiece = Piece.BlackKnight
            break
        case 'bB':
            promotionPiece = Piece.BlackBishop
            break
        case 'wQ':
            promotionPiece = Piece.WhiteQueen
            break
        case 'bQ':
            promotionPiece = Piece.BlackQueen
            break
    }
    return promotionPiece
}

const AnalysisChessBoard = (props: ChessBoardProps) => {
    let { gameTree, currentPositionId, position, flipped, onMove } = props

    const settings = useStoreState((state) => state.gameView.settings)
    const [rightClickedSquares, setRightClickedSquares] = useState<Square[]>([])
    const [moveFrom, setMoveFrom] = useState<Square | undefined>(undefined)
    const [moveTo, setMoveTo] = useState<Square | undefined>(undefined)
    const [promotionReason, setPromotionReason] = useState<'move' | 'premove' | undefined>(undefined)
    const [dragPiece, setDragPiece] = useState<LibPiece | undefined>(undefined)

    const playMoveSound = useMoveSounds(settings)

    const makeMoveHandler = useCallback(
        (move: Move) => {
            onMove(move)
            if (moveFrom === nameOfSquare(move.from)) setMoveFrom(undefined)
            setMoveTo(undefined)
        },
        [position, onMove],
    )

    const activeMove = useMemo(() => {
        if (gameTree && currentPositionId) {
            const tp = getPositionById(gameTree, currentPositionId)

            if (tp.previousMoveId !== undefined) {
                return getMoveById(gameTree, tp.previousMoveId)
            }
        }
    }, [gameTree, currentPositionId])

    useEffect(() => {
        playMoveSound(activeMove?.displayString)
    }, [activeMove])

    useEffect(() => {
        setRightClickedSquares([])
    }, [])

    const pieceDragBeginHandler = useCallback((piece, sourceSquare) => {
        setDragPiece(piece)
        setMoveFrom(sourceSquare)
    }, [])

    const onFirstClick = useCallback((sourceSquare: Square) => {
        setRightClickedSquares([])
        setMoveFrom(sourceSquare)
    }, [])

    const onSecondClick = useCallback(
        (moveFrom: Square, sourceSquare: Square) => {
            const moves = generateMoves(position, squareByName(moveFrom))
            const legalMove = moves.find((m) => nameOfSquare(m.to) === sourceSquare)
            if (legalMove) {
                if (legalMove.promotion) {
                    setMoveTo(sourceSquare)
                    setPromotionReason('move')
                    return
                } else {
                    makeMoveHandler(legalMove)
                }
            } else {
                onFirstClick(sourceSquare)
            }
        },
        [position, makeMoveHandler],
    )

    const onClickHandler = useCallback(
        (sourceSquare: Square) => {
            if (!moveFrom) {
                onFirstClick(sourceSquare)
                return
            }

            onSecondClick(moveFrom, sourceSquare)
        },
        [onFirstClick, onSecondClick, moveFrom],
    )

    const pieceDropHandler = useCallback(
        (sourceSquare: Square, targetSquare: Square, piece: LibPiece) => {
            setRightClickedSquares([])

            const isPromoting = dragPiece !== piece
            const promotion = isPromoting ? promotionPieceSelect(piece) : undefined

            const moves = generateMoves(position, squareByName(sourceSquare))
            const legalMove = moves.find((m) => nameOfSquare(m.to) === targetSquare && m.promotion === promotion)
            if (legalMove) {
                makeMoveHandler(legalMove)
                return true
            }

            return false
        },
        [dragPiece, makeMoveHandler, position],
    )

    const squareRightClickHandler = useCallback((sourceSquare) => {
        setRightClickedSquares((prevState) => {
            if (prevState.some((square) => square === sourceSquare)) {
                return prevState.filter((square) => square !== sourceSquare)
            } else {
                return [...prevState, sourceSquare]
            }
        })
    }, [])

    const direction = flipped ? BoardDirection.WHITE_VIEW : BoardDirection.BLACK_VIEW
    const boardColor = boardColors[settings.boardStyle] || boardColors.default

    const customPieces = useMemo(() => piecesProvider(settings.pieceStyle), [settings.pieceStyle])

    const customSquareStyles = useMemo(() => {
        return combineStyles(
            getLastMoveHighlightsStyles(activeMove?.move),
            getCheckHighlightStyles(position),
            getRightClickedSquareStyles(rightClickedSquares),
            getSelectedSquareStyles(position, moveFrom, [], position.turn),
        )
    }, [activeMove, position, rightClickedSquares, moveFrom])

    const isDraggablePiece = useCallback(() => true, [])

    return (
        <Chessboard
            allowDragOutsideBoard={false}
            animationDuration={50}
            autoPromoteToQueen={settings.autoQueen}
            promotionDialogVariant={'modal'}
            customSquareStyles={customSquareStyles}
            position={positionToFEN(position)}
            customDarkSquareStyle={boardColor.secondary}
            customLightSquareStyle={boardColor.main}
            showBoardNotation={settings.coordinates}
            boardOrientation={direction === BoardDirection.WHITE_VIEW ? 'black' : 'white'}
            isDraggablePiece={isDraggablePiece}
            onPieceDragBegin={pieceDragBeginHandler}
            onSquareClick={onClickHandler}
            onSquareRightClick={squareRightClickHandler}
            onPieceDrop={pieceDropHandler}
            customPieces={customPieces}
            customDropSquareStyle={{}}
            areArrowsAllowed={true}
            {...(promotionReason !== undefined && {
                showPromotionDialog: true,
                promotionToSquare: moveTo as unknown as Square,
                onPromotionPieceSelect: (piece) => {
                    if (piece) {
                        const promotionPiece = promotionPieceSelect(piece)

                        const move = {
                            from: squareByName(moveFrom!),
                            to: squareByName(moveTo!),
                            type: MoveType.Promotion,
                            promotion: promotionPiece,
                        }

                        makeMoveHandler(move)
                        return true
                    }
                    return false
                },
            })}
        />
    )
}

export default AnalysisChessBoard
