import { Box, Collapse, FormControlLabel, Paper, Stack, Switch, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { isCheckmate, isStalemate } from '../../../../chess/core'
import { positionFromFEN } from '../../../../chess/fen'
import { Move } from '../../../../chess/types'
import { StockfishLine } from '../../useStockfish/StockfishLine'
import { StockEngineResultType } from '../../useStockfish/useStockFish'

type Props = {
    isEngineLoaded: boolean
    startEngine: () => void
    stopEngine: () => void
    allVariations: StockEngineResultType
    addEngineMove?: (move: Move | Move[]) => void
    colorToMove: number
    isCalculating: boolean
}

const EngineSection: React.FC<Props> = ({
    isEngineLoaded,
    startEngine,
    stopEngine,
    allVariations,
    addEngineMove,
    colorToMove,
    isCalculating,
}) => {
    const [finishText, setFinishText] = React.useState<string>('')

    useEffect(() => {
        if (isCalculating === false) {
            const checkMated = Object.values(allVariations).every((variation) => !Boolean(variation.scoreType))
            if (checkMated) {
                const position = positionFromFEN(allVariations.v1.startFen)
                const isMate = isCheckmate(position)
                if (isMate) {
                    setFinishText(`${colorToMove === 1 ? 'White' : 'Black'} Checkmated`)
                } else {
                    const isStale = isStalemate(position)
                    if (isStale) {
                        setFinishText(`${colorToMove === 1 ? 'White' : 'Black'} Stalemated`)
                    }
                }
            } else {
                setFinishText('')
            }
        } else {
            setFinishText('')
        }
    }, [allVariations, isCalculating])

    return (
        <>
            <Box sx={{ position: 'absolute', right: '12px', top: 0, backgroundColor: 'background.paper' }}>
                <FormControlLabel
                    labelPlacement="start"
                    control={
                        <Switch
                            color="secondary"
                            checked={isEngineLoaded}
                            onChange={() => {
                                if (isEngineLoaded) {
                                    stopEngine()
                                } else {
                                    startEngine()
                                }
                            }}
                        />
                    }
                    label="Enable Engine"
                />
            </Box>
            {isEngineLoaded && (
                <Stack direction="row" alignItems={'center'} width="100%">
                    <Box sx={{ flexGrow: 1, minWidth: '0' }}>
                        <Collapse in={isEngineLoaded}>
                            <Paper className={'StockFishEngine'}>
                                <Stack direction="column" gap={1}>
                                    {finishText !== '' ? (
                                        <Typography ml={1} color="text.disabled" variant="body2">
                                            {finishText}
                                        </Typography>
                                    ) : (
                                        Object.keys(allVariations).map((key, index) => {
                                            const variation = allVariations[key]
                                            return (
                                                variation.startFen && (
                                                    <StockfishLine
                                                        key={index}
                                                        isCalculating={isCalculating}
                                                        analysisResults={variation}
                                                        onMoveSelected={
                                                            addEngineMove
                                                                ? addEngineMove
                                                                : () => console.log('no addEngineMove function')
                                                        }
                                                        colorToMove={colorToMove}
                                                    />
                                                )
                                            )
                                        })
                                    )}
                                </Stack>
                            </Paper>
                        </Collapse>
                    </Box>
                </Stack>
            )}
        </>
    )
}

export default EngineSection
