import { Button, Dialog, DialogContent, DialogContentText, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ConnectionStatus } from '../../../store/types'

type Props = {
    connectionStatus: ConnectionStatus
}

export const ConnectionFailedOverlay: React.FC<Props> = ({ connectionStatus }) => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        // to prevent this status to be overridden by ConnectionStatus.NOT_INITIALIZED
        if (
            connectionStatus === ConnectionStatus.RECONNECT_FAILED ||
            connectionStatus === ConnectionStatus.CONNECTING
        ) {
            setIsOpen(true)
        } else if (
            connectionStatus === ConnectionStatus.CONNECTED ||
            connectionStatus === ConnectionStatus.NOT_INITIALIZED
        ) {
            setIsOpen(false)
        }
    }, [connectionStatus])

    return connectionStatus !== ConnectionStatus.CONNECTED ? (
        <Dialog open={isOpen}>
            <DialogContent
                sx={{
                    maxWidth: '30rem',
                }}
            >
                <DialogContentText fontSize={'1.2rem'}>
                    {connectionStatus === ConnectionStatus.CONNECTING ? (
                        'Trying to reconnect...'
                    ) : (
                        <>
                            <Typography variant="h6" fontWeight="bold" color="secondary" paragraph>
                                Connection to the game server lost.
                            </Typography>
                            Please check your internet connection and refresh the page.
                            <br />
                            <Button onClick={() => window.location.reload()} fullWidth sx={{ mt: 4 }}>
                                Refresh
                            </Button>
                        </>
                    )}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    ) : null
}
