export const calculateWhitePercentage = (score: number, color: number, mate?: boolean) => {
    const isWhiteWinning = score > 0

    // Default values for win probability and percentages
    let winProb = 0.5
    let whitePercentage = 50
    let blackPercentage = 50

    if (mate) {
        // If mate in any moves, fill 100%
        return isWhiteWinning ? 100 : 0
    } else {
        // Calculate win probability based on score and color to move
        const flippedScore = -score
        const scoreForProb = color === 1 ? score : flippedScore
        const expScore = Math.exp(-scoreForProb / 10)

        switch (color) {
            case 1:
                // If white is to move, calculate win probability and percentages for white
                winProb = 1 / (1 + expScore)
                whitePercentage = Math.round(winProb * 100)
                return whitePercentage
            case -1:
                // If black is to move, calculate win probability and percentages for black
                winProb = 1 / (1 + 1 / expScore)
                blackPercentage = Math.round(winProb * 100)
                return 100 - blackPercentage
            default:
                // Error handling for invalid color to move values
                console.log('Invalid color to move value')
                return 50
        }
    }
}
