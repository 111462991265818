import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import { useStoreState } from '../../../store/hooks'

type Props = {
    onPositionToFENClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    fen: string
    setFen: (e) => void
    handleColorToMoveChange: (e) => void
    toggleCastle: (index) => (event) => void
    whiteShort: boolean
    whiteLong: boolean
    blackShort: boolean
    blackLong: boolean
    headers: [{ [index: string]: string }, number]
    handlePGNHeaderChange: (key: string, value: string) => void
    pieceSelection: React.ReactElement
}

const BoardSetupTab = (props: Props) => {
    const {
        onPositionToFENClick,
        fen,
        setFen,
        handleColorToMoveChange,
        toggleCastle,
        whiteShort,
        whiteLong,
        blackLong,
        blackShort,
        headers,
        handlePGNHeaderChange,
        pieceSelection,
    } = props
    const colorToMove = useStoreState((state) => state.analysisMode.colorToMove)
    const [pgnDetailsOpen, setPgnDetailsOpen] = useState(false)
    const [editPgn, setEditPgn] = useState('')

    const editButtonClick = (key: string, value: string) => {
        if (editPgn === key) {
            setEditPgn('')
        } else {
            setEditPgn(key)
        }
    }

    const headersList = (headers: [{ [index: string]: string }, number]) => {
        return Object.entries(headers[0]).map(([key, value]: [string, string]) => (
            <Stack direction={'row'} key={key + 'stack'} alignItems="center">
                <TextField
                    key={key}
                    label={key}
                    defaultValue={value}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    disabled={editPgn !== key}
                    onChange={(e) => handlePGNHeaderChange(key, e.target.value)}
                />
                <IconButton
                    disableRipple
                    size="medium"
                    key={key + 'edit'}
                    onClick={() => editButtonClick(key, value)}
                    sx={{
                        borderRadius: '0',
                        display: editPgn === key ? 'none' : 'block',
                    }}
                >
                    <EditIcon />
                </IconButton>
                <IconButton
                    disableRipple
                    key={key + 'check'}
                    onClick={() => editButtonClick(key, value)}
                    sx={{
                        borderRadius: '0',
                        display: editPgn === key ? 'block' : 'none',
                    }}
                >
                    <CheckIcon />
                </IconButton>
            </Stack>
        ))
    }

    return (
        <>
            <Stack
                sx={{
                    flexGrow: 1,
                    padding: 2,
                    paddingTop: 1,
                    border: '0.125rem solid #fff',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    gap: '24px',
                }}
            >
                {pieceSelection}
                <Stack>
                    <Typography variant="h6">Move Settings</Typography>
                    <RadioGroup
                        value={colorToMove}
                        onChange={handleColorToMoveChange}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Stack direction={'row'} width={'100%'} display={'flex'} justifyContent={'space-between'}>
                            <FormControlLabel
                                value={1}
                                control={<Radio inputProps={{ 'aria-label': 'Black' }} />}
                                label="White to move"
                            />
                            <FormControlLabel
                                labelPlacement="start"
                                value={-1}
                                control={<Radio inputProps={{ 'aria-label': 'Black' }} />}
                                label="Black to move"
                            />
                        </Stack>
                    </RadioGroup>
                </Stack>
                <Stack>
                    <Typography variant="h6">Castling</Typography>

                    <FormControl component="fieldset">
                        <Stack direction={'row'} width={'100%'} display={'flex'} justifyContent={'space-between'}>
                            <FormControlLabel
                                control={<Checkbox color="default" />}
                                label="WHITE (0-0)"
                                onChange={toggleCastle(0)}
                                checked={whiteShort}
                            />
                            <FormControlLabel
                                labelPlacement="start"
                                control={<Checkbox color="default" />}
                                label="WHITE (0-0-0)"
                                onChange={toggleCastle(1)}
                                checked={whiteLong}
                            />
                        </Stack>
                        <Stack direction={'row'} display={'flex'} justifyContent={'space-between'}>
                            <FormControlLabel
                                control={<Checkbox color="default" />}
                                label="BLACK (0-0)"
                                onChange={toggleCastle(2)}
                                checked={blackShort}
                            />
                            <FormControlLabel
                                labelPlacement="start"
                                control={<Checkbox color="default" />}
                                label="BLACK (0-0-0)"
                                onChange={toggleCastle(3)}
                                checked={blackLong}
                            />
                        </Stack>
                    </FormControl>
                </Stack>
                <Stack direction="row" alignItems={'center'} justifyContent={'flex-start'} gap={2}>
                    <TextField
                        label="Fen"
                        variant="outlined"
                        value={fen}
                        onChange={(e) => setFen(e.target.value)}
                        sx={{
                            flexGrow: 1,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '0rem',
                            },
                        }}
                    />
                </Stack>
                <Stack>
                    <Typography
                        sx={{ cursor: 'pointer', display: 'flex' }}
                        variant="h6"
                        paragraph
                        onClick={() => setPgnDetailsOpen(!pgnDetailsOpen)}
                    >
                        PGN Details
                        <IconButton size="small">
                            {pgnDetailsOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                        </IconButton>
                    </Typography>
                    {pgnDetailsOpen && (
                        <Box display="flex" flexDirection="column" gap="12px" py="6px" mt={'-12px'}>
                            {headersList(headers)}
                        </Box>
                    )}
                </Stack>
            </Stack>
            <Button
                fullWidth
                variant="outlined"
                onClick={(e) => {
                    onPositionToFENClick(e)
                }}
            >
                Analyze
            </Button>
        </>
    )
}

export default BoardSetupTab
