import Cookie from 'js-cookie'

const setCookie = (cookieName, cookieValue, domain, expire) => {
    Cookie.set(cookieName, cookieValue, {
        path: '/',
        domain: domain,
        expires: expire,
    })
}

export default setCookie
