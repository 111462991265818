import { format } from 'date-fns'
import PgnCommandHandler from './PgnCommandHandler'
import PgnParser from './PgnParser'
import { moveFromFanToSan } from './basics'
import { getMoveById, getPositionById, getStartingPosition } from './gameTree'
import { BasePositionFEN } from './positionPresets'
import { Color, GameTree, GameTreeMove, GameTreePosition, Move, Position } from './types'

export type PGN = {
    headers: { [index: string]: string }
    startPosition: Position
    root: PGNPosition
    tokens: string[]
    indexMap: { [idx: number]: PGNPosition }
    idMap: { [id: number]: PGNPosition }
}

export type PGNPosition = {
    id: number
    move: Move | null
    comment: string | null
    prev: PGNPosition | null
    next: PGNPosition[]
    halfMove: number
}

export type PGNData = {
    Black?: string
    Date?: string
    Description?: string
    Event?: string
    FEN?: string
    PlyCount?: string
    Result?: string
    Round?: string
    SetUp?: string
    Site?: string
    White?: string
    Opening?: string
    GameTree?: GameTree
}

export enum PGNFields {
    Black = 'Black',
    Date = 'Date',
    Event = 'Event',
    FEN = 'FEN',
    PlyCount = 'PlyCount',
    Result = 'Result',
    Round = 'Round',
    SetUp = 'SetUp',
    Site = 'Site',
    White = 'White',
    Opening = 'Opening',
    Description = 'Description',
    GameTree = 'GameTree',
}

export function pgnParseHeaders(pgn: string): [{ [index: string]: string }, number] {
    const result = {}
    let inHeader = false,
        headerStart = -1,
        firstSpace = -1,
        i = 0
    for (; i < pgn.length; i++) {
        const c = pgn.charAt(i)
        if (inHeader && c === ']') {
            inHeader = false
            if (firstSpace < 0) {
                result[pgn.substring(headerStart + 1, i)] = true
            } else {
                let value = pgn.substring(firstSpace + 1, i).trim()
                value = value.substring(1, value.length - 1)
                value = value.replace('\\"', '"').replace('\\\\', '\\')
                result[pgn.substring(headerStart + 1, firstSpace)] = value
            }
        } else if (c.match(/\s/)) {
            if (firstSpace < 0) {
                firstSpace = i
            }
            continue
        } else if (inHeader) {
            continue
        } else if (c === '[') {
            inHeader = true
            headerStart = i
            firstSpace = -1
        } else {
            break
        }
    }
    return [result, i]
}

export function gameFromPGN(pgn: string, customPosition?: string): GameTree {
    const [headers, headerLength] = pgnParseHeaders(pgn.trim())
    const moveText = pgn.substring(headerLength).trim()

    const startPos: string = customPosition || headers['FEN'] || BasePositionFEN

    const parser = new PgnParser(startPos, moveText)

    return parser.gameTree
}

export const getDataFromPGN = (pgn: string, fields: Array<PGNFields>): PGNData => {
    const [headers] = pgnParseHeaders(pgn.trim())
    let data = {}
    fields.map((field) => {
        if (field === PGNFields.GameTree) {
            const gameTree = gameFromPGN(pgn)
            data[field] = gameTree
        } else if (headers[field]) {
            const item = headers[field]
            data[field] = item
        }
    })
    return data
}

export const convertGameTreeToPGN = (gameTree: GameTree): string => {
    let pgn = ''
    let pos: GameTreePosition = getStartingPosition(gameTree)
    const writeMove = (move: GameTreeMove) => {
        if (!!move) {
            pgn += moveFromFanToSan(move.displayString) + ' '
            if (move.annotation || move.moveTime !== undefined || move.clock !== undefined) {
                const commands = new Map<string, string[]>()
                if (move.moveTime !== undefined)
                    commands.set('emt', [PgnCommandHandler.stringifyClockCommandParameter(move.moveTime)])
                if (move.clock !== undefined)
                    commands.set('clk', [PgnCommandHandler.stringifyClockCommandParameter(move.clock)])
                pgn += '{' + PgnCommandHandler.injectPgnCommandsIntoAnnotation(move.annotation || '', commands) + '} '
            }
        }
    }
    const writeMoveNumber = (pos: GameTreePosition) => {
        if (pos.position.turn === Color.White) {
            pgn += pos.position.moveNum + '. '
        } else if (pos.position.turn === Color.Black) {
            pgn += pos.position.moveNum + '... '
        }
    }

    const writeVariation = (pos: GameTreePosition) => {
        let forceMoveNumber: boolean = false
        while (pos.nextMoveIds.length > 0) {
            const nextMoveId = pos.nextMoveIds[0]
            // move number is only output before a white move
            if (pos.position.turn === Color.White || forceMoveNumber) {
                writeMoveNumber(pos)
            }
            forceMoveNumber = false

            const nextMove = getMoveById(gameTree, nextMoveId)
            if (nextMove) {
                writeMove(nextMove)
            }
            for (let i = 1; i < pos.nextMoveIds.length; i++) {
                const nextMove = getMoveById(gameTree, pos.nextMoveIds[i])
                if (!!nextMove) {
                    pgn += '('
                    writeMoveNumber(pos)
                    writeMove(nextMove)
                    const nextPos = getPositionById(gameTree, nextMove!.nextPositionId)
                    writeVariation(nextPos)
                    pgn += ')'
                    forceMoveNumber = true
                }
            }
            const nextPos = getPositionById(gameTree, nextMove!.nextPositionId)
            if (!nextPos) break
            pos = nextPos
        }
    }

    writeVariation(pos)
    return pgn
}

export const createPuzzlePGNString = (fen: string, moves: string) => {
    let result = `[Event "Puzzle from chessclub.com"]\n`
    result += `[Site "Internet Chess Club http://chessclub.com/"]\n`
    result += `[Date "${format(new Date(), 'yyyy.MM.dd')}"]\n`
    result += `[Time "${format(new Date(), 'HH:mm:ss')}"]\n`
    result += `[FEN "${fen}"]\n`
    if (moves.length > 0) result += `${moves}`
    return result
}
