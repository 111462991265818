const ProfileIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.55556 6.44444C7.55556 5.2657 8.02381 4.13524 8.8573 3.30175C9.6908 2.46825 10.8213 2 12 2C13.1787 2 14.3092 2.46825 15.1427 3.30175C15.9762 4.13524 16.4444 5.2657 16.4444 6.44444C16.4444 7.62318 15.9762 8.75365 15.1427 9.58714C14.3092 10.4206 13.1787 10.8889 12 10.8889C10.8213 10.8889 9.6908 10.4206 8.8573 9.58714C8.02381 8.75365 7.55556 7.62318 7.55556 6.44444ZM7.55556 13.1111C6.08213 13.1111 4.66905 13.6964 3.62718 14.7383C2.58532 15.7802 2 17.1932 2 18.6667C2 19.5507 2.35119 20.3986 2.97631 21.0237C3.60143 21.6488 4.44928 22 5.33333 22H18.6667C19.5507 22 20.3986 21.6488 21.0237 21.0237C21.6488 20.3986 22 19.5507 22 18.6667C22 17.1932 21.4147 15.7802 20.3728 14.7383C19.3309 13.6964 17.9179 13.1111 16.4444 13.1111H7.55556Z"
            fill="white"
        />
    </svg>
)

ProfileIcon.displayName = 'ProfileIcon'

export default ProfileIcon
