import { Button, Stack } from '@mui/material'
import { useMemo } from 'react'
import { InfoDialogTemplate, InfoDialogText } from '../../InfoDialogTemplate/InfoDialogTemplate'
import SettingsSwitch from '../../settings/SettingsSwitch'

interface WelcomeUserFormProps {
    username: string
    hideRating: boolean
    onHideRatingChange: (value: boolean) => void
    onClose: () => void
}

const WelcomeUserForm: React.FC<WelcomeUserFormProps> = ({ onClose, username, hideRating, onHideRatingChange }) => {
    const onHideRatingHandler = () => {
        onHideRatingChange(!hideRating)
    }

    const texts = useMemo(() => {
        if (hideRating) {
            return {
                header: `Welcome ${username}`,
                subheader: 'Your game is set to Casual Play',
                description: (
                    <>
                        Enjoy the game and get better with every move without the focus on ratings!
                        <br />
                        To show ratings, simply toggle the switch below or access your settings at anytime.
                    </>
                ),
            }
        } else {
            return {
                header: `Welcome ${username}`,
                subheader: 'Your game is set to display ratings',
                description: (
                    <>
                        If you prefer a more relaxed experience, switch to Casual Play below or in your settings.
                        <br />
                        To hide ratings, simply toggle the switch below or access your settings at anytime.
                    </>
                ),
            }
        }
    }, [hideRating])

    return (
        <InfoDialogTemplate
            open={true}
            handleClose={onClose}
            content={
                <>
                    <InfoDialogText header={texts.header} subheader={texts.subheader} description={texts.description} />
                    <Stack sx={{ mt: { xs: 2, md: 6 } }} alignItems="center">
                        <SettingsSwitch
                            label="Casual Play"
                            checked={hideRating}
                            helpText="Hide Ratings"
                            onChange={onHideRatingHandler}
                            // disabled={!settingsEnabled}
                            // onDisabledClick={openJoinUsForm}
                        />
                    </Stack>
                </>
            }
            actions={
                <Button variant="outlined" onClick={onClose}>
                    Close
                </Button>
            }
        />
    )
}

export default WelcomeUserForm
