const HomeIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.687 2.77334L12.687 2.77337L19.9515 9.64037L19.9517 9.6405C20.1248 9.80401 20.2627 10.0011 20.357 10.2198C20.4512 10.4384 20.4999 10.6739 20.5 10.912C20.5 10.9121 20.5 10.9121 20.5 10.9122V19.267C20.5 19.7311 20.3156 20.1763 19.9874 20.5044C19.6593 20.8326 19.2141 21.017 18.75 21.017H16.5C16.0359 21.017 15.5908 20.8326 15.2626 20.5044C14.9344 20.1763 14.75 19.7311 14.75 19.267V15.517C14.75 15.1855 14.6183 14.8675 14.3839 14.6331C14.1495 14.3987 13.8315 14.267 13.5 14.267H10.5C10.1685 14.267 9.85054 14.3987 9.61612 14.6331C9.3817 14.8675 9.25 15.1855 9.25 15.517V19.267C9.25 19.7311 9.06563 20.1763 8.73744 20.5044C8.40925 20.8326 7.96413 21.017 7.5 21.017H5.25C4.78607 21.017 4.34113 20.8328 4.01298 20.5049C3.68486 20.177 3.50035 19.7322 3.5 19.2683L3.5 10.9135L3.5 10.913C3.49976 10.6748 3.54817 10.439 3.64226 10.2201C3.7363 10.0014 3.87401 9.80411 4.04694 9.6404C4.04697 9.64038 4.04699 9.64035 4.04702 9.64032C4.04707 9.64028 4.04712 9.64023 4.04717 9.64018L11.3129 2.7734L11.313 2.77334C11.4987 2.59781 11.7445 2.5 12 2.5C12.2555 2.5 12.5013 2.59781 12.687 2.77334Z"
            fill="white"
            stroke="white"
        />
    </svg>
)

HomeIcon.displayName = 'HomeIcon'

export default HomeIcon
