const MembershipsIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9851 1.78271H13.0628L16.0063 8.10843H8.0297L10.9851 1.78271ZM5.66399 8.10843L8.61942 1.78271H5.73599C5.3155 1.79419 4.90354 1.90407 4.53319 2.10354C4.16284 2.303 3.8444 2.5865 3.60342 2.93129L0.488561 7.24786L0.483418 7.25471C0.299491 7.51412 0.163623 7.80444 0.0822754 8.11186H5.66399V8.10843ZM0.435418 10.2513H5.61942L8.91256 20.813L0.625704 10.5084C0.55728 10.4277 0.493739 10.343 0.435418 10.2547V10.2513ZM7.86342 10.2513H16.1726L12.1783 23.1136C12.0779 23.1207 11.9771 23.1218 11.8766 23.117L7.86513 10.253L7.86342 10.2513ZM18.4166 10.2513L15.1663 20.7136L23.3743 10.5067C23.4427 10.426 23.5062 10.3413 23.5646 10.253L18.4166 10.2513ZM23.916 8.10843C23.8349 7.80174 23.6997 7.51203 23.5166 7.253L23.5114 7.24614L20.3966 2.933C20.1556 2.58822 19.8371 2.30472 19.4668 2.10525C19.0964 1.90579 18.6845 1.79591 18.264 1.78443V1.78271H15.4286L18.3703 8.10843H23.916Z"
            fill="white"
        />
    </svg>
)

MembershipsIcon.displayName = 'MembershipsIcon'

export default MembershipsIcon
