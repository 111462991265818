import { Typography, useTheme } from '@mui/material'
import { format } from 'date-fns'
import React, { useEffect } from 'react'
import { getClockInstantCurrentRemaining } from '../../../../functions/getClockInstantCurrentRemaining'
import toPrecision from '../../../../functions/toPrecision'
import { colors } from '../../../../sharedComponents/src/globalHeader/theme/colors'
import { ClockInstant } from '../../../../store/types'
export type ClockProps = {
    onAddTime?: () => void
    extraClass?: string
    running: boolean
    instant?: ClockInstant
}

const calculateClock = (instant: ClockInstant | undefined, running: boolean) => {
    const remaining = running ? getClockInstantCurrentRemaining(instant) : instant?.secondsRemaining || 0
    const rounded = remaining > 30 ? Math.round(remaining) : toPrecision(remaining, 1)

    return rounded
}

export const Clock: React.FC<ClockProps> = ({ onAddTime, extraClass, running, instant }) => {
    const { palette } = useTheme()

    const [clock, setClock] = React.useState<number>(calculateClock(instant, running))

    const requestRef = React.useRef<number>()

    useEffect(() => {
        const updateClock = () => {
            setClock(calculateClock(instant, running))
            requestRef.current = requestAnimationFrame(updateClock)
        }

        requestRef.current = requestAnimationFrame(updateClock)

        return () => {
            if (requestRef.current) cancelAnimationFrame(requestRef.current)
        }
    }, [running, instant])

    const warn = clock !== 0 && clock < 30

    let clockString = format((clock + new Date(0).getTimezoneOffset() * 60) * 1000, 'H:mm:ss')
    if (clock < 3600 && clock > 30) clockString = format((clock + new Date(0).getTimezoneOffset() * 60) * 1000, 'm:ss')
    else if (clock <= 30) clockString = format((clock + new Date(0).getTimezoneOffset() * 60) * 1000, 'm:ss:S')

    return (
        <Typography
            sx={{
                overflow: 'hidden',
                fontVariantNumeric: 'tabular-nums',
                fontFamily: 'Space Grotesk',
                fontWeight: '700',
                fontSize: '32px',
                lineHeight: '32px',
                color: warn ? colors.red : palette.text.primary,
                margin: 0,
            }}
        >
            {clockString}
        </Typography>
    )
}
