const NewsIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_578_12866)">
            <path
                d="M16.3684 1C16.9261 0.999824 17.4633 1.21045 17.8722 1.58965C18.2811 1.96886 18.5316 2.48862 18.5734 3.04474L18.5789 3.21053V8.73684H20.2368C20.649 8.73686 21.0463 8.89038 21.3514 9.16747C21.6565 9.44456 21.8474 9.82535 21.887 10.2356L21.8947 10.3947V18.6842C21.8948 19.53 21.5716 20.3438 20.9914 20.9591C20.4112 21.5745 19.6178 21.9449 18.7735 21.9945L18.5789 22H4.21053C3.65284 22.0002 3.11569 21.7896 2.70676 21.4103C2.29784 21.0311 2.04735 20.5114 2.00553 19.9553L2 19.7895V3.21053C1.99982 2.65284 2.21045 2.11569 2.58965 1.70676C2.96886 1.29784 3.48862 1.04735 4.04474 1.00553L4.21053 1H16.3684ZM19.6842 10.9474H18.5789V19.7895C18.8721 19.7895 19.1532 19.673 19.3605 19.4657C19.5678 19.2585 19.6842 18.9773 19.6842 18.6842V10.9474ZM10.8421 12.0526H7.52632C7.24461 12.0529 6.97365 12.1608 6.7688 12.3542C6.56396 12.5476 6.44069 12.8119 6.42418 13.0931C6.40767 13.3743 6.49917 13.6513 6.67998 13.8673C6.86079 14.0833 7.11726 14.2221 7.397 14.2554L7.52632 14.2632H10.8421C11.1238 14.2628 11.3948 14.155 11.5996 13.9616C11.8045 13.7682 11.9277 13.5039 11.9442 13.2227C11.9608 12.9414 11.8693 12.6645 11.6884 12.4485C11.5076 12.2325 11.2512 12.0936 10.9714 12.0604L10.8421 12.0526ZM13.0526 6.52632H7.52632C7.23318 6.52632 6.95205 6.64276 6.74478 6.85004C6.5375 7.05732 6.42105 7.33844 6.42105 7.63158C6.42105 7.92471 6.5375 8.20584 6.74478 8.41312C6.95205 8.62039 7.23318 8.73684 7.52632 8.73684H13.0526C13.3458 8.73684 13.6269 8.62039 13.8342 8.41312C14.0414 8.20584 14.1579 7.92471 14.1579 7.63158C14.1579 7.33844 14.0414 7.05732 13.8342 6.85004C13.6269 6.64276 13.3458 6.52632 13.0526 6.52632Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_578_12866">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

NewsIcon.displayName = 'NewsIcon'

export default NewsIcon
