import { Box } from '@mui/material'
import Crown from '../../../../../assets/icons/crown.svg?react'

type Props = {
    winner?: boolean
    children?: React.ReactNode
}

export const GameOverColumnContent = ({ winner, children }: Props) => {
    return (
        <Box
            sx={{
                padding: '1rem',
                pb: '8px',
                backgroundColor: 'background.paper',
                boxShadow: winner ? 'inset 0 0 0 0.125rem #FFC58C' : undefined,
                position: 'relative',
                height: '100%',
            }}
        >
            {winner && (
                <Box
                    sx={{
                        height: { xs: '34px', md: '44px' },
                        position: 'absolute',
                        bottom: '100%',
                        left: 0,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        svg: { fill: '#FFC58C', height: { xs: '34px', md: '44px' } },
                    }}
                >
                    <Crown />
                </Box>
            )}
            {children}
        </Box>
    )
}
