import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

const gameTypeStyles = {
    px: '.75rem',
    py: 0,
    m: '.5rem',
    backgroundColor: 'background.default',
    textTransform: 'uppercase',
    borderRadius: '.5rem',
}

const hintStyles = {
    position: 'absolute',
    right: '5px',
    top: '5px',
    height: '1rem',
    width: '1rem',
    zIndex: '1',
    cursor: 'pointer',
}

const Hint = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="subtitle1" color="success.dark" sx={gameTypeStyles}>
                win
            </Typography>
            <Typography variant="subtitle1" color="text.primary" sx={gameTypeStyles}>
                draw
            </Typography>
            <Typography variant="subtitle1" color="error.dark" sx={gameTypeStyles}>
                loss
            </Typography>
        </Box>
        <Typography align="center" variant="subtitle1">
            Score resets 24h after last played match
        </Typography>
    </Box>
)

const ScoreboardWithHint: React.FC = () => {
    return (
        <Tooltip disableFocusListener enterTouchDelay={0} title={<Hint />} placement="top-start">
            <HelpOutlineOutlinedIcon color="secondary" sx={hintStyles} />
        </Tooltip>
    )
}

ScoreboardWithHint.displayName = 'ScoreboardWithHint'

export default ScoreboardWithHint
