import { Box, SxProps, Theme } from '@mui/material'

import { FC, useEffect } from 'react'

const styles: Record<string, SxProps<Theme>> = {
    countdown: {
        display: 'inline',
        color: 'inherit',
        fontSize: 'inherit',
        font: 'inherit',
        margin: '0 5px',
        fontVariantNumeric: 'tabular-nums',
    },
}

export interface CountdownProps {
    count: number
    setCount: React.Dispatch<React.SetStateAction<number>>
}

export const Countdown: FC<CountdownProps> = ({ count, setCount }) => {
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCount((prevState) => prevState - 1 || 0)
        }, 1000)
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    if (!Boolean(count)) return null

    return (
        <Box component="span" sx={styles.countdown}>
            {count}
        </Box>
    )
}
