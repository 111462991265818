import { Bot, SearchOptions } from "../../../../store/types"

const updateSelectedBotData = (searchOptions: SearchOptions, selectedBot: Bot): SearchOptions => {
    return {
        ...searchOptions,
        computerChallenge: {
            ...searchOptions.computerChallenge,
            selectedBot: selectedBot,
            minutes: selectedBot.timeMode ? Number(selectedBot.timeMode.durationMinutes) : 5,
            increment: selectedBot.timeMode ? Number(selectedBot.timeMode.clockIncrementSeconds) : 0,
            color: selectedBot.defaultColor,
            strength: selectedBot.difficultyLevel > 0 ? selectedBot.difficultyLevel : 5,
            customTime: true,
        },
    }
}

export default updateSelectedBotData