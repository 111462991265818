import { format } from 'date-fns'

export enum shortToFullPieceName {
    wP = 'WhitePawn',
    wN = 'WhiteKnight',
    wB = 'WhiteBishop',
    wR = 'WhiteRook',
    wQ = 'WhiteQueen',
    wK = 'WhiteKing',
    bP = 'BlackPawn',
    bN = 'BlackKnight',
    bB = 'BlackBishop',
    bR = 'BlackRook',
    bQ = 'BlackQueen',
    bK = 'BlackKing',
}

export const BasePGNHeaders = {
    Event: '-',
    Site: 'Internet Chess Club http://chessclub.com/',
    Date: format(new Date(), 'yyyy.MM.dd'),
    Round: '-',
    White: 'White',
    Black: 'Black',
    Result: '*',
    WhiteElo: '-',
    BlackElo: '-',
    Opening: '-',
    ECO: '-',
    Time: format(new Date(), 'HH:mm:ss'),
    TimeControl: '-',
}
