import { Chip, SxProps, Theme, Typography } from '@mui/material'

export interface UserTitleProps {
    title?: string
    black?: boolean
    sx?: SxProps<Theme>
}

export const UserTitle = (props: UserTitleProps) => {
    const { title, black, sx } = props
    if (!title) {
        return null
    }
    return (
        <Chip
            className={`userTitle ${black && 'black'}`}
            sx={{ ...sx }}
            label={
                <Typography
                    sx={{
                        textShadow: 'none',
                        fontFamily: 'Space Grotesk',
                        fontWeight: 700,
                        fontSize: '12px',
                        textTransform: 'uppercase',
                    }}
                >
                    {title}
                </Typography>
            }
        />
    )
}
