const calculateBarWidth = (elapsedTime: number) => {
    const lowerBound = 2
    const upperBound = 100

    const unboundedWidth = Math.sqrt(elapsedTime * 10)
    const boundedWidth = Math.min(Math.max(unboundedWidth, lowerBound), upperBound)

    return boundedWidth
}

const ElapsedTimeBar: React.FC<{ elapsedTime: number; black?: boolean }> = ({ elapsedTime, black }) => {
    return (
        <div
            className="elapsed-time-bar"
            style={{
                height: '0.938rem',
                width: `${calculateBarWidth(elapsedTime)}%`,
                backgroundColor: black ? 'black' : '#F4F4F4',
                border: '0.063rem solid #F4F4F4',
                float: 'right',
            }}
        />
    )
}

export default ElapsedTimeBar
