import { useCallback, useEffect, useState } from 'react'
import useSound from 'use-sound'
import { Settings } from '../../../sharedComponents/src/globalHeader/GlobalHeader'

const useMoveSounds = (settings: Settings) => {
    // Moves sounds
    const moveSoundURL = `/assets/sounds/${settings.soundStyle}/Move.mp3`
    const [playMove] = useSound(moveSoundURL, { volume: 0.5 })
    const captureSoundURL = `/assets/sounds/${settings.soundStyle}/Capture.mp3`
    const [playCapture] = useSound(captureSoundURL, { volume: 0.5 })
    const castleSoundURL = `/assets/sounds/${settings.soundStyle}/Castling.mp3`
    const [playCastle] = useSound(castleSoundURL, { volume: 0.25 })
    const checkSoundURL = `/assets/sounds/${settings.soundStyle}/Check.mp3`
    const [playCheck] = useSound(checkSoundURL, { volume: 0.25 })
    const [canPlaySound, setCanPlaySound] = useState(false)

    useEffect(() => {
        const handleFirstClick = () => {
            setCanPlaySound(true)
            window.removeEventListener('click', handleFirstClick)
            window.removeEventListener('dragstart', handleFirstClick)
            // window.removeEventListener('touchstart', handleFirstClick)
        }

        window.addEventListener('click', handleFirstClick)
        window.addEventListener('dragstart', handleFirstClick)
        // window.addEventListener('touchstart', handleFirstClick)

        return () => {
            window.removeEventListener('click', handleFirstClick)
            window.removeEventListener('dragstart', handleFirstClick)
            // window.removeEventListener('touchstart', handleFirstClick)
        }
    }, [])

    const playMoveSound = useCallback(
        (moveNotation?: string) => {
            console.log('playMoveSound', settings.soundOn, moveNotation)

            if (!settings.soundOn || !moveNotation || !canPlaySound) return

            if (moveNotation.includes('+' || '#')) {
                console.log('playCheck')
                playCheck()
            } else if (moveNotation.includes('x')) {
                console.log('playCapture')

                playCapture()
            } else if (moveNotation.includes('0-0') || moveNotation.includes('0-0-0')) {
                console.log('playCastle')
                playCastle()
            } else {
                console.log('playMove')
                playMove()
            }
        },
        [settings, playMove, playCapture, playCastle, playCheck, canPlaySound],
    )

    return playMoveSound
}

export default useMoveSounds
