import { Stack } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getLastPosition, getMoveById, getPositionById, getStartingPosition } from '../../chess/gameTree'
import { Color } from '../../chess/types'
import { PageView, PlayerInfoBar } from '../../components/PlayerInfoBar'
import { determineTimeControlName } from '../../functions/determineTimeControl'
import { getOpponentInfos } from '../../functions/getPlayerInfos'
import { useResponsiveSizings } from '../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { Clock } from '../gameView/components/clock/Clock'

import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import AppLayout from '../../components/AppLayout/AppLayout'
import Scoreboard from '../../components/Scoreboard/Scoreboard'
import { ConnectionFailedOverlay } from '../../components/overlays/ConnectionFailedOverlay/ConnectionFailedOverlay'
import { TabBar } from '../../components/tabBar/TabBar'
import convertMinutesToTime from '../../hooks/convertMinutesToTime'
import { GameViewState } from '../gameView/GameViewModel'
import { ObserveBoardContent } from './components/ObserveBoardContent/ObserveBoardContent'
import { ObserveNavigation } from './components/ObserveNavigation/ObserveNavigation'
import { ObserverGameOverDialog } from './components/gameOverDialog/ObserverGameOverDialog'
import { GameTab } from './observeTabs/GameTab'
import { ObserveTab } from './observeTabs/ObserveTab'
import ObserverServerConnector from './observerServerConnector/ObserverServerConnector'

export const ObserveView = () => {
    // ----------------------------------------------------------------- State
    const clockInstantWhite = useStoreState((state) => state.observeView.clockInstantWhite)
    const clockInstantBlack = useStoreState((state) => state.observeView.clockInstantBlack)
    const observeGameState = useStoreState((state) => state.observeView.gameState)
    const flipped = useStoreState((state) => state.observeView.flipped)
    let gameTree = useStoreState((state) => state.observeView.gameTree)
    const currentPositionId = useStoreState((state) => state.observeView.currentPositionId)
    const gameResult = useStoreState((state) => state.observeView.gameResult)
    const clockRunningFor = useStoreState((state) => state.observeView.clockRunningFor)
    const liveGameData = useStoreState((state) => state.observeView.liveGameData)
    const activeTab = useStoreState((state) => state.observeView.activeTab)
    const connectionStatus = useStoreState((state) => state.observeView.connectionStatus)
    const opening = useStoreState((state) => state.observeView.opening)

    // ----------------------------------------------------------------- actions
    const setCurrentPositionId = useStoreActions((state) => state.observeView.setCurrentPositionId)
    const setActiveTab = useStoreActions((state) => state.observeView.setActiveTab)
    const setAnalysisGameTree = useStoreActions((state) => state.analysisMode.setGameTree)
    const setToPreviousPosition = useStoreActions((state) => state.observeView.setToPreviousPosition)
    const setToNextPosition = useStoreActions((state) => state.observeView.setToNextPosition)
    const setDrawerOpen = useStoreActions((state) => state.setDrawerOpen)
    const setIsDrawerMenuOpen = useStoreActions((state) => state.setIsDrawerMenuOpen)
    const setActiveAnalysisTab = useStoreActions((state) => state.setActiveAnalysisTab)
    const setResultType = useStoreActions((state) => state.analysisMode.setResultType)
    const setPGNHeader = useStoreActions((state) => state.setPGNHeader)
    const setAnalysisCurrentPositionId = useStoreActions((state) => state.analysisMode.setCurrentPositionId)

    // ----------------------------------------------------------------- Declarations
    const [openGameEndDialog, setOpenGameEndDialog] = useState<boolean>(false)

    const { layout } = useResponsiveSizings()
    const navigate = useNavigate()

    const { playerWhite, playerBlack } = getOpponentInfos(liveGameData)
    const gtpos = useMemo(() => getPositionById(gameTree, currentPositionId), [gameTree, currentPositionId])

    const matchTitle = liveGameData
        ? liveGameData.timeMode.durationMinutes +
          ' + ' +
          liveGameData.timeMode.clockIncrementSeconds +
          ' ' +
          determineTimeControlName(liveGameData.timeMode.durationMinutes, liveGameData.timeMode.clockIncrementSeconds)
        : ''

    useEffect(() => {
        if (observeGameState === GameViewState.GAME_OVER) {
            setOpenGameEndDialog(true)
        }
    }, [observeGameState])

    // ----------------------------------------------------------------- Notation controls

    const hasNextMoves = gtpos ? gtpos.nextMoveIds.length > 0 : false
    const hasPrevMoves = gtpos ? gtpos.previousMoveId !== undefined : false

    const onMoveSelected = (id: string) => {
        if (!gameTree) return
        const m = getMoveById(gameTree, id)
        if (m) setCurrentPositionId(m.nextPositionId)
    }

    const onFirst = useCallback(() => {
        setCurrentPositionId(getStartingPosition(gameTree).id)
    }, [gameTree, setCurrentPositionId])

    const onLast = useCallback(() => {
        setCurrentPositionId(getLastPosition(gameTree).id)
    }, [gameTree, setCurrentPositionId])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'ArrowLeft') {
                event.preventDefault()
                setToPreviousPosition()
            }
            if (event.key === 'ArrowRight') {
                event.preventDefault()
                setToNextPosition()
            }
            if (event.key === 'ArrowUp') {
                event.preventDefault()
                onFirst()
            }
            if (event.key === 'ArrowDown') {
                event.preventDefault()
                onLast()
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [gameTree, gtpos, onFirst, onLast, setToPreviousPosition, setToNextPosition])

    // ----------------------------------------------------------------- Various Functions

    const onAnalysisClick = () => {
        if (liveGameData && gameResult) {
            const whitePlayer =
                liveGameData.player1.playerColor === 'white' ? liveGameData.player1 : liveGameData.player2
            const blackPlayer =
                liveGameData.player1.playerColor === 'black' ? liveGameData.player1 : liveGameData.player2
            const whitePlayerOldRating = whitePlayer.ratings
                ? whitePlayer.ratings[liveGameData.timeMode.name].rating
                : undefined
            const blackPlayerOldRating = blackPlayer.ratings
                ? blackPlayer.ratings[liveGameData.timeMode.name].rating
                : undefined

            setPGNHeader({
                Event: `chessclub.com Speed ${liveGameData.timeMode.durationMinutes}m+${
                    liveGameData.timeMode.clockIncrementSeconds
                }spm ${new Date().getFullYear()}`,
                Site: 'Internet Chess Club http://chessclub.com/',
                Date: format(new Date(), 'yyyy.MM.dd'),
                Round: '-',
                White: whitePlayer.userName || '',
                Black: blackPlayer.userName || '',
                WhiteTitle: whitePlayer.title,
                BlackTitle: blackPlayer.title,
                Result: gameResult.outcome || '*',
                WhiteElo: `${whitePlayerOldRating}` || '-',
                BlackElo: `${blackPlayerOldRating}` || '-',
                Opening: opening ? opening : '-',
                ECO: '-',
                BlackAvatarUrl: blackPlayer.avatarUrl,
                WhiteAvatarUrl: whitePlayer.avatarUrl,
                BlackCountry: blackPlayer.countryId,
                WhiteCountry: whitePlayer.countryId,
                TimeMode: `${liveGameData.timeMode.name}`,
                WhiteClock: convertMinutesToTime(liveGameData.timeMode.durationMinutes),
                BlackClock: convertMinutesToTime(liveGameData.timeMode.durationMinutes),
                Time: format(new Date(), 'HH:mm:ss'),
                TimeControl: `${liveGameData.timeMode.durationMinutes}+${liveGameData.timeMode.clockIncrementSeconds}`,
            })
            setResultType(gameResult.type)
        }

        setAnalysisGameTree(gameTree)
        setActiveAnalysisTab(0)
        setAnalysisCurrentPositionId(getLastPosition(gameTree).id)
        navigate('/analysis')
    }

    const topPlayerBar = (
        <PlayerInfoBar
            player={flipped ? playerWhite : playerBlack}
            clock={
                <Clock
                    running={
                        observeGameState === GameViewState.GAME_RUNNING &&
                        (flipped ? clockRunningFor === Color.White : clockRunningFor === Color.Black)
                    }
                    instant={flipped ? clockInstantWhite : clockInstantBlack}
                />
            }
            color={flipped ? Color.White : Color.Black}
            gameState={observeGameState}
            gtpos={gtpos}
            top={true}
            view={PageView.OBSERVE}
            showRating
        />
    )

    const bottomPlayerBar = (
        <PlayerInfoBar
            player={flipped ? playerBlack : playerWhite}
            clock={
                <Clock
                    running={
                        observeGameState === GameViewState.GAME_RUNNING &&
                        (flipped ? clockRunningFor === Color.Black : clockRunningFor === Color.White)
                    }
                    instant={flipped ? clockInstantBlack : clockInstantWhite}
                />
            }
            color={flipped ? Color.Black : Color.White}
            gameState={observeGameState}
            gtpos={gtpos}
            top={false}
            view={PageView.OBSERVE}
            showRating
        />
    )

    return (
        <>
            <AppLayout board={<ObserveBoardContent layout={layout} gameState={observeGameState} />} layout={layout}>
                {liveGameData && (
                    <Stack gap={1.5} sx={{ border: '.15rem solid', p: '.75rem' }}>
                        {topPlayerBar}
                        <Scoreboard
                            pairId={liveGameData.matchId}
                            topUserId={(flipped ? playerWhite.playerId : playerBlack.playerId) || ''}
                            bottomUserId={(flipped ? playerBlack.playerId : playerWhite.playerId) || ''}
                        />
                        {bottomPlayerBar}
                    </Stack>
                )}
                {liveGameData && (
                    <TabBar
                        tabLabels={['Live Games', 'Move List']}
                        initSelected={activeTab}
                        onTabClick={(tabIndex: number) => {
                            setActiveTab(tabIndex)
                        }}
                        notation
                    />
                )}

                {activeTab === 0 && <ObserveTab liveGame={liveGameData !== undefined} />}
                {activeTab === 1 && (
                    <GameTab
                        gameTree={gameTree}
                        currentPositionId={currentPositionId}
                        onMoveSelected={onMoveSelected}
                        gameResult={gameResult}
                        matchTitle={matchTitle}
                        hasNextMoves={hasNextMoves}
                        hasPrevMoves={hasPrevMoves}
                        onFirst={onFirst}
                        onPrev={setToPreviousPosition}
                        onNext={setToNextPosition}
                        onLast={onLast}
                        layout={layout}
                        opening={opening}
                    />
                )}

                <ObserveNavigation
                    onPlayClick={() => {
                        setDrawerOpen('open')
                        navigate('/')
                    }}
                    onMenuClick={() => {
                        setIsDrawerMenuOpen(true)
                        setDrawerOpen('open')
                        navigate('/')
                    }}
                />
            </AppLayout>
            {gameResult && liveGameData && (
                <ObserverGameOverDialog
                    open={openGameEndDialog}
                    liveGameData={liveGameData}
                    onAnalysis={onAnalysisClick}
                    onClose={() => setOpenGameEndDialog(false)}
                    result={gameResult}
                    layout={layout}
                />
            )}
            <ObserverServerConnector />
            <ConnectionFailedOverlay connectionStatus={connectionStatus} />
        </>
    )
}
