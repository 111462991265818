import { Button, Link, Typography } from '@mui/material'
import { InfoDialogTemplate, InfoDialogText } from '../../InfoDialogTemplate/InfoDialogTemplate'

export interface WelcomeGuestFormProps {
    onClose: () => void
    userName: string
    onSignUpClick: () => void
    onSignin: () => void
    open: boolean
}

export const WelcomeGuestForm: React.FC<WelcomeGuestFormProps> = ({
    onClose,
    userName,
    onSignUpClick,
    onSignin,
    open,
}) => {
    return (
        <InfoDialogTemplate
            open={open}
            handleClose={onClose}
            content={
                <InfoDialogText
                    header={<>Welcome {userName}</>}
                    subheader="This is your Temporary Guest Name"
                    description={
                        <>
                            Sign up to choose your own username and access all features, like stats, puzzles and game
                            history.
                            <br />
                            Registration takes less time than a hyper-bullet game!
                        </>
                    }
                />
            }
            actions={
                <>
                    <Button variant="outlined" onClick={onClose}>
                        Continue
                    </Button>
                    <Button variant="contained" onClick={onSignUpClick}>
                        Sign me up!
                    </Button>
                    <Typography variant="body2">
                        Already have an account?
                        <Link sx={{ ml: 1 }} onClick={onSignin}>
                            Log in
                        </Link>
                    </Typography>
                </>
            }
        />
    )
}

export default WelcomeGuestForm
