import { FTUE } from '../../sharedComponents/src/globalHeader/GlobalHeader'

export interface FTUEContent {
    key: FTUE
    content: {
        prefix?: string | JSX.Element
        title: string | JSX.Element
        description: string | JSX.Element
        image: string
    }[]
}

export const content: Record<string, FTUEContent> = {
    '/0': {
        key: 'newGame', // should be key to the BE value
        content: [
            {
                title: 'Quick Pairing',
                description: (
                    <>
                        Get matched with other players in just one click. <br /> Each pool features time controls
                        defined by two numbers: initial minutes per player and added seconds per move.
                    </>
                ),
                image: '/assets/images/FTUE/quick-pairing.webp',
            },
            {
                title: 'custom challenges',
                description: (
                    <>
                        Customize your challenge with your preferred color and time control. Your challenge will be open
                        for others to join the fun!
                    </>
                ),
                image: '/assets/images/FTUE/custom-challenges.webp',
            },
            {
                title: 'Open Challenges',
                description: <>View your created challenges and join challenges created by other players.</>,
                image: '/assets/images/FTUE/open-challenges.webp',
            },
            {
                title: 'Navigate the Tabs',
                description: (
                    <>
                        Quick access to the main menu, start a new game, see other players, or test your skills against
                        the computer.
                    </>
                ),
                image: '/assets/images/FTUE/navigate-the-tabs.webp',
            },
            {
                title: 'navigate the rest',
                description: (
                    <>
                        Utilize the drop down-menu next to the ICC logo for swift navigation to all features instantly.
                        Click on the logo for the homepage.
                    </>
                ),
                image: '/assets/images/FTUE/navigate-the-rest.webp',
            },
        ],
    },
    '/1': {
        key: 'players', // should be key to the BE value
        content: [
            {
                title: 'Players Tab',
                description: <>Check out who is online at any given moment, and challenge anyone to a match</>,
                image: '/assets/images/FTUE/players-tab.webp',
            },
        ],
    },
    '/2': {
        key: 'computer', // should be key to the BE value
        content: [
            {
                title: 'Play Bots Tab',
                description: (
                    <>
                        Test your skills against our bots. Some play traditional chess, while others bring a unique
                        twist with their own playing style.
                    </>
                ),
                image: '/assets/images/FTUE/computer-tab.webp',
            },
        ],
    },
    '/analysis': {
        key: 'analysis', // should be key to the BE value
        content: [
            {
                title: 'Analysis Tab',
                description: (
                    <>
                        You can review the games you have played in the analysis area, and use the engine to find out
                        where you could have played better.
                    </>
                ),
                image: '/assets/images/FTUE/analysis-tab.webp',
            },
            {
                title: 'Setup Tab',
                description: (
                    <>
                        Set up a position to create a puzzle or exercise. You can also play against the computer from a
                        specific starting point.
                    </>
                ),
                image: '/assets/images/FTUE/setup-tab.webp',
            },
            {
                title: 'Games Tab',
                description: (
                    <>All the games you play are automatically saved, and you can review them from this area.</>
                ),
                image: '/assets/images/FTUE/games-tab.webp',
            },
        ],
    },
    '/puzzles': {
        key: 'puzzle', // should be key to the BE value
        content: [
            {
                prefix: 'welcome',
                title: 'to puzzles',
                description: (
                    <>
                        Make puzzle-solving a daily habit to enhance your abilities. The more you practice, the better
                        you'll become at spotting opportunities and threats during your games.
                        <br />
                        Enjoy the flexibility of playing at your own pace while you master the game.
                    </>
                ),
                image: '',
            },
            {
                prefix: 'Learn',
                title: (
                    <>
                        With assistive <br style={{ height: '0', margin: 0 }} /> features
                    </>
                ),
                description: (
                    <>
                        Use hints and solutions to overcome difficult puzzles and deepen your understanding of the game.
                    </>
                ),
                image: '/assets/images/FTUE/learn-with-assistive-features.webp',
            },
            {
                prefix: 'Select',
                title: 'Tags for practice',
                description: (
                    <>
                        Want to practice specific scenarios?
                        <br />
                        Customize your tags for targeted practice.
                    </>
                ),
                image: '/assets/images/FTUE/select-tags-for-practice.webp',
            },
        ],
    },
}
