import { Button, Paper } from '@mui/material'
import { useStoreActions } from '../../store/hooks'

export function LoginHint() {
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)
    const setDrawerOpen = useStoreActions((state) => state.setDrawerOpen)

    return (
        <Paper className="loginHint">
            <p>This feature requires you to Log in.</p>
            <Button
                variant="text"
                onClick={() => {
                    setOverlayTrigger('signin')
                    setDrawerOpen('closed')
                }}
            >
                Log in now
            </Button>
            <p>No account? </p>
            <Button
                variant="text"
                onClick={() => {
                    setOverlayTrigger('signup')
                    setDrawerOpen('closed')
                }}
            >
                Register for free.
            </Button>
        </Paper>
    )
}
