import { Palette, useTheme } from '@mui/material'
import React from 'react'
import PgnCommandHandler from '../../chess/PgnCommandHandler'
import { oppositePieces } from '../../chess/basics'
import { GameTreeMove } from '../../chess/types'

const moveStyles = (palette: Palette, isActive: boolean = false) => ({
    cursor: 'pointer',
    padding: '0.25rem',
    borderRadius: '0.25rem',
    fontFamily: 'Space Grotesk',
    fontWeight: 700,
    fontSize: '1.125rem',
    color: isActive ? palette.background.default : palette.text.primary,
    backgroundColor: isActive ? palette.text.primary : palette.background.paper,
})

const chessPieceStyle = { fontSize: '1.375rem' }

type Props = {
    selectMove: () => void
    setEditMoveID: () => void
    setAnnotation: () => void
    handleClickOpen: () => void
    editableAnnotations?: boolean
    isActive?: boolean
    move: GameTreeMove
}

const MoveBox: React.FC<Props> = ({
    selectMove,
    setEditMoveID,
    setAnnotation,
    handleClickOpen,
    editableAnnotations,
    isActive,
    move,
}) => {
    const { palette } = useTheme()
    const chessPiece = move.displayString.match(/[♔-♟]/)

    const cleanAnnotation = PgnCommandHandler.extractCommandsFromAnnotation(move.annotation ?? '')[0]

    // This is because in dark mode, the white pieces appear as black pieces.
    // So depending on the theme mode, the pieces need to be inverted.

    return (
        <div
            onContextMenu={(e) => {
                if (!editableAnnotations) return
                e.preventDefault()
                selectMove()
                setEditMoveID()
                setAnnotation()
                handleClickOpen()
            }}
            onClick={selectMove}
            style={{ ...moveStyles(palette, isActive) }}
        >
            {move.displayString.split('').map((char, index) => {
                const isChessPiece = chessPiece && char === chessPiece[0]

                if (isChessPiece) {
                    return (
                        <span key={index} style={{ ...chessPieceStyle }}>
                            {isActive ? char : oppositePieces[char]}
                        </span>
                    )
                }
                return <React.Fragment key={index}>{char}</React.Fragment>
            })}
            {cleanAnnotation && <span> {cleanAnnotation} </span>}
        </div>
    )
}

export default MoveBox
