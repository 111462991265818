const LogoutIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.25 14.375V11.5H11.0625V8.625H18.25V5.75L22.5625 10.0625L18.25 14.375ZM16.8125 12.9375V18.6875H9.625V23L1 18.6875V0H16.8125V7.1875H15.375V1.4375H3.875L9.625 4.3125V17.25H15.375V12.9375H16.8125Z"
            fill="white"
        />
    </svg>
)

LogoutIcon.displayName = 'LogoutIcon'

export default LogoutIcon
