import { Button, Stack } from '@mui/material'

type Props = {
    onPlayClick: () => void
    onMenuClick: () => void
}

export const ObserveNavigation = ({ onPlayClick, onMenuClick }: Props) => {
    return (
        <Stack direction="row" justifyContent={'center'} spacing={1}>
            <Button variant={'contained'} onClick={onPlayClick}>
                Play
            </Button>
            <Button variant={'contained'} onClick={onMenuClick}>
                Menu
            </Button>
        </Stack>
    )
}
