import { Button, Tabs } from '@mui/material'
import React, { SyntheticEvent } from 'react'

export interface TabBarProps {
    onTabClick: (value: number) => void
    tabLabels: (string | React.ReactNode)[]
    initSelected: number
    notation?: boolean
}

export const TabBar = React.memo((props: TabBarProps) => {
    const { onTabClick, tabLabels, initSelected, notation } = props

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        onTabClick(newValue)
    }

    return (
        <Tabs className={`tabBar`} value={initSelected} onChange={handleChange} centered variant="fullWidth">
            {tabLabels.map((tab, index) => (
                <Button
                    color="primary"
                    key={`tab-${index}`}
                    size="small"
                    variant={initSelected === index ? 'contained' : 'outlined'}
                    onClick={() => onTabClick(index)}
                >
                    {tab}
                </Button>
            ))}
        </Tabs>
    )
})
