import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useStoreState } from '../../../../store/hooks'
import DetailsBlock from './DetailsBlock'
import StrengthSelector from './StrengthSelector'

const BotDetails: React.FC = () => {
    const searchOptions = useStoreState((state) => state.matchMaker.searchOptions.computerChallenge)
    const selectedBot = useStoreState((state) => state.matchMaker.searchOptions.computerChallenge.selectedBot)

    return selectedBot ? (
        <Stack gap={1}>
            {selectedBot.bio && <Typography variant="body2">{selectedBot.bio}</Typography>}
            <DetailsBlock title="Time Control:" value={`${searchOptions.minutes}+${searchOptions.increment}`} />
            <DetailsBlock title="Color:" color={searchOptions.color} />
            {selectedBot.difficultyLevel === -1 && <StrengthSelector />}
        </Stack>
    ) : null
}

BotDetails.displayName = 'BotDetails'

export default BotDetails
