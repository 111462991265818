const ResignIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.8321 11.5878L25.8252 11.5795C25.2753 10.915 24.5413 10.3938 23.7251 10.0841C22.6709 9.68371 21.4919 9.56254 20.2222 9.58067C18.5631 12.4468 16.9074 15.3152 15.2513 18.1845C15.0912 18.4618 14.9311 18.7392 14.7709 19.0166C15.7693 19.103 16.794 19.3014 17.7913 19.6969C19.1894 20.2431 20.382 21.1995 21.2535 22.3608C21.8001 23.0682 22.2385 23.8288 22.6229 24.4959C22.6365 24.5195 22.6501 24.5432 22.6637 24.5665C23.0763 25.2825 23.4349 25.8951 23.8663 26.4395L23.8753 26.4507C24.4236 27.1568 25.1539 27.6944 25.9497 27.9563C27.336 28.4134 28.8787 28.3717 30.5526 28.1482C32.3351 25.0774 34.1092 22.0012 35.8844 18.9229C34.7772 18.9543 33.6235 18.8676 32.4763 18.5446C31.1095 18.1606 29.872 17.3667 28.9519 16.2908C28.245 15.469 27.7162 14.5533 27.2613 13.7652L27.2416 13.7312C26.7598 12.8967 26.3531 12.1985 25.8392 11.596L25.8321 11.5878ZM30.0353 15.3623C30.7728 16.2254 31.7684 16.8639 32.8624 17.1713C34.2873 17.5725 35.7706 17.5572 37.2292 17.3972C37.7468 17.3405 38.125 17.8941 37.8649 18.345C37.632 18.7489 37.3991 19.1527 37.1662 19.5566C35.3103 22.7746 33.4546 25.9926 31.5891 29.2046C31.4952 29.3666 31.333 29.4777 31.1477 29.505C29.28 29.7801 27.328 29.9129 25.5034 29.3113C24.4094 28.9513 23.4511 28.2308 22.7479 27.3255C22.231 26.6735 21.817 25.9552 21.4031 25.2371C21.0051 24.5467 20.6073 23.8564 20.1179 23.2249C19.3909 22.2532 18.4034 21.467 17.2683 21.0242C16.0544 20.5421 14.7517 20.3918 13.4548 20.3869C12.9633 20.385 12.6398 19.8544 12.8854 19.4288C13.262 18.7765 13.6386 18.1241 14.0151 17.4717C15.7469 14.4714 17.4787 11.4711 19.2143 8.47338C19.3196 8.2917 19.5096 8.17493 19.7194 8.16699C21.2398 8.10958 22.7939 8.20436 24.2313 8.7504C25.274 9.14592 26.2139 9.81127 26.9247 10.6703C27.5345 11.3847 28.0018 12.1942 28.4692 13.0037C28.9422 13.8227 29.415 14.6418 30.0353 15.3623Z"
                fill="white"
            />
            <path
                d="M3.68294 29.1591L3.68292 29.1591C3.62536 29.1259 3.60573 29.0524 3.63887 28.995L3.63888 28.995L17.2887 5.36106L17.2887 5.36105C17.3219 5.30356 17.3956 5.28372 17.4533 5.31703L17.4533 5.31703L18.528 5.93726L18.528 5.93727C18.5855 5.97047 18.6052 6.04389 18.572 6.10142L4.92223 29.7353C4.889 29.7928 4.81523 29.8126 4.75755 29.7794L3.68294 29.1591Z"
                stroke="white"
            />
        </svg>
    )
}
export default ResignIcon
