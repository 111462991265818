const LiveGamesIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_578_12815)">
            <path d="M6.11857 20.6826V21.9622H2.64057V20.6826H6.11857ZM2.64057 14V21.9622H1V14H2.64057Z" fill="white" />
            <path d="M8.80363 14V21.9622H7.16853V14H8.80363Z" fill="white" />
            <path
                d="M13.2113 20.2998L15.087 14H16.9135L14.1409 21.9622H12.9167L13.2113 20.2998ZM11.4832 14L13.3535 20.2998L13.6378 21.9622H12.4238L9.66766 14H11.4832Z"
                fill="white"
            />
            <path
                d="M23 20.6826V21.9622H19.2978V20.6826H23ZM19.2978 14V21.9622H17.6572V14H19.2978ZM22.4477 17.2429V18.4897H19.2978V17.2429H22.4477ZM22.9945 14V15.2851H19.2978V14H22.9945Z"
                fill="white"
            />
            <path
                d="M18.2186 9.96144C18.0833 9.98561 17.9382 9.9849 17.7913 9.95935C17.6445 9.9338 17.499 9.88391 17.363 9.81253C17.227 9.74115 17.1033 9.64969 16.9989 9.54337C16.8945 9.43705 16.8115 9.31796 16.7545 9.1929C15.8338 7.17358 14.0829 5.3916 11.8871 4.2389C9.69119 3.0862 7.23011 2.65718 5.04514 3.04621C4.90946 3.07275 4.7632 3.07395 4.61489 3.04975C4.46658 3.02554 4.31919 2.97641 4.18132 2.90522C4.04345 2.83402 3.91786 2.7422 3.81189 2.6351C3.70591 2.528 3.62166 2.40777 3.56407 2.28143C3.50647 2.15508 3.47668 2.02515 3.47643 1.89922C3.47617 1.77329 3.50546 1.65388 3.56259 1.54795C3.61972 1.44202 3.70354 1.35171 3.80916 1.28226C3.91478 1.21282 4.04009 1.16565 4.17777 1.1435C9.86504 0.129694 16.4187 3.56993 18.8142 8.82673C18.9292 9.07904 18.9311 9.34032 18.8194 9.55311C18.7077 9.7659 18.4916 9.91278 18.2186 9.96144ZM13.9951 10.7814C13.7221 10.83 13.4146 10.7764 13.1402 10.6324C12.8659 10.4884 12.6471 10.2657 12.5321 10.0134C12.3088 9.52359 11.9833 9.05712 11.5742 8.64069C11.1651 8.22425 10.6803 7.866 10.1477 7.58638C9.615 7.30676 9.04483 7.11126 8.46972 7.01104C7.89461 6.91082 7.32582 6.90785 6.79583 7.00228C6.66059 7.02632 6.51546 7.02551 6.36873 6.9999C6.222 6.97428 6.07654 6.92436 5.94065 6.85298C5.80477 6.7816 5.68113 6.69017 5.57678 6.58389C5.47243 6.47762 5.38943 6.3586 5.3325 6.23361C5.27558 6.10862 5.24585 5.98012 5.24501 5.85545C5.24417 5.73078 5.27224 5.61237 5.32762 5.50699C5.383 5.40161 5.46461 5.31132 5.56777 5.24128C5.67093 5.17124 5.79364 5.12281 5.92888 5.09877C7.54525 4.81087 9.36593 5.1282 10.9904 5.98095C12.6149 6.8337 13.9101 8.15203 14.5912 9.64592C14.6482 9.77095 14.6781 9.89951 14.679 10.0243C14.68 10.149 14.6519 10.2675 14.5966 10.373C14.5412 10.4784 14.4596 10.5688 14.3564 10.6389C14.2532 10.709 14.1304 10.7574 13.9951 10.7814Z"
                fill="white"
            />
            <path
                d="M6 11C6 11.5304 6.21071 12.0391 6.58579 12.4142C6.96086 12.7893 7.46957 13 8 13C8.53043 13 9.03914 12.7893 9.41421 12.4142C9.78929 12.0391 10 11.5304 10 11C10 10.4696 9.78929 9.96086 9.41421 9.58579C9.03914 9.21071 8.53043 9 8 9C7.46957 9 6.96086 9.21071 6.58579 9.58579C6.21071 9.96086 6 10.4696 6 11Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_578_12815">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

LiveGamesIcon.displayName = 'LiveGamesIcon'

export default LiveGamesIcon
