const useCorrectPieceColor = (fill: string, stroke: string) => {
    return {
        '> svg path': {
            fill: fill,
            stroke: stroke,
        },
    }
}

export default useCorrectPieceColor
