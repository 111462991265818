import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material'

type Props = {
    isOpen: boolean
    onClose: () => void
    onSubmit: () => void
}

export const AlreadyConnectedDialog = ({ isOpen, onClose, onSubmit }: Props) => {
    return (
        <Dialog open={isOpen} onClose={onClose} sx={{ zIndex: 9999 }}>
            <DialogContent
                sx={{
                    maxWidth: '30rem',
                }}
            >
                <Typography variant="h3" color="secondary" paragraph>
                    Already Connected
                </Typography>
                <DialogContentText fontSize={'1.2rem'} paragraph>
                    You're currently connected from another browser or device.
                    <br />
                    <br />
                    To resume playing here, select <span style={{ fontWeight: 'bold' }}>Play Here</span>.
                </DialogContentText>
                <DialogActions sx={{ mt: 6 }}>
                    <Button onClick={onClose} variant="outlined">
                        Close
                    </Button>
                    <Button onClick={onSubmit}>Play Here</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
