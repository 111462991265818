import { Box } from '@mui/material'
import { PiecesTheme, piecesProvider } from '../../../../sharedComponents/src/globalHeader/common/PiecesProvider'

const customPieces = piecesProvider(PiecesTheme.DEFAULT)

function DraggablePiece({ piece, isSelected, onSelect }) {
    const handleClick = () => {
        if (onSelect) {
            onSelect(piece)
        }
    }

    return (
        <Box
            sx={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: isSelected ? 'primary.main' : 'transparent',
                borderRadius: 1,
                '&:hover': { backgroundColor: isSelected ? 'primary.dark' : 'primary.light' },
            }}
            onClick={handleClick}
        >
            {customPieces[piece]({ isDragging: false, squareWidth: '100%' })}
        </Box>
    )
}

export default DraggablePiece
