import * as Sentry from '@sentry/react'
import { debounce } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'

interface UseStockfishOptions {
    fen: string
    lines: number
    depth: number
}

export interface AnalysisResult {
    startFen: string
    depth: string
    scoreType: string
    score: string
    moves: string[]
}

export type StockEngineResultType = {
    v1: AnalysisResult
    v2: AnalysisResult
    v3: AnalysisResult
}

interface UseStockfishOutput {
    isEngineLoaded: boolean
    startEngine: () => void
    stopEngine: () => void
    allVariations: StockEngineResultType
    isCalculating: boolean
}

const defaultValues = (fen: string): StockEngineResultType => ({
    v1: { startFen: fen, depth: '0', scoreType: '', score: '0', moves: [] },
    v2: { startFen: fen, depth: '0', scoreType: '', score: '0', moves: [] },
    v3: { startFen: fen, depth: '0', scoreType: '', score: '0', moves: [] },
})

export function useStockfish({ fen, lines, depth }: UseStockfishOptions): UseStockfishOutput {
    const [isEngineLoaded, setEngineIsLoaded] = useState(false)
    const [isCalculating, setIsCalculating] = useState(true)
    const [mainStockfish, setMainStockfish] = useState<Worker | undefined>(undefined)
    const [allVariations, setAllVariations] = useState<StockEngineResultType>(defaultValues(fen))

    useEffect(() => {
        setMainStockfish(new Worker('/stockfish/stockfish.js'))
        setIsCalculating(true)

        if (isEngineLoaded && fen && mainStockfish) {
            mainStockfish.postMessage(`setoption name Skill Level value 19`)
            mainStockfish.postMessage(`position fen ${fen}`)
            mainStockfish.postMessage('setoption name Use NNUE value true')
            mainStockfish.postMessage(`go depth ${depth}`)
            mainStockfish.postMessage(`setoption name MultiPV value ${lines}`)
            mainStockfish.onerror = (error) => {
                Sentry.captureMessage(`Stockfish error: ${JSON.stringify(error)}`)
            }
            mainStockfish.onmessage = (event) => {
                const handleVariation = (multipv: number) => {
                    const matchPV = event.data.match(/^info .+ pv (.+)/)
                    const matchDepth = event.data.match(/^info depth (\d+)/)
                    const matchScore = event.data.match(/^info .+ score (cp|mate) (-?\d+)/)
                    if (matchPV && matchDepth && matchScore) {
                        const moves = matchPV[1].split(' ')
                        const depth = matchDepth[1]
                        const scoreType = matchScore[1]
                        const score = matchScore[2]
                        setAllVariations((prev) => ({
                            ...prev,
                            [`v${multipv}`]: { startFen: fen, depth, scoreType, score, moves },
                        }))
                    }
                }

                if (event.data.includes('multipv 1')) {
                    handleVariation(1)
                } else if (event.data.includes('multipv 2')) {
                    handleVariation(2)
                } else if (event.data.includes('multipv 3')) {
                    handleVariation(3)
                } else if (event.data.includes('bestmove (none)')) {
                    setAllVariations(defaultValues(fen))
                }
            }
        }

        return () => {
            mainStockfish?.terminate()
        }
    }, [fen, isEngineLoaded])

    const startEngine = useCallback(() => {
        setMainStockfish(new Worker('/stockfish/stockfish.js'))
        setEngineIsLoaded(true)
        setAllVariations(defaultValues(fen))
    }, [fen])

    const debouncedIsCalculating = useCallback(
        debounce(() => {
            setIsCalculating(false)
        }, 500),
        [],
    )

    useEffect(() => {
        debouncedIsCalculating()
    }, [allVariations, debouncedIsCalculating])

    const stopEngine = useCallback(() => {
        if (mainStockfish) {
            mainStockfish.terminate()
            setMainStockfish(undefined)
            setEngineIsLoaded(false)
        }
    }, [mainStockfish])

    return { allVariations, isEngineLoaded, startEngine, stopEngine, isCalculating }
}
