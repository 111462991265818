import LockIcon from '@mui/icons-material/Lock'
import MusicOffIcon from '@mui/icons-material/MusicOff'
import { Box, Button, Typography, alpha } from '@mui/material'
import { useMemo } from 'react'
import { Settings } from '../globalHeader/GlobalHeader'
import { PiecesTheme, piecesProvider } from '../globalHeader/common/PiecesProvider'
import { boardColors } from '../globalHeader/theme/colors'
import { BoardColor } from './BoardColor'
import { SettingsPage } from './SettingsDialog'

type Variant = 'pieces' | 'board' | 'sounds'

export const ThemePage: React.FC<{
    settings: Settings
    setSettings: (data: Settings) => void
    currentSettingsPage: SettingsPage
    settingsEnabled: boolean
    openJoinUsForm: () => void
}> = ({ settings, currentSettingsPage, setSettings, settingsEnabled, openJoinUsForm }) => {
    const items = useMemo(() => {
        switch (currentSettingsPage) {
            case SettingsPage.BOARD:
                return Object.keys(boardColors).map((color) => {
                    return {
                        item: <BoardColor color={color} />,
                        name: color,
                        key: color,
                        selected: (settings: Settings) => settings.boardStyle === color,
                        handleSelect: () => setSettings({ ...settings, boardStyle: color }),
                    }
                })
            case SettingsPage.PIECES:
                return Object.values(PiecesTheme).map((piece) => {
                    return {
                        item: piecesProvider(piece)['wR']({
                            isDragging: false,
                            squareWidth: '100%',
                            props: { style: { padding: '10%' } },
                        }),
                        name: piece,
                        key: piece,
                        selected: (settings: Settings) => settings.pieceStyle === piece,
                        handleSelect: () => setSettings({ ...settings, pieceStyle: piece }),
                    }
                })
        }
        return []
    }, [currentSettingsPage])

    return (
        <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" sx={{ gap: 1 }}>
            {items.map((entity, index) => {
                return (
                    <Box
                        key={entity.key}
                        onClick={() => {
                            Boolean(index) && !settingsEnabled ? openJoinUsForm() : entity.handleSelect()
                        }}
                        sx={(theme) => ({
                            // height: '124px',
                            // width: '124px',
                            background: '#2F3943',
                            aspectRatio: '1/1',
                            position: 'relative',
                            cursor: 'pointer',
                            overflow: 'hidden',
                            border: `2px solid ${entity.selected(settings) ? theme.palette.primary.main : 'transparent'}`,
                            ':hover': {
                                borderColor:
                                    Boolean(index) && !settingsEnabled ? 'transparent' : theme.palette.primary.main,
                            },
                        })}
                    >
                        {entity.item}
                        <Box
                            sx={{
                                zIndex: 1,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                                flexDirection: 'row',
                                background:
                                    'linear-gradient(0deg, #101820 0%, rgba(16, 24, 32, 0.80) 9.58%, rgba(16, 24, 32, 0.60) 14.87%, rgba(16, 24, 32, 0.40) 19.15%, rgba(16, 24, 32, 0.20) 26.21%, rgba(16, 24, 32, 0.09) 32.26%, rgba(16, 24, 32, 0.00) 41.43%, rgba(16, 24, 32, 0.00) 72.18%, rgba(16, 24, 32, 0.00) 100%)',
                            }}
                        >
                            {Boolean(index) && !settingsEnabled && (
                                // <Tooltip title="Become a member for free to unlock">
                                <LockIcon
                                    sx={(theme) => ({
                                        position: 'absolute',
                                        background: alpha(theme.palette.background.default, 0.7),
                                        color: theme.palette.primary.main,
                                        width: '100%',
                                        height: '100%',
                                        padding: '25%',
                                        // fontSize: '64px',
                                    })}
                                />
                                // </Tooltip>
                            )}
                            <Typography noWrap sx={{ textAlign: 'center', verticalAlign: 'bottom' }} variant="h6">
                                {entity.name}
                            </Typography>
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}

export const ThemesSelector: React.FC<{
    variant: Variant
    settings: Settings
    setCurrentSettingsPage: React.Dispatch<React.SetStateAction<SettingsPage>>
}> = ({ variant, settings, setCurrentSettingsPage }) => {
    const previews: Record<Variant, any> = {
        pieces: {
            title: 'Chess Pieces',
            content: piecesProvider(settings.pieceStyle)['wR']({ isDragging: false, squareWidth: '44px' }),
            handleEdit: () => setCurrentSettingsPage(SettingsPage.PIECES),
        },
        board: {
            title: 'Board Theme',
            content: <BoardColor color={settings.boardStyle} />,
            handleEdit: () => setCurrentSettingsPage(SettingsPage.BOARD),
        },
        sounds: {
            title: 'Custom Sound',
            content: <MusicOffIcon sx={{ width: '100%', height: '100%', padding: 1 }} />,
            handleEdit: () => setCurrentSettingsPage(SettingsPage.SOUNDS),
        },
    }

    return (
        <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexGrow={1} alignItems="center">
                {/* set `width: '180px'` if Sounds are enabled */}
                <Typography sx={{ fontSize: '1.25rem', pr: 4 }}>{previews[variant].title}</Typography>
                <Box width="44px" height="44px" sx={{ background: '#2F3943' }}>
                    {previews[variant].content}
                </Box>
            </Box>
            <Button
                variant="outlined"
                sx={(theme) => ({
                    width: '120px',
                    borderColor: `${theme.palette.primary.main} !important`,
                    color: `${theme.palette.primary.main} !important`,
                })}
                size="small"
                onClick={previews[variant].handleEdit}
            >
                Edit
            </Button>
        </Box>
    )
}

export const ThemesSettings: React.FC<{
    settings: Settings
    setCurrentSettingsPage: React.Dispatch<React.SetStateAction<SettingsPage>>
}> = ({ settings, setCurrentSettingsPage }) => {
    return (
        <Box display="flex" flexDirection="column" width="100%" gap={2}>
            <ThemesSelector setCurrentSettingsPage={setCurrentSettingsPage} settings={settings} variant="pieces" />
            <ThemesSelector setCurrentSettingsPage={setCurrentSettingsPage} settings={settings} variant="board" />
            {/* <ThemesSelector setCurrentSettingsPage={setCurrentSettingsPage} settings={settings} variant="sounds" /> */}
        </Box>
    )
}
