import { FormControlLabel, Stack, styled, Switch, Typography } from '@mui/material'
import HelpTooltip from '../globalHeader/components/HelpTooltip'

const StyledSwitch = styled(Switch)(({ theme }) => ({
    color: theme.palette.primary.main,
    width: '120px',
    fontSize: '22px',
    margin: '5px 0',
    fontWeight: 700,
    fontFamily: 'Space Grotesk',
    '.MuiSwitch-input': {
        left: 'unset',
        width: 'unset',
    },
    '::before': {
        content: '"Off"',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        textTransform: 'uppercase',
        // padding: theme.spacing(0.5),
        width: '50%',
        opacity: 1,
        textAlign: 'center',
    },
    '::after': {
        content: '"On"',
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        textTransform: 'uppercase',
        // padding: theme.spacing(0.5),
        width: '50%',
        opacity: 0.5,
        textAlign: 'center',
    },
    '&.checked::before': {
        opacity: 0.5,
    },
    '&.checked::after': {
        opacity: 1,
    },
    '& .MuiSwitch-switchBase': {
        position: 'absolute',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 0,
        width: '50%',
        height: '100%',
        color: theme.palette.primary.main,
        '::before': {
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.text.primary,
        },
        '::after': {
            border: 'none',
            color: theme.palette.text.primary,
        },
        '&.Mui-checked': {
            transform: 'translateX(100%)',
            '::before': {
                border: 'none',
            },
            '::after': {
                border: `1px solid ${theme.palette.primary.main}`,
            },
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
    },
    '& .MuiSwitch-thumb': {
        display: 'none',
    },
    '& .MuiSwitch-track': {
        display: 'none',
    },
    '& .MuiTouchRipple-root': {
        '& .MuiTouchRipple-child': { borderRadius: 0 },
    },
}))

interface SettingsSwitchProps {
    label: string
    checked: boolean
    disabled?: boolean
    helpText?: string
    onChange: () => void
    onDisabledClick?: () => void
}

const SettingsSwitch: React.FC<SettingsSwitchProps> = (props) => {
    const { label, checked, helpText, onChange, disabled = false, onDisabledClick } = props

    return (
        <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
            <HelpTooltip
                title={helpText}
                hideTooltip={helpText === undefined}
                placement="top-end"
                iconStyle={{ right: '10px', top: '-5px' }}
            >
                <Typography sx={{ fontSize: '1.25rem', pr: 4 }}> {label} </Typography>
            </HelpTooltip>
            <HelpTooltip
                title="Become a Member for Free to Unlock"
                hideTooltip={disabled !== true}
                placement="left-start"
                iconStyle={{ right: '-1px', top: '4px' }}
            >
                <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                        <StyledSwitch
                            checked={checked}
                            onChange={onChange}
                            className={`${checked ? 'checked' : ''}`}
                            disabled={disabled}
                        />
                    }
                    label=""
                    onClick={() => {
                        if (disabled && onDisabledClick) {
                            onDisabledClick()
                        }
                    }}
                />
            </HelpTooltip>
        </Stack>
    )
}
SettingsSwitch.displayName = 'SettingsSwitch'

export default SettingsSwitch
