const PuzzleIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.381 11.4762H18.8095V7.28571C18.8095 6.73002 18.5888 6.19709 18.1958 5.80416C17.8029 5.41122 17.27 5.19048 16.7143 5.19048H12.5238V3.61905C12.5238 2.92443 12.2479 2.25827 11.7567 1.7671C11.2655 1.27593 10.5994 1 9.90476 1C9.21015 1 8.54398 1.27593 8.05282 1.7671C7.56165 2.25827 7.28571 2.92443 7.28571 3.61905V5.19048H3.09524C2.53955 5.19048 2.00661 5.41122 1.61368 5.80416C1.22075 6.19709 1 6.73002 1 7.28571V11.2667H2.57143C4.14286 11.2667 5.4 12.5238 5.4 14.0952C5.4 15.6667 4.14286 16.9238 2.57143 16.9238H1V20.9048C1 21.4605 1.22075 21.9934 1.61368 22.3863C2.00661 22.7793 2.53955 23 3.09524 23H7.07619V21.4286C7.07619 19.8571 8.33333 18.6 9.90476 18.6C11.4762 18.6 12.7333 19.8571 12.7333 21.4286V23H16.7143C17.27 23 17.8029 22.7793 18.1958 22.3863C18.5888 21.9934 18.8095 21.4605 18.8095 20.9048V16.7143H20.381C21.0756 16.7143 21.7417 16.4384 22.2329 15.9472C22.7241 15.456 23 14.7899 23 14.0952C23 13.4006 22.7241 12.7345 22.2329 12.2433C21.7417 11.7521 21.0756 11.4762 20.381 11.4762Z"
            fill="white"
        />
    </svg>
)

PuzzleIcon.displayName = 'PuzzleIcon'

export default PuzzleIcon
