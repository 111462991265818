import { useEffect, useState } from 'react'
import Rating from './Rating'

type Props = {
    oldRating: number
    newRating?: number
    onAnimationEnd?: () => void
    animationDuration?: number // ms
    type?: 'bullet' | 'blitz' | 'rapid' | 'classic'
    isProvisional?: boolean
}

export const RatingCounter = ({
    oldRating,
    newRating = 0,
    onAnimationEnd,
    animationDuration = 1000,
    type,
    isProvisional,
}: Props) => {
    const [displayedRating, setDisplayedRating] = useState(oldRating)

    useEffect(() => {
        let start: number

        const step = (timestamp: number) => {
            if (start === undefined) {
                start = timestamp
            }

            if (newRating > 0) {
                const elapsed = timestamp - start
                const newDisplayedRating =
                    oldRating + Math.round(((newRating - oldRating) * elapsed) / animationDuration)
                setDisplayedRating(newDisplayedRating)

                if (elapsed < animationDuration) {
                    window.requestAnimationFrame(step)
                } else {
                    setDisplayedRating(newRating)
                    onAnimationEnd && onAnimationEnd()
                }
            }
        }

        requestAnimationFrame(step)
    }, [oldRating, newRating])

    return <Rating data={displayedRating} type={type} iconSize="small" textSize="body1" isProvisional={isProvisional} />
}
