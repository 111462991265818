import React from 'react'
import { AreaType } from '../GlobalHeader'

interface IProps {
    href?: string
    target?: string
    rel?: string
    style?: React.CSSProperties
    Link: any
    children: React.ReactNode
    area: AreaType
}

const GlobalLink: React.FC<IProps> = (props) => {
    const { href, target, rel, style, children, Link, area } = props

    if (area === AreaType.PLAY_AREA) {
        return (
            <Link to={href} target={target} rel={rel} style={style}>
                {children}
            </Link>
        )
    }

    return (
        <Link href={href} target={target} rel={rel} style={style}>
            {children}
        </Link>
    )
}

export default GlobalLink
