export const BlackPieces = [
    {
        piece: 'bK',
        square: 'undefined',
        id: '7',
    },
    {
        piece: 'bQ',
        square: 'undefined',
        id: '8',
    },
    {
        piece: 'bR',
        square: 'undefined',
        id: '9',
    },
    {
        piece: 'bB',
        square: 'undefined',
        id: '10',
    },
    {
        piece: 'bN',
        square: 'undefined',
        id: '11',
    },
    {
        piece: 'bP',
        square: 'undefined',
        id: '12',
    },
]
