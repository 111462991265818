import { useEffect } from 'react'

type Options = {
    shouldLoad?: boolean
    async?: boolean
    crossOrigin?: string
    loadFirst?: boolean
}

const useScript = (id: string, resourceUrl: string, options: Options, callback?: (err?: string | Event) => void) => {
    useEffect(() => {
        if (options.shouldLoad) {
            const existingScript = document.getElementById(id)
            if (existingScript) return

            const script = document.createElement('script')
            script.src = resourceUrl
            script.id = id
            script.async = !!options.async
            script.crossOrigin = options.crossOrigin || null
            script.type = 'text/javascript'

            script.onload = () => callback && callback()
            script.onerror = (err) => callback && callback(err)

            if (options.loadFirst) {
                const firstScript = document.getElementsByTagName('script')[0]
                firstScript.parentNode?.insertBefore(script, firstScript)
                return () => {
                    firstScript.parentNode?.removeChild(script)
                }
            } else {
                document.body.appendChild(script)
                if (id === 'gtm') console.log('gtm script loaded')
                return () => {
                    document.body.removeChild(script)
                    if (id === 'gtm') console.log('gtm script removed')
                }
            }
        }
    }, [id, resourceUrl, callback, options.async, options.crossOrigin, options.shouldLoad, options.loadFirst])
}

export default useScript
