import { useEffect, useState } from 'react'
import { UserData } from '../../GlobalHeader'
import {
    AcceptRematchEvent,
    AnalyticsEventMap,
    ComputerChallengeEvent,
    CustomChallengeEvent,
    DailyPuzzleEvent,
    EnterAutoPoolEvent,
    ExitAutoPoolEvent,
    GameFinishedEvent,
    GameStartedEvent,
    NextPuzzleEvent,
    OfferAbortEvent,
    OfferDrawEvent,
    OfferRematchEvent,
    ResignEvent,
    TimeInQueueEvent,
    analyticsManager,
} from './AnalyticsManager'
import { getHighestUserGroupName } from './utils'

type Props = {
    gaGameKey: string
    gaSecretKey: string
    version: string
    env: string
    userData?: UserData
}

export const GameAnalyticsServiceComponent = ({ gaGameKey, gaSecretKey, version, env, userData }: Props) => {
    const [gameanalytics, setGameanalytics] = useState<any>(null)

    useEffect(() => {
        const loadGameAnalytics = async () => {
            const { default: gameanalytics } = await import('gameanalytics')
            setGameanalytics(gameanalytics)
        }

        loadGameAnalytics()
    }, [])

    useEffect(() => {
        if (!gameanalytics) return

        if (userData?.id && userData.groups) {
            const userType = getHighestUserGroupName(userData.groups)
            const userId = userData.id
            const userName = userData.user_name

            const eventHandlers: { [key in keyof AnalyticsEventMap]?: Function } = {
                customChallenge: (ev: CustomChallengeEvent) => {
                    let colorNumber = 1
                    if (ev.color === 'black') colorNumber = 0
                    if (ev.color === 'noColor') colorNumber = 2

                    gameanalytics.GameAnalytics.addDesignEvent('CustomChallenge:time', ev.minutes)
                    gameanalytics.GameAnalytics.addDesignEvent('CustomChallenge:increment', ev.increment)
                    gameanalytics.GameAnalytics.addDesignEvent('CustomChallenge:rated', ev.rated ? 1 : 0)
                    gameanalytics.GameAnalytics.addDesignEvent('CustomChallenge:color', colorNumber)
                    gameanalytics.GameAnalytics.addDesignEvent('CustomChallenge:ratingFrom', ev.ratingFrom)
                    gameanalytics.GameAnalytics.addDesignEvent('CustomChallenge:ratingTo', ev.ratingTo)
                    gameanalytics.GameAnalytics.addDesignEvent(`CustomChallenge:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`CustomChallenge:userName:${userName}`)
                },
                computerChallenge: (ev: ComputerChallengeEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `ComputerChallenge:${ev.minutes}-${ev.increment}:${ev.color}:${ev.gameType}`,
                        ev.difficulty,
                    )
                    gameanalytics.GameAnalytics.addDesignEvent(`ComputerChallenge:botId:${ev.botId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`ComputerChallenge:botName:${ev.botName}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`ComputerChallenge:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`ComputerChallenge:userName:${userName}`)
                },
                enterAutoPool: (ev: EnterAutoPoolEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(`EnterAutoPool:${ev.challengeString}`, 1)
                    gameanalytics.GameAnalytics.addDesignEvent(`EnterAutoPool:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`EnterAutoPool:userName:${userName}`)
                },
                exitAutoPool: (ev: ExitAutoPoolEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(`ExitAutoPool:${ev.challengeString}`, 1)
                    gameanalytics.GameAnalytics.addDesignEvent(`ExitAutoPool:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`ExitAutoPool:userName:${userName}`)
                },
                timeInQueue: (ev: TimeInQueueEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(
                        `TimeInQueue:${ev.challengeType}:${ev.rated ? 'rated' : 'unrated'}:${ev.challengeString}`,
                        ev.timeInQueue / 1000,
                    )
                    gameanalytics.GameAnalytics.addDesignEvent(`TimeInQueue:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`TimeInQueue:userName:${userName}`)
                },
                gameStarted: (ev: GameStartedEvent) => {
                    const challengeString = ev.minutes + '-' + ev.increment

                    gameanalytics.GameAnalytics.addDesignEvent(
                        `GameStarted:${ev.matchKind}:${ev.rated ? 'rated' : 'unrated'}:${challengeString}`,
                        1,
                    )
                    gameanalytics.GameAnalytics.addDesignEvent(`GameStarted:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`GameStarted:userName:${userName}`)
                    if (ev.botId) gameanalytics.GameAnalytics.addDesignEvent(`GameStarted:botId:${ev.botId}`)
                    if (ev.botName) gameanalytics.GameAnalytics.addDesignEvent(`GameStarted:botName:${ev.botName}`)
                    if (ev.difficulty)
                        gameanalytics.GameAnalytics.addDesignEvent('PvEgamestartedDifficulty', ev.difficulty)
                },
                resign: (ev: ResignEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent('Resign', 1)
                    gameanalytics.GameAnalytics.addDesignEvent(`Resign:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`Resign:userName:${userName}`)
                },
                offerDraw: (ev: OfferDrawEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent('OfferDraw', 1)
                    gameanalytics.GameAnalytics.addDesignEvent(`OfferDraw:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`OfferDraw:userName:${userName}`)
                },
                offerAbort: (ev: OfferAbortEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent('OfferAbort', 1)
                    gameanalytics.GameAnalytics.addDesignEvent(`OfferAbort:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`OfferAbort:userName:${userName}`)
                },
                offerRematch: (ev: OfferRematchEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent('RematchSent', 1)
                    gameanalytics.GameAnalytics.addDesignEvent(`RematchSent:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`RematchSent:userName:${userName}`)
                },
                acceptRematch: (ev: AcceptRematchEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent('RematchAccepted', 1)
                    gameanalytics.GameAnalytics.addDesignEvent(`RematchAccepted:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`RematchAccepted:userName:${userName}`)
                },
                gameFinished: (ev: GameFinishedEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent('GameFinished:timeInGame', ev.timeInGame)
                    gameanalytics.GameAnalytics.addDesignEvent(`GameFinished:reason:${ev.reason}`, 1)
                    gameanalytics.GameAnalytics.addDesignEvent(`GameFinished:method:${ev.method}`, 1)
                    gameanalytics.GameAnalytics.addDesignEvent(`GameFinished:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`GameFinished:userName:${userName}`)
                },
                nextPuzzle: (ev: NextPuzzleEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(`NextPuzzle:datePlayed:${ev.datePlayed}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`NextPuzzle:puzzleId:${ev.puzzleId}`)
                    gameanalytics.GameAnalytics.addDesignEvent('NextPuzzle:puzzleRating', ev.puzzleRating)
                    gameanalytics.GameAnalytics.addDesignEvent('NextPuzzle:puzzleSource', ev.puzzleSource)
                    gameanalytics.GameAnalytics.addDesignEvent('NextPuzzle:userRating', ev.userRating)
                    gameanalytics.GameAnalytics.addDesignEvent(`NextPuzzle:result:${ev.result}`)
                    gameanalytics.GameAnalytics.addDesignEvent('NextPuzzle:attempts', ev.attempts)
                    gameanalytics.GameAnalytics.addDesignEvent('NextPuzzle:usedHints', ev.usedHints)
                    gameanalytics.GameAnalytics.addDesignEvent(`NextPuzzle:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`NextPuzzle:userName:${userName}`)
                },
                dailyPuzzle: (ev: DailyPuzzleEvent) => {
                    gameanalytics.GameAnalytics.addDesignEvent(`DailyPuzzle:datePlayed:${ev.datePlayed}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`DailyPuzzle:puzzleId:${ev.puzzleId}`)
                    gameanalytics.GameAnalytics.addDesignEvent('DailyPuzzle:puzzleRating', ev.puzzleRating)
                    gameanalytics.GameAnalytics.addDesignEvent('DailyPuzzle:puzzleSource', ev.puzzleSource)
                    gameanalytics.GameAnalytics.addDesignEvent('DailyPuzzle:userRating', ev.userRating)
                    gameanalytics.GameAnalytics.addDesignEvent(`DailyPuzzle:result:${ev.result}`)
                    gameanalytics.GameAnalytics.addDesignEvent('DailyPuzzle:attempts', ev.attempts)
                    gameanalytics.GameAnalytics.addDesignEvent('DailyPuzzle:usedHints', ev.usedHints)
                    gameanalytics.GameAnalytics.addDesignEvent(`DailyPuzzle:userId:${userId}`)
                    gameanalytics.GameAnalytics.addDesignEvent(`DailyPuzzle:userName:${userName}`)
                },
            }

            gameanalytics.GameAnalytics.setEnabledInfoLog(env === 'dev')
            gameanalytics.GameAnalytics.configureBuild(version)
            gameanalytics.GameAnalytics.configureUserId(userId)
            // if GroupNames enum changes, this will need to be updated
            gameanalytics.GameAnalytics.configureAvailableCustomDimensions01([
                'visitor',
                'guest',
                'member',
                'subscribed',
            ])
            gameanalytics.GameAnalytics.setCustomDimension01(userType)
            gameanalytics.GameAnalytics.setEnabledEventSubmission(true)
            gameanalytics.GameAnalytics.initialize(gaGameKey, gaSecretKey)

            // Add listeners using the stored references
            Object.entries(eventHandlers).forEach(([event, handler]) => {
                analyticsManager.addEventListener(event as any, handler as any)
            })

            return () => {
                gameanalytics.GameAnalytics.endSession()
                gameanalytics.GameAnalytics.setEnabledEventSubmission(false)

                // Remove listeners using the stored references
                Object.entries(eventHandlers).forEach(([event, handler]) => {
                    analyticsManager.removeEventListener(event as any, handler as any)
                })
            }
        }
    }, [userData?.id, userData?.groups, gameanalytics, gaGameKey, gaSecretKey, version, env])

    return null
}
