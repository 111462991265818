import { Alert, Box, Snackbar } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { squareByName } from '../../chess/basics'
import { isValidFen, positionFromFEN, positionToFEN } from '../../chess/fen'
import {
    createRootGameTree,
    getMoveById,
    getPositionById,
    getStartingPosition,
    removeVariationsFromGameTree,
} from '../../chess/gameTree'
import { convertGameTreeToPGN, gameFromPGN, PGNFields, pgnParseHeaders } from '../../chess/pgn'
import { BasePositionFEN, EmptyBoardFEN } from '../../chess/positionPresets'
import { Color, Move, Piece, Position } from '../../chess/types'
import AppLayout from '../../components/AppLayout/AppLayout'
import { TabBar } from '../../components/tabBar/TabBar'
import { Piece as BoardPiece, BoardPosition } from '../../react-chessboard/src/chessboard/types'
import { LoginState, Rules } from '../../sharedComponents/src/globalHeader/GlobalHeader'
import { useResponsiveSizings } from '../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { getGameResultType } from '../gameView/components/gameOverDialog/utils'
import { GAME_END_REASONS } from '../gameView/components/gameServerConnector/GSTypes'
import AnalysisBoardContent from './AnalysisBoardContent'
import AnalysisNavigation from './AnalysisNavigation'
import BoardSetupBoardContent from './BoardSetupBoardContent'
import AnalysisTab from './analysisTabs/AnalysisTab'
import BoardSetupTab from './analysisTabs/BoardSetupTab'
import MyGamesTab from './analysisTabs/MyGamesTab'
import PieceSelection from './components/PieceSelection/PieceSelection'
import { AnalysisControls } from './components/analyisControls/AnalysisControls'
import { useStockfish } from './useStockfish/useStockFish'
import { BasePGNHeaders, shortToFullPieceName } from './utils'

export const AnalysisView = () => {
    // ----------------------------------------------------------------- state
    const flipped = useStoreState((state) => state.analysisMode.flipped)
    const deleteModeOn = useStoreState((state) => state.analysisMode.deleteModeOn)
    const gameTree = useStoreState((state) => state.analysisMode.gameTree)
    const currentPositionId = useStoreState((state) => state.analysisMode.currentPositionId)
    const activeAnalysisTab = useStoreState((state) => state.activeAnalysisTab)
    const fen = useStoreState((state) => state.analysisMode.fen)
    const PGNHeader = useStoreState((state) => state.PGNHeader)
    const userData = useStoreState((state) => state.userData)
    const colorToMove = useStoreState((state) => state.analysisMode.colorToMove)
    const token = useStoreState((state) => state.token)
    const rules = useStoreState((state) => state.rules)
    const botsExist = useStoreState((state) => state.matchMaker.bots.length > 0)
    // ----------------------------------------------------------------- actions
    const setFlipped = useStoreActions((state) => state.analysisMode.setFlipBoard)
    const setDeleteModeOn = useStoreActions((state) => state.analysisMode.setDeleteModeOn)
    const setActiveTab = useStoreActions((state) => state.setActiveTab)
    const setActiveAnalysisTab = useStoreActions((state) => state.setActiveAnalysisTab)
    const setCurrentPositionId = useStoreActions((state) => state.analysisMode.setCurrentPositionId)
    const addVariationMove = useStoreActions((state) => state.analysisMode.addVariationMove)
    const setGameTreeWithFen = useStoreActions((state) => state.analysisMode.setGameTreeWithFen)
    const setNewGameTree = useStoreActions((state) => state.analysisMode.setGameTree)
    const setColorToMove = useStoreActions((state) => state.analysisMode.setColorToMove)
    const setFen = useStoreActions((state) => state.analysisMode.setFen)
    const setPlayComputerFen = useStoreActions((state) => state.analysisMode.setPlayComputerFen)
    const setOverlayTrigger = useStoreActions((state) => state.setOverlayTrigger)
    const setDrawerOpen = useStoreActions((state) => state.setDrawerOpen)
    const setResultType = useStoreActions((state) => state.analysisMode.setResultType)
    const setOpenCustomBotPanel = useStoreActions((state) => state.matchMaker.setOpenCustomBotPanel)
    const setLastBot = useStoreActions((state) => state.matchMaker.setLastBot)

    // ----------------------------------------------------------------- hooks
    const [whiteShort, setWhiteShort] = useState<boolean>(true)
    const [whiteLong, setWhiteLong] = useState<boolean>(true)
    const [blackShort, setBlackShort] = useState<boolean>(true)
    const [blackLong, setBlackLong] = useState<boolean>(true)
    const [headers, setHeaders] = useState<[{ [index: string]: string }, number]>([PGNHeader, 0])

    const [selectedPiece, setSelectedPiece] = useState<BoardPiece | undefined>(undefined)
    const [selectModeOn, setSelectModeOn] = useState<boolean>(false)

    const navigate = useNavigate()

    const gameHistoryEnabled = rules.some((rule) => rule === Rules.FULL_ACCESS || rule === Rules.GAME_HISTORY)
    const [open, setOpen] = useState(false)

    const { layout } = useResponsiveSizings()

    useEffect(() => {
        if (userData.state !== LoginState.LOGGED_IN) {
            setDrawerOpen('closed')
        }
    }, [userData.state])

    const { allVariations, isEngineLoaded, startEngine, stopEngine, isCalculating } = useStockfish({
        fen:
            (gameTree && currentPositionId && positionToFEN(getPositionById(gameTree, currentPositionId).position)) ||
            fen,

        // TODO: make this configurable
        lines: 3,
        // This is the depth of the analysis
        depth: 15,
    })

    useEffect(() => {
        if (gameTree !== undefined && currentPositionId === undefined) {
            setCurrentPositionId(getStartingPosition(gameTree).id)
        }
    }, [])

    const currentPositionToPositionObject = (currentPosition: BoardPosition) => {
        const position: Position = {
            board: [],
            turn: Color.White,
            castling: {
                whiteShort: true,
                whiteLong: true,
                blackShort: true,
                blackLong: true,
            },
            enPassant: null,
            moveNum: 1,
            halfmoveClock: 0,
        }

        for (let i = 0; i < 64; i++) {
            position.board.push(null)
        }

        for (const key in currentPosition) {
            const pieceFullName = shortToFullPieceName[currentPosition[key]]
            const square = squareByName(key)
            position.board[square] = Piece[pieceFullName] as unknown as Piece
        }
        return position
    }

    const gtpos = useMemo(() => getPositionById(gameTree, currentPositionId), [gameTree, currentPositionId])

    const addEngineMove = (newMove: Move | Move[]) => {
        // we need to set the current position to the previous position to add the move as a variation
        if (gtpos.previousMoveId) {
            const prevMove = getMoveById(gameTree, gtpos.previousMoveId)
            setCurrentPositionId(prevMove!.previousPositionId)
        }
        // if the engine returns multiple moves, we need to add them as variations
        if (Array.isArray(newMove)) {
            newMove.forEach((m) => {
                addVariationMove(m)
            })
            return
        } else {
            // if the engine returns a single move, we can just add it as a variation
            addVariationMove(newMove)
        }
    }

    const onPgnUpload = (event: React.ChangeEvent<HTMLInputElement> | HTMLTextAreaElement) => {
        if (event instanceof HTMLTextAreaElement) {
            const text = event.value
            let headers = pgnParseHeaders(text)
            setHeaders(headers)
            let result = gameFromPGN(text)
            setNewGameTree(result)
        } else if (event.target.files && event.target.files.length) {
            const file = event.target.files[0]
            const reader = new FileReader()
            reader.onload = (e) => {
                if (e.target?.result) {
                    const pgn = e.target.result as string
                    let headers = pgnParseHeaders(pgn)
                    setHeaders(headers)
                    let result = gameFromPGN(pgn)
                    setNewGameTree(result)
                }
            }
            reader.readAsText(file)
        }
    }

    const onGameHistoryClick = (pgn: string, reason: GAME_END_REASONS) => {
        if (!gameHistoryEnabled) {
            setOverlayTrigger('joinUs')
        } else {
            let resultType = getGameResultType(reason)
            setResultType(resultType)
            let headers = pgnParseHeaders(pgn)
            setHeaders(headers)
            let result = gameFromPGN(pgn)
            setNewGameTree(result)
            setActiveAnalysisTab(0)
        }
    }

    const onPositionToFENClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        if (fen.length === 0) {
            return
        }
        if (!isValidFen(fen)) {
            setOpen(true)
        }
        if (gameTree && fen.length !== 0 && isValidFen(fen)) {
            setGameTreeWithFen(fen)
        }
        //set active tab to analysis
        setActiveAnalysisTab(0)
    }

    useEffect(() => {
        setFlipped(headers[0]['Black'] === userData.userData?.user_name)
    }, [setNewGameTree, headers, userData.userData?.user_name])

    const onNewGameClickHandler = () => {
        const newGame = createRootGameTree(BasePositionFEN)

        setNewGameTree(removeVariationsFromGameTree(newGame))
        setHeaders((prev) => {
            return [BasePGNHeaders, 0]
        })
    }

    //should redirect and remove the game tree from the state
    const onPlayComputerClickHandler = () => {
        if (activeAnalysisTab === 1) {
            setPlayComputerFen(fen)
        } else if (gameTree && currentPositionId) {
            const GameTreePosition = getPositionById(gameTree, currentPositionId)
            const positionFen = positionToFEN(GameTreePosition.position)
            setPlayComputerFen(positionFen)
        }
        setLastBot()
        setActiveTab(2)
        setOpenCustomBotPanel(true)
        setDrawerOpen('open')
        navigate('/')
    }

    useEffect(() => {
        if (gameTree && currentPositionId) {
            const GameTreePosition = getPositionById(gameTree, currentPositionId)
            const fen = positionToFEN(GameTreePosition.position)
            setFen(fen)
        }
    }, [gameTree, currentPositionId, setFen])

    useEffect(() => {
        setColorToMove(gtpos.position.turn)
        setWhiteShort(gtpos.position.castling.whiteShort)
        setWhiteLong(gtpos.position.castling.whiteLong)
        setBlackShort(gtpos.position.castling.blackShort)
        setBlackLong(gtpos.position.castling.blackLong)
    }, [gtpos, setColorToMove])

    const handleColorToMoveChange = (e) => {
        setColorToMove(e.target.value)
        if (e.target.value === '1') {
            const newFen = fen.replace(/(\s)(b)/, '$1w')
            setFen(newFen)
        } else if (e.target.value === '-1') {
            const newFen = fen.replace(/(\s)(w)/, '$1b')
            setFen(newFen)
        }
    }

    //convert fen to position, change castling, convert back to fen
    const toggleCastling = (index) => (event) => {
        const position = positionFromFEN(fen)
        switch (index) {
            case 0:
                setWhiteShort(!whiteShort)
                position.castling.whiteShort = !whiteShort
                break
            case 1:
                setWhiteLong(!whiteLong)
                position.castling.whiteLong = !whiteLong
                break
            case 2:
                setBlackShort(!blackShort)
                position.castling.blackShort = !blackShort
                break
            case 3:
                setBlackLong(!blackLong)
                position.castling.blackLong = !blackLong
                break
            default:
                break
        }
        const newFen = positionToFEN(position)
        setFen(newFen)
    }

    const formatPgnHeaders = (headers): string => {
        let strHeaders = ''
        for (const key in headers) {
            strHeaders += `[${key} "${headers[key]}"]\n`
        }
        return strHeaders
    }

    const onDownloadPGNClickHandler = () => {
        let file: Blob | undefined
        const strHeaders = formatPgnHeaders(headers[0])
        let strArray = [strHeaders]
        // Setup mode
        if (activeAnalysisTab === 1) {
            if (headers[0][PGNFields.FEN] === undefined) {
                const strFen = `[FEN "${fen}"]\n`
                strArray.push(strFen)
            } else {
                const newHeaders = { ...headers[0] }
                newHeaders.FEN = fen
                const newStrHeaders = formatPgnHeaders(newHeaders)
                strArray = [newStrHeaders]
            }
        }
        // Analysis mode
        else if (gameTree) {
            if (headers[0][PGNFields.FEN] === undefined) {
                const startingPosition = getStartingPosition(gameTree)
                const startingFEN = positionToFEN(startingPosition.position)
                const strFen = `[FEN "${startingFEN}"]\n`
                strArray.push(strFen)
            }
            const pgn = convertGameTreeToPGN(gameTree)
            strArray.push(pgn)
        }
        file = new Blob(strArray, { type: 'text/plain' })
        if (file !== undefined) {
            const element = document.createElement('a')
            element.href = URL.createObjectURL(file)
            element.download = 'game.pgn'
            document.body.appendChild(element)
            element.click()
        }
    }

    const onResetVariationsClickHandler = () => {
        if (gameTree && currentPositionId) {
            const newGame = removeVariationsFromGameTree(gameTree)
            setNewGameTree(newGame)
        }
    }

    const onUpdatePosition = (currentPosition: BoardPosition) => {
        let result = positionToFEN(currentPositionToPositionObject(currentPosition))
        result = result.replace(/ .*/, '')
        const parts = fen.split(' ')
        result =
            result +
            ' ' +
            (parts[1] || 'w') +
            ' ' +
            (parts[2] || '-') +
            ' ' +
            (parts[3] || '-') +
            ' ' +
            (parts[4] || '0') +
            ' ' +
            (parts[5] || '1')
        setFen(result)
    }

    const onTrashIconClickHandler = () => {
        setFen(EmptyBoardFEN)
    }
    const handlePGNHeaderChange = (key, value) => {
        setHeaders((prev) => {
            return [
                {
                    ...prev[0],
                    [key]: value,
                },
                0,
            ]
        })
    }

    // selected pice
    const handlePieceSelect = (piece: any) => {
        if (selectedPiece === undefined) {
            setDeleteModeOn(false) // If no piece is selected, disable delete mode
        }
        if (selectedPiece && selectedPiece === piece) {
            setSelectedPiece(undefined)
            setSelectModeOn(false)
        } else {
            setSelectModeOn(true)
            setSelectedPiece(piece) // Select the piece if it's not selected
        }
    }

    const onPlayClick = () => {
        if (userData.state !== LoginState.LOGGED_IN) {
            navigate('/')
            setOverlayTrigger('signin')
        } else {
            navigate('/')
            setDrawerOpen('open')
        }
    }

    let evalScore = 0
    if (allVariations.v1.scoreType === 'cp') {
        evalScore = Number(allVariations.v1.score) / 100
    } else if (allVariations.v1.scoreType === 'mate') {
        if (colorToMove === 1) {
            evalScore = Number(allVariations.v1.score)
        } else {
            evalScore = -Number(allVariations.v1.score)
        }
    }

    return (
        <AppLayout
            board={
                activeAnalysisTab === 1 ? (
                    <BoardSetupBoardContent
                        fen={fen}
                        flipped={flipped}
                        onUpdatePosition={onUpdatePosition}
                        deleteModeOn={deleteModeOn}
                        selectedPiece={selectedPiece}
                        selectModeOn={selectModeOn}
                        layout={layout}
                    />
                ) : (
                    <AnalysisBoardContent
                        isEngineLoaded={isEngineLoaded}
                        evalScore={evalScore}
                        mate={allVariations.v1.scoreType === 'mate'}
                    />
                )
            }
            layout={layout}
        >
            <Box order={{ xs: 5, md: 'unset' }}>
                <TabBar
                    onTabClick={(tabIndex: number) => {
                        setActiveAnalysisTab(tabIndex)
                    }}
                    tabLabels={['Analysis', 'Setup', 'Games']}
                    initSelected={activeAnalysisTab}
                    notation
                />
            </Box>
            {activeAnalysisTab === 0 && (
                <AnalysisTab
                    isCalculating={isCalculating}
                    matchTitle={headers[0]['Title']}
                    gameResult={undefined}
                    editableAnnotations={true}
                    gtpos={gtpos}
                    userData={userData}
                    headers={headers}
                    isEngineLoaded={isEngineLoaded}
                    startEngine={startEngine}
                    stopEngine={stopEngine}
                    allVariations={allVariations}
                    addEngineMove={addEngineMove}
                    layout={layout}
                />
            )}
            {activeAnalysisTab === 1 && (
                <>
                    <BoardSetupTab
                        onPositionToFENClick={onPositionToFENClick}
                        fen={fen}
                        setFen={setFen}
                        handleColorToMoveChange={handleColorToMoveChange}
                        toggleCastle={toggleCastling}
                        whiteLong={whiteLong}
                        whiteShort={whiteShort}
                        blackLong={blackLong}
                        blackShort={blackShort}
                        headers={headers}
                        handlePGNHeaderChange={handlePGNHeaderChange}
                        pieceSelection={
                            <PieceSelection
                                onDeleteClick={() => {
                                    setDeleteModeOn(!deleteModeOn)
                                    setSelectModeOn(false)
                                    setSelectedPiece(undefined)
                                }}
                                onTrashClick={onTrashIconClickHandler}
                                deleteModeOn={deleteModeOn}
                                setDeleteModeOn={setDeleteModeOn}
                                onSelect={handlePieceSelect}
                                selectedPiece={selectedPiece}
                            />
                        }
                    />
                </>
            )}
            {activeAnalysisTab === 2 && <MyGamesTab token={token} onGameHistoryClick={onGameHistoryClick} />}

            {/* COMMON NAVIGATION */}

            <Box order={{ xs: 4, md: 'unset' }}>
                <AnalysisControls
                    onNewGameClick={onNewGameClickHandler}
                    onResetVariationsClick={onResetVariationsClickHandler}
                    onPlayComputerClick={onPlayComputerClickHandler}
                    onFolderClick={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onDownloadPGNClick={onDownloadPGNClickHandler}
                    onUploadPGNCLick={(e) => {
                        onPgnUpload(e)
                    }}
                    onUploadPGNCLickIsDisabled={false}
                    onPlayComputerClickIsDisabled={!botsExist}
                    onShareGameClick={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onFlipClick={() => {
                        setFlipped(!flipped)
                    }}
                />
            </Box>

            <Box order={{ xs: 5, md: 'unset' }}>
                <AnalysisNavigation onPlayClick={onPlayClick} />
            </Box>

            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
                sx={{
                    '& .MuiAlert-root': {
                        border: '0.063rem solid #d43f3a',
                        padding: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                    },
                }}
            >
                <Alert severity="error">Please enter a valid FEN</Alert>
            </Snackbar>
        </AppLayout>
    )
}
