import { Box, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { useEffect, useState } from 'react'
import { Layout, useResponsiveSizings } from '../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreState } from '../../store/hooks'
import ScoreboardWithHint from './ScoreboardWithHint'
import { getScoreColor, getTotalScoreColor } from './hooks/scoreColor'

const firstColumnStyles = {
    backgroundColor: 'background.paper',
    position: 'sticky',
    left: 0,
    zIndex: 1,
    padding: 0,
    paddingLeft: '0px',
    '::after': {
        position: 'absolute',
        content: "''",
        width: '18px',
        height: '100%',
        top: 0,
        right: '0px',
        backgroundColor: 'background.default',
        zIndex: 1,
    },
}

const columnContentStyles = {
    fontFamily: 'Space Grotesk',
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 700,
}

const rowStyles = {
    borderRadius: 0,
}

const otherColumnContentStyles = {
    fontFamily: 'Space Grotesk',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 400,
}

const emptyColumnStyles = {
    p: '0 !important',
    border: 0,
}

interface IRowProps {
    playerTotal: number
    opponentTotal: number
    scoreList: Array<number>
}

const ScoreboardRow: React.FC<IRowProps> = (props) => {
    const { playerTotal, opponentTotal, scoreList } = props
    const [emptyData, setEmptyData] = useState<Array<string>>([])
    const { layout } = useResponsiveSizings()

    useEffect(() => {
        const existingSize = (scoreList.length > 0 ? scoreList.length : 1) * 45 + 100
        const emptyElementsSize = (layout === Layout.DESKTOP ? 450 : window.innerWidth) - existingSize
        const elementsCount = emptyElementsSize / 45
        if (elementsCount >= 1) {
            setEmptyData(Array(Math.round(elementsCount)).fill('-'))
        }
    }, [scoreList.length])

    return (
        <TableRow sx={rowStyles}>
            <TableCell colSpan={1} align="center" sx={firstColumnStyles}>
                <Typography color={getTotalScoreColor(playerTotal, opponentTotal)} sx={columnContentStyles}>
                    {scoreList.length > 0 ? playerTotal : '-'}
                </Typography>
            </TableCell>
            {scoreList.length > 0 ? (
                scoreList.map((score, index) => (
                    <TableCell key={index} align="center">
                        <Typography color={getScoreColor(score)} sx={otherColumnContentStyles}>
                            {score}
                        </Typography>
                    </TableCell>
                ))
            ) : (
                <TableCell align="center">
                    <Typography color="default" sx={otherColumnContentStyles}>
                        -
                    </Typography>
                </TableCell>
            )}
            {emptyData.length > 0 &&
                emptyData.map((score, index) => (
                    <TableCell key={index} align="center">
                        <Typography color="default" sx={otherColumnContentStyles}>
                            {score}
                        </Typography>
                    </TableCell>
                ))}
            <TableCell style={{ width: 'auto' }} align="center" sx={emptyColumnStyles} />
        </TableRow>
    )
}

ScoreboardRow.displayName = 'ScoreboardRow'

interface IProps {
    pairId: string
    topUserId: string
    bottomUserId: string
    useBorder?: boolean
}

const Scoreboard: React.FC<IProps> = (props) => {
    const { topUserId, bottomUserId, pairId, useBorder = false } = props
    const scoreboards = useStoreState((state) => state.matchMaker.scoreboards)

    const [player1Total, setPlayer1Total] = useState<number>(0)
    const [player2Total, setPlayer2Total] = useState<number>(0)
    const [player1Score, setPlayer1Score] = useState<Array<number>>([])
    const [player2Score, setPlayer2Score] = useState<Array<number>>([])

    useEffect(() => {
        const scoreboard = scoreboards.find((item) => item.pairId === pairId)
        if (!!scoreboard) {
            const topPlayerScore =
                topUserId === scoreboard.challenger.playerId ? scoreboard.challenger.score : scoreboard.challengee.score
            const bottomPlayerScore =
                bottomUserId === scoreboard.challenger.playerId
                    ? scoreboard.challenger.score
                    : scoreboard.challengee.score

            if (!!topPlayerScore) {
                setPlayer1Total(topPlayerScore.reduce((a, b) => a + b, 0))
                setPlayer1Score(topPlayerScore)
            }
            if (bottomPlayerScore) {
                setPlayer2Total(bottomPlayerScore.reduce((a, b) => a + b, 0))
                setPlayer2Score(bottomPlayerScore)
            }
        }
    }, [pairId, topUserId, bottomUserId, scoreboards.length])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            <ScoreboardWithHint />
            <TableContainer
                component={Paper}
                sx={{
                    border: useBorder ? '2px solid !important' : '0px solid !important',
                    borderTop: 0,
                    borderRadius: 0,
                    overflowX: 'auto',
                    '.MuiTableRow-root': {
                        '&:first-of-type': {
                            '.MuiTableCell-root': {
                                pt: '8px',
                            },
                        },
                        '&:nth-last-of-type(1)': {
                            '.MuiTableCell-root': {
                                pb: '8px',
                            },
                        },
                    },
                    '.MuiTableCell-root': {
                        border: 'none',
                        px: '4px',
                        py: '4px',
                        width: '45px',
                        '&:first-of-type': {
                            pr: '18px',
                            pl: '0px',
                            width: '100px',
                        },
                        '&:nth-of-type(2)': {
                            pl: '12px',
                        },
                        '&:nth-last-of-type(2)': {
                            pr: '12px',
                        },
                    },
                }}
            >
                <Table style={{ tableLayout: 'fixed' }}>
                    <TableBody>
                        <ScoreboardRow
                            playerTotal={player1Total}
                            opponentTotal={player2Total}
                            scoreList={player1Score}
                        />
                        <ScoreboardRow
                            playerTotal={player2Total}
                            opponentTotal={player1Total}
                            scoreList={player2Score}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

Scoreboard.displayName = 'Scoreboard'

export default Scoreboard
