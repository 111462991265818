import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Dialog,
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Stack,
    Typography,
} from '@mui/material'
import throttle from 'lodash-es/throttle'
import { useRef, useState } from 'react'
import { forgotPassword, isEmailTaken } from '../functions/ams-functions'
import { ValidationState } from './types'

const throttledEmailCheck = throttle(isEmailTaken, 1000)

const inputStyle = {
    margin: '100px 0 16px 0',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderColor: 'rgb(16 24 32)',
    '.MuiFormLabel-root': {
        color: 'rgb(16 24 32)',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiInputBase-input': {
        borderBottom: '1px solid rgb(16 24 32)',
        color: 'rgb(16 24 32)',
    },
    '.MuiTypography-body1': {
        color: 'rgb(16 24 32)',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiInput-underline:after': {
        borderBottom: '1px solid rgb(16 24 32)',
    },
    '.MuiInputLabel-root.Mui-focused': {
        color: 'rgb(16, 24, 32)',
    },
}

const buttonStyle = {
    fontFamily: 'Fira Sans',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: '21px',
    border: '2px solid #000000',
    width: '182px',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '&:hover': {
        backgroundColor: '#101820',
        color: '#FFFFFF',
    },

    '&:disabled': {
        color: '#C3C3C3',
        border: '2px solid #C3C3C3',
    },
}
const errorBox = {
    padding: '10px',
    textAlign: 'center',
    fontFamily: 'Space Grotesk',
    color: 'rgb(220 38 38)',
    backgroundColor: ' rgb(254 226 226)',
    fontSize: '12px',
    marginBottom: '16px',
}

const stackStyle = {
    padding: '16px',
    backgroundColor: '#fff',
    minWidth: '338px',
    maxWidth: '338px',
    border: '2px solid #101820',
}

export interface ForgotPasswordFormProps {
    onClose: () => void
    apiURL: string
    env: string
}
function ForgotPasswordForm(props: ForgotPasswordFormProps) {
    const { onClose, apiURL, env } = props
    const [email, setEmail] = useState('')
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [emailCheckState, setEmailCheckState] = useState(ValidationState.WAITING)
    const [errorMessage, setErrorMessage] = useState('')
    const emailInputRef = useRef()

    const emailCheckCallback = (result: boolean) => {
        setEmailCheckState(result ? ValidationState.TAKEN : ValidationState.FREE)
    }

    const onEmailChange = async (email: string) => {
        const current = email
        setEmail(current)
        const regex =
            env === 'prod'
                ? /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                : /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        if (current.length < 3 || !regex.test(current)) {
            setIsEmailValid(false)
            return
        }
        setEmailCheckState(ValidationState.LOADING)
        setIsEmailValid(true)
        await throttledEmailCheck(current, apiURL, emailCheckCallback)
    }

    const submitHandler = async (event: any) => {
        event.preventDefault()
        if (isEmailValid) {
            try {
                const result = await forgotPassword(email, apiURL)
                if (!result.error) {
                    // TODO: dialog instead of alert
                    alert('An email has been sent to reset your password!')
                    onClose()
                } else {
                    setErrorMessage(result.error)
                }
            } catch (error) {
                console.log('Error while sending password reset', error)
            }
        } else if (!isEmailValid && emailInputRef.current) {
            // TODO: figure this out
            //emailInputRef.current.focus()
        }
    }

    return (
        <Dialog onClose={onClose} open={true} sx={{ zIndex: '1600' }} PaperProps={{ sx: { m: 0 } }}>
            <Stack sx={stackStyle}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton sx={{ color: 'black' }} onClick={onClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>
                <Stack style={{ padding: '16' }}>
                    <Typography
                        variant="h4"
                        style={{
                            color: 'rgb(16 24 32)',
                            textAlign: 'center',
                        }}
                    >
                        Forgot Password?
                    </Typography>
                    {errorMessage && <Box sx={errorBox}>{errorMessage}</Box>}

                    <FormControl sx={inputStyle} variant="standard">
                        <InputLabel htmlFor="email" sx={{ paddingLeft: '10px' }}>
                            Enter your email
                        </InputLabel>
                        <Input
                            id="email"
                            type="email"
                            style={{ marginBottom: 0 }}
                            value={email}
                            onChange={(e) => onEmailChange(e.currentTarget.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="" edge="end" tabIndex={-1}>
                                        <img src="/assets/images/icons/user.svg" alt="email" />
                                    </IconButton>
                                </InputAdornment>
                            }
                            error={!isEmailValid && isEmailValid !== undefined && email.length > 3}
                        />
                        {!isEmailValid && isEmailValid !== undefined && email.length > 3 && (
                            <FormHelperText
                                id="confirm-password-helper-text"
                                style={{ color: 'red', marginTop: '4px' }}
                            >
                                <Typography variant="body2">E-mail not valid</Typography>
                            </FormHelperText>
                        )}
                        {emailCheckState === ValidationState.FREE && (
                            <FormHelperText
                                id="confirm-password-helper-text"
                                style={{ color: 'red', marginTop: '4px' }}
                            >
                                <Typography variant="body2">E-mail not registered. Please sign up.</Typography>
                            </FormHelperText>
                        )}
                    </FormControl>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '120px',
                        }}
                    >
                        <Button
                            sx={buttonStyle}
                            disabled={!isEmailValid || emailCheckState !== ValidationState.TAKEN}
                            onClick={submitHandler}
                        >
                            Request reset
                        </Button>
                    </Box>
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default ForgotPasswordForm
