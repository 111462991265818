import { Badge, IconButton, Tooltip } from '@mui/material'
import React from 'react'

type Props = {
    onClick: () => void
    disabled?: boolean
    children?: React.ReactNode
    title?: string
    icon?: React.ReactNode
    badge?: number
    badgeColor?: string
    active?: boolean
}

// TODO: UPDATE CIRCULAR BUTTON

const CircularButton = ({ onClick, disabled, children, title, icon, badge = 0, active }: Props) => {
    return (
        <Tooltip title={title} placement="top">
            <Badge
                sx={(theme) => ({
                    '& .MuiBadge-badge': {
                        top: '.3rem',
                        right: '.3rem',
                        minWidth: '1.25rem',
                        height: '1.25rem',
                        padding: '0 .375rem',
                        lineHeight: '1rem',
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.getContrastText(theme.palette.primary.main),
                    },
                })}
                badgeContent={badge}
            >
                <IconButton
                    onClick={onClick}
                    color="primary"
                    size="large"
                    disabled={disabled}
                    sx={(theme) => ({
                        // transition: 'background-color 250ms, border 250ms',
                        height: '40px',
                        width: '40px',
                        borderRadius: '100%',
                        border: `0.125rem solid ${active ? theme.palette.primary.main : theme.palette.text.primary}`,
                        padding: '4px !important',
                        svg: {
                            color: active ? theme.palette.primary.main : theme.palette.text.primary,
                            path: {
                                // fill: theme.palette.text.primary,
                            },
                        },
                    })}
                >
                    {icon}
                </IconButton>
            </Badge>
        </Tooltip>
    )
}

export default CircularButton
