import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import { Bot } from '../../../../store/types'

interface IProps {
    bot: Bot
    selectedBot?: Bot
}

const BotAvatar: React.FC<IProps> = ({ bot, selectedBot }) => {
    return (
        <Stack
            sx={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Avatar
                src={bot.avatarURL}
                variant="square"
                sx={{
                    height: '100%',
                    width: '100%',
                    aspectRatio: '1 / 1',
                    border: '0px',
                }}
            />
        </Stack>
    )
}

BotAvatar.displayName = 'BotAvatar'

export default BotAvatar
