import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'

const containerStyles = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
}

const hintStyles = {
    position: 'absolute',
    right: '-20px',
    top: 0,
    height: '1rem',
    width: '1rem',
    zIndex: '1',
    cursor: 'pointer',
}

interface IProps {
    title: React.ReactNode
    children: JSX.Element | JSX.Element[]
    hideTooltip?: boolean
    wrapperStyle?: React.CSSProperties
    iconStyle?: React.CSSProperties
    modifiersOffset?: Array<number>
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top'
}

const HelpTooltip: React.FC<IProps> = (props) => {
    const {
        title,
        children,
        hideTooltip = false,
        placement = 'right-end',
        wrapperStyle = {},
        modifiersOffset = [0, -10],
        iconStyle = {},
    } = props

    return (
        <Box sx={{ ...containerStyles, ...wrapperStyle }}>
            {children}
            {!hideTooltip && (
                <Tooltip
                    disableFocusListener
                    enterTouchDelay={0}
                    title={title}
                    placement={placement}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: modifiersOffset,
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <HelpOutlineOutlinedIcon color="secondary" sx={{ ...hintStyles, ...iconStyle }} />
                </Tooltip>
            )}
        </Box>
    )
}

HelpTooltip.displayName = 'HelpTooltip'

export default HelpTooltip
