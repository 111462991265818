import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks'
import { Bot } from '../../../../store/types'
import updateSelectedBotData from '../hooks/updateSelectedBotData'
import BotAvatar from './BotAvatar'
import BotDetails from './BotDetails'

const BotsList: React.FC = () => {
    const settings = useStoreState((state) => state.gameView.settings)
    const bots = useStoreState((state) => state.matchMaker.bots)
    const selectedBot = useStoreState((state) => state.matchMaker.searchOptions.computerChallenge.selectedBot)
    const fullSearchOptions = useStoreState((state) => state.matchMaker.searchOptions)

    const setSearchOptions = useStoreActions((state) => state.matchMaker.setSearchOptions)
    const setPlayComputerFen = useStoreActions((state) => state.analysisMode.setPlayComputerFen)

    const [clickedRow, setClickedRow] = useState<number>(bots.findIndex((bot) => bot.id === selectedBot?.id))
    const botRefs = useRef<(HTMLDivElement | null)[]>([])

    const onBotSelect = (bot: Bot, index: number) => {
        const updatedSearchOptions = updateSelectedBotData(fullSearchOptions, bot)
        setSearchOptions(updatedSearchOptions)
        setClickedRow(index)
        setTimeout(() => {
            botRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }, 100)
    }

    useEffect(() => {
        if (selectedBot) {
            setPlayComputerFen(selectedBot.startingFEN || '')
        }
    }, [selectedBot])

    return (
        <Grid container sx={{ flexGrow: 0, minHeight: 0, overflowY: 'auto' }}>
            {bots.map((bot, index) => (
                <Fragment key={bot.id}>
                    <Grid
                        item
                        xs={4}
                        sx={{
                            mt: 1,
                            cursor: 'pointer',
                            px: 0.5,
                            opacity: bot.id === selectedBot?.id ? 1 : 0.5,
                        }}
                        ref={(el) => (botRefs.current[index] = el)}
                        onClick={() => onBotSelect(bot, index)}
                    >
                        <Stack
                            sx={{
                                border: '2px solid',
                                borderColor: bot.id === selectedBot?.id ? 'primary.main' : 'transparent',
                            }}
                        >
                            <BotAvatar bot={bot} selectedBot={selectedBot} />
                            <Stack
                                alignItems="center"
                                sx={{
                                    pb: 1,
                                }}
                            >
                                <Typography variant="h6">{bot.name}</Typography>
                                <Typography variant="body2" fontSize={'14px'}>
                                    {`${bot.strengthLevel ? bot.strengthLevel : !settings.friendlyMode ? bot.strengthRating : 'custom'}`}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    {selectedBot &&
                        (index + 1) % 3 === 0 &&
                        clickedRow !== null &&
                        clickedRow >= index - 2 &&
                        clickedRow <= index && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    backgroundColor: 'background.paper',
                                    p: 1.5,
                                    mx: 0.5,
                                    mt: 1,
                                }}
                            >
                                <BotDetails />
                            </Grid>
                        )}
                </Fragment>
            ))}
        </Grid>
    )
}

BotsList.displayName = 'BotsList'

export default BotsList
