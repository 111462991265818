import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { Color, Piece } from '../../../../chess/types'
import { PiecesTheme, piecesProvider } from '../../../../sharedComponents/src/globalHeader/common/PiecesProvider'

export type MaterialBalanceProps = {
    color: Color
    pieces: (Piece | null)[]
    size: string
}

const materialValues = {
    [Piece.WhiteQueen]: 9,
    [Piece.WhiteRook]: 5,
    [Piece.WhiteBishop]: 3,
    [Piece.WhiteKnight]: 3,
    [Piece.WhitePawn]: 1,
    [Piece.BlackQueen]: -9,
    [Piece.BlackRook]: -5,
    [Piece.BlackBishop]: -3,
    [Piece.BlackKnight]: -3,
    [Piece.BlackPawn]: -1,
}

export enum MaxPieces {
    Queen = 1,
    Rook = 2,
    Bishop = 2,
    Knight = 2,
    Pawn = 8,
}

const customPieces = piecesProvider(PiecesTheme.DEFAULT)

//display all pieces in a row
export function MaterialBalance(props: MaterialBalanceProps) {
    const { color, pieces, size } = props

    //color should be swapped. If white captured a black piece, it should be displayed as black
    const newColor = color === Color.White ? Color.Black : Color.White

    //For each piece, balance is set to true to have a different border in the material balance

    // counts how many there are an d how many there should be
    const getMissing = (pieces: (Piece | null)[], piece: Piece, maxPieces: number) => {
        return maxPieces - pieces.filter((p) => p === piece).length
    }

    const missingPieces = {
        whiteQueen: getMissing(pieces, Piece.WhiteQueen, MaxPieces.Queen),
        whiteRook: getMissing(pieces, Piece.WhiteRook, MaxPieces.Rook),
        whiteBishop: getMissing(pieces, Piece.WhiteBishop, MaxPieces.Bishop),
        whiteKnight: getMissing(pieces, Piece.WhiteKnight, MaxPieces.Knight),
        whitePawn: getMissing(pieces, Piece.WhitePawn, MaxPieces.Pawn),
        blackQueen: getMissing(pieces, Piece.BlackQueen, MaxPieces.Queen),
        blackRook: getMissing(pieces, Piece.BlackRook, MaxPieces.Rook),
        blackBishop: getMissing(pieces, Piece.BlackBishop, MaxPieces.Bishop),
        blackKnight: getMissing(pieces, Piece.BlackKnight, MaxPieces.Knight),
        blackPawn: getMissing(pieces, Piece.BlackPawn, MaxPieces.Pawn),
    }

    let whiteMaterial = 0
    let blackMaterial = 0

    whiteMaterial += missingPieces.whiteQueen * materialValues[Piece.WhiteQueen]
    whiteMaterial += missingPieces.whiteRook * materialValues[Piece.WhiteRook]
    whiteMaterial += missingPieces.whiteBishop * materialValues[Piece.WhiteBishop]
    whiteMaterial += missingPieces.whiteKnight * materialValues[Piece.WhiteKnight]
    whiteMaterial += missingPieces.whitePawn * materialValues[Piece.WhitePawn]
    blackMaterial += missingPieces.blackQueen * materialValues[Piece.BlackQueen]
    blackMaterial += missingPieces.blackRook * materialValues[Piece.BlackRook]
    blackMaterial += missingPieces.blackBishop * materialValues[Piece.BlackBishop]
    blackMaterial += missingPieces.blackKnight * materialValues[Piece.BlackKnight]
    blackMaterial += missingPieces.blackPawn * materialValues[Piece.BlackPawn]

    const materialBalance = whiteMaterial + blackMaterial

    const white = newColor === Color.White

    let x = 1
    const chessParams = (key) => ({ isDragging: false, squareWidth: size, props: { key } })

    let piecesArray = [] as JSX.Element[]
    if (white ? missingPieces.whiteQueen : missingPieces.blackQueen > 0) {
        piecesArray.push(customPieces[white ? 'wQ' : 'bQ'](chessParams(white ? 'wQ' : 'bQ')))
        x += 60
    }
    if (white ? missingPieces.whiteRook : missingPieces.blackRook > 0) {
        const count = getMissing(pieces, white ? Piece.WhiteRook : Piece.BlackRook, MaxPieces.Rook)
        for (let i = 0; i < count; i++, x += 20) {
            piecesArray.push(customPieces[white ? 'wR' : 'bR'](chessParams(`${white ? 'wR' : 'bR'}-${i}`)))
        }
        x += 40
    }
    if (white ? missingPieces.whiteBishop : missingPieces.blackBishop > 0) {
        const count = getMissing(pieces, white ? Piece.WhiteBishop : Piece.BlackBishop, MaxPieces.Bishop)
        for (let i = 0; i < count; i++, x += 20) {
            piecesArray.push(customPieces[white ? 'wB' : 'bB'](chessParams(`${white ? 'wB' : 'bB'}-${i}`)))
        }
        x += 40
    }
    if (white ? missingPieces.whiteKnight : missingPieces.blackKnight > 0) {
        const count = getMissing(pieces, white ? Piece.WhiteKnight : Piece.BlackKnight, MaxPieces.Knight)
        for (let i = 0; i < count; i++, x += 20) {
            piecesArray.push(customPieces[white ? 'wN' : 'bN'](chessParams(`${white ? 'wN' : 'bN'}-${i}`)))
        }
        x += 40
    }
    if (white ? missingPieces.whitePawn : missingPieces.blackPawn > 0) {
        const count = getMissing(pieces, white ? Piece.WhitePawn : Piece.BlackPawn, MaxPieces.Pawn)
        for (let i = 0; i < count; i++, x += 20) {
            piecesArray.push(customPieces[white ? 'wP' : 'bP'](chessParams(`${white ? 'wP' : 'bP'}-${i}`)))
        }
        x += 40
    }

    //set height to scale the pieces
    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                '& > *': {
                    '&[alt^="w"] ~ [alt^="w"], &[alt^="b"] ~ [alt^="b"]': {
                        marginLeft: '-14px',
                        filter: 'drop-shadow(0px 0px 1px black)',
                    },
                    '&[alt^="b"], &[alt^="w"]': {
                        filter: 'drop-shadow(0px 0px 1px black)',
                    },
                },
                height: '22px',
                margin: '0rem',
            }}
        >
            {piecesArray}
            {newColor === Color.Black && materialBalance < 0 && (
                <Typography variant="subtitle1" sx={{ lineHeight: size }}>
                    +{Math.abs(materialBalance)}
                </Typography>
            )}
            {newColor === Color.White && materialBalance > 0 && (
                <Typography variant="subtitle1" sx={{ lineHeight: size }}>
                    +{materialBalance}
                </Typography>
            )}
        </Stack>
    )
}
