export interface ITag {
    id: number
    name: string
    friendlyName: string
}

export enum PuzzleStatus {
    PASSED = 'passed',
    FAILED = 'failed',
    SKIPPED = 'skipped',
    SHOWED = 'solution_showed',
}

export interface IResolvePuzzle {
    puzzleId: string
    status: PuzzleStatus
    usedHints: number
    attempts: number
}

interface IPuzzleError {
    error?: unknown
}

interface IPuzzleData extends IPuzzleError {
    puzzle?: {
        id: string
        startingFen: string
        moves: string
        rating: number
        tags: Array<ITag>
        description: string
        isFirstMove: boolean
    }
    userPuzzleRating?: number
}

interface ITagsData extends IPuzzleError {
    tags?: Array<ITag>
}

interface IResolvePuzzleData extends IPuzzleError {
    result: boolean
    userPuzzleRating: number
}

export const PUZZLE_LIMIT = 'Puzzles limit reached'
export const PUZZLE_ACCESS = 'Puzzles ony allowed for members and subscribers'

export const getPuzzleTags = async (token: string): Promise<ITagsData> => {
    try {
        const response = await fetch(`${import.meta.env.VITE_REACT_APP_PUZZLE_URL}/api/get_tags?token=${token}`, {
            method: 'GET',
            // mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                // Authorization: 'Bearer ' + token,
            },
        })
        const defaultData = { tags: [] }
        const data = await response.json()

        if (!response.ok) {
            return defaultData
        }

        return data ? { tags: data?.puzzleTags } : defaultData
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const getPracticePuzzle = async (tags: Array<number>, token: string): Promise<IPuzzleData | null> => {
    try {
        const response = await fetch(`${import.meta.env.VITE_REACT_APP_PUZZLE_URL}/api/get_puzzle?token=${token}`, {
            method: 'PUT',
            // mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                // Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify({ puzzleTags: tags }),
        })
        const data = await response.json()

        if (!response.ok) {
            if (data.includes('puzzle practice limit reached')) {
                throw new Error(PUZZLE_LIMIT)
            }
            if (data.includes('puzzle practice feature is disabled')) {
                throw new Error(PUZZLE_ACCESS)
            }
            return null
        }

        return data || null
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const resolvePracticePuzzle = async (puzzle: IResolvePuzzle, token: string): Promise<IResolvePuzzleData> => {
    try {
        const response = await fetch(`${import.meta.env.VITE_REACT_APP_PUZZLE_URL}/api/resolve_puzzle?token=${token}`, {
            method: 'PUT',
            // mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                // Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(puzzle),
        })
        const data = await response.json()

        if (!response.ok) {
            return { result: false, userPuzzleRating: 0, error: data.errors?.message }
        }

        return { result: true, userPuzzleRating: data.userPuzzleRating }
    } catch (error) {
        return { result: false, userPuzzleRating: 0, error: error instanceof Error ? error.message : error }
    }
}

export const getDailyPuzzle = async (token: string): Promise<IPuzzleData | null> => {
    try {
        const response = await fetch(`${import.meta.env.VITE_REACT_APP_PUZZLE_URL}/api/daily/puzzles/next?token=${token}`, {
            method: 'GET',
            // mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                // Authorization: 'Bearer ' + token,
            },
        })
        const data = await response.json()

        if (!response.ok) {
            if (data.includes('puzzle practice limit reached')) {
                throw new Error(PUZZLE_LIMIT)
            }
            if (data.includes('puzzle practice feature is disabled')) {
                throw new Error(PUZZLE_ACCESS)
            }
            return null
        }

        return data || null
    } catch (error) {
        return { error: error instanceof Error ? error.message : error }
    }
}

export const resolveDailyPuzzle = async (puzzle: IResolvePuzzle, token: string): Promise<IResolvePuzzleData> => {
    try {
        const { puzzleId, ...restOfPuzzle } = puzzle
        const response = await fetch(`${import.meta.env.VITE_REACT_APP_PUZZLE_URL}/api/daily/puzzles/${puzzleId}/resolve?token=${token}`, {
            method: 'PUT',
            // mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                // Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(restOfPuzzle),
        })
        const data = await response.json()

        if (!response.ok) {
            return { result: false, userPuzzleRating: 0, error: data.errors?.message }
        }

        return { result: true, userPuzzleRating: data.userPuzzleRating }
    } catch (error) {
        return { result: false, userPuzzleRating: 0, error: error instanceof Error ? error.message : error }
    }
}
