const TagIcon: React.FC = () => {
    return (
        <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.9967 19.1407L23.1193 16.9804C24.3243 15.7538 25.0013 14.0903 25.0013 12.3558C25.0013 10.6213 24.3243 8.9578 23.1193 7.73122L16.9967 1.5M6.32998 6.9279H6.31665M0.996338 4.21395V9.87524C0.996489 10.595 1.27754 11.2852 1.77767 11.794L9.391 19.5423C9.99361 20.1555 10.8109 20.5 11.663 20.5C12.5151 20.5 13.3324 20.1555 13.935 19.5423L18.7243 14.6681C19.3268 14.0548 19.6653 13.223 19.6653 12.3558C19.6653 11.4885 19.3268 10.6568 18.7243 10.0435L11.111 2.29519C10.611 1.78619 9.93286 1.50015 9.22567 1.5H3.663C2.95576 1.5 2.27748 1.78593 1.77739 2.2949C1.27729 2.80386 0.996338 3.49416 0.996338 4.21395Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

TagIcon.displayName = 'TagIcon'

export default TagIcon
