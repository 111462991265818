import { Box, Snackbar, Typography } from '@mui/material'

import { colors } from '../../../sharedComponents/src/globalHeader/theme/colors'

type Props = {
    isOpen: boolean
    onClose: () => void
    message?: string
}

const StyledSnackbar = ({ isOpen, onClose, message }: Props) => {
    return (
        <Snackbar
            open={isOpen && Boolean(message)}
            autoHideDuration={20000}
            onClose={onClose}
            style={{
                fontVariantNumeric: 'tabular-nums',
                top: '0.625rem',
                left: '8px',
                right: '8px',
                backgroundColor: 'none',
                display: 'flex',
                justifyContent: 'center',
                transform: 'none',
                pointerEvents: 'none',
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            {message ? (
                <Box
                    sx={{
                        borderRadius: '1.25rem',
                        backgroundColor: '#fff',
                        padding: '0.875rem 3.75rem',
                        pointerEvents: 'initial',
                    }}
                >
                    <Typography variant="h6" textTransform="none" p={1} style={{ color: colors.black, padding: 0 }}>
                        {message}
                    </Typography>
                </Box>
            ) : undefined}
        </Snackbar>
    )
}

export default StyledSnackbar
