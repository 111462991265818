const AnalysisIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_578_12833)">
            <path
                d="M21.1749 2.56605C20.9757 2.31334 20.6808 2.14938 20.3547 2.11009C20.1939 2.08922 20.0299 2.09936 19.8722 2.13994L13.855 3.76329C13.5358 3.8494 13.263 4.05514 13.0964 4.33527C12.9299 4.6154 12.8833 4.94695 12.967 5.25701C13.0506 5.56706 13.2576 5.8302 13.5425 5.98855C13.8273 6.14691 14.1666 6.18749 14.4858 6.10138L17.6027 5.26049L12.6386 13.6104L7.63847 10.8207C7.49703 10.7413 7.34047 10.6902 7.17781 10.6704C7.01515 10.6507 6.84961 10.6625 6.69074 10.7054C6.53187 10.7483 6.38282 10.8212 6.25218 10.9202C6.12155 11.0191 6.01191 11.142 5.9296 11.2817L0.601378 20.244C0.517902 20.3831 0.463257 20.5366 0.440594 20.6955C0.417931 20.8545 0.427699 21.0158 0.469335 21.1701C0.510971 21.3244 0.58365 21.4687 0.68318 21.5947C0.782711 21.7207 0.907122 21.8258 1.04924 21.9041C1.19067 21.9835 1.34724 22.0345 1.5099 22.0543C1.67256 22.0741 1.8381 22.0622 1.99697 22.0194C2.15583 21.9765 2.30488 21.9035 2.43552 21.8046C2.56616 21.7057 2.6758 21.5828 2.75811 21.443L7.45267 13.5296L12.4528 16.3193C12.5942 16.3987 12.7508 16.4497 12.9134 16.4695C13.0761 16.4893 13.2416 16.4774 13.4005 16.4346C13.5594 16.3917 13.7084 16.3187 13.839 16.2198C13.9697 16.1209 14.0793 15.998 14.1616 15.8582L19.7442 6.45106L20.5611 9.4789C20.6447 9.78895 20.8518 10.0521 21.1366 10.2105C21.4214 10.3688 21.7607 10.4094 22.0799 10.3233C22.3991 10.2372 22.672 10.0314 22.8385 9.7513C23.005 9.47117 23.0516 9.13961 22.968 8.82956L21.391 2.98432C21.3479 2.83206 21.2744 2.68996 21.1749 2.56605Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_578_12833">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

AnalysisIcon.displayName = 'AnalysisIcon'

export default AnalysisIcon
