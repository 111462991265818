import { Button, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useStoreActions } from '../../../store/hooks'

const DailyPuzzleButtons: React.FC = () => {
    const navigate = useNavigate()
    const setDrawerOpen = useStoreActions((state) => state.setDrawerOpen)

    const onMainMenuClick = () => {
        navigate('/')
        setDrawerOpen('open')
    }

    const onMorePuzzlesClick = () => {
        navigate('/puzzles')
    }

    return (
        <Stack direction="row" justifyContent="center" gap={1}>
            <Button onClick={onMainMenuClick}>Play Chess</Button>
            <Button onClick={onMorePuzzlesClick}>More Puzzles</Button>
        </Stack>
    )
}

DailyPuzzleButtons.displayName = 'DailyPuzzleButtons'

export default DailyPuzzleButtons
