import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { Color } from '../../../../../../chess/types'
import { Layout, useResponsiveSizings } from '../../../../../../sharedComponents/src/hooks/useResponsiveSizings'
import { TimeMode } from '../../../../../../store/types'
import { ColorIndicator } from '../ColorIndicator/ColorIndicator'

type Props = {
    layout: Layout
    TimeControlIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    timeMode: TimeMode
    whiteWins: number
    blackWins: number
    draws: number
    resultString: string
}

const Result: FC<{ result: string; isBlack?: boolean; isWhite?: boolean }> = ({ result, isWhite, isBlack }) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}
    >
        {isWhite && <ColorIndicator color={Color.White} />}
        <Typography
            variant="body2"
            sx={{
                // fontSize: result[0] === '½' ? '30px' : '24px',
                fontSize: '24px',
                letterSpacing: '7px',
                pl: '8px',
            }}
        >
            {result}
        </Typography>
        {isBlack && <ColorIndicator color={Color.Black} />}
    </Box>
)

export const ResultColumn = ({
    layout,
    TimeControlIcon,
    timeMode,
    whiteWins,
    blackWins,
    draws,
    resultString,
}: Props) => {
    const {
        resolution: { isMobile, isDesktop },
    } = useResponsiveSizings()
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            flexGrow={1}
            height="100%"
            alignItems="center"
            padding={2}
            sx={{ backgroundColor: 'background.paper' }}
        >
            {isDesktop && (
                <>
                    <Result isWhite isBlack result={resultString} />

                    <Typography
                        my="8px"
                        variant="body2"
                        color="secondary"
                        textTransform="capitalize"
                        lineHeight="140%"
                        textAlign="center"
                    >
                        <TimeControlIcon
                            style={{
                                width: '24px',
                                height: '24px',
                                fill: '#FFC58C',
                                marginBottom: '-8px',
                                marginRight: '6px',
                            }}
                        />
                        {timeMode.name}
                        <br />
                        {timeMode.durationMinutes}+{timeMode.clockIncrementSeconds}
                    </Typography>
                </>
            )}

            <Box display="flex" flexDirection="row" width="100%" justifyContent="space-around">
                {isMobile && <Result isWhite result={resultString[0]} />}
                <Box
                    sx={{
                        fontVariantNumeric: 'tabular-nums',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="body2">{whiteWins}</Typography>
                    <Typography variant="body2">wins</Typography>
                    <Typography variant="body2">{blackWins}</Typography>

                    <Typography variant="body2">{blackWins}</Typography>
                    <Typography variant="body2">losses</Typography>
                    <Typography variant="body2">{whiteWins}</Typography>

                    <Typography variant="body2">{draws}</Typography>
                    <Typography variant="body2">draws</Typography>
                    <Typography variant="body2">{draws}</Typography>

                    <Typography variant="body2">{whiteWins + draws * 0.5}</Typography>
                    <Typography variant="body2">score</Typography>
                    <Typography variant="body2">{blackWins + draws * 0.5}</Typography>
                </Box>
                {isMobile && <Result isBlack result={resultString[2]} />}
            </Box>
        </Box>
    )
}
