import { useEffect, useRef, useState } from 'react'
import { useStoreState } from '../../../store/hooks'
import StyledSnackbar from './StyledSnackbar'

export const OpponentDisconnectedSnackbar = () => {
    const opponentDisconnected = useStoreState((state) => state.gameView.opponentDisconnected)

    const [timeLeft, setTimeLeft] = useState(0)

    const requestRef = useRef<number>()

    useEffect(() => {
        if (opponentDisconnected) {
            const updateTimeLeft = () => {
                const endTS = opponentDisconnected.timestamp + opponentDisconnected.secondsTillVictoryClaim * 1000

                setTimeLeft(Math.max(Math.round((endTS - Date.now()) / 1000), 0))
                requestRef.current = requestAnimationFrame(updateTimeLeft)
            }

            requestRef.current = requestAnimationFrame(updateTimeLeft)

            return () => {
                if (requestRef.current) cancelAnimationFrame(requestRef.current)
            }
        }
    }, [opponentDisconnected])

    return (
        <StyledSnackbar
            isOpen={!!opponentDisconnected}
            onClose={() => {}}
            message={`Opponent disconnected. ${Boolean(timeLeft) ? `${timeLeft}s` : ''} `}
        />
    )
}
