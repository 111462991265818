import { generateMoves, inCheckOrMate, isStalemate, makeMove } from './core'
import { Color, Move, MoveType, Notation, Piece, Position, Square } from './types'
export const oppositePieces = {
    '♙': '♟',
    '♖': '♜',
    '♘': '♞',
    '♗': '♝',
    '♔': '♚',
    '♕': '♛',
    '♚': '♔',
    '♛': '♕',
    '♝': '♗',
    '♞': '♘',
    '♜': '♖',
    '♟': '♙',
}

// all the squares as an array
// prettier-ignore
const squares: Square[] = [
    Square.A8, Square.B8, Square.C8, Square.D8, Square.E8, Square.F8, Square.G8, Square.H8,
    Square.A7, Square.B7, Square.C7, Square.D7, Square.E7, Square.F7, Square.G7, Square.H7,
    Square.A6, Square.B6, Square.C6, Square.D6, Square.E6, Square.F6, Square.G6, Square.H6,
    Square.A5, Square.B5, Square.C5, Square.D5, Square.E5, Square.F5, Square.G5, Square.H5,
    Square.A4, Square.B4, Square.C4, Square.D4, Square.E4, Square.F4, Square.G4, Square.H4,
    Square.A3, Square.B3, Square.C3, Square.D3, Square.E3, Square.F3, Square.G3, Square.H3,
    Square.A2, Square.B2, Square.C2, Square.D2, Square.E2, Square.F2, Square.G2, Square.H2,
    Square.A1, Square.B1, Square.C1, Square.D1, Square.E1, Square.F1, Square.G1, Square.H1,
]

export const PieceNotations = {
    [Color.White]: {
        [Piece.Pawn]: { [Notation.FAN]: '♙', [Notation.SAN]: 'P' },
        [Piece.Knight]: { [Notation.FAN]: '♘', [Notation.SAN]: 'N' },
        [Piece.Bishop]: { [Notation.FAN]: '♗', [Notation.SAN]: 'B' },
        [Piece.Rook]: { [Notation.FAN]: '♖', [Notation.SAN]: 'R' },
        [Piece.Queen]: { [Notation.FAN]: '♕', [Notation.SAN]: 'Q' },
        [Piece.King]: { [Notation.FAN]: '♔', [Notation.SAN]: 'K' },
    },
    [Color.Black]: {
        [Piece.Pawn]: { [Notation.FAN]: '♟', [Notation.SAN]: 'P' },
        [Piece.Knight]: { [Notation.FAN]: '♞', [Notation.SAN]: 'N' },
        [Piece.Bishop]: { [Notation.FAN]: '♝', [Notation.SAN]: 'B' },
        [Piece.Rook]: { [Notation.FAN]: '♜', [Notation.SAN]: 'R' },
        [Piece.Queen]: { [Notation.FAN]: '♛', [Notation.SAN]: 'Q' },
        [Piece.King]: { [Notation.FAN]: '♚', [Notation.SAN]: 'K' },
    },
}

// converts a Square (which is an index) to x and y coordinates on to board
export function squareToXY(sqr: Square): [number, number] {
    return [sqr % 8, Math.floor(sqr / 8)]
}

// converts x and y on the board to a Square (index)
export function XYToSquare(x: number, y: number): Square {
    return squares[y * 8 + x]
}

// gets a Square (index) from a string like "e4"
export function squareByName(squareName: string): Square {
    const x = 'abcdefgh'.indexOf(squareName[0])
    const y = 8 - Number(squareName[1])
    return squares[y * 8 + x]
}

// get a short name for a square ("f4")
export function nameOfSquare(square: Square): string {
    const [tx, ty] = squareToXY(square)
    return 'abcdefgh'.charAt(tx) + '87654321'.charAt(ty)
}

// convert a move to an algebraic long notation move
export function moveToAlgebraicLong(m: Move): string {
    let str = Square[m.from].toString().toLowerCase() + Square[m.to].toString().toLowerCase()
    if (m.promotion) {
        const pr = Math.abs(m.promotion)
        if (pr === Piece.Queen) str += 'q'
        else if (pr === Piece.Rook) str += 'r'
        else if (pr === Piece.Knight) str += 'n'
        else if (pr === Piece.Bishop) str += 'b'
    }
    return str
}

export function pieceSymbol(pos: Position, s: Square) {
    const piece = pos.board[s]
    if (!piece) {
        return ''
    }
    const pieceSym = PieceNotations[pos.turn][Math.abs(piece)][Notation.SAN]

    return `${piece > 0 ? 'w' : 'b'}${pieceSym}`
}

// convert the move to short form notation
export function moveToShortNotation(pos: Position, m: Move, notation: Notation = Notation.FAN): string {
    const piece = pos.board[m.from]

    if (!piece) {
        return ''
    }
    const [fx, fy] = squareToXY(m.from)
    const [tx, ty] = squareToXY(m.to)
    const toXStr = 'abcdefgh'.charAt(tx)
    const toYStr = '87654321'.charAt(ty)
    const nextPos = makeMove(pos, m)
    const checkSym = inCheckOrMate(nextPos)
    const drawSym = isStalemate(nextPos) ? ' 1/2-1/2' : ''
    if (Math.abs(piece) === Piece.Pawn) {
        const promotionPiece = m.type === MoveType.Promotion ? '__NBRQ_'.charAt(Math.abs(m.promotion!)) : ''
        const unicodePromotionPiece = promotionPiece ? PieceNotations[pos.turn][Math.abs(m.promotion!)][notation] : ''
        if (fx === tx) {
            return toXStr + toYStr + unicodePromotionPiece + checkSym
        }
        return 'abcdefgh'.charAt(fx) + 'x' + toXStr + toYStr + unicodePromotionPiece + checkSym
    }
    if (m.type === MoveType.Castling) {
        return tx > fx ? '0-0' + checkSym : '0-0-0' + checkSym
    }

    const captureSym = pos.board[m.to] == null ? '' : 'x'
    const moves = generateMoves(pos)
    let disambiguate = 0
    for (; disambiguate < 3; disambiguate++) {
        let count = 0
        for (let m2 of moves) {
            if (m2.to !== m.to || pos.board[m2.from] !== piece) {
                continue
            }
            const [fx2, fy2] = squareToXY(m2.from)
            if (disambiguate === 1 && fx2 !== fx) {
                continue
            }
            if (disambiguate === 2 && fy2 !== fy) {
                continue
            }
            count++
        }
        if (count === 1) {
            break
        }
    }

    const pieceSym = PieceNotations[pos.turn][Math.abs(piece)][notation]
    // for letter pieces use next line. ex '♔' -> 'K'
    //const pieceSym = '__NBRQK'.charAt(Math.abs(piece))
    const fromXStr = disambiguate === 1 || disambiguate === 3 ? 'abcdefgh'.charAt(fx) : ''
    const fromYStr = disambiguate === 2 || disambiguate === 3 ? '87654321'.charAt(fy) : ''
    return pieceSym + fromXStr + fromYStr + captureSym + toXStr + toYStr + checkSym + drawSym
}

export function moveFromFanToSan(moveDisplayString: string) {
    Object.keys(PieceNotations).forEach((colorKey) => {
        const color = PieceNotations[colorKey]
        Object.keys(color).forEach((pieceKey) => {
            const piece = color[pieceKey]
            moveDisplayString = moveDisplayString.replace(piece[Notation.FAN], piece[Notation.SAN])
        })
    })

    return moveDisplayString
}

// determine the color of a piece
export function pieceColor(piece: Piece): Color | null {
    if (piece > 0) {
        return Color.White
    } else if (piece < 0) {
        return Color.Black
    }
    return null
}

// deep clone a position
export function clonePosition(pos: Position): Position {
    return {
        board: [...pos.board],
        turn: pos.turn,
        castling: { ...pos.castling },
        enPassant: pos.enPassant,
        moveNum: pos.moveNum,
        halfmoveClock: pos.halfmoveClock,
    }
}

// generate a string board from a position and a lookup string of all the pieces
function positionString(pos: Position, charset: string): string {
    let str = ''
    for (let y = 0; y < 8; y++) {
        for (let x = 0; x < 8; x++) {
            let piece = pos.board[y * 8 + x]
            if (piece === null || piece < -6 || piece > 6) {
                piece = 0
            }
            str += charset[piece + 6]
        }
        str += '\n'
    }
    return str
}

// generate a string board in unicode chars
export function positionUnicode(pos: Position): string {
    return positionString(pos, '\u265A\u265B\u265C\u265D\u265E\u265F_\u2659\u2658\u2657\u2656\u2655\u2654')
}

// generate a string board with ascii
export function positionASCII(pos: Position): string {
    return positionString(pos, 'kqrbnp_PNBRQK')
}

const pieceStrings: string[] = ['bk', 'bq', 'br', 'bb', 'bn', 'bp', '', 'wp', 'wn', 'wb', 'wr', 'wq', 'wk']

// get a two letter string for a piece (white knight is "wn")
export function pieceAsString(p: Piece): string {
    return pieceStrings[p + 6]
}

// get the unicode char for a piece
export function pieceAsUnicode(p: Piece): string {
    return '\u265A\u265B\u265C\u265D\u265E\u265F_\u2659\u2658\u2657\u2656\u2655\u2654'.charAt(p + 6)
}
