import { Button, Grid } from '@mui/material'

type Props = {
    onNewGame?: () => void
    onRematch?: () => void
    onAnalysis: () => void
}

export const GameOverNavigation = ({ onNewGame, onRematch, onAnalysis }: Props) => {
    return (
        <Grid container gap={{ xs: '8px', md: '16px' }}>
            <Grid display="flex" gap={{ xs: '8px', md: '16px' }} flexGrow={1}>
                {onNewGame && (
                    <Button fullWidth variant={'outlined'} onClick={onNewGame}>
                        New Game
                    </Button>
                )}
                {onRematch && (
                    <Button fullWidth variant={'outlined'} onClick={onRematch}>
                        Rematch
                    </Button>
                )}
            </Grid>

            <Button variant="contained" fullWidth onClick={onAnalysis}>
                Analysis
            </Button>
        </Grid>
    )
}
