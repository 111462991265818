import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import packageJSON from '../package.json'
import HeaderBar from './components/HeaderBar'
import DeepLinksService from './features/deepLinks/DeepLinksService'
import { MatchMakingConnector } from './features/matchMakingDrawer/matchMakingConnector/MatchMakingConnector'
import { AnalyticsLoggingServiceComponent } from './sharedComponents/src/globalHeader/services/analytics/AnalyticsLoggingServiceComponent'
import { GameAnalyticsServiceComponent } from './sharedComponents/src/globalHeader/services/analytics/GameAnalyticsServiceComponent'
import { GoogleAnalyticsServiceComponent } from './sharedComponents/src/globalHeader/services/analytics/GoogleAnalyticsServiceComponent'
import { boardColors } from './sharedComponents/src/globalHeader/theme/colors'
import { useStoreActions, useStoreState } from './store/hooks'
import { ColorModeContext } from './theme/colorContext'
import { getDesignTokens } from './theme/theme'

function App() {
    const userData = useStoreState((state) => state.userData)
    const darkMode = useStoreState((state) => state.darkMode)
    const serverConnectionData = useStoreState((state) => state.serverConnectionData)

    const setDarkMode = useStoreActions((state) => state.setDarkMode)
    const setServerConnectionData = useStoreActions((state) => state.setServerConnectionData)

    const mode = darkMode ? 'dark' : 'light'

    const colorMode = {
        toggleColorMode: () => {
            setDarkMode(!darkMode)
        },
    }

    const settings = useStoreState((state) => state.gameView.settings)

    let boardColor = useMemo(() => {
        return boardColors[settings.boardStyle] || boardColors.default
    }, [settings.boardStyle])

    // user can press f9 to toggle serverConnectionData
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'F9') {
                setServerConnectionData(!serverConnectionData)
            }
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [serverConnectionData])

    // creates a theme based on the mode which is used by theme provider. CssBaseLine is needed to reset the default styles on html/body
    const theme = useMemo(() => getDesignTokens(mode, boardColor), [mode, boardColor])

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme={true}>
                    <Container maxWidth={false} sx={{ padding: '0rem !important' }}>
                        <DeepLinksService />
                        <HeaderBar />
                        <MatchMakingConnector />
                        <Outlet />
                        <AnalyticsLoggingServiceComponent
                            env={import.meta.env.VITE_REACT_APP_ENV}
                            userData={userData.userData || undefined}
                        />
                        <GameAnalyticsServiceComponent
                            gaGameKey={import.meta.env.VITE_REACT_APP_GAME_KEY}
                            gaSecretKey={import.meta.env.VITE_REACT_APP_SECRET_KEY}
                            version={packageJSON.version}
                            env={import.meta.env.VITE_REACT_APP_ENV}
                            userData={userData.userData || undefined}
                        />
                        <GoogleAnalyticsServiceComponent
                            env={import.meta.env.VITE_REACT_APP_ENV}
                            userData={userData.userData || undefined}
                            gtmId={import.meta.env.VITE_REACT_APP_GTM_ID}
                            loginState={userData.state}
                        />
                    </Container>
                </CssBaseline>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}

export default App
