import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, FormGroup, IconButton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Rules, Settings, UserGroup } from '../globalHeader/GlobalHeader'
import GetCookie from '../globalHeader/common/getCookie'
import { saveUserSettings } from '../globalHeader/functions/ams-functions'
import { IAppConfig, getAppConfig } from '../globalHeader/services/AppConfigService'
import SettingsSwitch from './SettingsSwitch'
import { ThemePage, ThemesSettings } from './Themes'

const headers: Record<SettingsPage, string> = {
    SETTINGS: 'Settings',
    PIECES: 'Chess Pieces',
    BOARD: 'Board Theme',
    SOUNDS: 'Custom Sound',
}

const closeIconStyles = {
    position: 'absolute',
    left: { xs: '8px', md: '20px' },
    top: { xs: '8px', md: '20px' },
}

export type SettingsDialogsProps = {
    apiURL: string
    settings: Settings
    groups: Array<UserGroup>
    setSettings: (data: Settings) => void
    onClose: (data?: Settings) => void
    openJoinUsForm: () => void
}

export enum SettingsPage {
    'SETTINGS' = 'SETTINGS',
    'PIECES' = 'PIECES',
    'BOARD' = 'BOARD',
    'SOUNDS' = 'SOUNDS',
}

function SettingsDialog(props: SettingsDialogsProps) {
    const { apiURL, settings, groups, setSettings, onClose, openJoinUsForm } = props

    const token = GetCookie('jwt')
    const rules = groups.flatMap((group) => group.rules.map((rule) => rule.name))
    const [localSettings, setLocalSettings] = useState(settings)
    const [currentSettingsPage, setCurrentSettingsPage] = useState<SettingsPage>(SettingsPage.SETTINGS)
    const [fullScreen, setFullScreen] = useState<boolean>(false)
    const [appConfig, setAppConfig] = useState<IAppConfig | null>(null)

    useEffect(() => {
        const config = getAppConfig()
        setAppConfig(config)
    }, [])

    const settingsEnabled = rules.some((rule) => rule === Rules.FULL_ACCESS || rule === Rules.GAME_SETTINGS)

    const onFullScreenClick = () => {
        if (!fullScreen) {
            document.documentElement.requestFullscreen()
        } else {
            document.exitFullscreen()
        }
        setFullScreen(!fullScreen)
    }

    const onSubmitClick = async () => {
        if (currentSettingsPage === SettingsPage.SETTINGS) {
            if (!token) return
            const data = {
                autoAccept: true,
                preMove: settings.preMove,
                autoQueen: settings.autoQueen,
                dragAndDrop: settings.dragAndDrop,
                soundOn: settings.soundOn,
                soundCheckOn: settings.soundCheckOn,
                soundMateOn: settings.soundMateOn,
                soundDrawOn: settings.soundDrawOn,
                soundSmateOn: settings.soundSmateOn,
                soundMoveOn: settings.soundMoveOn,
                pieceStyle: settings.pieceStyle,
                boardStyle: settings.boardStyle,
                coordinates: settings.coordinates,
                soundStyle: settings.soundStyle,
                darkMode: settings.darkMode,
                briefToggle: settings.briefToggle,
                friendlyMode: settings.friendlyMode,
            }
            await saveUserSettings(apiURL, token, data)
            setLocalSettings(data)
            setSettings(data)
            onClose()
        } else {
            setCurrentSettingsPage(SettingsPage.SETTINGS)
        }
    }

    const onCancelClick = () => {
        if (currentSettingsPage === SettingsPage.SETTINGS) {
            setSettings(localSettings)
            onClose(localSettings)
        } else {
            setSettings(localSettings)
            setCurrentSettingsPage(SettingsPage.SETTINGS)
        }
    }

    const switches = [
        {
            label: 'Coordinates',
            checked: settings.coordinates,
            onChange: () => {
                setSettings({ ...settings, coordinates: !settings.coordinates })
            },
        },
        {
            label: 'Sounds',
            checked: settings.soundOn,
            onChange: () => {
                setSettings({ ...settings, soundOn: !settings.soundOn })
            },
        },
        {
            label: 'Premove',
            checked: settings.preMove,
            onChange: () => {
                setSettings({ ...settings, preMove: !settings.preMove })
            },
        },
        {
            label: 'Autoqueen',
            checked: settings.autoQueen,
            onChange: () => {
                setSettings({ ...settings, autoQueen: !settings.autoQueen })
            },
        },
        {
            label: 'Casual Play',
            checked: settings.friendlyMode,
            disabled: !settingsEnabled,
            helpText: 'Hide Ratings',
            onChange: () => {
                setSettings({ ...settings, friendlyMode: !settings.friendlyMode })
            },
        },
        ...(!!appConfig?.hideFullScreenToggle
            ? []
            : [
                  {
                      label: 'Fullscreen',
                      checked: fullScreen,
                      onChange: onFullScreenClick,
                  },
              ]),
        {
            label: `Legacy sounds`,
            checked: settings.soundStyle === 'legacy',
            disabled: !settingsEnabled,
            onChange: () => {
                setSettings({
                    ...settings,
                    soundStyle: settings.soundStyle === 'legacy' ? 'default' : 'legacy',
                })
            },
        },
    ]

    return (
        <Dialog
            open={true}
            onClose={(e: React.SyntheticEvent, reason: string) => (reason === 'backdropClick' ? onCancelClick() : null)}
            hideBackdrop
            sx={{
                '&::-webkit-scrollbar': {
                    width: '0',
                },
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '584px',
                    height: { xs: '100dvh', md: 'min(660px, 100% - 32px)' },
                    flexDirection: 'column',
                    margin: { xs: 0, md: '16px' },
                    border: '0.25rem solid white',
                    borderRadius: '0',
                    p: { xs: '14px', md: '30px' },
                    maxHeight: 'unset',
                    pb: '0',
                },
            }}
        >
            <IconButton onClick={onCancelClick} sx={closeIconStyles}>
                {currentSettingsPage === SettingsPage.SETTINGS ? (
                    <CloseIcon className={`closeIcon`} />
                ) : (
                    <ArrowBackIos className="closeIcon" />
                )}
            </IconButton>
            <Typography variant="h4" align="center" paragraph>
                {headers[currentSettingsPage]}
            </Typography>

            <DialogContent sx={{ p: 0, pb: '70px' }}>
                {currentSettingsPage === SettingsPage.SETTINGS ? (
                    <FormGroup sx={{ alignItems: 'center' }}>
                        <ThemesSettings settings={settings} setCurrentSettingsPage={setCurrentSettingsPage} />
                        <Stack
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'left',
                                marginTop: '1rem',
                            }}
                            direction={'column'}
                        >
                            {switches.map((item) => (
                                <SettingsSwitch
                                    label={item.label}
                                    checked={item.checked}
                                    onChange={item.onChange}
                                    helpText={item.helpText}
                                    key={item.label}
                                    disabled={item.disabled}
                                    onDisabledClick={openJoinUsForm}
                                />
                            ))}
                        </Stack>
                    </FormGroup>
                ) : (
                    <ThemePage
                        settingsEnabled={settingsEnabled}
                        settings={settings}
                        setSettings={setSettings}
                        currentSettingsPage={currentSettingsPage}
                        openJoinUsForm={openJoinUsForm}
                    />
                )}
            </DialogContent>

            <DialogActions
                sx={(theme) => ({
                    gap: 1,
                    position: 'absolute',
                    zIndex: 2,
                    bottom: '30px',
                    left: '30px',
                    right: '30px',
                    background: theme.palette.background.paper,
                    boxShadow: `0px 5px 30px 20px ${theme.palette.background.paper}`,
                    mt: 0,
                })}
            >
                <Button onClick={onCancelClick} variant="outlined">
                    Cancel
                </Button>
                <Button onClick={onSubmitClick} variant="contained">
                    {currentSettingsPage === SettingsPage.SETTINGS ? 'Save' : 'Apply'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SettingsDialog
