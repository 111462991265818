import React, { useCallback, useMemo } from 'react'

import { generateMoves } from '../../chess/core'
import { positionFromFEN } from '../../chess/fen'
import { getMoveById, getPositionById } from '../../chess/gameTree'
import { BasePositionFEN } from '../../chess/positionPresets'
import { Move } from '../../chess/types'
import { EvalBar } from '../../components/evalBar/EvalBar'
import HorizontalEvalBar from '../../components/evalBar/HorizontalEvalBar'
import { useResponsiveSizings } from '../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../store/hooks'
import AnalysisChessBoard from '../chessboard/AnalysisChessboard'

type Props = {
    isEngineLoaded: boolean
    evalScore: number
    mate?: boolean
}

const AnalysisBoardContent: React.FC<Props> = ({ isEngineLoaded, evalScore, mate }) => {
    const {
        resolution: { isDesktop, isMobile },
    } = useResponsiveSizings()
    const gameTree = useStoreState((state) => state.analysisMode.gameTree)
    const currentPositionId = useStoreState((state) => state.analysisMode.currentPositionId)
    const flipped = useStoreState((state) => state.analysisMode.flipped)
    const colorToMove = useStoreState((state) => state.analysisMode.colorToMove)

    const setCurrentPositionId = useStoreActions((state) => state.analysisMode.setCurrentPositionId)
    const addVariationMove = useStoreActions((state) => state.analysisMode.addVariationMove)

    const gtpos = useMemo(() => {
        return gameTree !== undefined && currentPositionId !== undefined
            ? getPositionById(gameTree, currentPositionId)
            : undefined
    }, [gameTree, currentPositionId])

    const gameOver = gtpos ? generateMoves(gtpos.position).length === 0 : false

    const onMove = useCallback(
        (move: Move) => {
            if (!gtpos || !gameTree) return false
            const position = gtpos.position
            const moves = generateMoves(position, move.from) //get all moves from the active square

            const legalMove = moves.find((m: Move) => m.to === move.to && m.promotion === move.promotion) //check if the move is legal
            const nextMove = getMoveById(gameTree, gtpos.nextMoveIds[0])
            // check if promotion
            if (
                nextMove &&
                nextMove.move.promotion &&
                nextMove.move.from === move.from &&
                nextMove.move.to === move.to
            ) {
                setCurrentPositionId(nextMove.nextPositionId)
            }
            //if next move exists and is the same as the move we just made, set the current position to the next position
            if (nextMove && nextMove.move.from === move.from && nextMove.move.to === move.to) {
                setCurrentPositionId(nextMove.nextPositionId)
            }
            // if not the next move, add variation
            else if (legalMove) {
                addVariationMove(legalMove)
            }
            return false
        },
        [gtpos, gameTree, addVariationMove, setCurrentPositionId],
    )

    return (
        <>
            {isDesktop && isEngineLoaded && !gameOver && (
                <EvalBar score={evalScore} mate={mate} colorToMove={colorToMove} />
            )}
            <AnalysisChessBoard
                gameTree={gameTree}
                currentPositionId={currentPositionId}
                position={gtpos ? gtpos.position : positionFromFEN(BasePositionFEN)}
                flipped={flipped}
                onMove={onMove}
            />

            {isMobile && isEngineLoaded && !gameOver && (
                <HorizontalEvalBar colorToMove={colorToMove} evalScore={evalScore} mate={mate} />
            )}
        </>
    )
}

export default AnalysisBoardContent
