// Start at A8 instead of A1 to match the physical board layout
// prettier-ignore
export enum Square {
    A8, B8, C8, D8, E8, F8, G8, H8,
    A7, B7, C7, D7, E7, F7, G7, H7,
    A6, B6, C6, D6, E6, F6, G6, H6,
    A5, B5, C5, D5, E5, F5, G5, H5,
    A4, B4, C4, D4, E4, F4, G4, H4,
    A3, B3, C3, D3, E3, F3, G3, H3,
    A2, B2, C2, D2, E2, F2, G2, H2,
    A1, B1, C1, D1, E1, F1, G1, H1,
}

// White pieces are positive, black pieces are negative
export enum Piece {
    ERROR = 0,
    Pawn = 1,
    Knight = 2,
    Bishop = 3,
    Rook = 4,
    Queen = 5,
    King = 6,
    WhitePawn = 1,
    WhiteKnight = 2,
    WhiteBishop = 3,
    WhiteRook = 4,
    WhiteQueen = 5,
    WhiteKing = 6,
    BlackPawn = -1,
    BlackKnight = -2,
    BlackBishop = -3,
    BlackRook = -4,
    BlackQueen = -5,
    BlackKing = -6,
}

export enum Color {
    White = 1,
    Black = -1,
}

export enum MoveType {
    Normal,
    Castling,
    EnPassant,
    Promotion,
    Premove,
}

export type Move = {
    type: MoveType
    from: Square
    to: Square
    promotion?: Piece
}

// These fields are the exact fields that is in FEN
export type Position = {
    board: (Piece | null)[] // 64 entries for each square, either null or a Piece
    turn: Color
    castling: {
        whiteShort: boolean
        whiteLong: boolean
        blackShort: boolean
        blackLong: boolean
    }
    enPassant: Square | null // this is the square where the pawn tha can be captured by en passant is now
    moveNum: number // two moves in a row will be the same move num (frist white and black moves are 1, then the next two are move 2 etc)
    halfmoveClock: number // number of moves since the last capture, for 50 move rule
}

export type TakenPieces = {
    white: Piece[]
    black: Piece[]
}

export type GameTree = {
    positions: GameTreePosition[]
    moves: GameTreeMove[]
}

export type GameTreePosition = {
    id: string
    position: Position
    nextMoveIds: string[]
    previousMoveId?: string
    evaluation?: number
}

export type GameTreeMove = {
    id: string
    nextPositionId: string
    previousPositionId: string
    move: Move
    displayString: string
    annotation?: string
    opening?: string
    clock?: number
    moveTime?: number
    timestamp?: number
}

export enum ColorScheme {
    white,
    blue,
    orange,
    brown,
    green,
    red,
}

export enum Notation {
    SAN,
    FAN,
}
