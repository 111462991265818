import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ColorSelection } from '../../../../components/colorSelector/ColorSelector'
import ColorPiece from '../../../../components/colorSelector/RadioButtons/ColorPiece'
import Random from '../../../../components/colorSelector/RadioButtons/Random'

interface IProps {
    title: string
    value?: string
    color?: ColorSelection
    accentOnValue?: boolean
}

const DetailsBlock: React.FC<IProps> = ({ title, value, color, accentOnValue = false }) => {
    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Typography variant="body2">{title}</Typography>
            {value && (
                <Typography
                    variant="body2"
                    color={accentOnValue ? 'secondary' : 'text.primary'}
                    sx={accentOnValue ? { fontWeight: 'bold', textTransform: 'uppercase' } : {}}
                >
                    {value}
                </Typography>
            )}
            {color && (
                <>
                    {color === ColorSelection.RANDOM ? (
                        <Random size={20} />
                    ) : (
                        <ColorPiece color={color === ColorSelection.WHITE ? 1 : -1} size={20} />
                    )}
                </>
            )}
        </Stack>
    )
}

DetailsBlock.displayName = 'DetailsBlock'

export default DetailsBlock
