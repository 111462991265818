import { Box } from '@mui/material'
import React from 'react'
import { colors } from '../../sharedComponents/src/globalHeader/theme/colors'

export type EvalBarProps = {
    score: number
    mate?: boolean
    colorToMove: number
}

export const EvalBar = (props: EvalBarProps) => {
    const { score, mate, colorToMove } = props
    const [color, setColor] = React.useState(1)

    React.useEffect(() => {
        if (score) setColor(colorToMove)
        //only run when score changes to avoid bar flickering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [score])
    const isWhiteWinning = score > 1

    // Default values for win probability and percentages
    let winProb = 0.5
    let whitePercentage = 50
    let blackPercentage = 50

    if (mate) {
        // If mate in any moves, fill 100%
        whitePercentage = isWhiteWinning ? 100 : 0
        blackPercentage = isWhiteWinning ? 0 : 100
    } else {
        // Calculate win probability based on score and color to move
        const flippedScore = -score
        const scoreForProb = color === 1 ? score : flippedScore
        const expScore = Math.exp(-scoreForProb / 10)

        switch (color) {
            case 1:
                // If white is to move, calculate win probability and percentages for white
                winProb = 1 / (1 + expScore)
                whitePercentage = Math.round(winProb * 100)
                blackPercentage = 100 - whitePercentage
                break
            case -1:
                // If black is to move, calculate win probability and percentages for black
                winProb = 1 / (1 + 1 / expScore)
                blackPercentage = Math.round(winProb * 100)
                whitePercentage = 100 - blackPercentage
                break
            default:
                // Error handling for invalid color to move values
                console.log('Invalid color to move value')
        }
    }

    return (
        <Box
            sx={(theme) => ({
                position: 'absolute',
                left: '-16px',
                top: '-2px',
                bottom: '-2px',
                border: `2px solid ${theme.palette.primary.light} !important`,
                borderColor: 'primary.light',
                display: 'flex',
                flexDirection: 'column',

                borderRadius: '0rem',
                overflow: 'hidden',
                animation: '1s ease-in-out infinite alternate',
                margin: '0',
            })}
        >
            <Box
                style={{
                    backgroundColor: colors.darkBlue,
                    flex: `${blackPercentage} 0 0`,
                    borderBottom: `${whitePercentage === 0 ? `0.063rem solid ${colors.white}` : 'none'}`,
                    borderRadius: ' 0',
                    transition: 'flex 0.5s ease-in-out',
                    width: '12px',
                }}
            />
            <Box
                style={{
                    backgroundColor: 'white',
                    flex: `${whitePercentage} 0 0`,
                    borderTop: `${blackPercentage === 0 ? `0.063rem solid ${colors.white}` : 'none'}`,
                    borderRadius: '0 ',
                    transition: 'flex 0.5s ease-in-out',
                    width: '12px',
                }}
            />
        </Box>
    )
}
