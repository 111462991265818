import { positionFromFEN } from './fen'
import { Color, Position } from './types'

// regular chess starting position
export const BasePositionFEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
export function basePosition(): Position {
    return positionFromFEN(BasePositionFEN)
}

// an empty board
export const EmptyBoardFEN = '8/8/8/8/8/8/8/8 w - - 0 1'
export function emptyPosition(): Position {
    return positionFromFEN(EmptyBoardFEN)
}

// 1 Move to promotion + checkmate
export function promotionCheckmatePosition(): Position {
    return positionFromFEN('7k/8/8/8/8/8/2qp4/5K2 b KQkq - 0 1')
}

// Mega promotion
export const MegaPromotionWhiteFEN = '8/PPPPPPPP/K7/8/8/k7/pppppppp/8 w KQkq - 0 1'
export const MegaPromotionBlackFEN = '8/PPPPPPPP/K7/8/8/k7/pppppppp/8 b KQkq - 0 1'
export function megaPromotion(color: Color): Position {
    const fen = color === Color.White ? MegaPromotionWhiteFEN : MegaPromotionBlackFEN
    return positionFromFEN(fen)
}

// 1 Move to checkmate
export function checkmatePosition(color: Color): Position {
    const fen = color === Color.White ? '7k/1R6/8/8/8/8/8/RK6 w KQkq - 0 1' : 'rk6/8/8/8/8/8/1r6/7K b KQkq - 0 1'
    return positionFromFEN(fen)
}

// Castling short/long
export const CastlingPositionWhiteFEN = '7k/8/8/8/8/8/8/R3K2R w KQkq - 0 1'
export const CastlingPositionBlackFEN = 'r3k2r/8/8/8/8/8/8/7K b KQkq - 0 1'
export function castlingPosition(color: Color): Position {
    const fen = color === Color.White ? CastlingPositionWhiteFEN : CastlingPositionBlackFEN
    return positionFromFEN(fen)
}

// 1 Move to 50 move rule
export function fiftyMovePosition(): Position {
    return positionFromFEN('k7/8/8/8/8/8/1r6/7K b KQkq - 49 50')
}
