import { useCallback, useEffect, useState } from 'react'

export const useMouseHold = () => {
    const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>(undefined)
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined)

    const onClickWithHold = useCallback((callback: () => void) => {
        callback()
        const timer = setTimeout(() => {
            callback()

            const interval = setInterval(() => {
                callback()
            }, 200)

            setIntervalId(interval)
        }, 1000)

        setTimerId(timer)
    }, [])

    useEffect(() => {
        const onMouseUp = () => {
            clearInterval(timerId)
            setTimerId(undefined)
            if (intervalId) {
                clearInterval(intervalId)
                setIntervalId(undefined)
            }
        }
        window.addEventListener('mouseup', onMouseUp)
        return () => {
            window.removeEventListener('mouseup', onMouseUp)
        }
    }, [timerId, intervalId])

    return onClickWithHold
}
