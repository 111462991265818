import { Theme, useMediaQuery } from '@mui/material'

export enum PlayerInfoMode {
    FULL = 'full',
    COMPACT = 'compact',
    MINIMAL = 'minimal',
}

export enum Layout {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
}

export const useResponsiveSizings = () => {
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const layout = mdDown ? Layout.MOBILE : Layout.DESKTOP
    const resolution = {
        isMobile: mdDown,
        isDesktop: !mdDown,
    }

    // const playerInfoMode = useMemo(() => {
    //     if (useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))) {
    //         return PlayerInfoMode.COMPACT
    //     } else if (useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))) {
    //         return PlayerInfoMode.MINIMAL
    //     } else {
    //         return PlayerInfoMode.FULL
    //     }
    // }, [layout])

    // todo refactor playerInfoMode
    const playerInfoMode = PlayerInfoMode.FULL

    return {
        resolution,
        layout,
        playerInfoMode,
    }
}
