import getCookie from '../common/getCookie'
import setCookie from '../common/setCookie'
import { getCookieDomain } from '../functions/ams-functions'

export const APP_CONFIG_KEY = 'ICC-appConfig'
enum SYSTEM {
    Web = 'Web',
    MOBILE_APP = 'mobileApp',
}

export interface IAppConfig {
    hideSSO: boolean
    hideCookies: boolean
    hideMemberships: boolean
    hideFullScreenToggle: boolean
    hideAccountDeletion: boolean
}

const setAppConfigCookie = (props: IAppConfig, domain: string) => {
    const data = JSON.stringify(props)
    setCookie(APP_CONFIG_KEY, data, getCookieDomain(domain))
}

const setAppConfigStorage = (props: IAppConfig) => {
    const data = JSON.stringify(props)
    localStorage.setItem(APP_CONFIG_KEY, data)
}

export const getAppConfig = (): IAppConfig | null => {
    const cookieData = getCookie(APP_CONFIG_KEY)
    const storageData = localStorage.getItem(APP_CONFIG_KEY)
    if (cookieData) {
        if (storageData && cookieData === storageData) {
            return JSON.parse(storageData)
        } else {
            const parsedData = JSON.parse(cookieData)
            if (parsedData) {
                setAppConfigStorage(parsedData)
            }
            return parsedData
        }
    } else {
        if (storageData) {
            return JSON.parse(storageData)
        }
    }

    return {
        hideSSO: false,
        hideCookies: false,
        hideMemberships: false,
        hideFullScreenToggle: false,
        hideAccountDeletion: true,
    }
}

export const useConfig = (param: string, domain: string) => {
    if (param && param.toLowerCase() === SYSTEM.MOBILE_APP.toLowerCase()) {
        const data = {
            hideSSO: true,
            hideCookies: true,
            hideMemberships: true,
            hideFullScreenToggle: true,
            hideAccountDeletion: false,
        }
        setAppConfigCookie(data, domain)
        setAppConfigStorage(data)
    }
}
