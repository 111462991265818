import { useCallback, useState } from 'react'
import { squareByName } from '../../../chess/basics'
import { generateSinglePremove } from '../../../chess/core'
import { Color, Move, Position } from '../../../chess/types'
import { Square } from '../../../react-chessboard/src/chessboard/types'

const usePremove = (position: Position, myColor: Color, arePremovesAllowed: boolean) => {
    const [premove, setPremove] = useState<Move | undefined>()
    const [legalPremoves, setLegalPremoves] = useState<Move[]>([])

    const clearPremove = useCallback(() => {
        setPremove(undefined)
        setLegalPremoves([])
    }, [])

    const calculatePremoves = useCallback(
        (sourceSquare: string) => {
            if (position.turn === myColor) return []
            if (!arePremovesAllowed) return []
            return generateSinglePremove(position, squareByName(sourceSquare))
        },
        [arePremovesAllowed, position, myColor],
    )

    const prepareLegalPremoves = useCallback(
        (sourceSquare: Square) => {
            setLegalPremoves(calculatePremoves(sourceSquare))
        },
        [calculatePremoves],
    )

    const preparePremove = useCallback(
        (premove: Move) => {
            if (!arePremovesAllowed) return

            setPremove(premove)
        },
        [arePremovesAllowed],
    )

    return { premove, legalPremoves, setLegalPremoves, prepareLegalPremoves, preparePremove, clearPremove }
}

export default usePremove
