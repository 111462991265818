import { Box, Button, Dialog, DialogContent, DialogContentText, Typography } from '@mui/material'
import { useStoreActions, useStoreState } from '../../../../store/hooks'

const dialogActionsStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 1,
}

const ConfirmationVictoryDialog: React.FC = () => {
    const canClaimVictory = useStoreState((state) => state.gameView.canClaimVictory)
    const claimVictory = useStoreActions((state) => state.gameView.claimVictory)
    const waitForOpponent = useStoreActions((state) => state.gameView.waitForOpponent)

    return (
        <Dialog open={canClaimVictory}>
            <Box display="flex" flexDirection="column" p={{ xs: 2, md: 3 }}>
                <DialogContent
                    sx={{
                        p: '0',
                    }}
                >
                    <DialogContentText textAlign="center">
                        <Typography variant="h4" color="secondary" paragraph>
                            Opponent disconnected
                        </Typography>
                        <Typography variant="body1">You can claim victory or wait for reconnect</Typography>
                    </DialogContentText>
                </DialogContent>
                <Box sx={dialogActionsStyle} mt={8}>
                    <Button variant="outlined" fullWidth onClick={() => waitForOpponent()}>
                        Wait for Opponent
                    </Button>
                    <Button variant="contained" fullWidth onClick={() => claimVictory()}>
                        Claim Victory
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}

ConfirmationVictoryDialog.displayName = 'ConfirmationVictoryDialog'

export default ConfirmationVictoryDialog
