export enum GS_MESSAGES {
    END_OF_GAME = 'endOfGameMessage',
    OPPONENT_DISCONNECT = 'opponentDisconnectMessage',
    OPPONENT_CONNECTED = 'opponentConnectedMessage',
    MATCH_IS_VALID_FOR_CLAIM = 'matchIsValidForClaimMessage',
    MATCH_STARTED = 'matchStartedMessage',
    MOVE_AND_CLOCK = 'moveAndClockMessage',
    DRAW_OFFER = 'drawOfferMessage',
    DRAW_OFFER_REMOVED = 'drawOfferRemovedMessage',
    ABORT_OFFER = 'abortOfferMessage',
    ABORT_OFFER_REMOVED = 'abortOfferRemovedMessage',
    CLOCK_UPDATED = 'clockUpdatedMessage',
    CONNECT_ON_ANOTHER_DEVICE = 'connectOnAnotherDeviceMessage',
}

export enum GS_REQUESTS {
    KEEP_ALIVE = 'keepAliveRequest',
    JOIN = 'joinRequest',
    MATCH_CLAIM_VICTORY = 'matchClaimVictoryRequest',
    MATCH_WAIT_FOR_OPPONENT = 'matchWaitForOpponentRequest',
    MOVE = 'moveRequest',
    DRAW_OFFER = 'drawOfferRequest',
    DRAW_ACCEPTED = 'drawAcceptedRequest',
    DRAW_REJECTED = 'drawRejectedRequest',
    RESIGNATION = 'resignationRequest',
    ABORT_OFFER = 'abortOfferRequest',
    ABORT_REJECTED = 'abortRejectedRequest',
    ABORT_ACCEPTED = 'abortAcceptedRequest',
    ADD30_SECONDS = 'add30SecondsRequest',
}

export enum GAME_END_REASONS {
    CHECK_MATE = 'checkMate',
    RESIGNATION = 'resignation',
    DRAW = 'draw',
    STALE_MATE = 'staleMate',
    THREE_FOLD_REPETITION = 'threeFoldRepetition',
    FIVE_FOLD_REPETITION = 'fiveFoldRepetition',
    FIFTY_MOVE_RULE = 'fiftyMoveRule',
    SEVENTY_FIVE_MOVE_RULE = 'seventyFiveMoveRule',
    INSUFFICIENT_MATERIAL = 'insufficientMaterial',
    REASON_NORMAL = 'reasonNormal',
    ABORTED = 'aborted',
    CLOCK_EXPIRATION = 'clockExpiration',
    NO_PLAYER1_MOVE_SINCE_START = 'noPlayer1MoveSinceStart',
    NO_PLAYER2_MOVE_SINCE_START = 'noPlayer2MoveSinceStart',
    TECHNICAL_ERROR_AI = 'technicalErrorAi',
    SECOND_PLAYER_JOIN_TIMEOUT = 'secondPlayerJoinTimeout',
    ALL_PLAYERS_DISCONNECTED = 'reasonAllPlayersDisconnected',
    PLAYER1_DISCONNECTED = 'reasonPlayer1Disconnected',
    PLAYER2_DISCONNECTED = 'reasonPlayer2Disconnected',
}