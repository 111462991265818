import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AppleAuth from './AppleAuth'
import FacebookAuth from './FacebookAuth'
import GoogleAuth from './GoogleAuth'

export enum SocialType {
    FACEBOOK = 1,
    GOOGLE = 2,
    APPLE = 3,
}

export type Theme = 'light' | 'dark'

interface IProps {
    title: string
    isGuest?: boolean
    onSignUpSocial: (socialResponse: any) => void
    onLoginSuccessful: () => void
    logoutHandler: () => Promise<void>
    apiURL: string
    googleClientId?: string
    facebookAppId?: string
    facebookApiVersion?: string
    appleClientId?: string
    appleRedirectURL?: string
    theme?: Theme
    titleFontStyles?: any
}

const SocialLogin: React.FC<IProps> = (props) => {
    const {
        title,
        onSignUpSocial,
        onLoginSuccessful,
        isGuest = false,
        logoutHandler,
        apiURL,
        googleClientId,
        facebookAppId,
        facebookApiVersion,
        appleClientId,
        appleRedirectURL,
        theme = 'light',
        titleFontStyles: fontStyles = {},
    } = props

    if (!(googleClientId || facebookAppId || facebookApiVersion || appleClientId || appleRedirectURL)) {
        return null
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Typography
                sx={{
                    color: theme === 'light' ? 'rgb(16 24 32)' : '#F5F5F5',
                    fontWeight: 400,
                    fontSize: '.8rem',
                    fontFamily: 'Space Grotesk',
                    py: 2,
                    ...fontStyles,
                }}
            >
                {title}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    height: '70px',
                }}
            >
                {googleClientId && (
                    // <GoogleOAuthProvider clientId={googleClientId}>
                    //     <GoogleAuthV2
                    //         onSignUpSocial={onSignUpSocial}
                    //         onLoginSuccessful={onLoginSuccessful}
                    //         isGuest={isGuest}
                    //         logoutHandler={logoutHandler}
                    //         apiURL={apiURL}
                    //         googleClientId={googleClientId}
                    //         theme={theme}
                    //     />
                    // </GoogleOAuthProvider>
                    <GoogleAuth
                        onSignUpSocial={onSignUpSocial}
                        onLoginSuccessful={onLoginSuccessful}
                        isGuest={isGuest}
                        logoutHandler={logoutHandler}
                        apiURL={apiURL}
                        googleClientId={googleClientId}
                        theme={theme}
                    />
                )}
                {appleClientId && appleRedirectURL && (
                    <AppleAuth
                        onSignUpSocial={onSignUpSocial}
                        onLoginSuccessful={onLoginSuccessful}
                        isGuest={isGuest}
                        logoutHandler={logoutHandler}
                        apiURL={apiURL}
                        appleClientId={appleClientId}
                        appleRedirectURL={appleRedirectURL}
                        theme={theme}
                    />
                )}
                {facebookAppId && facebookApiVersion && (
                    <FacebookAuth
                        onSignUpSocial={onSignUpSocial}
                        onLoginSuccessful={onLoginSuccessful}
                        isGuest={isGuest}
                        logoutHandler={logoutHandler}
                        apiURL={apiURL}
                        facebookAppId={facebookAppId}
                        facebookApiVersion={facebookApiVersion}
                        theme={theme}
                    />
                )}
            </Box>
        </Box>
    )
}

SocialLogin.displayName = 'SocialLogin'

export default SocialLogin
