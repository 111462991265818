import React, { useEffect, useRef } from 'react'

import { Typography } from '@mui/material'
import { getMoveById, getNextPosition, getStartingPosition } from '../../chess/gameTree'
import { Color, GameTree, GameTreeMove } from '../../chess/types'
import ElapsedTimeBar from './ElapsedTimeBar'
import MoveBox from './MoveBox'

const printMoveTime = (clock: number) => {
    const minutes = Math.floor(clock / 60)
    const seconds = clock >= 60 ? clock % 60 : Math.max(clock, 0.1)
    const secondsString = seconds.toFixed(1)

    return minutes > 0 ? `${minutes}:${seconds < 10 ? '0' : ''}${secondsString}` : `${secondsString}s`
}

const getFullMoves = (gameTree: GameTree) => {
    let pos = getStartingPosition(gameTree)

    let fullMovesCounter = 0
    let halfMovesCounter = 0
    const fullMoves: (GameTreeMove | null)[][] = []

    if (pos.position.turn === Color.Black) {
        halfMovesCounter = 1
    }

    while (pos.nextMoveIds.length > 0) {
        const nextMoveId = pos.nextMoveIds[0]
        const nextMove = getMoveById(gameTree, nextMoveId)

        if (!nextMove) break

        if (!fullMoves[fullMovesCounter]) fullMoves[fullMovesCounter] = new Array(2).fill(null)
        fullMoves[fullMovesCounter][halfMovesCounter] = nextMove

        const nextPos = getNextPosition(gameTree, pos.id)
        if (!nextPos) break
        pos = nextPos

        fullMovesCounter += halfMovesCounter
        halfMovesCounter = (halfMovesCounter + 1) % 2
    }

    return fullMoves
}

type MoveCellProps = {
    children?: React.ReactNode
}

const MoveCell: React.FC<MoveCellProps> = ({ children }) => {
    return (
        <td
            rowSpan={2}
            style={{
                width: '18.75rem',
                minWidth: '3.125rem',
                padding: '0.375rem 0.625rem',
                borderBottom: '0.063rem solid #40464D',
            }}
        >
            {children}
        </td>
    )
}

const TimeCell: React.FC<{ children?: React.ReactNode; style?: React.CSSProperties }> = ({ children, style }) => {
    return (
        <td style={{ width: '18.75rem', minWidth: '3.125rem', padding: '0.125rem 0.313rem', ...style }}>{children}</td>
    )
}

const whiteMoveRowStyles = { borderBottom: 'none' }

const moveStyles = {
    padding: '0.25rem',
    borderRadius: '0.25rem',
    fontSize: '1.125rem',
}

const moveTimeStyles = {
    fontSize: '0.875rem',
}

type Props = {
    gameTree: GameTree
    currentPositionId?: string
    selectMove: (moveId: string) => void
    setEditMoveID: (moveId: string) => void
    setAnnotation: (annotation: string) => void
    handleClickOpen: () => void
    editableAnnotations?: boolean
    result: string
}

const NotationTable: React.FC<Props> = ({
    gameTree,
    selectMove,
    handleClickOpen,
    setAnnotation,
    setEditMoveID,
    currentPositionId,
    editableAnnotations,
    result,
}) => {
    const containerRef = useRef<HTMLTableElement>(null)
    const fullMoves = getFullMoves(gameTree)

    useEffect(() => {
        const container = containerRef.current
        if (container) {
            container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' })
        }
    }, [gameTree])

    return (
        <>
            <table
                ref={containerRef}
                style={{
                    fontFamily: 'Space Grotesk',
                    fontWeight: 700,
                    overflow: 'auto',
                    display: 'flex',
                    borderSpacing: '0',
                }}
            >
                <tbody>
                    {fullMoves.map((halfMoves, i) => {
                        const whiteMoveTime = halfMoves[0]?.moveTime
                        const blackMoveTime = halfMoves[1]?.moveTime

                        return (
                            <React.Fragment key={i}>
                                <tr style={whiteMoveRowStyles}>
                                    <td
                                        rowSpan={2}
                                        style={{
                                            padding: '0.375rem 0.625rem',
                                            borderBottom: '0.063rem solid rgb(64, 70, 77)',
                                        }}
                                    >
                                        <span>{i + 1}</span>
                                    </td>
                                    {halfMoves.map((move, index) => {
                                        if (!move && index === 0)
                                            return (
                                                <MoveCell key={0}>
                                                    <span style={moveStyles}>...</span>
                                                </MoveCell>
                                            )

                                        if (!move) return <MoveCell key={1} />

                                        return (
                                            <MoveCell key={move.id}>
                                                <MoveBox
                                                    selectMove={() => selectMove(move.id)}
                                                    setEditMoveID={() => setEditMoveID(move.id)}
                                                    setAnnotation={() => setAnnotation(move.annotation ?? '')}
                                                    handleClickOpen={handleClickOpen}
                                                    editableAnnotations={editableAnnotations}
                                                    isActive={move.nextPositionId === currentPositionId}
                                                    move={move}
                                                />
                                            </MoveCell>
                                        )
                                    })}
                                    <TimeCell style={{ borderBottom: 'none' }}>
                                        {typeof whiteMoveTime === 'number' ? (
                                            <ElapsedTimeBar elapsedTime={whiteMoveTime} />
                                        ) : null}
                                    </TimeCell>
                                    <TimeCell style={{ borderBottom: 'none', width: 'fit' }}>
                                        <span style={moveTimeStyles}>
                                            {typeof whiteMoveTime === 'number' ? printMoveTime(whiteMoveTime) : null}
                                        </span>
                                    </TimeCell>
                                </tr>
                                <tr>
                                    <TimeCell style={{ borderBottom: '0.063rem solid #40464D' }}>
                                        {typeof blackMoveTime === 'number' ? (
                                            <ElapsedTimeBar elapsedTime={blackMoveTime} black />
                                        ) : null}
                                    </TimeCell>
                                    <TimeCell style={{ borderBottom: '0.063rem solid #40464D', width: 'fit' }}>
                                        <span style={moveTimeStyles}>
                                            {typeof blackMoveTime === 'number' ? printMoveTime(blackMoveTime) : null}
                                        </span>
                                    </TimeCell>
                                </tr>
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </table>
            {result && <Typography m="6px 10px" variant="subtitle1">{`Result: ${result}`}</Typography>}
        </>
    )
}

export default NotationTable
