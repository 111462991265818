import PgnCommandHandler from './PgnCommandHandler'
import { squareToXY } from './basics'
import { generateMoves } from './core'
import { addMoveToGTPosition, createRootGameTree, getStartingPosition } from './gameTree'
import { Color, GameTree, GameTreePosition, Move, Piece, Position } from './types'

type PgnMove = {
    move: string
    comments: string[]
    variations: PgnMove[][]
}

type ExplodedMove = {
    move: string
    comments: string[][]
    variations: string[][]
}

export class PgnTextInterpreter {
    pgnMoveText: string
    inComment = false
    variationDepth = 0
    moveCounter = -1
    variationCounter = -1
    commentCounter = 0
    moves: ExplodedMove[] = []
    result = '*'

    constructor(pgnMoveText: string) {
        this.pgnMoveText = pgnMoveText
    }

    getPgnMoveTree(): PgnMove[] {
        const sanitized = this.sanitizePgnMoveText(this.pgnMoveText)
        const words = this.getWordsFromPgnMoveText(sanitized)

        words.forEach((word) => {
            if (this.inComment) {
                if (this.inVariation()) {
                    this.writeInVariation(word)
                } else {
                    this.writeInComment(word)
                }

                if (this.isCommentEnd(word)) {
                    this.inComment = false
                    this.commentCounter++
                }

                return
            }

            if (this.isCommentStart(word)) {
                if (this.inComment) throw new Error('Invalid PGN - Nested comments are not supported')

                this.inComment = true

                if (this.inVariation()) this.writeInVariation(word)
                else {
                    this.moves[this.moveCounter].comments.push([])
                    this.writeInComment(word)
                }

                return
            }

            if (this.isVariationStart(word)) {
                this.variationDepth++

                if (this.variationDepth === 1) {
                    this.moves[this.moveCounter].variations.push([])
                    this.variationCounter++
                }

                this.writeInVariation(word)

                return
            }

            if (this.inVariation()) {
                this.writeInVariation(word)

                if (this.isVariationEnd(word)) this.variationDepth--

                return
            }

            if (this.isMoveNumber(word)) {
                return
            }

            if (this.isResult(word)) {
                this.result = word
                return
            }

            this.moveCounter++
            this.commentCounter = 0
            this.variationCounter = -1
            this.moves.push({ move: '', comments: [], variations: [] })
            this.writeMove(word)
        })

        if (this.inComment || this.variationDepth !== 0)
            throw new Error('Invalid PGN - Comment or variation not closed')

        const moves = this.moves.map((move) => {
            return {
                move: move.move,
                comments: move.comments.map((comment) => comment.slice(1, -1).join(' ')),
                variations: move.variations.map((variation) =>
                    new PgnTextInterpreter(variation.slice(1, -1).join(' ')).getPgnMoveTree(),
                ),
            }
        })

        return moves
    }

    private sanitizePgnMoveText(text: string): string {
        return text
            .replace(/\n/g, ' ')
            .replace(/\r/g, ' ')
            .replace(/\t/g, ' ')
            .replaceAll('(', ' ( ')
            .replaceAll(')', ' ) ')
            .replaceAll('{', ' { ')
            .replaceAll('}', ' } ')
            .trim()
    }

    private getWordsFromPgnMoveText(text: string): string[] {
        return text.split(' ').filter((word) => word !== '')
    }

    private isCommentStart(word: string): boolean {
        return word.indexOf('{') !== -1
    }

    private isCommentEnd(word: string): boolean {
        return word.indexOf('}') !== -1
    }

    private isVariationStart(word: string): boolean {
        return word.indexOf('(') !== -1
    }

    private isVariationEnd(word: string): boolean {
        return word.indexOf(')') !== -1
    }

    private inVariation(): boolean {
        return this.variationDepth > 0
    }

    private isMoveNumber(word: string): boolean {
        return /\d+\./.test(word) || /\d+\.{3}/.test(word)
    }

    private isResult(word: string): boolean {
        return ['1-0', '0-1', '1/2-1/2', '*'].includes(word)
    }

    private writeMove(word: string): void {
        this.moves[this.moveCounter].move = word
    }

    private writeInComment(word: string): void {
        this.moves[this.moveCounter].comments[this.commentCounter].push(word)
    }

    private writeInVariation(word: string): void {
        this.moves[this.moveCounter].variations[this.variationCounter].push(word)
    }
}

class PgnParser {
    private _gameTree: GameTree

    constructor(startPosition: string, pgnMoveText: string) {
        this._gameTree = createRootGameTree(startPosition)

        this.runGame(pgnMoveText)
    }

    get gameTree(): GameTree {
        return this._gameTree
    }

    private runGame(pgnMoveText: string) {
        const pgnMoveTree = new PgnTextInterpreter(pgnMoveText).getPgnMoveTree()

        let currentPosition = getStartingPosition(this.gameTree)

        this.addLineToGameTree(pgnMoveTree, currentPosition)
    }

    private addLineToGameTree(pgnMoveTree: PgnMove[], parentPosition: GameTreePosition) {
        let currentPosition = parentPosition

        pgnMoveTree.forEach((pgnMove) => {
            const matchedMove = this.matchMove(pgnMove.move, currentPosition.position)
            if (matchedMove === null) throw new Error(`Invalid PGN - Invalid Move "${pgnMove.move}"`)

            const comment = pgnMove.comments.join(' ')
            const [pureComment, commands] = PgnCommandHandler.extractCommandsFromAnnotation(comment)
            const clock = PgnCommandHandler.parseClkCommand(commands)
            const moveTime = PgnCommandHandler.parseEmtCommand(commands)

            const nextPos = addMoveToGTPosition(
                this.gameTree,
                currentPosition,
                matchedMove,
                undefined,
                pureComment,
                clock,
                moveTime,
            )
            pgnMove.variations.forEach((variation) => {
                this.addLineToGameTree(variation, currentPosition)
            })
            currentPosition = nextPos
        })
    }

    private matchMove(str: string, pos: Position): Move | null {
        let fromX = -1,
            fromY = -1,
            toX = -1,
            toY = -1
        let piece: Piece = Piece.Pawn,
            promotion: Piece | null = null

        if (str.length >= 5 && (str.substring(0, 5) === '0-0-0' || str.substring(0, 5) === 'O-O-O')) {
            fromX = 4
            fromY = pos.turn === Color.White ? 7 : 0
            toX = 2
            toY = fromY
            piece = Piece.King
        } else if (str.length >= 3 && (str.substring(0, 3) === '0-0' || str.substring(0, 3) === 'O-O')) {
            fromX = 4
            fromY = pos.turn === Color.White ? 7 : 0
            toX = 6
            toY = fromY
            piece = Piece.King
        } else {
            for (let i = 0; i < str.length; i++) {
                const c = str[i]
                if (c >= 'a' && c <= 'h') {
                    fromX = toX
                    toX = c.charCodeAt(0) - 'a'.charCodeAt(0)
                } else if (c >= '1' && c <= '8') {
                    fromY = toY
                    toY = 7 - (c.charCodeAt(0) - '1'.charCodeAt(0))
                } else if ('NBRQK'.indexOf(c) >= 0) {
                    let piece2: Piece | null = null
                    switch (c) {
                        case 'K':
                            piece2 = Piece.King
                            break
                        case 'Q':
                            piece2 = Piece.Queen
                            break
                        case 'R':
                            piece2 = Piece.Rook
                            break
                        case 'B':
                            piece2 = Piece.Bishop
                            break
                        case 'N':
                            piece2 = Piece.Knight
                            break
                        case 'P':
                            piece2 = Piece.Pawn
                            break
                    }
                    if (i === 0) {
                        piece = piece2 || Piece.Pawn
                    } else {
                        promotion = piece2
                    }
                }
            }
        }

        const moves = generateMoves(pos)
        for (let i = 0; i < moves.length; i++) {
            const move = moves[i]
            const [mfromX, mfromY] = squareToXY(move.from)
            const [mtoX, mtoY] = squareToXY(move.to)
            if (toX !== mtoX || toY !== mtoY) {
                continue
            }
            if (fromX !== -1 && fromX !== mfromX) {
                continue
            }
            if (fromY !== -1 && fromY !== mfromY) {
                continue
            }
            if (piece !== null) {
                const piece2 = pos.board[move.from]
                if (piece2 === null || piece !== Math.abs(piece2)) {
                    continue
                }
            }
            if (move.promotion !== undefined && promotion !== Math.abs(move.promotion)) {
                continue
            }
            return move
        }
        return null
    }
}

export default PgnParser
