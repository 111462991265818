/*
The calculation of the time control name is as follows:
We calculate an "etime" value like this: the initial time plus 2/3 the increment.  

For instance: 
A 2 12 game has an "etime" of 10 (2+ (12*2)/3 = 10)
A 5 0 game has an etime of 5 (5+ (0*2)/3 = 5)
A 2 1 game has an etime of 2.6666 (2+ (1*2)/3 = 2.666)

Based on this etime value we sort games out as:

Bullet : etime < 3
Blitz : 3 <= etime < 10
Rapid : 10 <= etime < 60
Classical : etime => 60
*/

export enum TimeControl {
    Bullet = 'bullet',
    Blitz = 'blitz',
    Rapid = 'rapid',
    Classic = 'classic',
}

export const determineTimeControlName = (time: number, increment: number): TimeControl => {
    const etime = time + (increment * 2) / 3

    if (etime < 3) return TimeControl.Bullet
    else if (etime < 10) return TimeControl.Blitz
    else if (etime < 60) return TimeControl.Rapid
    return TimeControl.Classic
}
