import Cookie from 'js-cookie'

const removeCookie = (cookieName, domain) => {
    Cookie.remove(cookieName, {
        path: '/',
        domain: domain,
    })
}

export default removeCookie
