import { IconButton, Paper, SxProps } from '@mui/material'

import React from 'react'
import BackMove from '../../../assets/icons/controls/back.svg?react'
import FirstMove from '../../../assets/icons/controls/back_linebefore.svg?react'
import NextMove from '../../../assets/icons/controls/forward.svg?react'
import LastMove from '../../../assets/icons/controls/forward_linebefore.svg?react'
import { useMouseHold } from './useMouseHold'

export type NotationProps = {
    commonDisabled?: boolean
    hasNextMoves: boolean
    hasPrevMoves: boolean
    onFirstMove: () => void
    onPrevMove: () => void
    onNextMove: () => void
    onLastMove: () => void
    extraButtons?: JSX.Element[]
    vertical?: boolean
    wrapperStyle?: React.CSSProperties
    sx?: SxProps
}

export const NotationControls = React.memo((props: NotationProps) => {
    const {
        commonDisabled,
        hasNextMoves,
        hasPrevMoves,
        onFirstMove,
        onLastMove,
        onNextMove,
        onPrevMove,
        extraButtons,
        wrapperStyle,
        sx,
    } = props

    const onClickWithHold = useMouseHold()

    const prevDisabled = !hasPrevMoves || commonDisabled
    const nextDisabled = !hasNextMoves || commonDisabled

    // TODO: fix notation controls
    return (
        <Paper
            square
            style={wrapperStyle}
            sx={{
                display: 'flex',
                margin: '0 auto',
                justifyContent: 'center',
                width: '100%',
                gap: '34px',
                py: '8px',
                ...sx,
            }}
        >
            <IconButton onClick={onFirstMove} disabled={prevDisabled} size="small">
                <FirstMove />
            </IconButton>

            <IconButton onMouseDown={() => onClickWithHold(onPrevMove)} disabled={prevDisabled} size="small">
                <BackMove />
            </IconButton>
            {extraButtons &&
                extraButtons.map((button, index) => <React.Fragment key={'' + Math.random()}>{button}</React.Fragment>)}
            <IconButton onMouseDown={() => onClickWithHold(onNextMove)} disabled={nextDisabled} size="small">
                <NextMove />
            </IconButton>

            <IconButton onClick={onLastMove} disabled={nextDisabled} size="small">
                <LastMove />
            </IconButton>
        </Paper>
    )
})
