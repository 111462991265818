import { Button, Paper, Stack, Typography } from '@mui/material'
import { Fragment, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import GetCookie from './common/getCookie'

import 'react-circular-progressbar/dist/styles.css'
import CookiesDialog from '../CookiesDialog/CookiesDialog'
import { SocialType } from '../SocialLogin/SocialLogin'
import SettingsDialog from '../settings/SettingsDialog'
import { PiecesTheme } from './common/PiecesProvider'
import { clearQueryParams } from './common/queryParams'
import BurgerNavigation from './components/BurgerNavigation'
import GlobalLink from './components/GlobalLink'
import UserMenu from './components/UserMenu'
import ConfirmEmailForm from './forms/ConfirmEmailForm'
import DiveInForm from './forms/DiveInForm'
import ErrorForm from './forms/ErrorForm'
import ForgotPasswordForm from './forms/ForgotPasswordForm'
import JoinUsForm from './forms/JoinUsForm'
import LogInForm from './forms/LogInForm'
import ResetPasswordForm from './forms/ResetPasswordForm'
import SignUpForm from './forms/SignUpForm'
import SignUpSocialForm from './forms/SignUpSocialForm'
import WelcomeUserForm from './forms/WelcomeUserForm'
import {
    ensureLogin,
    getUserData,
    guestRegistration,
    loginSocial,
    logout,
    resendConfirmationEmail,
    saveUserSettings,
    signup,
    updateFTUE,
    upgradeGuest,
    upgradeGuestSocial,
} from './functions/ams-functions'
import { analyticsManager } from './services/analytics/AnalyticsManager'

let newPasswordTokens: string[] = []
let lastToken = ''

const HeaderBarStyle = {
    '&.gameHeaderBar': {
        backgroundImage: 'none',
        elevation: 0,
        px: 2,
        py: '12px',
        borderRadius: 0,
        height: '80px',
        backgroundColor: '#101820',
        position: 'relative',
        zIndex: 1300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
        '& .MuiTypography-root ': {
            fontFamily: 'Space Grotesk',
            fontWeight: 700,
        },
        'img.header-logo': {
            width: '2.813rem',
        },
        '& .iccLogo': { marginLeft: '2rem', maxWidth: '6.25rem' },
        '& .burger': {
            '& .burgerLines': {
                fill: '#F4F4F4',
            },
        },
    },
}

const HeaderStyle = {
    '&.globalHeader': {
        fontFamily: 'Fira Sans',
        textTransform: 'uppercase',
        fontSize: '1.25rem',
        borderRadius: '0',
        boxShadow: 'none',
        color: '#F4F4F4',
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        '& .MuiMenuItem-root': {
            borderRadius: '0',
        },
        '& .header .MuiPaper-root': {
            borderRadius: 0,

            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
        '& .login': {
            justifyContent: 'flex-end',
            '& .btn': {
                height: '2rem',
                width: '6.625rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                '@media (max-width: 870px)': {
                    width: 'auto',
                },

                '& .typo': {
                    fontFamily: 'Fira Sans',
                    fontSize: '1.25rem',
                    '@media (max-width: 870px)': {
                        fontSize: '1rem',
                    },
                },
                '&.btn-login': {
                    backgroundColor: 'transparent',
                    color: '#F4F4F4',
                    borderRadius: 0,
                    border: '0.125rem solid #F4F4F4',
                    '&:hover': {
                        backgroundColor: '#F4F4F4',
                        color: '#101820',
                        border: `0.125rem solid '#F4F4F4'`,
                    },
                },
                '&.btn-signup': {
                    backgroundColor: '#F4F4F4',
                    color: '#101820',
                    borderRadius: 0,
                    border: `0.125rem solid '#F4F4F4'`,
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#F4F4F4',
                        border: '0.125rem solid #F4F4F4',
                    },
                },
            },
            '& .burger-menu-wrapper': {
                backgroundColor: 'transparent',
                alignItems: 'center',
                display: 'flex',
            },
            '& .extra-buttons': {
                display: 'flex',
            },
        },
    },
}

export type UserRatingData = {
    best: number
    best_date: string
    draw: number
    game_type: string
    is_provisional: boolean
    loss: number
    rating: number
    total_games: number
    user_id: string
    win: number
}

export type Settings = {
    autoAccept: boolean
    boardStyle: string
    coordinates: boolean
    dragAndDrop: boolean
    pieceStyle: PiecesTheme
    preMove: boolean
    soundCheckOn: boolean
    soundDrawOn: boolean
    soundMateOn: boolean
    soundMoveOn: boolean
    soundOn: boolean
    soundSmateOn: boolean
    autoQueen: boolean
    darkMode: boolean
    soundStyle: string
    briefToggle: boolean
    friendlyMode: boolean
}

export enum Rules {
    FULL_ACCESS = 'full_access',
    GAME_HISTORY = 'game_history',
    GAME_SETTINGS = 'game_settings',
    LIVE_GAMES = 'live_games',
    PROFILE = 'profile',
    NEWS = 'news',
    VIDEOS = 'videos',
    USER_MEMBERSHIPS = 'user_memberships',
    PLAY_BOTS = 'play_bots',
}

export enum GroupNames {
    GUEST = 'guest',
    MEMBER = 'member',
    SUBSCRIBED = 'subscribed',
}

export type UserGroup = {
    name: GroupNames
    id: number
    rules: [
        {
            // TODO: add rules as they are defined
            name: Rules
        },
    ]
}

export type UserData = {
    id: string
    user_name: string
    first_name?: string
    last_name?: string
    title: string | null
    avatar_url: string | null
    email: string
    birthday?: string
    phone_number?: string
    country?: string
    email_confirmed: boolean
    created_at: any
    over_12_years_old: boolean
    subscription_state: number
    subscription_days_left: number
    free_login: boolean
    ratings: {
        blitz: UserRatingData
        bullet: UserRatingData
        classic: UserRatingData
        rapid: UserRatingData
    }
    ftue: {
        mode: 'guest' | 'user'
        newGame: boolean
        players: boolean
        computer: boolean
        analysis: boolean
        puzzle: boolean
        welcomeGuest: boolean
        welcomeUser: boolean
    }
    game_settings: Settings
    groups: Array<UserGroup>
}

export type FTUE = keyof Pick<UserData, 'ftue'>['ftue']

export type LoggedInUserData = {
    state: LoginState
    userData: UserData | null
    token: string
}

export enum AreaType {
    WEBSITE = 'website',
    PLAY_AREA = 'play_area',
}

export interface GlobalHeaderProps {
    apiURL: string
    onSettingsChange: (data: Settings) => void
    onLoginStateChange: (data: LoggedInUserData) => void
    extraButtons?: Array<JSX.Element>
    playAreaURL: string
    websiteURL: string
    forumsURL: string
    learningURL: string
    shopURL: string
    env: string
    gaGameKey?: string
    gaSecretKey?: string
    area: AreaType
    googleClientId?: string
    facebookAppId?: string
    facebookApiVersion?: string
    appleClientId?: string
    appleRedirectURL?: string
    initialUserData?: UserData | null
    Link: any
}

export enum OverlayType {
    NONE,
    SIGNUP,
    SIGNUP_SOCIAL,
    SIGNIN,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    CONFIRM_EMAIL,
    JOIN_US,
    DIVE_IN,
    ERROR,
    SETTINGS,
    EMAIL_NOT_CONFIRMED,
}

export enum LoginState {
    NOT_LOGGED_IN = 'not_logged_in',
    LOGGED_IN = 'logged_in',
    INITIALIZING = 'initializing',
}
export enum ErrorAction {
    GUEST_REGISTRATION = 'guestRegistration',
    SOCIAL_LOGIN = 'socialLogin',
}

export const resendEmailTimeout = 60 // 1 minute

function GlobalHeader(props: GlobalHeaderProps, ref: any) {
    const {
        onSettingsChange,
        onLoginStateChange,
        apiURL,
        extraButtons,
        websiteURL,
        playAreaURL,
        learningURL,
        forumsURL,
        shopURL,
        area,
        env,
        googleClientId,
        facebookAppId,
        facebookApiVersion,
        appleClientId,
        appleRedirectURL,
        initialUserData,
        Link,
    } = props

    const [loginState, setLoginState] = useState(!!initialUserData ? LoginState.LOGGED_IN : LoginState.INITIALIZING)
    const [overlay, setOverlay] = useState(OverlayType.NONE)
    const [socialData, setSocialData] = useState<any>(null)
    const [userName, setUserName] = useState(initialUserData?.user_name || '')
    const [avatarUrl, setAvatarUrl] = useState(initialUserData?.avatar_url || '')
    const [resentEmail, setResentEmail] = useState<'' | 'success' | 'error'>('')
    const [resentEmailMessage, setResentEmailMessage] = useState('')
    const [resentMessageCountdown, setResentMessageCountdown] = useState(resendEmailTimeout)
    const [registerInfo, setRegisterInfo] = useState<{
        email: string
        password: string
    }>({
        email: '',
        password: '',
    })
    const [errorAction, setErrorAction] = useState<ErrorAction | null>(null)
    const ensuredRef = useRef<Boolean>(false)

    const [userData, setUserData] = useState<UserData | null>(initialUserData || null)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const search = window.location.search
            const params = new URLSearchParams(search)

            const overlay = params.get('overlay')?.toLocaleUpperCase() as keyof typeof OverlayType
            if (overlay) {
                const allowedOverlays = ['SIGNUP', 'SIGNIN', 'FORGOT_PASSWORD', 'SETTINGS']
                allowedOverlays.includes(overlay) && setOverlay(OverlayType[overlay])
                clearQueryParams(['overlay'])
            }

            newPasswordTokens = params.getAll('token')
            const isResetPasswordFlow = params.has('resetPassword')
            lastToken = newPasswordTokens[newPasswordTokens.length - 1]
            if (isResetPasswordFlow && lastToken) {
                setOverlay(OverlayType.RESET_PASSWORD)
            }
        }
    }, [])

    useEffect(() => {
        if (initialUserData) {
            setUserData(initialUserData)
            setUserName(initialUserData.user_name)
            setAvatarUrl(initialUserData.avatar_url || '')
        }
    }, [initialUserData])

    useEffect(() => {
        if (loginState === LoginState.LOGGED_IN) {
            const fullUserData = getUserData()
            if (!!fullUserData) {
                setUserData(fullUserData.user)
            }
        }
    }, [loginState])

    useEffect(() => {
        setAvatarUrl(userData?.avatar_url || '')
    }, [userData?.avatar_url])

    const [isGuest, setIsGuest] = useState(
        initialUserData?.groups.some((group: UserGroup) => group.name === GroupNames.GUEST) || false,
    )

    useEffect(() => {
        if (window.location) {
            const urlParams = new URLSearchParams(decodeURIComponent(window.location.search))
            const emailConfirmed = urlParams.get('emailConfirmed')
            if (emailConfirmed === 'false') {
                setOverlay(OverlayType.EMAIL_NOT_CONFIRMED)
            }
        }
    }, [])

    useImperativeHandle(ref, () => ({
        openSignUpForm: () => setOverlay(OverlayType.SIGNUP),
        openSignUpSocialForm: () => setOverlay(OverlayType.SIGNUP_SOCIAL),
        openSignInForm: () => setOverlay(OverlayType.SIGNIN),
        openJoinUsForm: () => setOverlay(OverlayType.JOIN_US),
        openDiveInForm: () => setOverlay(OverlayType.DIVE_IN),
        openResetPasswordForm: () => setOverlay(OverlayType.RESET_PASSWORD),
        openProfileSettings: () => setOverlay(OverlayType.SETTINGS),
        openError: (action: ErrorAction) => onErrorHandler(action),
        onSignUpAsGuest: () => onGuestRegistration(),
        onSignUpSocial: (socialResponse: any) => onSignUpSocialClick(socialResponse),
        onSuccessLogin: () => onLoginSuccessful(),
        onSettingsUpdate: (settings: Settings) => localSettingsUpdate(settings),
        onLogout: () => logoutHandler(),
        onUpdateUserData: (userData: UserData) => setUserData(userData),
    }))

    const localSettingsUpdate = (settings: Settings) => {
        if (userData) {
            setUserData({ ...userData, game_settings: settings })
        }
    }

    const onErrorHandler = (action: ErrorAction) => {
        setErrorAction(action)
        setOverlay(OverlayType.ERROR)
    }

    const ensureLoginCallback = useCallback(async () => {
        if (ensuredRef.current === true) return
        ensuredRef.current = true
        const userData = await ensureLogin(apiURL)

        if (userData && userData.user_name) {
            setLoginState(LoginState.LOGGED_IN)
            setUserName(userData.user_name)
            setAvatarUrl(userData.avatar_url)
            setIsGuest(userData.groups.some((group: UserGroup) => group.name === GroupNames.GUEST))
            setUserData(userData)
            const token = GetCookie('jwt')

            onLoginStateChange({
                state: LoginState.LOGGED_IN,
                userData: userData,
                token: token,
            })
        } else {
            setLoginState(LoginState.NOT_LOGGED_IN)
            onLoginStateChange({
                state: LoginState.NOT_LOGGED_IN,
                userData: null,
                token: '',
            })
        }
    }, [])

    useEffect(() => {
        if (!initialUserData) {
            ensureLoginCallback()
        }
    }, [])

    const onResend = async (event?: any, email?: string) => {
        try {
            const response = await resendConfirmationEmail(email || registerInfo.email, apiURL)
            analyticsManager.dispatchEvent('requestResend', {})

            if (response.ok) {
                setResentEmailMessage('Email resent successfully.')
                setResentEmail('success')
                setResentMessageCountdown(resendEmailTimeout)
            } else {
                if (response.status === 429) {
                    const data = await response.json()
                    setResentEmailMessage('Too many requests, please try again later.') // send email limitation reached
                    setResentEmail('error')
                    setResentMessageCountdown(data.timeLeft)
                } else {
                    setResentEmailMessage('Unknown Error, please try again later.')
                    setResentEmail('error')
                }
            }
        } catch (e) {
            console.log('Error when resending', e)
        }
    }

    const logoutHandler = async () => {
        try {
            await logout(apiURL)
            setOverlay(OverlayType.NONE)
            setLoginState(LoginState.NOT_LOGGED_IN)
            onLoginStateChange({
                state: LoginState.NOT_LOGGED_IN,
                userData: null,
                token: '',
            })
            setUserData(null)
            analyticsManager.dispatchEvent('logout', {})
        } catch (e) {
            console.log('Error when logging out', e)
        }
    }

    const onLoginSuccessful = async () => {
        setOverlay(OverlayType.NONE)
        setLoginState(LoginState.LOGGED_IN)
        const token = GetCookie('jwt')
        const userData = getUserData()
        setSocialData(null)
        setUserName(userData.user.user_name)
        setAvatarUrl(userData.user.avatar_url)
        setUserData(userData.user)
        setIsGuest(userData.user.groups.some((group: UserGroup) => group.name === GroupNames.GUEST))
        if (userData && userData.user)
            onLoginStateChange({
                state: LoginState.LOGGED_IN,
                userData: userData.user,
                token: token,
            })
    }

    const onGuestRegistration = async () => {
        try {
            const result = await guestRegistration(apiURL)
            if (!result.error) {
                onLoginSuccessful()
                analyticsManager.dispatchEvent('createGuestAccount', {})
            } else {
                onErrorHandler(ErrorAction.GUEST_REGISTRATION)
            }
        } catch (e) {
            console.log('Error when registering guest', e)
        }
    }

    const onSubmit = async (email: string, password: string, username: string, country: string, over12: boolean) => {
        try {
            if (isGuest) {
                await upgradeGuest(email, password, username, country, over12, apiURL)
                await logout(apiURL)
                setLoginState(LoginState.NOT_LOGGED_IN)
                onLoginStateChange({
                    state: LoginState.NOT_LOGGED_IN,
                    userData: null,
                    token: '',
                })
            } else {
                signup(email, password, username, country, over12, apiURL)
            }
            setRegisterInfo({
                email: email,
                password: password,
            })

            analyticsManager.dispatchEvent('completeSignup', {
                country,
                isOver12: over12,
                authType: 'email/user_name',
            })

            setOverlay(OverlayType.CONFIRM_EMAIL)
        } catch (e) {
            console.log('Error while submitting', e)
        }
    }

    const onSocialSubmit = async (username: string, country: string, over12: boolean) => {
        try {
            let socialToken: string = ''
            let accessToken: string = ''
            switch (socialData.socialType) {
                case SocialType.APPLE:
                    socialToken = socialData.authorization.code
                    accessToken = socialData.socialToken
                    break
                case SocialType.FACEBOOK:
                    socialToken = socialData.authResponse.accessToken
                    accessToken = socialData.authResponse.signedRequest
                    break
                case SocialType.GOOGLE:
                    socialToken = socialData.credential
                    accessToken = socialData.clientId
                    break
                default:
                    break
            }

            analyticsManager.dispatchEvent('completeSignup', {
                country,
                isOver12: over12,
                authType:
                    socialData.socialType === SocialType.APPLE
                        ? 'apple'
                        : socialData.socialType === SocialType.FACEBOOK
                          ? 'facebook'
                          : 'google',
            })

            let data: any = null
            if (isGuest) {
                data = await upgradeGuestSocial(
                    apiURL,
                    socialToken,
                    accessToken,
                    socialData.socialType,
                    logoutHandler,
                    username,
                    country,
                    over12,
                )
            } else {
                data = await loginSocial(
                    apiURL,
                    socialToken,
                    accessToken,
                    socialData.socialType,
                    username,
                    country,
                    over12,
                )
            }

            if (data?.error) {
                onErrorHandler(ErrorAction.SOCIAL_LOGIN)
            } else {
                onLoginSuccessful()
            }
        } catch (e) {
            console.log('Error while submitting', e)
        }
    }

    const onSignUpClick = () => {
        setOverlay(OverlayType.SIGNUP)
    }

    const onSignUpSocialClick = (socialResponse: any) => {
        setSocialData(socialResponse)
        setOverlay(OverlayType.SIGNUP_SOCIAL)
    }

    const onErrorTryAgain = () => {
        switch (errorAction) {
            case ErrorAction.GUEST_REGISTRATION:
                onErrorClose()
                break
            case ErrorAction.SOCIAL_LOGIN:
                setOverlay(OverlayType.SIGNUP_SOCIAL)
                break
            default:
                break
        }
        setErrorAction(null)
    }

    const onErrorGoToSignIn = () => {
        setSocialData(null)
        setErrorAction(null)
        setOverlay(OverlayType.SIGNIN)
    }

    const onErrorClose = () => {
        setSocialData(null)
        setErrorAction(null)
        setOverlay(OverlayType.NONE)
    }

    const onHideRatingHandler = async (value: boolean) => {
        const token = GetCookie('jwt')
        if (userData && token) {
            const updatedSettings = { ...userData.game_settings, friendlyMode: value }
            localSettingsUpdate(updatedSettings)
            onSettingsChange(updatedSettings)
            const result = await saveUserSettings(apiURL, token, updatedSettings)
            if (result.error) {
                console.error('Error saving friendly mode setting:', result.error)
            }
        }
    }

    return (
        <Paper className="gameHeaderBar" sx={HeaderBarStyle}>
            <Fragment>
                <Paper className={`globalHeader`} sx={HeaderStyle}>
                    <Stack className="header" direction="row" gap={2} alignItems="center">
                        <BurgerNavigation
                            websiteURL={websiteURL}
                            playAreaURL={playAreaURL}
                            learningURL={learningURL}
                            forumsURL={forumsURL}
                            shopURL={shopURL}
                            area={area}
                            Link={Link}
                        />
                        <GlobalLink
                            Link={Link}
                            href={area === AreaType.PLAY_AREA ? websiteURL : '/'}
                            area={area}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img src={'/assets/images/logo.svg'} alt="logo" height="46px" width="46px" />
                        </GlobalLink>
                    </Stack>
                    <Stack className="login" direction="row" gap={2}>
                        <Stack className="extra-buttons" direction="row" alignItems="center" gap={2}>
                            {extraButtons &&
                                extraButtons.map((button, index) => <Fragment key={index}>{button}</Fragment>)}
                        </Stack>

                        {loginState === LoginState.LOGGED_IN && (
                            <UserMenu
                                onLogout={logoutHandler}
                                userName={userName}
                                avatarUrl={avatarUrl ? avatarUrl : ''}
                                websiteURL={websiteURL}
                                isGuest={isGuest}
                                area={area}
                                Link={Link}
                                onLogIn={() => {
                                    setOverlay(OverlayType.SIGNIN)
                                    analyticsManager.dispatchEvent('openLogin', {
                                        origin: 'guestProfileDropdown',
                                    })
                                }}
                                onSignUp={() => {
                                    setOverlay(OverlayType.SIGNUP)
                                    analyticsManager.dispatchEvent('openSignup', {
                                        origin: 'guestProfileDropdown',
                                    })
                                }}
                                onSettings={() => {
                                    setOverlay(OverlayType.SETTINGS)
                                }}
                            />
                        )}
                        {loginState === LoginState.NOT_LOGGED_IN && (
                            <Stack className="login-buttons" direction="row" gap={1} sx={{ marginLeft: '0.313rem' }}>
                                <Button
                                    className=" btn btn-login"
                                    onClick={() => {
                                        setOverlay(OverlayType.SIGNIN)
                                        analyticsManager.dispatchEvent('openLogin', {
                                            origin: 'header',
                                        })
                                    }}
                                >
                                    <Typography className="typo">Log in</Typography>
                                </Button>
                                <Button
                                    className="btn btn-signup"
                                    onClick={() => {
                                        onSignUpClick()
                                        analyticsManager.dispatchEvent('openSignup', {
                                            origin: 'header',
                                        })
                                    }}
                                    data-gaid="open-signup"
                                    data-gaorigin="header"
                                >
                                    <Typography className="typo">Sign up</Typography>
                                </Button>
                            </Stack>
                        )}
                        {loginState === LoginState.INITIALIZING && (
                            <Button className="btn btn-login">
                                <Typography className="login">...</Typography>
                            </Button>
                        )}
                    </Stack>
                </Paper>
                {(!userData || isGuest) && overlay === OverlayType.SIGNIN && (
                    <LogInForm
                        onClose={() => setOverlay(OverlayType.NONE)}
                        onSwichToSignup={() => {
                            setOverlay(OverlayType.SIGNUP)
                            analyticsManager.dispatchEvent('openSignup', {
                                origin: 'loginDialog',
                            })
                        }}
                        onSwitchToForgotPassword={() => setOverlay(OverlayType.FORGOT_PASSWORD)}
                        onSwitchToEmailConfirmation={(email: string) => {
                            setOverlay(OverlayType.CONFIRM_EMAIL)
                            setRegisterInfo({ email, password: '' })
                            onResend(undefined, email)
                        }}
                        onSignUpSocial={onSignUpSocialClick}
                        apiURL={apiURL}
                        onLoginSuccessful={onLoginSuccessful}
                        isGuest={isGuest}
                        logoutHandler={logoutHandler}
                        googleClientId={googleClientId}
                        facebookAppId={facebookAppId}
                        facebookApiVersion={facebookApiVersion}
                        appleClientId={appleClientId}
                        appleRedirectURL={appleRedirectURL}
                    />
                )}

                {(!userData || isGuest) && overlay === OverlayType.SIGNUP && (
                    <SignUpForm
                        onClose={() => setOverlay(OverlayType.NONE)}
                        onSignin={() => {
                            setOverlay(OverlayType.SIGNIN)
                            analyticsManager.dispatchEvent('openLogin', {
                                origin: 'signupDialog',
                            })
                        }}
                        onSignUpSocial={onSignUpSocialClick}
                        onLoginSocialSuccessful={onLoginSuccessful}
                        apiURL={apiURL}
                        websiteURL={websiteURL}
                        onSubmit={onSubmit}
                        logoutHandler={logoutHandler}
                        guestUsername={isGuest ? userName : undefined}
                        googleClientId={googleClientId}
                        facebookAppId={facebookAppId}
                        facebookApiVersion={facebookApiVersion}
                        appleClientId={appleClientId}
                        appleRedirectURL={appleRedirectURL}
                        env={env}
                    />
                )}

                {overlay === OverlayType.SIGNUP_SOCIAL && (
                    <SignUpSocialForm
                        onClose={() => setOverlay(OverlayType.NONE)}
                        apiURL={apiURL}
                        websiteURL={websiteURL}
                        onSubmit={onSocialSubmit}
                    />
                )}

                {overlay === OverlayType.CONFIRM_EMAIL && (
                    <ConfirmEmailForm
                        onClose={() => {
                            clearQueryParams(['emailConfirmed', 'email'])
                            setOverlay(OverlayType.NONE)
                        }}
                        onResend={onResend}
                        resendMessage={resentEmailMessage}
                        resent={resentEmail}
                        failed={false}
                        countDown={resentMessageCountdown}
                        setCountDown={setResentMessageCountdown}
                    />
                )}

                {overlay === OverlayType.EMAIL_NOT_CONFIRMED && (
                    <ConfirmEmailForm
                        onClose={() => {
                            clearQueryParams(['emailConfirmed', 'email'])
                            setOverlay(OverlayType.NONE)
                        }}
                        onResend={onResend}
                        resendMessage={resentEmailMessage}
                        resent={resentEmail}
                        failed={true}
                        countDown={resentMessageCountdown}
                        setCountDown={setResentMessageCountdown}
                    />
                )}

                {overlay === OverlayType.FORGOT_PASSWORD && (
                    <ForgotPasswordForm apiURL={apiURL} onClose={() => setOverlay(OverlayType.NONE)} env={env} />
                )}

                {userData?.ftue.mode === 'user' && !userData.ftue.welcomeUser && area === AreaType.WEBSITE && (
                    <WelcomeUserForm
                        username={userName}
                        hideRating={userData.game_settings?.friendlyMode || false}
                        onHideRatingChange={onHideRatingHandler}
                        onClose={() => {
                            setUserData({ ...userData, ftue: { ...userData.ftue, welcomeUser: true } })
                            updateFTUE({ welcomeUser: true }, apiURL, GetCookie('jwt'))
                        }}
                    />
                )}

                {overlay === OverlayType.RESET_PASSWORD && (
                    <ResetPasswordForm
                        apiURL={apiURL}
                        onClose={() => {
                            clearQueryParams(['resetPassword', 'token'])
                            setOverlay(OverlayType.SIGNIN)
                        }}
                        newPasswordToken={lastToken}
                    />
                )}

                {overlay === OverlayType.JOIN_US && (
                    <JoinUsForm
                        onClose={() => setOverlay(OverlayType.NONE)}
                        onSignUp={() => setOverlay(OverlayType.SIGNUP)}
                        onSignIn={() => setOverlay(OverlayType.SIGNIN)}
                    />
                )}

                {overlay === OverlayType.DIVE_IN && (
                    <DiveInForm
                        onClose={() => setOverlay(OverlayType.NONE)}
                        onSignUp={() => setOverlay(OverlayType.SIGNUP)}
                        onSignIn={() => setOverlay(OverlayType.SIGNIN)}
                    />
                )}

                {overlay === OverlayType.ERROR && (
                    <ErrorForm onClose={onErrorClose} onSignIn={onErrorGoToSignIn} onTryAgain={onErrorTryAgain} />
                )}

                {userData && overlay === OverlayType.SETTINGS && (
                    <SettingsDialog
                        apiURL={apiURL}
                        settings={userData.game_settings}
                        groups={userData.groups}
                        openJoinUsForm={() => setOverlay(OverlayType.JOIN_US)}
                        setSettings={(settings) => {
                            localSettingsUpdate(settings)
                            onSettingsChange(settings)
                        }}
                        onClose={(settings) => {
                            if (settings) {
                                userData.game_settings = settings
                            }
                            setOverlay(OverlayType.NONE)
                        }}
                    />
                )}
                <CookiesDialog Link={Link} websiteURL={websiteURL} apiURL={apiURL} area={area} />
            </Fragment>
        </Paper>
    )
}

export default forwardRef(GlobalHeader)
