import { Box, CircularProgress, LinearProgress, Stack, Typography } from '@mui/material'
import { debounce } from 'lodash-es'
import uniqBy from 'lodash-es/uniqBy'
import { useEffect, useMemo, useRef, useState } from 'react'
import { UserResult } from '../../../components/userSearchBar/UserResult'
import { UserSearchBar } from '../../../components/userSearchBar/UserSearchBar'
import { UserSearchResult, getPlayers } from '../../../sharedComponents/src/globalHeader/functions/ams-functions'
import { useStoreState } from '../../../store/hooks'

export type PlayFriendsPanelProps = {
    onClose: () => void
    loggedIn: boolean
    setChallengee: (challengee: UserSearchResult | undefined) => void
}

function PlayFriendsPanel({ setChallengee }: PlayFriendsPanelProps) {
    const token = useStoreState((state) => state.token)

    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState<UserSearchResult[]>([])
    const [loading, setLoading] = useState(false)
    const [init, setInit] = useState(false)
    const [page, setPage] = useState(0)
    const [filter, setFilter] = useState('blitz')

    const playersListRef = useRef<HTMLDivElement>(null)

    const fetchUsers = async (token, page, searchTerm, filter, loading) => {
        if (!token || loading) return

        setLoading(true)
        const pageSize = 50

        try {
            const users = await getPlayers(
                import.meta.env.VITE_REACT_APP_AMS_URL,
                token,
                pageSize * (page + 1),
                searchTerm,
                filter,
            )

            setSearchResults((prevResults) => {
                if (page === 0) {
                    return users || []
                } else {
                    return uniqBy([...prevResults, ...(users || [])], 'id')
                }
            })
        } catch (error) {
            console.error('Failed to fetch users:', error)
        } finally {
            setLoading(false)
            setInit(true)
        }
    }

    const debouncedFetch = useMemo(
        () =>
            debounce((token, page, searchTerm, filter, loading) => {
                fetchUsers(token, page, searchTerm, filter, loading)
            }, 500),
        [],
    )

    const handleScroll = () => {
        if (playersListRef.current) {
            const { scrollTop, clientHeight, scrollHeight } = playersListRef.current
            if (scrollTop + clientHeight >= scrollHeight - 1 && !loading) {
                setPage((prevPage) => prevPage + 1)
            }
        }
    }

    useEffect(() => {
        const element = playersListRef.current
        if (element) {
            element.addEventListener('scroll', handleScroll)
        }
        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll)
            }
        }
    }, [loading])

    useEffect(() => {
        setSearchResults([])
        setPage(0)
        setLoading(true)
        debouncedFetch(token, 0, searchTerm, filter, false)
    }, [searchTerm, filter])

    useEffect(() => {
        page && debouncedFetch(token, page, searchTerm, filter, loading)
    }, [page])

    return (
        <Box sx={{ display: 'flex', flexGrow: 1, minHeight: 0, flexFlow: 'column' }}>
            <UserSearchBar
                searchTerm={searchTerm}
                onSearchChange={setSearchTerm}
                filter={filter}
                onFilterChange={setFilter}
            />
            <Stack sx={{ minHeight: 0, flex: 1, overflowY: 'auto', margin: '1rem -1rem 0' }} ref={playersListRef}>
                {(page === 0 && loading) || !init ? (
                    <CircularProgress style={{ width: '100px', height: '100px' }} sx={{ m: 'auto auto' }} />
                ) : searchResults.length > 0 ? (
                    searchResults.map((user) => (
                        <UserResult
                            filter={filter}
                            user={user}
                            onChallengeClick={() => setChallengee(user)}
                            key={user.id}
                        />
                    ))
                ) : (
                    <Typography sx={{ textAlign: 'center' }}>No results</Typography>
                )}

                <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                    {loading && searchResults.length > 0 && <LinearProgress sx={{ height: '6px' }} />}
                </Box>
            </Stack>
        </Box>
    )
}

export default PlayFriendsPanel
