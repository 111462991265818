export function Classical() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 16.3071C13.0356 16.3071 16.3071 13.0356 16.3071 9C16.3071 4.96438 13.0356 1.69286 9 1.69286C4.96438 1.69286 1.69286 4.96438 1.69286 9C1.69286 13.0356 4.96438 16.3071 9 16.3071ZM9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8323 14.4612C11.4465 13.1099 11.85 11.1799 11.85 9C11.85 6.82006 11.4465 4.89007 10.8323 3.53879C10.1812 2.10627 9.46558 1.65 9 1.65C8.53442 1.65 7.81883 2.10627 7.16768 3.53879C6.55346 4.89007 6.15 6.82006 6.15 9C6.15 11.1799 6.55346 13.1099 7.16768 14.4612C7.81883 15.8937 8.53442 16.35 9 16.35C9.46558 16.35 10.1812 15.8937 10.8323 14.4612ZM9 17.25C11.0711 17.25 12.75 13.5563 12.75 9C12.75 4.44365 11.0711 0.75 9 0.75C6.92893 0.75 5.25 4.44365 5.25 9C5.25 13.5563 6.92893 17.25 9 17.25Z"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.5 9.4498H1.5V8.5498H16.5V9.4498Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.53857 3.33214C4.88985 3.94636 6.81984 4.34982 8.99978 4.34982C11.1797 4.34982 13.1097 3.94636 14.461 3.33214C14.5894 3.27379 14.7099 3.21492 14.823 3.15576C15.0412 3.37325 15.2474 3.60289 15.4403 3.84357C13.9284 4.70088 11.6051 5.24982 8.99978 5.24982C6.39445 5.24982 4.07118 4.70088 2.55924 3.84357C2.75218 3.60289 2.95833 3.37325 3.1766 3.15576C3.28967 3.21492 3.4102 3.27379 3.53857 3.33214ZM15.8218 13.6404C14.3369 12.6501 11.8354 11.9998 8.99978 11.9998C6.16418 11.9998 3.66271 12.6501 2.17773 13.6404C2.34721 13.8891 2.52992 14.128 2.72485 14.3562C2.95198 14.2091 3.22134 14.0617 3.53857 13.9175C4.88985 13.3033 6.81984 12.8998 8.99978 12.8998C11.1797 12.8998 13.1097 13.3033 14.461 13.9175C14.7782 14.0617 15.0476 14.2091 15.2747 14.3562C15.4696 14.128 15.6524 13.8891 15.8218 13.6404Z"
            />
        </svg>
    )
}
