const PlayIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.4335 6.71707C23.4339 6.93641 23.3797 7.15241 23.2759 7.34561C23.1721 7.53882 23.0218 7.70316 22.8386 7.82383C22.6555 7.9445 22.4452 8.01772 22.2267 8.03688C22.0082 8.05605 21.7883 8.02056 21.587 7.93361L18.2923 15.8813H5.12614L1.83408 7.93731C1.53657 8.06218 1.20368 8.07397 0.898084 7.97045C0.592492 7.86694 0.335291 7.65526 0.174918 7.37529C0.0145452 7.09532 -0.0379324 6.76638 0.027368 6.45041C0.0926683 6.13444 0.27124 5.85324 0.529455 5.65978C0.787669 5.46632 1.10771 5.37394 1.4293 5.40004C1.75089 5.42614 2.05184 5.56892 2.27548 5.80149C2.49912 6.03406 2.63 6.34037 2.6435 6.66274C2.65699 6.98511 2.55215 7.30128 2.34873 7.55172L6.95042 11.3177L8.05694 4.63203C7.74544 4.57675 7.46409 4.41162 7.26394 4.16661C7.06379 3.92161 6.95812 3.61296 6.9661 3.2967C6.97408 2.98044 7.09518 2.67751 7.30743 2.44291C7.51968 2.20831 7.809 2.05758 8.12289 2.01808C8.43678 1.97857 8.75443 2.05292 9.01819 2.22761C9.28194 2.40231 9.47433 2.66578 9.56042 2.9702C9.64652 3.27463 9.62061 3.59983 9.48742 3.88679C9.35422 4.17375 9.12255 4.40344 8.83446 4.53418L11.7071 10.7227L14.5855 4.51831C14.2992 4.38327 14.0708 4.14999 13.9419 3.86086C13.813 3.57173 13.792 3.24593 13.8829 2.94269C13.9738 2.63944 14.1705 2.37886 14.4372 2.20831C14.7039 2.03776 15.0229 1.96855 15.3363 2.01326C15.6497 2.05797 15.9367 2.21363 16.1451 2.45196C16.3534 2.69029 16.4694 2.99546 16.4719 3.31203C16.4743 3.62859 16.3631 3.93554 16.1585 4.17708C15.9539 4.41863 15.6694 4.57874 15.3567 4.62833L16.4637 11.3151L21.0792 7.54379C20.9471 7.37888 20.8564 7.1847 20.8147 6.97753C20.7731 6.77035 20.7817 6.55622 20.8399 6.35306C20.898 6.1499 21.004 5.96364 21.149 5.80988C21.2939 5.65612 21.4736 5.53935 21.673 5.46933C21.8724 5.39932 22.0857 5.37811 22.2949 5.40748C22.5042 5.43685 22.7034 5.51594 22.8758 5.63814C23.0482 5.76033 23.1888 5.92207 23.2858 6.10979C23.3828 6.29751 23.4335 6.50576 23.4335 6.71707ZM19.6431 19.4775H3.77525V22.6511H19.6431V19.4775ZM18.1754 16.7271H5.25784V18.6312H18.1754V16.7271Z"
            fill="white"
        />
    </svg>
)

PlayIcon.displayName = 'PlayIcon'

export default PlayIcon
