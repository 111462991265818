const HintIcon: React.FC = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.9553 5.25958C22.2765 5.26226 24.5017 6.22308 26.143 7.93125C27.7843 9.63952 28.7074 11.9556 28.71 14.3713C28.7096 16.5491 27.9607 18.6548 26.5986 20.3078C26.2736 20.7005 25.9177 21.0643 25.5345 21.3953L25.3907 21.5425C24.0881 23.1165 23.2171 25.0267 22.8695 27.0724H17.0411C16.6919 25.0278 15.8221 23.1181 14.5223 21.5425L14.3761 21.3953C13.9937 21.0643 13.6386 20.7005 13.3144 20.3078C11.9497 18.6561 11.1989 16.5499 11.1982 14.3713C11.2008 11.9556 12.124 9.63952 13.7653 7.93125C15.4065 6.22308 17.6318 5.26226 19.9529 5.25958M19.9529 4.01244C17.3132 4.01244 14.7817 5.10377 12.9151 7.0465C11.0487 8.98913 10 11.624 10 14.3713C9.99574 16.8474 10.8462 19.2428 12.3966 21.121C12.7617 21.571 13.1626 21.9882 13.5948 22.3681C14.7938 23.8194 15.5821 25.5888 15.8715 27.479C15.9147 27.7608 15.9483 28.0453 15.9675 28.3321H23.9072C23.9265 28.0453 23.96 27.7608 24.0031 27.479C24.2945 25.5822 25.0888 23.808 26.2966 22.3557C26.729 21.9756 27.1298 21.5585 27.495 21.1085C29.0452 19.2303 29.8958 16.835 29.8915 14.3589C29.8915 11.6115 28.8429 8.97668 26.9763 7.03405C25.1099 5.09133 22.5782 4 19.9386 4L19.9529 4.01244Z"
                fill="white"
            />
            <path
                d="M23.3754 30.5709H16.514C16.444 30.5712 16.3745 30.5571 16.3097 30.5295C16.2448 30.5018 16.1859 30.461 16.1362 30.4096C16.0865 30.3581 16.0471 30.297 16.0203 30.2296C15.9934 30.1623 15.9796 30.09 15.9796 30.0172C15.9796 29.8696 16.0359 29.7281 16.1361 29.6238C16.2363 29.5195 16.3722 29.4609 16.514 29.4609H23.3754C23.5167 29.4615 23.652 29.5205 23.7517 29.6247C23.8514 29.7289 23.9074 29.87 23.9074 30.0172C23.9074 30.164 23.8513 30.3049 23.7516 30.4087C23.6518 30.5125 23.5164 30.5709 23.3754 30.5709Z"
                fill="white"
            />
            <path
                d="M23.3775 32.7126H16.516C16.3756 32.71 16.2417 32.6499 16.1435 32.5453C16.0453 32.4408 15.9906 32.3001 15.9912 32.1539C15.9912 32.0063 16.0476 31.8649 16.1478 31.7605C16.248 31.6563 16.384 31.5977 16.5257 31.5977H23.387C23.525 31.6034 23.6555 31.6646 23.751 31.7683C23.8467 31.872 23.9 32.0102 23.8999 32.1539C23.9005 32.2997 23.8462 32.44 23.7484 32.5444C23.6508 32.649 23.5175 32.7093 23.3775 32.7126Z"
                fill="white"
            />
            <path
                d="M22.22 34.8748H17.5371C17.4639 34.8751 17.3913 34.8603 17.3236 34.8315C17.2559 34.8025 17.1943 34.7599 17.1424 34.7061C17.0905 34.6524 17.0494 34.5884 17.0213 34.5181C16.9931 34.4478 16.9787 34.3723 16.9787 34.296C16.9787 34.1419 17.0375 33.9941 17.1422 33.8851C17.2469 33.7762 17.389 33.7148 17.5371 33.7148H22.22C22.2932 33.7148 22.3657 33.73 22.4333 33.7591C22.5009 33.7884 22.5623 33.8312 22.6139 33.8853C22.6656 33.9392 22.7065 34.0034 22.7344 34.0738C22.7621 34.1443 22.7763 34.2199 22.776 34.296C22.776 34.4496 22.7175 34.5967 22.6132 34.7052C22.5088 34.8137 22.3674 34.8748 22.22 34.8748Z"
                fill="white"
            />
            <path
                d="M13.9155 13.6954C13.7566 13.6954 13.6042 13.6297 13.4918 13.5127C13.3794 13.3957 13.3163 13.2371 13.3163 13.0718C13.3195 11.4743 13.9304 9.94312 15.0156 8.81326C16.1007 7.68341 17.5716 7.04695 19.1065 7.04297C19.2653 7.04297 19.4178 7.10868 19.5302 7.22565C19.6424 7.34253 19.7056 7.50112 19.7056 7.66658C19.7059 7.74852 19.6906 7.82976 19.6606 7.90562C19.6307 7.98138 19.5865 8.05027 19.5309 8.10821C19.4751 8.16615 19.4089 8.21215 19.3361 8.24331C19.2633 8.27457 19.1852 8.2905 19.1065 8.2901C17.8871 8.29149 16.7181 8.79614 15.8558 9.69354C14.9936 10.5909 14.5087 11.8077 14.5074 13.0767C14.5068 13.2402 14.4443 13.3967 14.3335 13.5126C14.2228 13.6284 14.0725 13.694 13.9155 13.6954Z"
                fill="white"
            />
        </svg>
    )
}

HintIcon.displayName = 'HintIcon'

export default HintIcon
