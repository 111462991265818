import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useEffect } from 'react'
import { isValidFen } from '../../../../chess/fen'
import { BasePositionFEN } from '../../../../chess/positionPresets'
import { useStoreActions, useStoreState } from '../../../../store/hooks'

interface IProps {
    validFen: boolean
    setValidFen: (value: boolean) => void
}

const FenInput: React.FC<IProps> = (props) => {
    const { validFen, setValidFen } = props
    const playComputerFen = useStoreState((state) => state.analysisMode.playComputerFen)

    const setAnalysisFen = useStoreActions((state) => state.analysisMode.setFen)
    const setPlayComputerFen = useStoreActions((state) => state.analysisMode.setPlayComputerFen)

    useEffect(() => {
        return () => {
            setValidFen(true)
        }
    }, [])

    const onFenChange = (fen: string) => {
        if (fen === '') {
            setValidFen(true)
        } else if (isValidFen(fen)) {
            setValidFen(true)
            setPlayComputerFen(fen)
        } else {
            setValidFen(false)
        }
        setAnalysisFen(fen)
    }

    return (
        <Stack>
            <Typography variant="h6" pb={1}>
                Starting Position (Paste FEN)
            </Typography>
            <TextField
                error={!validFen}
                helperText={!validFen ? 'Invalid FEN' : ''}
                placeholder="paste FEN here"
                fullWidth
                value={playComputerFen === BasePositionFEN ? '' : playComputerFen}
                onChange={(e) => onFenChange(e.target.value)}
                sx={{
                    '& .Mui-focused .MuiIconButton-root': { color: 'secondary.main' },
                }}
            />
            {(playComputerFen !== '' || !validFen) && (
                <Button
                    fullWidth
                    size="small"
                    variant="text"
                    color="primary"
                    onClick={() => {
                        setPlayComputerFen('')
                        setAnalysisFen('')
                        setValidFen(true)
                    }}
                >
                    Reset Starting Position
                </Button>
            )}
        </Stack>
    )
}

FenInput.displayName = 'FenInput'

export default FenInput
