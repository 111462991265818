import { Button } from '@mui/material'
import Box from '@mui/material/Box'

const buttonContainerStyles = {
    display: 'flex',
    flexGrow: 0,
    justifyContent: 'center',
    gap: 1,
}

interface IProps {
    disableNextButton: boolean
    isPlayerInteractedWithBoard: boolean
    onAnalysis: () => void
    onNext: () => void
}

const PuzzleButtons: React.FC<IProps> = (props) => {
    const { disableNextButton, isPlayerInteractedWithBoard, onAnalysis, onNext } = props

    return (
        <Box display="flex" justifyContent="center" width="100%" gap="0.5rem">
            <Button onClick={onAnalysis} disabled={!isPlayerInteractedWithBoard}>
                Analysis
            </Button>
            <Button onClick={onNext} disabled={disableNextButton || !isPlayerInteractedWithBoard}>
                Next
            </Button>
        </Box>
    )
}

PuzzleButtons.displayName = 'PuzzleButtons'

export default PuzzleButtons
