const convertMinutesToTime = (minutes) => {
    const totalSeconds = minutes * 60
    const hours = Math.floor(totalSeconds / 3600)
    const remainingMinutes = Math.floor((totalSeconds % 3600) / 60)
    const remainingSeconds = Math.floor(totalSeconds % 60)

    const formattedHours = String(hours).padStart(2, '0')
    const formattedMinutes = String(remainingMinutes).padStart(2, '0')
    const formattedSeconds = String(remainingSeconds).padStart(2, '0')

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export default convertMinutesToTime