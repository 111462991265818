import { boardColors } from '../globalHeader/theme/colors'

export interface BoardColorProps {
    color: string
}

export function BoardColor(props: BoardColorProps) {
    const { color } = props
    const boardColor = boardColors[color] || boardColors.default

    // Image background color
    let main: string = boardColor.main.backgroundColor
    let secondary: string = boardColor.secondary.backgroundColor
    let backgroundImageMain: string | undefined = boardColor.main.backgroundImage
    let backgroundImageSecondary: string | undefined = boardColor.secondary.backgroundImage

    // Extract URL from backgroundImage if it exists
    if (backgroundImageMain) {
        backgroundImageMain = extractURL(backgroundImageMain)
        main = 'none'
    }

    // Extract URL from backgroundImageSecondary if it exists
    if (backgroundImageSecondary) {
        backgroundImageSecondary = extractURL(backgroundImageSecondary)
        secondary = 'none'
    }

    // Function to extract URL from background image property
    function extractURL(backgroundImage: string): string {
        const urlRegex = /url\(['"]?([^'"]+?)['"]?\)/
        const matches = backgroundImage.match(urlRegex)
        if (matches && matches.length > 1) {
            return matches[1]
        }
        return ''
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108" style={{ width: '100%', height: '100%' }}>
            <defs>
                <clipPath id="clip-diagonal-left">
                    <polygon points="0,0 108,0 0,108" />
                </clipPath>
                <clipPath id="clip-diagonal-right">
                    <polygon points="108,0 108,108 0,108" />
                </clipPath>
            </defs>

            <rect x="0" y="0" width="108" height="108" fill={main} />
            {backgroundImageMain && (
                <image
                    href={backgroundImageMain}
                    x="0"
                    y="0"
                    width="108"
                    height="108"
                    preserveAspectRatio="xMidYMid slice"
                    clipPath="url(#clip-diagonal-left)"
                />
            )}

            <rect x="0" y="0" width="108" height="108" fill={secondary} clipPath="url(#clip-diagonal-right)" />
            {backgroundImageSecondary && (
                <image
                    href={backgroundImageSecondary}
                    x="0"
                    y="0"
                    width="108"
                    height="108"
                    preserveAspectRatio="xMidYMid slice"
                    clipPath="url(#clip-diagonal-right)"
                />
            )}
        </svg>
    )
}
