import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Fade, IconButton, LinearProgress, Palette, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { oppositePieces } from '../../../chess/basics'
import {
    addStrMoveToMainline,
    createRootGameTree,
    getMoveById,
    getNextPosition,
    getStartingPosition,
} from '../../../chess/gameTree'
import { Color, GameTreeMove, GameTreePosition, Move } from '../../../chess/types'
import { Evaluation } from './Evaluation'
import { AnalysisResult } from './useStockFish'

const expandButtonStyles = (palette: Palette) => ({
    width: '1.5rem',
    height: '1.5rem',
    padding: 0,
})

export type RenderVariationProps = {
    analysisResults: AnalysisResult
    onMoveSelected: (move: Move | Move[]) => void
    colorToMove: number
    isCalculating: boolean
}

export function StockfishLine(props: RenderVariationProps) {
    const { palette } = useTheme()

    let { analysisResults, onMoveSelected, colorToMove, isCalculating } = props
    const [expanded, setExpanded] = React.useState<boolean>(false)
    let output: JSX.Element[] = []
    let gameTree = createRootGameTree(analysisResults.startFen)
    try {
        analysisResults.moves.forEach((move) => {
            addStrMoveToMainline(gameTree, move, analysisResults.startFen)
        })
    } catch (error) {
        console.log(error)
    }
    let pos: GameTreePosition = getStartingPosition(gameTree)
    // const FIRSTPOS: GameTreePosition = getStartingPosition(gameTree)
    const writeMove = (move: GameTreeMove) => {
        const chessPiece = move.displayString.match(/[♔-♟]/)

        if (!!move) {
            output.push(
                <Box
                    key={move.id}
                    // onClick={() => {
                    // DISABLED DUE TO DEV-2183
                    // ===========================
                    // get all moves from root to this move
                    // let moves: Move[] = []
                    // let pos: GameTreePosition = FIRSTPOS
                    // while (pos.id !== move.nextPositionId) {
                    //     const nextMove = getMoveById(gameTree, pos.nextMoveIds[0])
                    //     if (!!nextMove) {
                    //         moves.push(nextMove.move)
                    //         pos = getPositionById(gameTree, nextMove.nextPositionId)
                    //     } else {
                    //         break
                    //     }
                    // }
                    // onMoveSelected(moves)
                    // }}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '30px',
                        whiteSpace: 'nowrap',
                        // cursor: 'pointer',
                        paddingRight: '0.375rem',
                        fontSize: '16px',
                    }}
                >
                    <Typography variant="body2" sx={{ verticalAlign: 'middle', display: 'flex' }}>
                        {move.displayString.split('').map((char, index) => {
                            const isChessPiece = chessPiece && char === chessPiece[0]

                            if (isChessPiece) {
                                return (
                                    <Box display="inline-block" mt="-2px" fontSize="30px" key={index}>
                                        {oppositePieces[char]}
                                    </Box>
                                )
                            }

                            return <React.Fragment key={index}>{char}</React.Fragment>
                        })}
                    </Typography>

                    {move.annotation ? (
                        <Typography component={'span'} className="annotation" variant="body2">
                            {' '}
                            {move.annotation}{' '}
                        </Typography>
                    ) : null}
                </Box>,
            )
        }
    }

    const writeMoveNumber = (pos: GameTreePosition) => {
        output.push(
            <Typography
                sx={{ height: '30px', display: 'flex', alignItems: 'center' }}
                variant="body2"
                key={Math.random()}
            >
                {pos.position.moveNum}.
            </Typography>,
        )
    }
    const writeVariation = (pos: GameTreePosition) => {
        while (pos.nextMoveIds.length > 0) {
            const nextMoveId = pos.nextMoveIds[0]

            // move number is only output before a white move
            if (pos.position.turn === Color.White) {
                writeMoveNumber(pos)
            }

            const nextMove = getMoveById(gameTree, nextMoveId)
            if (nextMove) writeMove(nextMove)

            const nextPos = getNextPosition(gameTree, pos.id)
            if (!nextPos) break
            pos = nextPos
        }
    }

    writeVariation(pos)

    const scoreType = analysisResults.scoreType
    const signedScore = colorToMove * parseInt(analysisResults.score)
    return (
        <Stack direction="row" gap={1}>
            <Box
                overflow="hidden"
                sx={{ flexWrap: 'wrap', display: 'flex', flexDirection: 'row' }}
                className={`StockFishEngine-line ${expanded ? 'expanded' : ''}`}
                minHeight="30px"
            >
                {isCalculating ? (
                    <Fade in={true} timeout={1000}>
                        <Box ml={1} sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    </Fade>
                ) : (
                    <>
                        {Boolean(analysisResults.scoreType) || output.length > 0 ? (
                            <>
                                <Evaluation score={signedScore} scoreType={scoreType} />
                                {output}
                            </>
                        ) : null}
                        {/* ) : (
                            <Typography ml={1} color="text.disabled" variant="body2">
                                No legal moves to analyze
                            </Typography>
                        )} */}
                    </>
                )}
            </Box>

            <IconButton
                disabled={!Boolean(analysisResults.scoreType)}
                color="primary"
                size="small"
                aria-expanded={expanded}
                onClick={() => setExpanded(!expanded)}
                style={expandButtonStyles(palette)}
            >
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
        </Stack>
    )
}
