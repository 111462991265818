export const WhitePieces = [
    {
        piece: 'wP',
        square: 'undefined',
        id: '6',
    },
    {
        piece: 'wN',
        square: 'undefined',
        id: '5',
    },
    {
        piece: 'wB',
        square: 'undefined',
        id: '4',
    },
    {
        piece: 'wR',
        square: 'undefined',
        id: '3',
    },
    {
        piece: 'wQ',
        square: 'undefined',
        id: '2',
    },

    {
        piece: 'wK',
        square: 'undefined',
        id: '1',
    },
]
