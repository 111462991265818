import { moveToShortNotation, squareByName } from './basics'
import { generateMoves, makeMove } from './core'
import { positionFromFEN, positionToFEN } from './fen'
import { GameTree, GameTreeMove, GameTreePosition, Move, Piece } from './types'

const idchars = 'abcdefghijklmnopqrstuvwxyz0123456789'

// creates a 3 character randomstring that has not been used in the game tree yet
const createUnusedId = (gameTree: GameTree) => {
    let move, pos, id
    do {
        let nid = [...Array(3).keys()].map((v) => idchars.charAt(Math.floor(Math.random() * 36))).join('')
        move = gameTree.moves.find((m) => m.id === nid)
        pos = gameTree.positions.find((p) => p.id === nid)
        id = nid
    } while (move !== undefined || pos !== undefined)
    return id
}

// gets the position with the passed id from the passed game tree
export const getPositionById = (gameTree: GameTree, id: string) => {
    const pos = gameTree.positions.find((p) => p.id === id)
    if (pos !== undefined) return pos
    console.log(gameTree)
    throw Error('no position with id ' + id)
}

// gets the move with the passed id from the passed game tree
export const getMoveById = (gameTree: GameTree, id: string) => {
    return gameTree.moves.find((m) => m.id === id)
}

// gets the starting position from the passed game tree
export const getStartingPosition = (gameTree: GameTree): GameTreePosition => {
    const root = gameTree.positions.find((p) => !p.previousMoveId)
    if (root) return root
    throw new Error('this game tree has no root')
}

// creates a root GameTreePosition from the passed FEN with no moves
export const createRootGameTree = (fen: string): GameTree => {
    const pos = positionFromFEN(fen)

    const tree: GameTree = {
        moves: [],
        positions: [],
    }
    tree.positions.push({
        id: createUnusedId(tree),
        position: pos,
        nextMoveIds: [],
    })

    return tree
}

// get the last position of the mainline from a root position
export const getLastPosition = (gameTree: GameTree): GameTreePosition => {
    let p: GameTreePosition | undefined = getStartingPosition(gameTree)

    // find last position
    while (p && p.nextMoveIds.length > 0) {
        const move = getMoveById(gameTree, p.nextMoveIds[0])
        // if (!move) throw Error('broken move ID in game tree in position' + JSON.stringify(p))
        p = getPositionById(gameTree, move!.nextPositionId)
    }
    return p!
}

// appends the move to a passed positon
export const addMoveToMainline = (
    gameTree: GameTree,
    m: Move,
    opening?: string,
    annotation?: string,
    clock?: number,
    moveTime?: number,
    timestamp?: number,
): GameTreePosition => {
    return addMoveToGTPosition(gameTree, getLastPosition(gameTree), m, opening, annotation, clock, moveTime, timestamp)
}

// appends the move to a passed positon
export const addMoveToGTPosition = (
    gameTree: GameTree,
    p: GameTreePosition,
    m: Move,
    opening?: string,
    annotation?: string,
    clock?: number,
    moveTime?: number,
    timestamp?: number,
): GameTreePosition => {
    // create the node with the resulting position:
    const newPos: GameTreePosition = {
        id: createUnusedId(gameTree),
        position: makeMove(p.position, m),
        nextMoveIds: [],
    }

    // create the move node
    const newMove: GameTreeMove = {
        id: createUnusedId(gameTree),
        previousPositionId: p.id,
        nextPositionId: newPos.id,
        move: m,
        displayString: moveToShortNotation(p.position, m),
        clock,
        moveTime,
        timestamp,
    }

    if (opening) newMove.opening = opening
    if (annotation) newMove.annotation = annotation

    newPos.previousMoveId = newMove.id // link them

    // add move to game tree position
    p.nextMoveIds.push(newMove.id)

    gameTree.moves.push(newMove)
    gameTree.positions.push(newPos)
    return newPos
}

// will append a move in long algebraic notation (2 char from, 2 char to, 1? char promotion) to the mainline
export const addStrMoveToMainline = (
    gameTree: GameTree,
    move: string,
    opening?: string,
    annotation?: string,
    clock?: number,
    moveTime?: number,
    timestamp?: number,
): GameTreePosition => {
    const p = getLastPosition(gameTree)
    return addStrMoveToGTPosition(gameTree, p, move, opening, annotation, clock, moveTime, timestamp)
}

// will append a move in long algebraic notation (2 char from, 2 char to, 1? char promotion) to the position
export const addStrMoveToGTPosition = (
    gameTree: GameTree,
    p: GameTreePosition,
    move: string,
    opening?: string,
    annotation?: string,
    clock?: number,
    moveTime?: number,
    timestamp?: number,
): GameTreePosition => {
    const allMoves = generateMoves(p.position)
    // we need to identify the move that is the one we got
    const from = squareByName(move.substring(0, 2))
    const to = squareByName(move.substring(2, 4))

    const fittingMoves = allMoves.filter((m: Move) => {
        return m.from === from && m.to === to
    })

    if (fittingMoves.length === 1)
        return addMoveToGTPosition(gameTree, p, fittingMoves[0], opening, annotation, clock, moveTime, timestamp)
    // if there are more than one possible move it must be promotion (each promotion is one possible move)
    if (fittingMoves.length > 0) {
        const promotionStr = move.substring(4).toLowerCase()
        let m: Move | undefined
        if (promotionStr === 'q') m = fittingMoves.find((m) => m.promotion && Math.abs(m.promotion) === Piece.Queen)
        else if (promotionStr === 'r') m = fittingMoves.find((m) => m.promotion && Math.abs(m.promotion) === Piece.Rook)
        else if (promotionStr === 'n')
            m = fittingMoves.find((m) => m.promotion && Math.abs(m.promotion) === Piece.Knight)
        else if (promotionStr === 'b')
            m = fittingMoves.find((m) => m.promotion && Math.abs(m.promotion) === Piece.Bishop)
        if (m) return addMoveToGTPosition(gameTree, p, m, opening, annotation, clock, moveTime, timestamp)
    }
    throw Error(`the move ${move} is not a legal move`)
}

export const generateGameTreeFromMoves = (moves: string[], fen: string): GameTree => {
    const tree = createRootGameTree(fen)
    moves.forEach((m) => {
        addStrMoveToMainline(tree, m)
    })
    return tree
}

// remove all variations from a game tree
export const removeVariationsFromGameTree = (gameTree: GameTree): GameTree => {
    const root = getStartingPosition(gameTree)
    const newTree = createRootGameTree(positionToFEN(root.position))
    let node = root
    while (node.nextMoveIds.length > 0) {
        const move = getMoveById(gameTree, node.nextMoveIds[0])
        if (!move) throw Error('broken move ID in game tree in position' + JSON.stringify(node))
        addMoveToGTPosition(newTree, getLastPosition(newTree), move.move, move.opening, move.annotation)
        node = getPositionById(gameTree, move.nextPositionId)
    }
    return newTree
}

export function getMovesByIDs(gameTree: GameTree, ids: string[]): GameTreeMove[] {
    return ids.map((id) => getMoveById(gameTree, id)).filter((m) => m !== undefined) as GameTreeMove[]
}

export function getMoveId(gameTree: GameTree, move: Move): string | undefined {
    const moveNode = gameTree.moves.find((m) => m.move === move)
    if (moveNode) return moveNode.id
    return undefined
}

export const getPreviousPosition = (gameTree: GameTree, currentPositionId: string): GameTreePosition | undefined => {
    const currentPosition = getPositionById(gameTree, currentPositionId)
    if (!currentPosition || !currentPosition.previousMoveId) return undefined
    const previousMove = getMoveById(gameTree, currentPosition.previousMoveId)
    if (!previousMove || !previousMove.previousPositionId) return undefined
    return getPositionById(gameTree, previousMove.previousPositionId)
}

export const getNextPosition = (gameTree: GameTree, currentPositionId: string): GameTreePosition | undefined => {
    const currentPosition = getPositionById(gameTree, currentPositionId)
    if (!currentPosition || !currentPosition.nextMoveIds.length) return undefined
    const nextMove = getMoveById(gameTree, currentPosition.nextMoveIds[0])
    if (!nextMove || !nextMove.nextPositionId) return undefined
    return getPositionById(gameTree, nextMove.nextPositionId)
}

export const undoLastMove = (gameTree: GameTree): GameTreePosition => {
    gameTree.positions.pop()
    gameTree.moves.pop()
    gameTree.positions[gameTree.positions.length - 1].nextMoveIds = []
    return getLastPosition(gameTree)
}
