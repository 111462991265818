const FlipIcon: React.FC = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.2933 28.6106V29.1106H26.7933H30.9828L24.9938 35.0847L19.0047 29.1106H23.1942H23.6942V28.6106V16.4955H26.2933V28.6106ZM12.8967 10.5788V10.0788H12.3967H8.20717L14.1963 4.10466L20.1853 10.0788H15.9958H15.4958V10.5788V22.6939H12.8967V10.5788Z"
                stroke="white"
            />
        </svg>
    )
}

FlipIcon.displayName = 'FlipIcon'

export default FlipIcon
