const ShowDescriptionIcon: React.FC = () => {
    return (
        <svg width="17px" height="22px" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.739375 0.742281L0.739756 0.741897C1.06419 0.41464 1.46536 0.25 1.96107 0.25H11.4317L16.75 5.60308V20.0261C16.75 20.5267 16.5861 20.9313 16.2617 21.2579C15.9363 21.5853 15.5346 21.75 15.0389 21.75H1.96107C1.46519 21.75 1.06396 21.5856 0.739565 21.2591C0.414162 20.9316 0.25 20.5265 0.25 20.0261V1.97389C0.25 1.4735 0.414151 1.06892 0.739375 0.742281ZM15.7857 5.86111H11.1786V1.22222V0.972222H10.9286H1.96107C1.69625 0.972222 1.46422 1.08628 1.27054 1.28023L1.27053 1.28023L1.26962 1.28115C1.07687 1.47617 0.964286 1.70898 0.964286 1.97389V20.0261C0.964286 20.291 1.07687 20.5238 1.26962 20.7189L1.27054 20.7198C1.46422 20.9137 1.69625 21.0278 1.96107 21.0278H15.0389C15.3037 21.0278 15.5358 20.9137 15.7295 20.7198L15.7304 20.7189C15.9231 20.5238 16.0357 20.291 16.0357 20.0261V6.11111V5.86111H15.7857ZM3.25 12.25H13.75V12.75H3.25V12.25ZM3.25 16.25H13.75V16.75H3.25V16.25Z"
                stroke="white"
                strokeWidth="0.5"
            />
        </svg>
    )
}

ShowDescriptionIcon.displayName = 'ShowDescriptionIcon'

export default ShowDescriptionIcon
