import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, IconButton, Link, Stack, Typography } from '@mui/material'

const paragraphStyle = {
    color: '#FFFFFF',
    fontSize: '1rem',
    textAlign: 'center',
    width: '100%',
    padding: '0 1rem',
}

const stackStyle = {
    backgroundColor: '#101820',
    minWidth: '338px',
    maxWidth: '338px',
    border: '2px solid #FFFFFF',
}

const linkStyle = {
    color: '#FFFFFF',
    fontFamily: 'Space Grotesk',
    marginTop: '1rem',
    fontSize: '14px',
    textAlign: 'center',
}

const loginLinkStyle = {
    paddingLeft: '10px',
    color: '#FFFFFF',
    fontSize: '14px',
    textDecoration: 'underline',
    cursor: 'pointer',
}

// -------------------------------------------------------

export type JoinUsFormProps = {
    onClose: () => void
    onSignIn: () => void
    onSignUp: () => void
}

function JoinUsForm(props: JoinUsFormProps) {
    const { onClose, onSignUp, onSignIn } = props

    return (
        <Dialog onClose={onClose} open={true} sx={{ zIndex: '1600' }} PaperProps={{ sx: { m: 0 } }}>
            <Stack sx={stackStyle}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={onClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>

                <Stack
                    spacing={3}
                    sx={{
                        p: 2,
                    }}
                >
                    <Typography
                        variant="h1"
                        style={{
                            fontSize: '60px',
                            textAlign: 'center',
                        }}
                    >
                        Join Us!
                    </Typography>

                    <Typography sx={paragraphStyle}>
                        Sign up to access this feature. Becoming an ICC member is FREE, and registering is faster than
                        delivering a checkmate with two rooks.
                    </Typography>

                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button onClick={onSignUp}>Sign Up</Button>
                    </Box>

                    <Box sx={linkStyle}>
                        Already have an account?
                        <Link
                            sx={loginLinkStyle}
                            onClick={() => {
                                onSignIn()
                            }}
                        >
                            Log in
                        </Link>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <Button variant="outlined" onClick={onClose}>
                            Contine as guest
                        </Button>
                    </Box>
                    <Typography
                        sx={{
                            color: '#aaaaaa',
                            fontSize: '14px',
                            textAlign: 'center',
                            marginTop: '2rem',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        P.S. Signing up takes 60 seconds or less
                    </Typography>
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default JoinUsForm
