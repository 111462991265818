import { clonePosition, pieceColor, squareToXY } from './basics'
import { Color, Move, MoveType, Piece, Position, Square } from './types'

function directionScan(
    pos: Position,
    from: Square,
    dx: number,
    dy: number,
    max: number,
    onlyCapture: boolean,
    captureColor: Color | null,
    fn: (sqr: Square, piece: Piece | null) => void,
): void {
    if (max > 7) {
        max = 7
    }
    let [x, y] = squareToXY(from)
    for (let i = 0; i < max; i++) {
        x += dx
        y += dy
        if (x < 0 || y < 0 || x >= 8 || y >= 8) {
            break
        }
        const sqr: Square = y * 8 + x
        const toPiece = pos.board[sqr]
        if (
            (toPiece !== null && toPiece > 0 && (captureColor === null || captureColor <= 0)) ||
            (toPiece !== null && toPiece < 0 && (captureColor === null || captureColor >= 0))
        ) {
            break
        }
        if (!onlyCapture || toPiece !== null) {
            fn(sqr, toPiece)
        }
        if (toPiece !== null) {
            break
        }
    }
}

function basePieceMoveGen(
    pos: Position,
    from: Square,
    piece: Piece,
    fn: (sqr: Square, piece: Piece | null) => void,
): void {
    const absPiece = Math.abs(piece)
    if (absPiece <= 0 || absPiece > 6) {
        return
    }
    let color = pieceColor(piece)
    if (color !== null) {
        color = -color
    }
    if (absPiece === Piece.Knight) {
        directionScan(pos, from, -2, -1, 1, false, color, fn)
        directionScan(pos, from, -1, -2, 1, false, color, fn)
        directionScan(pos, from, 1, -2, 1, false, color, fn)
        directionScan(pos, from, 2, -1, 1, false, color, fn)
        directionScan(pos, from, 2, 1, 1, false, color, fn)
        directionScan(pos, from, 1, 2, 1, false, color, fn)
        directionScan(pos, from, -1, 2, 1, false, color, fn)
        directionScan(pos, from, -2, 1, 1, false, color, fn)
        return
    }
    if (piece === Piece.WhitePawn) {
        const y = squareToXY(from)[1]
        const max = y === 6 ? 2 : 1
        directionScan(pos, from, 0, -1, max, false, null, fn)
        directionScan(pos, from, -1, -1, 1, true, Color.Black, fn)
        directionScan(pos, from, 1, -1, 1, true, Color.Black, fn)
        return
    }
    if (piece === Piece.BlackPawn) {
        const y = squareToXY(from)[1]
        const max = y === 1 ? 2 : 1
        directionScan(pos, from, 0, 1, max, false, null, fn)
        directionScan(pos, from, -1, 1, 1, true, Color.White, fn)
        directionScan(pos, from, 1, 1, 1, true, Color.White, fn)
        return
    }
    let max = 7
    if (absPiece === Piece.King) {
        max = 1
    }
    if (absPiece === Piece.Rook || absPiece === Piece.Queen || absPiece === Piece.King) {
        directionScan(pos, from, 0, -1, max, false, color, fn)
        directionScan(pos, from, 1, 0, max, false, color, fn)
        directionScan(pos, from, 0, 1, max, false, color, fn)
        directionScan(pos, from, -1, 0, max, false, color, fn)
    }
    if (absPiece === Piece.Bishop || absPiece === Piece.Queen || absPiece === Piece.King) {
        directionScan(pos, from, -1, -1, max, false, color, fn)
        directionScan(pos, from, 1, -1, max, false, color, fn)
        directionScan(pos, from, 1, 1, max, false, color, fn)
        directionScan(pos, from, -1, 1, max, false, color, fn)
    }
}

export function makeMove(pos: Position, move: Move): Position {
    const newPos = clonePosition(pos)
    newPos.turn = -pos.turn
    newPos.enPassant = null
    newPos.halfmoveClock++
    if (pos.turn === Color.Black) {
        newPos.moveNum++
    }

    if (newPos.board[move.to] !== null) {
        newPos.halfmoveClock = 0
    }
    newPos.board[move.to] = newPos.board[move.from]
    newPos.board[move.from] = null

    switch (move.type) {
        case MoveType.Normal:
            // already done
            break
        case MoveType.Castling:
            if (squareToXY(move.to)[0] > 4) {
                newPos.board[move.to - 1] = newPos.board[move.to + 1]
                newPos.board[move.to + 1] = null
            } else {
                newPos.board[move.to + 1] = newPos.board[move.to - 2]
                newPos.board[move.to - 2] = null
            }
            break
        case MoveType.EnPassant:
            const epx = squareToXY(move.to)[0]
            const epy = squareToXY(move.from)[1]
            newPos.board[epy * 8 + epx] = null
            break
        case MoveType.Promotion:
            newPos.board[move.to] = move.promotion ? move.promotion : null
            break
    }

    if (pos.castling.whiteLong && (move.from === Square.A1 || move.to === Square.A1)) {
        newPos.castling.whiteLong = false
    }
    if (pos.castling.whiteShort && (move.from === Square.H1 || move.to === Square.H1)) {
        newPos.castling.whiteShort = false
    }
    if ((pos.castling.whiteShort || pos.castling.whiteLong) && move.from === Square.E1) {
        newPos.castling.whiteShort = false
        newPos.castling.whiteLong = false
    }
    if (pos.castling.blackLong && (move.from === Square.A8 || move.to === Square.A8)) {
        newPos.castling.blackLong = false
    }
    if (pos.castling.blackShort && (move.from === Square.H8 || move.to === Square.H8)) {
        newPos.castling.blackShort = false
    }
    if ((pos.castling.blackShort || pos.castling.blackLong) && move.from === Square.E8) {
        newPos.castling.blackShort = false
        newPos.castling.blackLong = false
    }

    const fromPiece = pos.board[move.from]
    if (
        fromPiece !== null &&
        Math.abs(fromPiece) === Piece.Pawn &&
        Math.abs(squareToXY(move.from)[1] - squareToXY(move.to)[1]) === 2
    ) {
        const [x, y] = squareToXY(move.from)
        if (fromPiece < 0) {
            newPos.enPassant = (y + 1) * 8 + x
        } else {
            newPos.enPassant = (y - 1) * 8 + x
        }
    }

    return newPos
}

const promotionPieces = [Piece.Queen, Piece.Rook, Piece.Bishop, Piece.Knight]

export function isAttacked(pos: Position, sqr: Square, byColor: Color): boolean {
    let attacked = false

    basePieceMoveGen(pos, sqr, Piece.Rook * byColor * -1, (_: Square, piece: Piece | null) => {
        attacked = attacked || Math.abs(piece ? piece : 0) === Piece.Rook || Math.abs(piece ? piece : 0) === Piece.Queen
    })
    basePieceMoveGen(pos, sqr, Piece.Bishop * byColor * -1, (_: Square, piece: Piece | null) => {
        attacked =
            attacked || Math.abs(piece ? piece : 0) === Piece.Bishop || Math.abs(piece ? piece : 0) === Piece.Queen
    })
    basePieceMoveGen(pos, sqr, Piece.King * byColor * -1, (_: Square, piece: Piece | null) => {
        attacked = attacked || Math.abs(piece ? piece : 0) === Piece.King
    })
    basePieceMoveGen(pos, sqr, Piece.Knight * byColor * -1, (_: Square, piece: Piece | null) => {
        attacked = attacked || Math.abs(piece ? piece : 0) === Piece.Knight
    })

    if (byColor === Color.Black) {
        directionScan(pos, sqr, -1, -1, 1, true, byColor, (_: Square, piece: Piece | null) => {
            attacked = attacked || Math.abs(piece ? piece : 0) === Piece.Pawn
        })
        directionScan(pos, sqr, 1, -1, 1, true, byColor, (_: Square, piece: Piece | null) => {
            attacked = attacked || Math.abs(piece ? piece : 0) === Piece.Pawn
        })
    } else {
        directionScan(pos, sqr, -1, 1, 1, true, byColor, (_: Square, piece: Piece | null) => {
            attacked = attacked || Math.abs(piece ? piece : 0) === Piece.Pawn
        })
        directionScan(pos, sqr, 1, 1, 1, true, byColor, (_: Square, piece: Piece | null) => {
            attacked = attacked || Math.abs(piece ? piece : 0) === Piece.Pawn
        })
    }

    return attacked
}

export function positionFindFirstPiece(pos: Position, piece: Piece): Square | null {
    for (let sqr: Square = 0; sqr < 64; sqr++) {
        if (pos.board[sqr] === piece) {
            return sqr
        }
    }
    return null
}

export function isInCheck(pos: Position): boolean {
    const kingSqr = positionFindFirstPiece(pos, pos.turn * Piece.King)
    return kingSqr !== null && isAttacked(pos, kingSqr, -pos.turn)
}

export function inCheckOrMate(pos: Position): string {
    const kingSqr = positionFindFirstPiece(pos, pos.turn * Piece.King)
    if (kingSqr !== null && isAttacked(pos, kingSqr, -pos.turn) && generateMoves(pos).length === 0) {
        return '#'
    } else if (kingSqr !== null && isAttacked(pos, kingSqr, -pos.turn)) {
        return '+'
    } else {
        return ''
    }
}

export function generateMoves(pos: Position, from: Square | null = null): Move[] {
    const moves: Move[] = []
    for (let sqr: Square = 0; sqr < 64; sqr++) {
        if (from !== null && sqr !== from) {
            continue
        }
        const piece = pos.board[sqr]
        if (piece === null || piece * pos.turn <= 0) {
            continue
        }
        basePieceMoveGen(pos, sqr, piece, (toSqr: Square) => {
            const move: Move = {
                type: MoveType.Normal,
                from: sqr,
                to: toSqr,
            }
            if (piece === Piece.WhitePawn && squareToXY(toSqr)[1] === 0) {
                for (const piece of promotionPieces) {
                    moves.push({
                        type: MoveType.Promotion,
                        from: sqr,
                        to: toSqr,
                        promotion: piece,
                    })
                }
            } else if (piece === Piece.BlackPawn && squareToXY(toSqr)[1] === 7) {
                for (const piece of promotionPieces) {
                    moves.push({
                        type: MoveType.Promotion,
                        from: sqr,
                        to: toSqr,
                        promotion: -piece,
                    })
                }
            } else {
                moves.push(move)
            }
        })
        if (piece === Piece.WhitePawn) {
            directionScan(pos, sqr, -1, -1, 1, false, null, (toSqr: Square) => {
                if (toSqr === pos.enPassant) {
                    moves.push({
                        type: MoveType.EnPassant,
                        from: sqr,
                        to: toSqr,
                    })
                }
            })
            directionScan(pos, sqr, 1, -1, 1, false, null, (toSqr: Square) => {
                if (toSqr === pos.enPassant) {
                    moves.push({
                        type: MoveType.EnPassant,
                        from: sqr,
                        to: toSqr,
                    })
                }
            })
        } else if (piece === Piece.BlackPawn) {
            directionScan(pos, sqr, -1, 1, 1, false, null, (toSqr: Square) => {
                if (toSqr === pos.enPassant) {
                    moves.push({
                        type: MoveType.EnPassant,
                        from: sqr,
                        to: toSqr,
                    })
                }
            })
            directionScan(pos, sqr, 1, 1, 1, false, null, (toSqr: Square) => {
                if (toSqr === pos.enPassant) {
                    moves.push({
                        type: MoveType.EnPassant,
                        from: sqr,
                        to: toSqr,
                    })
                }
            })
        }
        if (
            piece === Piece.WhiteKing &&
            sqr === Square.E1 &&
            pos.castling.whiteShort &&
            pos.board[Square.F1] === null &&
            pos.board[Square.G1] === null &&
            !isAttacked(pos, Square.E1, Color.Black) &&
            !isAttacked(pos, Square.F1, Color.Black) &&
            !isAttacked(pos, Square.G1, Color.Black)
        ) {
            moves.push({
                type: MoveType.Castling,
                from: sqr,
                to: Square.G1,
            })
        }
        if (
            piece === Piece.WhiteKing &&
            sqr === Square.E1 &&
            pos.castling.whiteLong &&
            pos.board[Square.D1] === null &&
            pos.board[Square.C1] === null &&
            pos.board[Square.B1] === null &&
            !isAttacked(pos, Square.E1, Color.Black) &&
            !isAttacked(pos, Square.D1, Color.Black) &&
            !isAttacked(pos, Square.C1, Color.Black)
        ) {
            moves.push({
                type: MoveType.Castling,
                from: sqr,
                to: Square.C1,
            })
        }
        if (
            piece === Piece.BlackKing &&
            sqr === Square.E8 &&
            pos.castling.blackShort &&
            pos.board[Square.F8] === null &&
            pos.board[Square.G8] === null &&
            !isAttacked(pos, Square.E8, Color.White) &&
            !isAttacked(pos, Square.F8, Color.White) &&
            !isAttacked(pos, Square.G8, Color.White)
        ) {
            moves.push({
                type: MoveType.Castling,
                from: sqr,
                to: Square.G8,
            })
        }
        if (
            piece === Piece.BlackKing &&
            sqr === Square.E8 &&
            pos.castling.blackLong &&
            pos.board[Square.D8] === null &&
            pos.board[Square.C8] === null &&
            pos.board[Square.B8] === null &&
            !isAttacked(pos, Square.E8, Color.White) &&
            !isAttacked(pos, Square.D8, Color.White) &&
            !isAttacked(pos, Square.C8, Color.White)
        ) {
            moves.push({
                type: MoveType.Castling,
                from: sqr,
                to: Square.C8,
            })
        }
    }

    let max = 0
    for (const i in moves) {
        const nextPos = makeMove(pos, moves[i])
        nextPos.turn = -nextPos.turn
        if (isInCheck(nextPos)) {
            continue
        }
        moves[max++] = moves[i]
    }
    moves.splice(max, moves.length - max)

    return moves
}

export function isCheckmate(pos: Position): boolean {
    return isInCheck(pos) && generateMoves(pos).length === 0
}

export function isStalemate(pos: Position): boolean {
    return generateMoves(pos).length === 0 && !isInCheck(pos)
}
export function generatePremoves(pos: Position): Move[] {
    const moves: Move[] = []
    for (const move of generateMoves(pos)) {
        const nextPos = makeMove(pos, move)
        for (const nextMove of generateMoves(nextPos)) {
            moves.push(nextMove)
        }
    }
    return moves
}

export function generateSinglePremove(pos: Position, from: Square): Move[] {
    const moves: Move[] = []
    for (const move of generatePremoves(pos)) {
        if (move.from === from) {
            moves.push(move)
        }
    }
    return moves
}
