import { Box, CircularProgress, Dialog, DialogContent, Paper, Typography } from '@mui/material'
import { colors } from '../../../sharedComponents/src/globalHeader/theme/colors'
const mainMenuStyles = {
    '& .MuiDialog-paperScrollPaper': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '@media (max-width: 600px)': {
            display: 'grid',
        },
    },
}

const boxStyles = {
    margin: '0',
    backgroundColor: colors.darkBlue,
    justifySelf: 'center',
    alignSelf: 'center',
    maxWidth: '35.938rem',
    width: '100%',
    border: `0.313rem solid ${colors.white}`,
    borderRadius: '0rem',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '48px',
    height: '100%',
    justifyContent: 'center',
    flexGrow: 1,
}

const dialogStyles = {
    position: 'relative',
    height: 'auto',
    overflow: 'hidden',
    marginRight: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
}

const innerBoxStyles = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '24px',
}

const progressStyles = {
    color: 'white',
    height: '5rem !important',
    width: '5rem !important',
}

// TODO: needs a full design
export interface LoadingBoxProps {
    dialog: boolean
}
export const LoadingBox = (prop: LoadingBoxProps) => {
    const { dialog } = prop
    const loadingBox = (
        <Paper sx={boxStyles}>
            <Typography variant="h5" textAlign="center">
                Opponent Connecting...
            </Typography>
            <Box sx={innerBoxStyles}>
                <CircularProgress sx={progressStyles} />
            </Box>
        </Paper>
    )

    return dialog ? (
        <Dialog
            open={true}
            className={'mainMenu'}
            BackdropProps={{
                onClick: undefined,
            }}
            disableEnforceFocus
            sx={mainMenuStyles}
        >
            <DialogContent sx={dialogStyles}>{loadingBox}</DialogContent>
        </Dialog>
    ) : (
        loadingBox
    )
}
